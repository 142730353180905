import { InputBase, styled } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: 'var(--fontColor1)',
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 3,
    position: "relative",
    border: "1px solid rgba(207, 207, 207, 0.05)",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderColor: "#6C5ECF",
    },
  },
}));
