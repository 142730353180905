export const getExtensionFromMimeType = (mimeType: string) => {

    if (!mimeType) {
        return ''
    }

    const mType = mimeType.toLocaleLowerCase();
    switch (mType)
    {
        case 'image/svg+xml': return 'svg'
        default: return mType.split('/')[1] ?? '';
    }
}