import { RedactEditorState } from "../../context/RedactEditorContext/types";
import DETECTION_TYPE from "../../enums/detectionType";
import { ApiResponse } from "../../types/apiResponse";
import { IApi } from "../../types/apiTypes";
import { DetectedObject } from "../../types/object";
import {
  ProjectDetail,
  ProjectHeartbeat,
  ProjectStatus,
  ProjectStatusResponse,
  SaveProjectPayload,
  UpdateProjectResponse,
} from "../../types/project";
import { GetProjectResponse } from "../../types/projectApiResponse";
import { randomColorHex } from "../../utils/redact-util";

const getSaveProjectData = (
  applyRedaction: boolean,
  state: RedactEditorState
): SaveProjectPayload => {
  return {
    applyRedaction,
    name: state.name,
    description: state.description,
    confidence: state.confidence,
    detectFace: state.detectFace,
    detectLicensePlate: state.detectLicensePlate,
    detectWeapon: state.detectWeapon,
    detectedObjects: state.detectedObjects
      .filter((x) => x.modified)
      .map((x) => ({
        name: x.name,
        description: x.description,
        classId: x.classId,
        objectId: x.objectId,
        sequence: x.sequence,
        redact: x.redact,
        duplicates: x.duplicates,
        startTime: x.startTime,
        endTime: x.endTime,
        detectionType: x.detectionType,
        frames: x.frames,
      })),
    audioChannels: state.channels,
    deletedObjectIds: state.deletedObjects,
    transcriptionUpdates: state.transcriptionUpdates,
    trackingSession: state.trackingSession,
    frameVersion: state.frameVersions[state.frameVersions.length - 1]?.version,
    videoRedactionType: state.videoRedactionType,
  };
};

export const updateProject = (
  api: IApi,
  applyRedaction: boolean,
  state: RedactEditorState
): Promise<UpdateProjectResponse> => {
  const payload: SaveProjectPayload = getSaveProjectData(applyRedaction, state);
  return api
    .post(`redact/${state.projectId}/update`, payload)
    .then((result) => {
      return result.data;
    });
};

export const deleteProject = (api: IApi, projectId: string) => {
  const url = `redact/${projectId}/delete`;
  return api.remove(url);
};

export const getProjectList = (
  api: IApi,
  groupId: string,
  keyword?: string,
  page: number = 1,
  folderId?: string
) => {
  const url = `redact/list?page=${page}&keyword=${keyword}&groupId=${groupId}${
    folderId ? "&folderId=" + folderId : ""
  }`;
  return api.get(url);
};

export const getProjectStatuses = (
  api: IApi,
  groupId: string,
  projectIds: string[]
): Promise<Array<ProjectStatusResponse>> => {
  let ids = "";
  projectIds.every((item) => {
    ids += `&projectIds=${item}`;
    return true;
  });
  const url = `redact/status/list?groupId=${groupId}${ids}`;
  return api.get(url).then((result: ApiResponse<Array<ProjectStatusResponse>>) => {
    if (result) {
      return result.data;
    }
    return null;
  });
};

export const createProject = (api: IApi, formData: FormData) => {
  return api
    .postForm("redact/create", formData)
    .then((result) => {
      if (!result) {
        return;
      }

      return result.data;
    })
    .catch((error) => {
      return null;
    });
};

export const retryCreateProject = (api: IApi, projectId: string) => {
  return api.post(`redact/${projectId}/retry/create`);
};

export const redactProject = (api: IApi, projectId: string) => {
  return api.post(`redact/${projectId}/preview`);
};

export const saveThumbnail = (
  api: IApi,
  formData: FormData,
  projectId: string
) => {
  return api
    .postForm(`redact/${projectId}/thumbnail`, formData)
    .then((result) => {
      if (!result) {
        return;
      }
      return result.data;
    });
};

export const getProject = (
  api: IApi,
  projectId: string
): Promise<ProjectDetail> => {
  return api
    .get(`redact/${projectId}`)
    .then((result: ApiResponse<GetProjectResponse>) => {
      if (!result || !result.data) {
        return undefined;
      }

      const detectedObjects: DetectedObject[] = result.data.detectedObjects.map(
        (x) => {
          return {
            objectId: x.objectId,
            sequence: x.sequence,
            classId: x.classId,
            thumbnailUrl: x.thumbnailUrl,
            name: x.name,
            description: x.description,
            redact: x.redact,
            color: randomColorHex(),
            duplicates: x.duplicates,
            startTime: x.startTime,
            endTime: x.endTime,
            frames: x.frames,
            detectionType: x.detectionType ?? DETECTION_TYPE.AUTO,
          };
        }
      );

      return {
        name: result.data.name,
        description: result.data.description,
        thumbnailUrl: result.data.thumbnailUrl,
        previewUrl: result.data.previewUrl,
        transcribeUrl: result.data.transcribeUrl,
        folderId: result.data.folderId,
        videoUrl: result.data.videoUrl,
        audioPeakUrls: result.data.audioPeakUrls,
        originalFileExtension: result.data.originalFileExtension,
        confidence: result.data.confidence,
        detectFace: result.data.detectFace,
        transcriptionState: result.data.transcriptionState,
        videoRedactionState: result.data.videoRedactionState,
        audioRedactionState: result.data.audioRedactionState,
        detectLicensePlate: result.data.detectLicensePlate,
        videoRedactionType: result.data.videoRedactionType,
        detectWeapon: result.data.detectWeapon,
        frameRate: result.data.frameRate,
        frameCount: result.data.frameCount,
        frameHeight: result.data.frameHeight,
        frameWidth: result.data.frameWidth,
        detectedObjects,
        regionOfInterest: result.data.regionOfInterest,
        audioChannels: (result.data.audioChannels || []).map((x) => {
          return {
            id: x.id,
            name: x.name,
            url: result.data.videoUrl,
            segments: (x.segments || []).map((s) => {
              return {
                id: s.id,
                name: s.name,
                blip: s.blip,
                mute: s.mute,
                startTime: s.startTime,
                endTime: s.endTime,
              };
            }),
          };
        }),
      };
    });
};

export const downloadProject = (api: IApi, projectId: string, name: string) => {
  const url = `media/project/${projectId}/download`;
  const urlCreator = window.URL || window.webkitURL;
  return api.getFile(url).then((blob) => {
    if (!blob) {
      return;
    }
    const url = urlCreator.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });
};

export const getProjectStatus = (
  api: IApi,
  projectId: string
): Promise<ProjectStatus> => {
  return api
    .get(`redact/${projectId}/status`)
    .then((result: ApiResponse<ProjectStatus>) => {
      if (result) {
        return result.data;
      }

      return null;
    });
};

export const getProjectHeartbeat = (
  api: IApi,
  projectId: string
): Promise<ProjectHeartbeat> => {
  return api
    .get(`redact/${projectId}/heartbeat`)
    .then((result: ApiResponse<ProjectHeartbeat>) => {
      if (result) {
        return result.data;
      }

      return null;
    });
};

export const enableVideoRedaction = (
  api: IApi,
  projectId: string,
  detectFace: boolean
) => {
  return api.post(`redact/${projectId}/detect?face=${detectFace}`);
};

export const enableAudioRedaction = (api: IApi, projectId: string) => {
  return api.post(`redact/${projectId}/audiowave`);
};

export const transcribeProject = (api: IApi, projectId: string) => {
  return api.post(`transcribe/${projectId}`);
};
