import { HeadCell } from "../../types/table";

export const usersTableHeaders: readonly HeadCell[] = [
  {
    id: "s/n",
    label: "#",
  },
  {
    id: "fullName",
    label: "Name",
    sort: "FullName",
  },
  {
    id: "roleName",
    label: "Workspace Role",
    sort: "RoleName",
  },
  {
    id: "email",
    label: "Email Address",
    sort: "Email",
  },
  {
    id: "action",
    label: "",
  },
];

export const workspaceDeviceHeaders: readonly HeadCell[] = [
  {
    id: "s/n",
    label: "#",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "identifier",
    label: "Identifier",
  },
  {
    id: "identifierType",
    label: "Identifier Type",
  },
  {
    id: "action",
    label: "",
  },
];
