import { useState, useEffect, useContext } from "react";
import "./Admin.scss";
import {
  Button,
  Paper,
  InputLabel,
  FormControl,
  Grid,
  Checkbox,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { cloneDeep } from "lodash";
import { ArrowBackIos, Replay } from "@material-ui/icons";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useHistory, useParams, Prompt } from "react-router-dom";
import { BootstrapInput } from "../../components/InfoNameInput";
import { workspaceDeviceHeaders, workspaceSettingHeaders } from "../../components/Admin/TableHeader";
import Table from "../../components/Table/Table";
import {
  addGroup,
  getGroupUsers,
  updateGroup,
  updateLicenseKey,
  getPartnerInfo
} from "../../services/api/workspaceApi";
import useApi from "../../hooks/useApi";
import { IDevice } from "../../types/device";
import AddEditUserModal from "../../components/Workspace/UserList/AddEditUserModal";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete-icon.svg";
import { toast } from "react-toastify";
import DeleteModal from "../../components/DeleteModal";
import AppContext from "../../context/AppContext";
import { InputAdornment, Tooltip } from "@material-ui/core";
import AddEditDeviceModal from "../../components/Workspace/DeviceList/AddEditDeviceModal";
import { IGroupPayload } from "../../types/workspace";
import ConfirmationModal from "../../components/ConfirmationModal";
import INTEGRATED_PLATFORM_TYPE from "../../enums/integratedPlatformType";
import useDebounce from "../../hooks/useDebounce";

const AddEditWorkspace = () => {
  const history = useHistory();
  const { reloadWorkspace, setReloadWorkspace } = useContext(AppContext);
  const { id } = useParams() as { id: string };
  const { api, apiToken } = useApi();
  const [loading, setLoading] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [formData, setFormData] = useState<IGroupPayload>({
    displayName: "",
    members: [],
    redact: false,
    transcribe: false,
    seatCount: 0,
    addProjectFromWebApp: true,
    enableMfa: true,
    devices: [],
    integratedPlatform: INTEGRATED_PLATFORM_TYPE.WEB as string
  });

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isModified, setModified] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [enableSettings, setEnableSettings] = useState(false);
  const [enableDevices, setEnableDevices] = useState(false);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const [editDeviceData, setEditDeviceData] = useState(null);
  const [isEditDevice, setDeviceEdit] = useState(false);
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [openLicenseKeyModal, setOpenLicenseKeyModal] = useState(false);
  const debouncedExternalId = useDebounce(formData.externalId, 2000);

  const handleVisibleModal = () => {
    setOpenModal(!openModal);
    openModal && setEditData(null);
  };

  const handleDeviceVisibleModal = () => {
    setOpenDeviceModal(!openDeviceModal);
    openDeviceModal && setEditDeviceData(null);
  }


  const handlePlaformChange = (platform: INTEGRATED_PLATFORM_TYPE) => {

    if (platform == INTEGRATED_PLATFORM_TYPE.MILESTONE)
      setFormData({ ...formData, ['addProjectFromWebApp']: false, ['enableMfa']: false, ['integratedPlatform']: platform });
    else
      setFormData({ ...formData, ['addProjectFromWebApp']: true, ['enableMfa']: true, ['integratedPlatform']: platform });

  }

  useEffect(() => {
    if (!apiToken || !id) return;
    getGroupUsers(api, id).then((result) => {
      const data = {
        displayName: result.displayName,
        redact: result.redact,
        transcribe: result.transcribe,
        addProjectFromWebApp: result.addProjectFromWebApp,
        enableMfa: result.enableMfa,
        seatCount: result.seatCount,
        externalId: result.externalId,
        externalName: result.externalName,
        integratedPlatform: result.integratedPlatform,
        members: result.members.map((item) => {
          return {
            ...item,
            roleName: item.role,
          };
        }),
        licenseKey: result.licenseKey,
        devices: result.devices
      };
      setFormData(data);
    });
  }, [id, apiToken, reload]);

  useEffect(() => {
    if (!debouncedExternalId) return;

    handleGetPartnerInfo(debouncedExternalId)
  }, [debouncedExternalId]);

  const handleBack = () => {
    history.push("/admin?tab=workspaces");
  };

  const getTableData = () => {
    return formData?.members?.length
      ? formData.members?.map((row: any, index: number) => {
        return {
          "s/n": index + 1,
          id: row.id,
          fullName: row.firstName ? `${row.firstName} ${row.lastName}` : "",
          role: (
            <div
              className={
                row?.roleName === "Admin" ? "adminRoles" : "memberRoles"
              }
            >
              {row?.roleName || "N/A"}
            </div>
          ),
          email: row.email,
          admin: (
            <Checkbox
              checked={row?.roleName === "Admin"}
              onChange={(e) => handleOnChangeAdmin(e.target.checked, index)}
            />
          ),
          action: (
            <div>
              {!row?.addedByEmail && (
                <IconButton
                  onClick={() => {
                    setEdit(true);
                    setEditData({ ...row, role: row.roleName, key: index });
                    handleVisibleModal();
                  }}
                  aria-label="edit"
                  className="btn-edit btn"
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDeleteModal(true);
                  setEditData(row);
                }}
                aria-label="delete"
                className="btn-delete btn"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        };
      })
      : [];
  };

  const getDeviceTableData = () => {
    return formData?.devices.length
      ? formData.devices?.map((row: IDevice, index: number) => {
        return {
          "s/n": index + 1,
          id: row.id,
          name: row.name ? row.name : "",
          identifier: row.identifier ? row.identifier : "",
          identifierType: row.identifierType ? row.identifierType : "",
          action: (
            <div>
              <IconButton
                onClick={() => {
                  setDeviceEdit(true);
                  setEditDeviceData({ ...row, key: index })
                  handleDeviceVisibleModal();
                }}
                aria-label="edit"
                className="btn-edit btn"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDeleteDeviceModal(true);
                  setEditDeviceData({ ...row, key: index });
                }}
                aria-label="delete"
                className="btn-delete btn"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )
        }
      })
      : [];
  };

  const deleteUser = () => {
    const members = cloneDeep(formData.members);
    const memberId = members.find((item) => item.email === editData.email)?.id;
    setFormData(
      Object.assign(
        {
          ...formData,
          members: members.filter((item) => item.email !== editData.email),
        },
        id &&
        memberId && {
          deleteUserIds: formData.deleteUserIds
            ? [...formData.deleteUserIds, memberId]
            : [memberId],
        }
      )
    );
    setEditData(null);
    setOpenDeleteModal(false);
    setModified(true);
  };

  const deleteDevice = () => {
    const devices = cloneDeep(formData.devices);

    if (editDeviceData.id) {
      setFormData(
        Object.assign(
          {
            ...formData,
            devices: devices.filter((item) => item.id !== editDeviceData.id),
          },
          id && editDeviceData.id && {
            deleteDeviceIds: formData.deleteDeviceIds
              ? [...formData.deleteDeviceIds, editDeviceData.id]
              : [editDeviceData.id]
          }
        )
      )
    } else {
      setFormData(
        Object.assign(
          {
            ...formData,
            devices: devices.filter((item, index) => index != editDeviceData.key),
          }
        )
      )
    }

    setEditDeviceData(null);
    setOpenDeleteDeviceModal(false);
    setModified(true);
  };

  const handleSave = () => {
    setLoading(true);
    if (id) {
      const formDataPayload = { ...formData };

      if (typeof formDataPayload.seatCount == "string") {
        formDataPayload.seatCount = parseInt(formDataPayload.seatCount);
      }

      formDataPayload.members = formDataPayload.members
        .map((item) => {
          if (item.isModified) {
            delete item.isModified;
            return item;
          }
        })
        .filter(Boolean);

      formDataPayload.devices = formDataPayload.devices
        .map((item) => {
          if (item.isModified) {
            delete item.isModified;
            return item;
          }
        })
        .filter(Boolean);

      updateGroup(api, formDataPayload, id)
        .then((response) => {
          if (!response.success) {
            toast(response.message, { type: "error" });
          } else {
            setModified(false);
            toast(`Workspace saved successfully`, { type: "success" });
            setReload(!reload);
            setReloadWorkspace(!reloadWorkspace);
          }
          setLoading(false);
        })
        .catch((e) => {
          toast(e?.message ?? `Something went wrong`, {
            type: "error",
          });
          setLoading(false);
          setModified(false);
        });
    } else {

      const formDataPayload = { ...formData };

      if (typeof formDataPayload.seatCount == "string") {
        formDataPayload.seatCount = parseInt(formDataPayload.seatCount);
      }

      addGroup(api, formDataPayload)
        .then((response) => {
          if (!response.success) {
            toast(response.message, { type: "error" });
          } else {
            setModified(false);
            if (response?.data?.id) {
              setReloadWorkspace(!reloadWorkspace);
              history.push(`/admin/workspace/create/${response.data.id}`);
            }
            toast(`Workspace saved successfully`, { type: "success" });
          }
          setLoading(false);
        })
        .catch((e) => {
          toast(e?.message ?? `Something went wrong`, {
            type: "error",
          });
          setLoading(false);
          setModified(false);
        });
    }
  };

  const onChangeHandle = (name, value) => {
    setModified(true);

    setFormData({ ...formData, [name]: value });
  };

  const handleAddUser = (data) => {
    const tableData = [...formData.members];
    tableData.push({ ...data, isModified: true });
    setFormData({ ...formData, members: tableData });
    setModified(true);
  };

  const handleGetPartnerInfo = (partnerId: string) => {
    if (!apiToken) return;

    setLoadingCompany(true);
    getPartnerInfo(api, id, { partnerRecordId: partnerId })
      .then((result) => {
        setFormData({
          ...formData,
          externalName: result.data
        });
        setLoadingCompany(false);
      });
  };

  const handleAddDevice = (data) => {
    const tableData = [...formData.devices];

    if (tableData.length + 1 > formData.seatCount) {
      toast("Workspace devices can't exceed seat count", { type: 'error' });
    } else {
      tableData.push({ ...data, isModified: true });
      setFormData({ ...formData, devices: tableData });
      setModified(true);
    }
  }

  const handleOnChangeAdmin = (checked, selectedIndex) => {
    const tableData = [...formData.members].map((item, i) => {
      if (i === selectedIndex) {
        item.roleName = checked ? "Admin" : "Member";
        item.isModified = true;
      }
      return item;
    });
    setFormData({ ...formData, members: tableData });
    setModified(true);
  };

  const handleEditUser = (data, key) => {
    const tableData = [...formData.members].map((item, index) => {
      if (index === key) {
        delete data.role;
        data.isModified = true;
        item = data;
      }
      return item;
    });
    setFormData({ ...formData, members: tableData });
    setEditData(null);
    setModified(true);
  };

  const handleEditDevice = (data, key) => {
    const tableData = [...formData.devices].map((item, index) => {
      if (index === key) {
        data.isModified = true;
        item = data;
      }
      return item;
    });
    setFormData({ ...formData, devices: tableData });
    setEditDeviceData(null);
    setModified(true);
  }

  const refreshLicenseToken = () => {
    updateLicenseKey(api, id).then((result) => {
      setFormData({
        ...formData,
        licenseKey: result
      });
    });
  }

  const copyLicenseKey = () => {
    if (formData?.licenseKey) {
      navigator.clipboard.writeText(formData.licenseKey);
      toast("License key copied to clipboard.", {
        type: "success",
        autoClose: 1500
      });
    }
  }

  return (
    <div className="add-workspace">
      <div className="header">
        <div className="left-wrapper">
          <div className="icon-wrapper" onClick={handleBack}>
            <ArrowBackIos />
          </div>
          <span className="page-title">
            {id ? "Edit Workspace" : "Create Workspace"}
          </span>
        </div>
        <div>
          <Button
            className={`setting-button btn btn-primary ${!enableSettings && !enableDevices && 'active'}`}
            variant="contained"
            onClick={() => {
              setEnableDevices(false);
              setEnableSettings(false);
            }}
          >
            Users
          </Button>
          <Button
            className={`add-workspace-button btn btn-primary ${enableDevices && 'active'}`}
            variant="contained"
            onClick={() => {
              setEnableSettings(false);
              setEnableDevices(true);
            }}>
            Devices
          </Button>
          <Button
            className={`add-workspace-button btn btn-primary ${enableSettings && 'active'}`}
            variant="contained"
            onClick={() => {
              setEnableDevices(false);
              setEnableSettings(true);
            }}
          >
            Settings
          </Button>
          <Button
            className="save-button btn btn-primary"
            variant="contained"
            onClick={handleSave}
            disabled={loading || !formData.displayName}
          >
            {loading && (
              <CircularProgress
                size={18}
                color="inherit"
                style={{ marginRight: 8 }}
              />
            )}
            Save
            <div
              style={{
                height: 15,
                width: 15,
                backgroundColor: "#ff0000",
                borderRadius: "50%",
                position: "absolute",
                top: -5,
                right: 5,
                display: isModified ? "block" : "none",
              }}
            />
          </Button>
        </div>
      </div>
      <Paper className="name-input-wrap">
        {!enableSettings && !enableDevices &&
          <Button
            className="add-button btn btn-primary"
            variant="contained"
            onClick={() => {
              setEdit(false);
              handleVisibleModal();
            }}
          >
            Add User
          </Button>
        }
        {enableDevices &&
          <Button
            className="add-button btn btn-primary"
            variant="contained"
            onClick={() => {
              setDeviceEdit(false);
              handleDeviceVisibleModal();
            }}
          >
            Add MFA Device
          </Button>
        }
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 12 }}
        >
          {enableSettings &&
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Enduser Workspace Name
                  </InputLabel>
                  <BootstrapInput
                    placeholder="Name"
                    className="project-input"
                    id="displayName"
                    name="displayName"
                    value={formData.displayName}
                    onChange={(e) => onChangeHandle("displayName", e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Partner Id
                  </InputLabel>
                  <BootstrapInput
                    disabled
                    placeholder="Enter hubspot company id"
                    className="project-input"
                    id="externalId"
                    name="externalId"
                    value={formData.externalId}
                    // onChange={(e) => onChangeHandle("externalId", e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Partner Name
                  </InputLabel>
                  <BootstrapInput
                    disabled
                    className="project-input"
                    id="externalName"
                    name="externalName"
                    value={formData.externalName}
                    color="primary"
                    endAdornment = {loadingCompany ? (
                      <CircularProgress
                        size={18}
                        color="inherit"
                        style={{ marginLeft: "-20px" }}
                      />
                    ) : null}
                  />

                </FormControl>
              </Grid>
            </>



          }
          {id && enableSettings && <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
            <FormControl fullWidth variant="standard">
              <InputLabel className="" shrink>
                License Key
              </InputLabel>
              <BootstrapInput
                placeholder="License Key"
                className="project-input"
                id="licenseKey"
                name="licenseKey"
                readOnly
                value={formData.licenseKey}
                onChange={(e) => onChangeHandle("licenseKey", e.target.value)}
                required
                startAdornment={
                  <InputAdornment
                    position="start"
                  >
                    <Tooltip
                      title="Copy license key"
                    >
                      <IconButton
                        aria-label="copy license key"
                        onClick={copyLicenseKey}
                        edge="start"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>

                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment
                    position="end"
                  >
                    <Tooltip
                      title="Regenerate license key"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenLicenseKeyModal(true)
                        }}
                        edge="end"
                      >
                        <Replay />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          }
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 12 }}
          className="settings"
        >
          {enableSettings &&
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Enable Features
                  </InputLabel>

                </FormControl>

                <FormControlLabel
                  className="project-checkbox"
                  control={
                    <Checkbox
                      checked={formData.redact}
                      onChange={(e) => {
                        onChangeHandle(e.target.name, e.target.checked);
                      }}
                      className="chk-box-color"
                      name="redact"
                    />
                  }
                  label="Redact"
                />
                <FormControlLabel
                  className="project-checkbox"
                  control={
                    <Checkbox
                      checked={formData.transcribe}
                      onChange={(e) =>
                        onChangeHandle(e.target.name, e.target.checked)
                      }
                      className="chk-box-color"
                      name="transcribe"
                    />
                  }
                  label="Transcribe"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Access Restrictions
                  </InputLabel>
                </FormControl>
                <FormControlLabel
                  className="project-checkbox"
                  control={
                    <Checkbox
                      checked={formData.addProjectFromWebApp}
                      disabled={formData.integratedPlatform == INTEGRATED_PLATFORM_TYPE.MILESTONE}
                      onChange={(e) =>
                        onChangeHandle(e.target.name, e.target.checked)
                      }
                      className="chk-box-color"
                      name="addProjectFromWebApp"
                    />
                  }
                  label="Add Project"
                />
                <FormControlLabel
                  className="project-checkbox"
                  control={
                    <Checkbox
                      checked={formData.enableMfa}
                      disabled={formData.integratedPlatform == INTEGRATED_PLATFORM_TYPE.MILESTONE}
                      onChange={(e) =>
                        onChangeHandle(e.target.name, e.target.checked)
                      }
                      className="chk-box-color"
                      name="enableMfa"
                    />
                  }
                  label="Enable MFA"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Integrated Platforms
                  </InputLabel>
                </FormControl>

                <FormControl
                  className="project-checkbox">
                  <RadioGroup
                    aria-labelledby="integrated-platform-type-label"
                    defaultValue={INTEGRATED_PLATFORM_TYPE.WEB}
                    style={{ flexDirection: "row" }}
                    name="integratedPlatformType"
                    value={formData.integratedPlatform}
                    className="radio-buttons"
                    onChange={(e) =>
                      handlePlaformChange(e.target.value as INTEGRATED_PLATFORM_TYPE)
                    }
                  >
                    <FormControlLabel value={INTEGRATED_PLATFORM_TYPE.WEB} control={<Radio className="chk-box-color" />} label="Web" />
                    <FormControlLabel value={INTEGRATED_PLATFORM_TYPE.MILESTONE} control={<Radio className="chk-box-color" />} label="Milestone" />
                  </RadioGroup>
                </FormControl>

              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    No. of Seats
                  </InputLabel>
                  <BootstrapInput
                    placeholder="Maximum number of users allowed in this workspace"
                    className="project-input"
                    id="seatCount"
                    name="seatCount"
                    type="number"
                    value={formData.seatCount}
                    onChange={(e) => onChangeHandle("seatCount", e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>
            </>
          }
        </Grid>
      </Paper>
      <div className="table-wrapper">
        {
          !enableSettings && !enableDevices &&
          <Table tableHeaders={workspaceSettingHeaders} rows={getTableData()} />
        }
        {
          enableDevices &&
          <Table tableHeaders={workspaceDeviceHeaders} rows={getDeviceTableData()} />
        }
      </div>
      {openModal && (
        <AddEditUserModal
          open={openModal}
          isEdit={isEdit}
          onClose={handleVisibleModal}
          editData={editData}
          handleAddUser={handleAddUser}
          handleEditUser={handleEditUser}
          workspace={id}
          workspaceName={formData.displayName}
          isAdminWorkspace
        />
      )}
      {openDeviceModal && (
        <AddEditDeviceModal
          open={openDeviceModal}
          isEdit={isEditDevice}
          onClose={handleDeviceVisibleModal}
          editData={editDeviceData}
          handleAddevice={handleAddDevice}
          handleEditDevice={handleEditDevice}
          workspace={id}
          isAdminWorkspace
        />
      )}
      <Prompt
        when={isModified}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onConfirm={deleteUser}
          title="Delete User"
          contentText={`Are you sure you want to delete ${editData?.fullName}?`}
        />
      )}
      {openDeleteDeviceModal && (
        <DeleteModal
          open={openDeleteDeviceModal}
          onClose={() => setOpenDeleteDeviceModal(false)}
          onConfirm={deleteDevice}
          title="Delete Device"
          contentText={`Are you sure you want to delete ${editDeviceData?.name}?`}
        />
      )}

      {
        <ConfirmationModal
          open={openLicenseKeyModal}
          onClose={() => setOpenLicenseKeyModal(false)}
          onConfirm={refreshLicenseToken}
          title="Regenerate License Key"
          contentText="You are about to regenerate this license key. This will make the existing license key non-usable for all users. This change is immediate and non-revocable"
        />
      }
    </div>
  );
};

export default AddEditWorkspace;

