import { useEffect, useReducer, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import AppContext from "..";
import { AppState, WorkspaceInfo } from "../types"
import useWorkspace from '../../../hooks/useWorkspace';
import { useMsal } from "@azure/msal-react";
import { ProjectNotification } from "../../../types/notification";
import useHubConnection from "../../../hooks/notifications/useHubConnection";
import useNotificationCallback from "../../../hooks/notifications/useNotificationCallback";
import { IRedactProjectFolder } from "../../../types/redactProjectFolder";
import { IUserPreference } from "../../../types/user";
import useUser from "../../../hooks/useUser";

interface AppContextProviderProps {
    children: React.ReactNode;
}

const AppReducer: React.Reducer<
    AppState,
    Partial<AppState>
> = (state, action) => {
    return { ...state, ...action };
};

const AppContextProvider: React.FC<AppContextProviderProps>
    = ({ children }): React.ReactElement => {
        const [reloadWorkspace, setReloadWorkspace] = useState(false);

        const setFolders = (folders: IRedactProjectFolder[]) => {
            const newState: Partial<AppState> = {
                ...state,
                folders
            }

            dispatch(newState);
        }

        const setWorkspace = (workspaceInfo: WorkspaceInfo): void => {
            const newState: Partial<AppState> = Object.assign(
              {
                ...state,
                ...workspaceInfo,
              },
              workspaceInfo.isAcessAdmin && { isAcessAdmin: workspaceInfo.isAcessAdmin }
            );
            dispatch(newState);
          };

        const setLatestNotification = (notification: ProjectNotification) => {
            const newState: Partial<AppState> = {
                ...state,
                notification
            }

            dispatch(newState);
        }

        const setUserPreferences = (userPreferences: IUserPreference) => {
            const newState: Partial<AppState> = {
                ...state,
                userPreferences
            }

            dispatch(newState);
        }

        const initialState: AppState = {
            setWorkspace,
            reloadWorkspace,
            setReloadWorkspace,
            setLatestNotification,
            setFolders,
            setUserPreferences
        };
        
        const [state, dispatch] = useReducer(AppReducer, initialState);
        const { instance } = useMsal();
        
        const hubConnection = useHubConnection();
        const pollConnection = useNotificationCallback();
        useEffect(() => {
            
            let connection;
            if (hubConnection) {
                connection = hubConnection;
            } else if (pollConnection) {
                connection = pollConnection;
            }
            
            const newState: Partial<AppState> = {
                ...state,
                hubConnection: connection
            }
            dispatch(newState);
        }, [hubConnection, pollConnection])
        
        const workspaceDetail = useWorkspace(reloadWorkspace);
        useEffect(() => {
            if (workspaceDetail) {
                const newState: Partial<AppState> = {
                    ...state,
                    ...workspaceDetail
                }
                dispatch(newState)
            }
        }, [workspaceDetail])
        

        useEffect(() => {
            const newState: Partial<AppState> = {
                ...state,
                reloadWorkspace,
            }
            dispatch(newState)
        }, [reloadWorkspace])

        const userPreferencesDetail = useUser();
        useEffect(() => {
            if (userPreferencesDetail) {

                const newState: Partial<AppState> = {
                    ...state,
                    userPreferences: userPreferencesDetail
                }

                dispatch(newState)
            }
        }, [userPreferencesDetail])

        const sessionTimeout: number = Number(process.env.REACT_APP_SESSION_TIMEOUT) || 20;
        const idleTimer = useIdleTimer({
            timeout: 60000 * sessionTimeout,
            onIdle: () => {
                instance.logoutRedirect().then(() => {
                }).catch((e) => {
                console.error(e);
                });
            },
            debounce: 1000,
            crossTab: {
                emitOnAllTabs: true,
            },
        });

        useEffect(() => {
            if (!state.idleTimer) {
                const newState: Partial<AppState> = { idleTimer }
                dispatch(newState)
            }
        }, [idleTimer])

        return (
            <AppContext.Provider value={state}>
                {children}
            </AppContext.Provider>
        )
    }

export default AppContextProvider