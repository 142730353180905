import _ from 'lodash';
import DbRepository from "../../database/dbRepository";
import { DetectedObject } from "../../types/object";
import { getFrameTimeSegment } from '../../services/functions/frames';
import { FrameSegment } from '../../types/frame';

type FuncType = (updateObjects: DetectedObject[], deleteObjects: DetectedObject[]) => void
export type IntervalItem = {
    id: string,
    sequence: number,
    start: number,
    end: number,
    loadingStart: number,
    startLeft: number,
    endLeft: number,
    loadingLeft: number,
    color: string,
    timeOverlapCount: number
}

export const mapAndSortIntervals = (detectedObjects: DetectedObject[]) => {
    return detectedObjects.reduce((accumulator: IntervalItem[], detectedObject: DetectedObject) => {
        const intervalItem: IntervalItem = {
          id: detectedObject.objectId,
          sequence: detectedObject.sequence,
          start: detectedObject.startTime * 1000 ?? 0,
          end: detectedObject.endTime * 1000 ?? 1000,
          loadingStart: (detectedObject.trackingInfo?.startTimeSec ?? -1) * 1000,
          startLeft: 0,
          endLeft: 0,
          loadingLeft: 0,
          timeOverlapCount: 0,
          color: detectedObject.color
        };
      
        let low = 0;
        let high = accumulator.length - 1;
        let insertIndex = accumulator.length;
      
        while (low <= high) {
          const mid = Math.floor((low + high) / 2);
          if (accumulator[mid].start > intervalItem.start) {
            insertIndex = mid;
            high = mid - 1;
          } else {
            low = mid + 1;
          }
        }
      
        accumulator.splice(insertIndex, 0, intervalItem);
      
        return accumulator;
      }, []);
}

export const updateObjectDuration = async (
    current: DetectedObject,
    previous: DetectedObject,
    updateFunc: FuncType) => {

    const target = _.cloneDeep(current);
    //if object end time diff (between prev and current)
    if (current.endTime !== previous.endTime) {
        //find the object whose duration intersects with endTime. when such object is found,
        //it's end value should be set to endTime, and other objects after it should be removed from the array
        let intersectedIndex = -1;
        let segmentTimeSource = 0; //determines where the new frame data should be sourced from
        if (current.endTime > previous.endTime) { //duration was elongated by the end time
            segmentTimeSource = previous.endTime;
            intersectedIndex = current.frames.findIndex((range) => range.startTimeSec <= previous.endTime && range.endTimeSec >= previous.endTime);
        } else {
            segmentTimeSource = current.endTime;
            intersectedIndex = current.frames.findIndex((range) => range.startTimeSec <= current.endTime && range.endTimeSec >= current.endTime);
        }

        if (intersectedIndex !== -1) {
            const intersectedRange = target.frames[intersectedIndex];
            intersectedRange.endTimeSec = current.endTime;
            target.frames = target.frames.slice(0, intersectedIndex + 1);
        } else {
            //create a new frame from the repo
            //remove all user defined frames that appear before the new frame end time
            target.frames = target.frames.filter(x => x.startTimeSec > current.endTime);
            //create new frame
            const newFrame = await getEndFrameFromRepo(current.objectId, segmentTimeSource, previous.endTime, current.endTime);
            if (newFrame) {
                target.frames.push(newFrame);
            }
        }
    }

    if (current.startTime !== previous.startTime) {
        let intersectedIndex = -1;
        let segmentTimeSource = 0; //determines where the new frame data should be sourced from
        if (current.startTime < previous.startTime) {
            //find intersection with previous
            segmentTimeSource = previous.startTime;
            intersectedIndex = current.frames.findIndex((range) => range.startTimeSec <= previous.startTime && range.endTimeSec >= previous.startTime);
        } else {
            segmentTimeSource = current.startTime;
            intersectedIndex = current.frames.findIndex((range) => range.startTimeSec <= current.startTime && range.endTimeSec >= current.startTime);
        }

        if (intersectedIndex > -1) {
            const intersectedRange = target.frames[intersectedIndex];
            intersectedRange.startTimeSec = current.startTime;
            target.frames = target.frames.filter(x => x.startTimeSec >= current.startTime); //remove everything that occurs before new interval start
        } else {
            const newFrame = await getStartFrameFromRepo(current.objectId, segmentTimeSource, current.startTime, previous.startTime);
            if (newFrame) {
                target.frames.unshift(newFrame);
            }
        }
    }

    updateFunc([target], []);
}

const getEndFrameFromRepo = async (targetObjId:string, time: number, newStart: number, newEnd: number) => {
    const repository = new DbRepository('equatureStudioDB', 'frameDataStore', 'segment');
    const endSegmentKey = getFrameTimeSegment(time);
    const endSegment: FrameSegment = await repository.getById(endSegmentKey);
    if (endSegment) {
        const matchingFrames = endSegment.frames.filter(x =>
            x.objects.findIndex(o => o.objectId === targetObjId) > -1);
        
        const lastObjFrame = matchingFrames[matchingFrames.length - 1];
        if (lastObjFrame) {
            const frameObj = lastObjFrame.objects.find(x => x.objectId === targetObjId);
            return {
                classId: frameObj.classId.toString(),
                height: frameObj.height,
                width: frameObj.width,
                top: frameObj.top,
                left: frameObj.left,
                angle: 0,
                objectId: frameObj.objectId,
                startTimeSec: newStart,
                endTimeSec: newEnd
            }
        }
    }
}

const getStartFrameFromRepo = async (targetObjId:string, time: number, newStart: number, newEnd: number) => {
    const repository = new DbRepository('equatureStudioDB', 'frameDataStore', 'segment');
    const startSegmentKey = getFrameTimeSegment(time);
    const startSegment: FrameSegment = await repository.getById(startSegmentKey);
    if (startSegment) {
        const firstObjFrame = startSegment.frames.find(x =>
            x.objects.findIndex(o => o.objectId === targetObjId) > -1);

        if (firstObjFrame) {
            const frameObj = firstObjFrame.objects.find(x => x.objectId === targetObjId);
            return {
                classId: frameObj.classId.toString(),
                height: frameObj.height,
                width: frameObj.width,
                top: frameObj.top,
                left: frameObj.left,
                angle: 0,
                objectId: frameObj.objectId,
                startTimeSec: newStart,
                endTimeSec: newEnd
            }
        }
    }
}