import { Redirect, Route, Switch } from "react-router-dom";
import LicenseKeyAuthentication from "./pages/licensekeyauth";
import { authenticate } from "./services/api/authApi";
import useInterval from "./hooks/useInterval";
import useApi from "./hooks/useApi";
import { getLicenseKey, getLicenseKeyAccessToken } from "./services/functions/licenseKeyAuth";
import { appConfig } from "./appConfig";

function LicenseKeyAuthenticationWrapper({children}) {

    const { api} = useApi();
    useInterval(async () => {
        //get a new token every n interval to prevent token expiry
        const licenseKey = getLicenseKey();
        if (appConfig.IsLicenseKeyAuth && licenseKey) {
            const token = await authenticate(api, licenseKey);
            //If license key auth fails redirect to authentication page
            if (!token) {
                window.location.reload();
            }
        }
    }, 900000)

    const IsAuthenticated = (): boolean=> {
        if (!appConfig.IsLicenseKeyAuth) {
            return true;
        }

        return getLicenseKeyAccessToken();
    }

    //if auth method is license key and the user is authenticated return regular routes
    //else show sign in screen
    return IsAuthenticated() ? children :
        <Switch>
            <Route path="/license" exact component={LicenseKeyAuthentication} />
            <Route path="/license/:licenseKey" exact component={LicenseKeyAuthentication} />
            <Route path="/license/:licenseKey/project/:projectId" component={LicenseKeyAuthentication} />
            <Route path="/" exact component={LicenseKeyAuthentication} />
            <Redirect from='*' to='/' />
        </Switch>
}

export default LicenseKeyAuthenticationWrapper