import React, { useContext, FC, useState, useEffect, useCallback } from "react";
import { Button, InputBase } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import _ from "lodash";
import useApi from "../../hooks/useApi";
import { tableHeaders } from "./data";
import { IWorkspace } from "../../types/workspace";
import { ApiResponse } from "../../types/apiResponse";
import AppContext from "../../context/AppContext";
import '../admin/Admin.scss'
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tab from '@mui/material/Tab';
import { useHistory, useLocation } from "react-router-dom";
import UserList from "../../components/Workspace/UserList";
import DeviceList from "../../components/Workspace/DeviceList";

const BootstrapInput = styled(InputBase)(({ }) => ({}));

const Workspace: FC = () => {
  const { workspace, workspaceName, seatCount, isAcessAdmin } = useContext(AppContext);
  const [value, setValue] = useState("users");
  const [deviceCount, setDeviceCount] = useState(0);
  const [openDeviceModal, toggleDeviceModal] = useState(false);
  const [isDeviceEdit, setIsDeviceEdit] = useState(false);
  const [editDeviceData, setEditDeviceData] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const querySearch = new URLSearchParams(location.search);
  const tabValue = querySearch.get('tab');

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
      history.push(`/workspace?tab=${tabValue}`);
      return;
    }
    setValue('users');
    history.push(`/workspace?tab=users`);
  }, [tabValue]);

  const handleTabChange = (_: any, tabValue: React.SetStateAction<string>) => {
    setValue(tabValue);
    history.push(`/workspace?tab=${tabValue}`)
  };

  const handleOnCloseDeviceModal = () => {
    setIsDeviceEdit(false);
    toggleDeviceModal(false);
  }

  return (
    <div className="admin-wrapper">
      <div className="inline-title">
        <h2 className="page-title">
          <span className="">{workspaceName}</span>
          <span className="title-badge">{`Seats: ${seatCount == undefined ? 0 : seatCount}`}</span>{" "}
        </h2>
      </div>
      <div className="tab-content">
        <TabContext value={value}>
          <TabList
            variant="standard"
            value={value}
            onChange={handleTabChange}
            textColor="inherit"
            indicatorColor="primary"
            className="tab-list"
          >
            <Tab value="users" label="Users" />
            <Tab value="devices" label="Devices" />
          </TabList>
          <TabPanel value="users">
            <UserList />
          </TabPanel>
          <TabPanel value="devices">
            <DeviceList />
          </TabPanel>
        </TabContext>
      </div>
    </div >
  )
};

export default Workspace;
