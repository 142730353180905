import { LICENSE_KEY, LICENSE_KEY_ACCESS_TOKEN } from "../../constants/authentication"

export const getLicenseKey = () => localStorage.getItem(LICENSE_KEY);

export const saveLicenseKey = (licenseKey: string, accessToken: string) => {
    localStorage.setItem(LICENSE_KEY, licenseKey);
    setLicenseKeyAccessToken(accessToken);
}

export const getLicenseKeyAccessToken = () => {
    const itemStr = localStorage.getItem(LICENSE_KEY_ACCESS_TOKEN);

    if (!itemStr) {
        return null;
    }

    try {
        const item = JSON.parse(itemStr);
        const now = new Date();
    
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(LICENSE_KEY_ACCESS_TOKEN);
            return null;
        }
    
        return item.token;
    }
    catch(ex) {
        return null;
    }
}

export const clearLicenseKey = () => {
    localStorage.removeItem(LICENSE_KEY_ACCESS_TOKEN);
    localStorage.removeItem(LICENSE_KEY);
}

const setLicenseKeyAccessToken = (token) => {
    const hour = 60 * 60 * 1000;
    const now = new Date();
    const item = {
        token: token,
        expiry: now.getTime() + (3 * hour),
    };
    localStorage.setItem(LICENSE_KEY_ACCESS_TOKEN, JSON.stringify(item));
}