import React, {useEffect, useState, useCallback} from 'react';
import '../../../pages/admin/Admin.scss';
import {IconButton, Button, InputBase} from "@mui/material";
import {Search} from "@mui/icons-material";
import styled from "@emotion/styled";
import useApi from "../../../hooks/useApi";
import { getUsers, deleteUser } from "../../../services/api/userApi"
import { IUser } from "../../../types/user";
import { ApiResponse } from "../../../types/apiResponse";
import LoadingSpinner from "../../shared/LoadingScreen/index";
import Table from "../../Table/Table";
import { tableUserHeaders } from '../TableHeader'
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete-icon.svg";
import _ from "lodash";
import AddEditUserAdmin from "./AddEditUserAdmin";
import DeleteModal from "../../DeleteModal";
import { toast } from 'react-toastify';

const BootstrapInput = styled(InputBase)(({}) => ({}));

interface IProps {
    setReloadWorkspaces: (val: number) => void
}
const Users = ({ setReloadWorkspaces }: IProps) => {
    const { api, apiToken } = useApi();
    const [paginatedUsers, setPaginatedUser] = useState(null);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [reload, setReload] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        fetchGroupsData();
    }, [apiToken, page, pageSize, reload, searchTerm]);

    const fetchGroupsData = () => {
        if (apiToken) {
          setIsLoading(true);
          getUsers(api, page + 1, pageSize, searchTerm)
            .then((result: ApiResponse<IUser[]>) => {
                setCount(result.totalCount);
                setPaginatedUser(result.data);
                setIsLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsLoading(false);
            });
        }
    }

    const getTableData = () => {
        return paginatedUsers?.length
            ? paginatedUsers?.map((row: any, index: number) => {
                return {
                "s/n": page * pageSize + index + 1,
                id: row.id,
                fullName: row.fullName,
                roleName: (
                    <div
                        className={
                            row?.roleName === "Admin" ? "adminRoles" : "memberRoles"
                        }
                        >
                        {row?.roleName || "N/A"}
                    </div>
                ),
                email: row.email,
                action: (
                    <div>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenDeleteModal(true);
                          setEditData(row);
                        }}
                        aria-label="delete"
                        className="btn-delete btn"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ),
                };
            })
        : [];
    };
    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
        ) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleSearch = useCallback(
        _.debounce((val: any) => {
            setPage(0);
            setSearchTerm(val);
        }, 1000),
    [apiToken]);

    const handleOnCloseModal = () => {
        setIsEdit(false);
        setOpenModal(false);
    };

    const handleFetch = () => {
        setReload(reload+1)
    }

    const handleDelete = () => {
        setOpenDeleteModal(false);
        setIsLoading(true);
        deleteUser(api, editData.id)
          .then(() => {
            setReload(reload+1)
            toast('User deleted successfully',{ type: 'success' });
            setReloadWorkspaces(Math.random())
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
            toast('Something went wrong while delete user',{ type: 'error' });
          });
        setEditData(null);
    };

    const handleRowClick = (row) => {
        setIsEdit(true);
        setOpenModal(true);
        setEditData(paginatedUsers.find((item) => item.id === row.id));
    }

    return (
        <div className="user-wrapper">
            <div className="user-header">
                <div className="left-wrapper">
                    <div className="total-wrapper">
                        <span>{count || 0} Users</span>
                    </div>
                    <div className="project-search-wrapper">
                        <div className="project-search-icn">
                            <IconButton aria-label="search" className="project-icon">
                                <Search />
                            </IconButton>
                        </div>
                        <BootstrapInput
                            className="project-search"
                            placeholder="Search"
                            onChange={(e) => {
                                handleSearch(e.target.value);
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        onClick={() => setOpenModal(true)}
                        variant="contained"
                        className="add-button btn btn-primary"
                    >
                        Add User
                    </Button>
                </div>
            </div>
            <div className="table-wrapper">
                {isLoading ? (
                    <LoadingSpinner messages={["Loading..."]} />
                    ) : (
                    <Table
                        tableHeaders={tableUserHeaders}
                        rows={getTableData()}
                        page={page}
                        pageSize={pageSize}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        count={count}
                        handleRowClick={handleRowClick}
                    />
                )}
            </div>
            {
                openModal && 
                <AddEditUserAdmin
                    open={openModal}
                    isEdit={isEdit}
                    onClose={handleOnCloseModal}
                    editData={editData}
                    fetchData={handleFetch}
                />
            }
            {
                <DeleteModal
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onConfirm={handleDelete}
                    title="Delete User"
                    contentText={`Are you sure you want to delete ${editData?.fullName}?`}
                />
            }
        </div>
    )
}

export default Users