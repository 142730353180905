import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "./index.scss"

type IProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  contentText: string;
  handleDontAsk?: (checked: boolean) => void;
  isDontAsk?: boolean;
  confirmText?: string
};

function DeleteModal(props: IProps) {
  const { open, onClose, onConfirm, title, contentText, handleDontAsk, isDontAsk } = props;
  const [dontAsk, setDontAsk] = useState(false);

  const handleClose = () => {
    onClose();
    setDontAsk(false);
  };

  const handleSubmit = () => {
    handleDontAsk?.(dontAsk);
    onClose();
    setDontAsk(false);
    onConfirm();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} className="delete-dialog">
        <DialogTitle
          id="responsive-dialog-title"
          className="delete-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent className="delete-dialog-title-content">
          <DialogContentText className="delete-dialog-title-notes">
            {contentText}
          </DialogContentText>
          {isDontAsk &&
            <FormGroup className="detect-alert-dialog-ask">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dontAsk}
                    onChange={(e) => setDontAsk(e.target.checked)}
                  />
                }
                label="Don't ask again"
              />
            </FormGroup>
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit} className='delete-button'>
            {props.confirmText ? (
              <>{props.confirmText}</>
            ) : (
              <>Delete</>
            )}

          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            className='cancel-button'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteModal;
