import { DeviceUUID } from "device-uuid";
import { fetchIdentifierTypes } from "../api/deviceApi";
import { IApi } from "../../types/apiTypes";
import { IIdentifierType } from "../../types/device";

export const getDeviceUUID = (): string => {
  return new DeviceUUID().get();
};

export const getDeviceIdentifierTypes = (api: IApi, apiToken: string) => {
  const cachedIdentifierTypes = localStorage.getItem("identifierTypes");
  if (cachedIdentifierTypes) {
    return JSON.parse(cachedIdentifierTypes);
  } else if (apiToken) {
    fetchIdentifierTypes(api).then((result: IIdentifierType[]) => {
      localStorage.setItem("identifierTypes", JSON.stringify(result));
      return result;
    });
  }
};
