import { Fragment, MutableRefObject, useContext, useEffect, useRef, useState } from "react"
import WaveSurfer from "wavesurfer.js/src/wavesurfer";
import AudioRedaction from "./AudioRedaction"
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import useInterval from "../../hooks/useInterval";
import { clamp } from "../../services/functions/coordinates";
import { AudioChannel, AudioSegment } from "../../types/audio";
import { beep, muteAudio, unmuteAudio, disposeAudioContext } from "../../utils/redact-util";


const RedactAudio = () => {
    const { videoPlayer, videoElement, channels, setAudioChannels }
        = useContext<RedactEditorState>(RedactEditorContext);

    const [lastVideoTime, setLastVideoTime] = useState<number>(0)
    const [currentRedactedSegment, setCurrentRedactedSegment] = useState<AudioSegment>()
    const [waveSurferRefs, setWaveSurferRefs] = useState<Array<MutableRefObject<WaveSurfer>>>([])

    useEffect(() => {

        return () => {
            disposeAudioContext();
        }
    }, [])

    useEffect(() => {
        if (videoPlayer) {
            waveSurferRefs.forEach((wavesurfer: MutableRefObject<WaveSurfer>, i) => {
                console.log("[waveSurferRefs] Completed sync with video..")
                wavesurfer.current.drawer.on("click", (_, time: number) => {
                    const seekedTime = time * waveSurferRefs[i].current.getDuration()
                    videoPlayer.currentTime(seekedTime)
                })
            })

            videoPlayer.on('play', () => {
                setCurrentRedactedSegment(undefined)
                waveSurferRefs.forEach(x => {
                    x.current.pause()
                })
            })

            videoPlayer.on('seeked', () => {
                setCurrentRedactedSegment(undefined)
                const seekTime = clamp(videoPlayer?.currentTime() / waveSurferRefs[0]?.current?.getDuration(), 0, 1);
                waveSurferRefs.forEach(x => {
                    x.current.pause();
                    x.current.seekAndCenter(seekTime);
                })
            })
        }
    }, [videoPlayer, waveSurferRefs])

    useInterval(() => {
        executeAudioRedaction()
    }, 10)

    const executeAudioRedaction = () => {
        if (!videoPlayer || waveSurferRefs.length === 0) {
            return
        }

        const currentVideoTime = videoPlayer.currentTime()
        if (currentVideoTime == lastVideoTime) {
            return
        }

        if (!videoPlayer.paused()) {
            //avoid scrolling issues when video is paused
            const seekTime = clamp(currentVideoTime / waveSurferRefs[0].current.getDuration(), 0, 1)
            waveSurferRefs.forEach(x => {
                x.current.seekAndCenter(seekTime)
            });
        }
        
        const segments = channels.map(x => x.segments).flat()
        if (!segments || segments.length < 1) {
            return
        }

        const closestRegion = segments.reduce((prev, curr) =>
            ((curr.startTime <= currentVideoTime) && (curr.endTime >= currentVideoTime)) ? curr : prev
        )

        const isWithinSegmentRange = (closestRegion.startTime <= currentVideoTime) && (closestRegion.endTime >= currentVideoTime)
        if (!videoPlayer.paused() && !videoPlayer.ended() && isWithinSegmentRange) {
            if ((!currentRedactedSegment || currentRedactedSegment.id !== closestRegion.id)) {

                const { mute, blip } = closestRegion
                if (blip === true) {
                    muteAudio(videoElement);
                    waveSurferRefs.forEach(x => {
                        x.current.setMute(true)
                    })

                    const { startTime, endTime } = closestRegion
                    const timeout = (endTime - currentVideoTime) * 1000
                    beep(timeout)
                }
                else if (mute === true) {
                    muteAudio(videoElement);
                    waveSurferRefs.forEach(x => {
                        x.current.setMute(true)
                    })
                }

                setCurrentRedactedSegment(closestRegion)
            }
        }
        else {
            unmuteAudio(videoElement);
            waveSurferRefs.forEach(x => {
                x.current.setMute(true)
            })
        }

        setLastVideoTime(currentVideoTime)
    }

    const updateChannel = (channel: AudioChannel) => {
        const clone = [...channels];
        const index = clone.findIndex(x => x.id === channel.id);
        if (index > -1) {
            clone[index] = {...channel}
        }

        setAudioChannels(clone);
    }

    const addWaveSurferRef = (ref: MutableRefObject<WaveSurfer>) => {
        const clone = [...waveSurferRefs]
        clone.push(ref)
        setWaveSurferRefs(clone)
    }

    return (
        <Fragment>
            {channels && channels.length > 0 && channels.map((channel: AudioChannel) => (
                <AudioRedaction key={channel.id} channelData={channel} updateChannel={updateChannel} addRef={addWaveSurferRef} />
            ))}
        </Fragment>
    )
}

export default RedactAudio