import { useState, useContext, useEffect } from "react";
import { RedactEditorState } from "../context/RedactEditorContext/types";
import RedactEditorContext from "../context/RedactEditorContext";
import { DetectedObject } from "../types/object";
import DbRepository from "../database/dbRepository";
import { FrameItem, FrameObject, FrameSegment, UserDefinedFrameObject } from "../types/frame";
import { getFrameTimeSegment } from "../services/functions/frames";
import { TrackingRequest, trackObject } from "../services/api/trackingApi";
import useApi from "./useApi";

type TrackedObject = {
    objectId: string
    version: string
    startTimeSec: number
    debounceId: NodeJS.Timeout
}

const useTracking = () => {

    const state = useContext<RedactEditorState>(RedactEditorContext);
    const { api } = useApi();
    const [trackingList] = useState<Map<string, TrackedObject>>(new Map<string, TrackedObject>());
    const [repository] = useState<DbRepository>(new DbRepository('equatureStudioDB', 'frameDataStore', 'segment'));

    //NOTE: Not concerned about mutating tracking list for now
    useEffect(() => {
        state.objectMap.forEach(async x => {
            //check if object meets parameters to be tracked
            const match = trackingList.get(x.objectId);
            if (!x.trackingInfo) {
                if (match) {
                    cancelTracking(match);
                }
                return;
            }

            //if it does, check if object is already being tracked and track it if it isn't
            if (!match) {
                const trackedObject = await triggerTracking(x);
                if (trackedObject) {
                    trackingList.set(x.objectId, trackedObject);
                }
                return;
            }

            
            //if it is, check if the versions are the same (version & trigger timestamp)
            //if they are do nothing
            if (x.trackingInfo.version !== match.version) {
                //else update match, cancel tracking interval if it exists and retrack
                cancelTracking(match);
                const trackedObject = await triggerTracking(x);
                if (trackedObject) {
                    trackingList.set(x.objectId, trackedObject);
                }
            }
        })
    }, [state.objectMap])

    const cancelTracking = (trackedObj: TrackedObject) => {
        clearTimeout(trackedObj.debounceId);
        trackingList.delete(trackedObj.objectId);
    }

    const triggerTracking = async (target: DetectedObject): Promise<TrackedObject> => {

        const version = target.trackingInfo.version;
        const startTime = target.trackingInfo.startTimeSec;
        const segmentKey = getFrameTimeSegment(startTime);
        let targetFrame: UserDefinedFrameObject | FrameObject;
        //check if there is an intersecting user frame and use that
        const intersectedIndex = target.frames.findIndex((range) => range.startTimeSec <= startTime && range.endTimeSec >= startTime);
        if (intersectedIndex > -1) {
            targetFrame = target.frames[intersectedIndex];
        } else {

            let segmentFrames: FrameItem[];
            //frame has already been loaded, use that
            if (state.frames.length > 0 && getFrameTimeSegment(state.frames[0].bufPts) === segmentKey) {
                segmentFrames = state.frames;
            } else {
                const segment: FrameSegment = await repository.getById(segmentKey);
                if (segment) {
                    segmentFrames = segment.frames;
                }
            }

            const segmentTarget = segmentFrames.find(x => x.objects.findIndex(o => o.objectId === target.objectId) > -1);
            if (segmentTarget) {
                targetFrame = segmentTarget.objects.find(x => x.objectId === target.objectId);
            }
        }

        if (!targetFrame) {
            return undefined;
        }

        //end time calculation:
        //get the current segment (minute)
        //increment it to the next minute, convert to seconds
        //subtract one to get the last possible time for the current segment
        const endTime = ((segmentKey + 1) * 60) + 1;
        const payload: TrackingRequest = {
            versionId: version,
            sessionId: state.trackingSession,
            objectId: parseInt(target.objectId),
            top: targetFrame.top,
            left: targetFrame.left,
            height: targetFrame.height,
            width: targetFrame.width,
            startTime: startTime,
            endTime
        };

        const timeoutId = setTimeout(async () => {

            if (state.trackingList.findIndex(x => x.objectId === payload.objectId) === -1) {
  
                state.updateTrackingList([...state.trackingList, {
                    version,
                    projectId: state.projectId,
                    objectId: payload.objectId,
                    lastUpdate: new Date(),
                    started: false
                }]);
            }

            await trackObject(api, state.projectId, payload);
            
        }, 3000)

        return {
            objectId: target.objectId,
            version: version,
            debounceId: timeoutId,
            startTimeSec: startTime
        }
    }

}

export default useTracking