import { Button, CircularProgress } from "@mui/material";
import { FC, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import "./index.scss";
import { LoadingButton } from "@mui/lab";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
}

const EnableFeatureButton: FC<Props> = ({ children, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button
      variant="contained"
      onClick={handleOnClick}
      className="btn-enable-feature"
      disabled={isLoading}
      startIcon={
        isLoading ? <CircularProgress size={20} color="inherit" /> : null
      }
    >
      {children}
    </Button>
  );
};
export default EnableFeatureButton;
