import React , {useState, useEffect} from 'react';
import './Admin.scss';
import { useTabContext, TabContext, TabList, TabContextValue } from "@material-ui/lab"
import Tab from '@mui/material/Tab';
import Users from '../../components/Admin/UsersList';
import Workspace from '../../components/Admin/WorkspaceList';
import {useHistory, useLocation} from 'react-router-dom';

export function TabPanel(props) {
    const { children, value: id } = props
    const context: TabContextValue = useTabContext()
    if (context === null) {
        throw new TypeError("No TabContext provided")
    }

    const tabId: string = context.value
    return (
        <section
            style={{
                marginTop: "2rem",
                display: id === tabId ? "block" : "none",
            }}
        >
            {children}
        </section>
    )
}

const Admin = () => {
    const [value, setValue] = useState<string>("users");
    const [reloadWorkspaces, setReloadWorkspaces] = useState<number>(undefined);
    
    const history = useHistory();
    const location = useLocation();
	const querySearch = new URLSearchParams(location.search);
	const tabValue = querySearch.get('tab');

    useEffect(() => {
		if (tabValue) {
			setValue(tabValue);
			history.push(`/admin?tab=${tabValue}`);
			return;
		}
		setValue('users');
		history.push(`/admin?tab=users`);
	}, [tabValue]);

    const handleChange = (_: any, tabValue: React.SetStateAction<string>) => {
        setValue(tabValue);
        history.push(`/admin?tab=${tabValue}`)
    };

    return(
        <div className="admin-wrapper">
            <div className="inline-title">
                <h2 className="page-title">
                    Admin
                </h2>
            </div>
            <div className="tab-content">
                <TabContext value={value}>
                    <TabList
                        variant="standard"
                        value={value}
                        onChange={handleChange}
                        textColor="inherit"
                        indicatorColor="primary"
                        className="tab-list"
                    >
                        <Tab value="users" label="Users" />
                        <Tab value="workspaces" label="Workspaces" />
                    </TabList>
                    <TabPanel value="users">
                        <Users setReloadWorkspaces={setReloadWorkspaces}/>
                    </TabPanel>
                    <TabPanel value="workspaces">
                        <Workspace reloadWorkspaces={reloadWorkspaces} />
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    )
}

export default Admin;