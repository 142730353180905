import _ from 'lodash';
import { AudioChannel, AudioSegment } from "../../types/audio";

export const saveAudioSegment = (channelId: string, segment: AudioSegment, list: AudioChannel[]): AudioChannel[] => {

    const channelClone = _.cloneDeep(list);
    const index = channelClone.findIndex(x => x.id === channelId);
    if (index < 0) {
        return channelClone;
    }

    const segmentIndex = channelClone[index].segments.findIndex(x => x.id === segment.id)
    if (segmentIndex < 0) {
        return channelClone;
    }

    channelClone[index].segments[segmentIndex] = _.cloneDeep(segment);
    return channelClone;
}

export const removeAudioSegment = (channelId: string, segmentId: string, list: AudioChannel[]) : AudioChannel[] => {

    const index = list.findIndex(x => x.id === channelId)
    if (index < 0) {
        return list
    }

    const segmentIndex = list[index].segments.findIndex(x => x.id === segmentId)
    if (segmentIndex < 0) {
        return list
    }

    list[index].segments.splice(segmentIndex, 1)

    return list
}