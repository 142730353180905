import { useEffect, useState, useContext } from "react";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecialOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import "./folders.scss";
import useApi from "../../hooks/useApi";
import AppContext from "../../context/AppContext";
import _ from "lodash";
import { IRedactProjectFolder } from "../../types/redactProjectFolder";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { BootstrapInput } from "../../components/InfoNameInput";
import {
  createFolder,
  deleteFolder,
  getFolderList,
  updateFolder,
} from "../../services/api/folderApi";

import { IUserPreference, IUserPreferencePayload } from "../../types/user";
import {
  getUserPreferences,
  saveUserPreferences,
} from "../../services/api/userApi";
import { IApi } from "../../types/apiTypes";
import { updateMatchingProperties } from "../../utils/object-util";

const ProjectFolders = () => {
  const {
    workspace,
    setFolders,
    folders,
    userPreferences,
    setUserPreferences,
  } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [currentFolder, setCurrentFolder] =
    useState<IRedactProjectFolder>(undefined);
  const [newFolderName, setNewFolderName] = useState("");
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { apiToken, api } = useApi();

  useEffect(() => {
    if (apiToken && workspace) {
      fetchFoldersList();
    }
  }, [apiToken, workspace]);

  useEffect(() => {
    if (apiToken && folders) {
      handleGetUserPreferences(api);
    }
  }, [apiToken, folders]);

  const handleGetUserPreferences = (api: IApi) => {
    getUserPreferences(api).then((preference) => {
      if (preference) {
        setUserPreferences(preference);
      }
    });
  };

  const fetchFoldersList = () => {
    getFolderList(api, workspace).then((result: IRedactProjectFolder[]) => {
      if (result) {
        setFolders([{ id: null, name: "Main" }, ...result]);
      }
    });
  };

  const handleClick = (id) => {
    handleSaveUserPreferences({
      groupId: workspace,
      folderId: id,
    });
    setIsMenuOpen(false);
  };

  const handleMenuOpen = (event, folder) => {
    setCurrentFolder(folder);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsMenuOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setCurrentFolder(undefined);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteWithMove = () => {
    setIsLoading(true);
    deleteFolder(api, currentFolder.id, { moveToDefault: true })
      .then(() => {
        reload();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteWithCascade = () => {
    setIsLoading(true);
    deleteFolder(api, currentFolder.id, { deleteProjects: true })
      .then(() => {
        reload();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleSaveUserPreferences = (payload: IUserPreferencePayload) => {
    const updatedUserPreferences = updateMatchingProperties(
      userPreferences,
      payload
    );
    setUserPreferences(updatedUserPreferences);
    saveUserPreferences(api, payload);
  };

  const reload = () => {
    if (userPreferences.folderId === currentFolder.id) {
      handleSaveUserPreferences({
        groupId: workspace,
        folderId: null,
      });
    }

    setIsLoading(false);
    setCurrentFolder(undefined);
    setIsDeleteModalOpen(false);
    fetchFoldersList();
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
    setIsMenuOpen(false);
  };

  const handleCloseEditModal = () => {
    setCurrentFolder(undefined);
    setIsEditModalOpen(false);
  };

  const handleSave = () => {
    setIsLoading(true);
    updateFolder(api, currentFolder)
      .then(() => {
        setIsLoading(false);
        setCurrentFolder(undefined);
        setIsEditModalOpen(false);
        fetchFoldersList();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onChangeFolderName = (name) => {
    setCurrentFolder({
      ...currentFolder,
      name,
    });
  };

  const handleCreate = () => {
    if (!newFolderName) return;

    setIsLoading(true);
    createFolder(api, {
      workspaceId: workspace,
      name: newFolderName,
    })
      .then(() => {
        setIsLoading(false);
        setCurrentFolder(undefined);
        setIsAddMenuOpen(false);
        fetchFoldersList();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getFolderTitle = (title: string) => {
    if (title.length < 11) {
      return <span>{title}</span>;
    }
    const shortTitle = title.slice(0, 8) + "...";
    return (
      <Tooltip title={title}>
        <span>{shortTitle}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <Dialog
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        className="folder folder-dialog delete-folder-dialog"
      >
        <DialogTitle id="dialog-title" className="dialog-title">
          Delete Folder
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to move this project to Main Folder or Delete the
            Project?
            <p className="observation">
              Please Note, once a project is deleted it cannot be undone.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={handleDeleteWithMove}
            className="finish-button"
          >
            {isLoading && (
              <CircularProgress
                size={18}
                color="inherit"
                style={{ marginRight: 8 }}
              />
            )}
            Move to Main
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleDeleteWithCascade}
            className="cancel-button"
          >
            {isLoading && (
              <CircularProgress
                size={18}
                color="inherit"
                style={{ marginRight: 8 }}
              />
            )}
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        fullWidth={true}
        className="folder folder-dialog edit-folder-dialog"
      >
        <DialogTitle id="dialog-title" className="dialog-title">
          Edit Folder
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl fullWidth variant="standard">
              <InputLabel className="project-label" shrink htmlFor="project">
                Folder Name<span style={{ color: "red" }}> *</span>
              </InputLabel>
              <BootstrapInput
                className="folder-name"
                id="name"
                placeholder="Enter folder name"
                value={currentFolder?.name}
                onChange={(e) => onChangeFolderName(e.target.value)}
                required
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            autoFocus
            onClick={handleSave}
            className="finish-button"
          >
            {isLoading && (
              <CircularProgress
                size={18}
                color="inherit"
                style={{ marginRight: 8 }}
              />
            )}
            Save
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleCloseEditModal}
            className="cancel-button"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div className="header">
        <h3>Folders</h3>
        <IconButton
          className="add-button"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={() => setIsAddMenuOpen(true)}
        >
          <AddIcon />
        </IconButton>
      </div>
      {isAddMenuOpen && (
        <Box className="add-folder-form">
          <div className="form-header">
            <InputLabel className="project-label" shrink htmlFor="project">
              Add New Folder
            </InputLabel>
            <div className="buttons">
              <IconButton
                disabled={isLoading}
                className="editButton confirm"
                onClick={() => handleCreate()}
              >
                {isLoading && <CircularProgress size={18} color="inherit" />}
                {!isLoading && <CheckIcon />}
              </IconButton>
              <IconButton
                disabled={isLoading}
                className="editButton cancel"
                onClick={() => setIsAddMenuOpen(false)}
              >
                <ClearIcon />
              </IconButton>
            </div>
          </div>
          <FormControl fullWidth variant="standard">
            <BootstrapInput
              className="folder-name"
              id="name"
              placeholder="Folder Name"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              required
            />
          </FormControl>
        </Box>
      )}

      <hr />

      <Menu
        id="long-menu"
        className="floating-menu"
        keepMounted
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={openEditModal}>Rename Folder</MenuItem>
        <MenuItem onClick={openDeleteModal}>Delete Folder</MenuItem>
      </Menu>
      {folders?.map((folder) => {
        return (
          <div
            className={`folder-row ${
              folder.id === userPreferences.folderId ? "active" : "inactive"
            }`}
          >
            <div className="folder" onClick={() => handleClick(folder.id)}>
              <div className="folder-icon">
                {!folder.id && <FolderSpecialIcon />}
                {!!folder.id && <FolderIcon />}
              </div>
              {getFolderTitle(folder.name)}
              {!!folder.id && (
                <div className="menu-icon">
                  <IconButton
                    id={`${folder.id}-menu`}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event, folder)}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProjectFolders;
