import { HubConnectionState } from "@microsoft/signalr";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import AppContext from "../../context/AppContext";
import { AppState } from "../../context/AppContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import DETECTION_TYPE from "../../enums/detectionType";
import { FocusedDetectedObject } from "../../types/focusedObjects";
import { FrameItem, UserDefinedFrameObject } from "../../types/frame";
import useInterval from "../useInterval";
import { getFrameTimeSegment } from "../../services/functions/frames";
import { DetectedObject } from "../../types/object";

export interface TrackingMetadata {
    projectId: string
    versionId: string
    objectId: number
    startTime: number
    endTime: number
    frames: FrameItem[]
}

export interface TrackingUpdate {
    projectId: string
    objectId: number
    version: string
    percentComplete: number
}

const useTrackingNotification = () => {
    const { hubConnection, idleTimer } = useContext<AppState>(AppContext);
    const state = useContext<RedactEditorState>(RedactEditorContext);
    const stateRef = useRef<RedactEditorState>(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state])

    useEffect(() => {
        if (stateRef.current.projectId) {
            subscribe();
        }
    }, [hubConnection?.state, hubConnection?.connectionId, stateRef.current.projectId])

    useInterval(() => {
        const lastUpdateDiff = 120000 //milliseconds
        const currentTime = new Date();
        for (var i = stateRef.current.trackingList.length - 1; i >= 0; i--) {
            const toUpdate = stateRef.current.trackingList[i];
            if (toUpdate && toUpdate.started && (currentTime.getTime() - toUpdate.lastUpdate.getTime()) > lastUpdateDiff) {
                handleFailedTracking({
                    objectId: toUpdate.objectId,
                    projectId: toUpdate.projectId,
                    percentComplete: 0,
                    version: toUpdate.version
                });
            }
        }
    }, 20000)

    const handleFailedTracking = (update: TrackingUpdate) => {
        const targetObject= stateRef.current.objectMap.get(update.objectId.toString());
        if (!targetObject || !targetObject.trackingInfo || targetObject.trackingInfo.version !== update.version) {
            return;
        }

        toast(`Tracking Failed for Object ${targetObject?.name ?? targetObject?.sequence}`, { type: 'error' });
        if (idleTimer) {
            idleTimer.resume();
        }
        
        const clone: DetectedObject = {...targetObject, trackingInfo: undefined};
        stateRef.current.updateDetectedObjectList([clone], []);
        const trackingList = [...stateRef.current.trackingList].filter(x => x.objectId !== update.objectId);
        stateRef.current.updateTrackingList(trackingList);
        if ((stateRef.current.focusedItem as FocusedDetectedObject).objectId === update.objectId.toString()) {
            stateRef.current.setCurrentObjectId(update.objectId.toString());
        }
    }

    const subscribe = () => {
        if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
            hubConnection.invoke("SubscribeToProject", stateRef.current.projectId);
            hubConnection.off("TrackingStarted");
            hubConnection.off("TrackingComplete");
            hubConnection.off("TrackingFailed");
            hubConnection.off("TrackingProgress");

            hubConnection.on("TrackingStarted", (update: TrackingUpdate) => {
                const targetObject = stateRef.current.objectMap.get(update.objectId.toString());
                if (!targetObject || !targetObject.trackingInfo || targetObject.trackingInfo.version !== update.version) {
                    return;
                }

                const objName = (!targetObject?.name || targetObject?.name.length === 0) ? targetObject?.sequence : targetObject?.name;
                toast(`Tracking Started for Object ${objName}`, { type: 'info' });

                if (idleTimer) {
                    idleTimer.reset();
                    idleTimer.pause();
                }

                //update tracking list entry update time if it exists
                stateRef.current.updateTrackingList(stateRef.current.trackingList.map(x => {
                    let updateEntry = {...x};
                    if (updateEntry.objectId === update.objectId) {
                        updateEntry.lastUpdate = new Date();
                        updateEntry.started = true;
                    }

                    return updateEntry;
                }));
            });

            hubConnection.on("TrackingComplete", async (metadata: TrackingMetadata) => {
                /*
                Rules of Updating Tracking
                verify notification is for this user session, project and tracking version
                object start time should always remain unchanged
                object end time should be updated to the last frame data ONLY if obj end time is less than tracked time or this is the final occurence of the object in the video
                if this isn't the last occurence in the video, create a new tracking request for the next segment (set the value in tracking info)
                if this is the last occurence in the video, clear any existing tracking request of this version
                clear any user defined frames that occur within the duration of update segment
                */
                
                //remove from the tracking list reference if exists
                stateRef.current.updateTrackingList([...stateRef.current.trackingList.filter(x => 
                    x.objectId !== metadata.objectId || x.version !== metadata.versionId)]);
                
                //TODO: do a tracking session verification
                if (metadata.projectId !== stateRef.current.projectId) {
                    return;
                }

                if (idleTimer) {
                    idleTimer.resume();
                }

                const targetObject= stateRef.current.objectMap.get(metadata.objectId.toString());
                if (!targetObject || !targetObject.trackingInfo || targetObject.trackingInfo.version !== metadata.versionId) {
                    return;
                }

                const clone = {...targetObject};
                if (metadata.endTime < 0) {
                    toast(`Tracking Complete for Object ${targetObject?.name ?? targetObject?.sequence}`, { type: 'info' });
                    clone.trackingInfo = undefined;
                    clone.loading = false;
                    stateRef.current.updateDetectedObjectList([clone], []);
                    return;
                }

                //determine if this is the final frame for the object or if there's more that can be tracked
                const endTimeFrameNum = metadata.endTime * stateRef.current.frameRate;
                //if start time segment is is not equal to end time segment, that means there may be more to be tracked (and vice versa)
                const shouldTrackNext = getFrameTimeSegment(metadata.startTime) !== getFrameTimeSegment(metadata.endTime);
                if (clone.detectionType === DETECTION_TYPE.MANUAL) {
                    clone.detectionType = DETECTION_TYPE.HYBRID;
                }
                clone.frames = clone.frames.filter(x => {
                    //take objects that are out of range of tracked frames
                    if (x.startTimeSec > metadata.endTime || x.endTimeSec < metadata.startTime) {
                        return true;
                    }

                    //remove objects that are completely within range of tracked frames
                    if (x.startTimeSec >= metadata.startTime && x.endTimeSec <= metadata.endTime) {
                        return false
                    }

                    //clip frame that intersects tracked frame start time
                    if (x.startTimeSec < metadata.startTime) {
                        x.endTimeSec = metadata.startTime;
                        return true;
                    }

                    if (x.endTimeSec > metadata.endTime) {
                        x.startTimeSec = metadata.endTime
                        return true;
                    }

                    return false;
                })

                if (shouldTrackNext) {
                    const frameObj = metadata.frames[1].objects[0];
                    const trackingFrame: UserDefinedFrameObject = {
                        classId: frameObj.classId.toString(),
                        height: frameObj.height,
                        width: frameObj.width,
                        top: frameObj.top,
                        left: frameObj.left,
                        angle: 0,
                        objectId: frameObj.objectId,
                        startTimeSec: metadata.frames[1].bufPts,
                        endTimeSec: metadata.frames[1].bufPts
                    };

                    const intersectedIndex = clone.frames.findIndex((range) => range.startTimeSec <= metadata.endTime && range.endTimeSec >= metadata.endTime);
                    if (intersectedIndex > -1) {
                        //split the intersected user def frame and insert a new frame with the last tracked coordinates (so it can be used for the next segment tracking)
                        const nextFrameTime = (endTimeFrameNum + 1) / stateRef.current.frameRate;
                        const endPartOfSplitFrame: UserDefinedFrameObject = {...clone.frames[intersectedIndex], startTimeSec: nextFrameTime }
                        clone.frames[intersectedIndex].endTimeSec = (endTimeFrameNum - 1) / stateRef.current.frameRate;
                        clone.frames.splice(intersectedIndex + 1, 0, trackingFrame);
                        clone.frames.splice(intersectedIndex + 2, 0, endPartOfSplitFrame);
                    } else {
                        clone.frames.push(trackingFrame);
                    }

                    clone.trackingInfo = {
                        version: uuidv4(),
                        startTimeSec: metadata.endTime
                    }

                    if (clone.endTime < metadata.endTime) {
                        clone.endTime = metadata.endTime;
                    }

                    clone.loading = true;
                } else {
                    toast(`Tracking Complete for Object ${targetObject?.name ?? targetObject?.sequence}`, { type: 'success' });
                    clone.endTime = metadata.endTime;
                    clone.loading = false;
                    clone.trackingInfo = undefined;
                }
                
                stateRef.current.updateFrameVersions([clone], [], [...stateRef.current.frameVersions, {
                    version: metadata.versionId,
                    startTime: metadata.startTime,
                    endTime: metadata.endTime
                }]);
                
                if ((stateRef.current.focusedItem as FocusedDetectedObject)?.objectId === metadata.objectId.toString()) {
                    stateRef.current.setCurrentObjectId(metadata.objectId.toString());
                }
            });

            hubConnection.on("TrackingFailed", (update: TrackingUpdate) => {
                handleFailedTracking(update);
            });

            hubConnection.on("TrackingProgress", (update: TrackingUpdate) => {
                if (idleTimer) {
                    idleTimer.reset();
                    idleTimer.pause();
                }

                //update tracking list entry update time if it exists
                stateRef.current.updateTrackingList(stateRef.current.trackingList.map(x => {
                    let updateEntry = {...x};
                    if (updateEntry.objectId === update.objectId) {
                        updateEntry.lastUpdate = new Date();
                    }

                    return updateEntry;
                }));
            });
        }
    }
    
}

export default useTrackingNotification