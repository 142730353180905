import { useContext, useState, useEffect } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { BootstrapInput } from "../../components/InfoNameInput";
import VideoBlobPreview from "../../components/VideoBlobPreview";
import VideoThumbnailSelector from "../../components/VideoThumbnailSelector";
import { VideoThumbnail } from "../../components/VideoThumbnailSelector/types";
import useRedactProjectActions from "../../hooks/useRedactProjectActions";
import RedactionStatusWrapper from "../../components/RedactionStatusWrapper";
import { getExtensionFromMimeType } from "../../services/functions/fileType";
import { useHistory } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { ArrowBackIos } from "@material-ui/icons";

import "./createProject.scss";
import {
  getUploadData,
  uploadThumbnailToServer,
  uploadToServer,
  uploadToStorage,
} from "../../services/api/storageApi";
import useApi from "../../hooks/useApi";
import VIDEO_REDACTION_TYPE from "../../enums/videoRedactionType";
import { AppState } from "../../context/AppContext/types";
import DeviceVerificationWrapper from "../../components/DeviceVerificationWrapper";
import { IDeviceVerificationActions } from "../../types/device";
import { appConfig } from "../../appConfig";
import { FormatBold } from "@mui/icons-material";
import VideoPreviewAndThumbnailSelector from "../../components/VideoPreviewAndThumbnailSelector";

interface IFormData {
  name: string;
  description: string;
  detectFace: boolean;
  transcription: boolean;
  detectLP: boolean;
  detectWeapon: boolean;
  audioRedaction: boolean;
  videoRedaction: boolean;
  videoRedactionType: string;
  file: any;
  confidence: number;
}

enum PROJECT_OPTIONS {
  TRANSCRIBE = "transcribe",
  DETECT_FACE = "detectFace",
  VIDEO_REDACTION = "videoRedaction",
}

const CreateProject = () => {
  const history = useHistory();

  const { api } = useApi();
  const projectActions = useRedactProjectActions();
  const { workspace, idleTimer, redact: canRedactWorkspace, transcribe: canTranscribeWorkspace, userPreferences } = useContext<AppState>(AppContext);
  const initialData = {
    name: "",
    description: "",
    detectFace: false,
    transcription: false,
    detectLP: false,
    detectWeapon: false,
    audioRedaction: false,
    videoRedaction: false,
    videoRedactionType: "",
    file: null,
    confidence: 0.3,
  };

  const [formObject, setData] = useState<IFormData>(initialData);
  const [error, setError] = useState([]);
  const [previewURL, setPreviewURL] = useState<string>(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState<VideoThumbnail>();
  const [showPreview, setShowPreview] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState<string[]>();
  const [verificationActions, setVerificationActions] =
    useState<IDeviceVerificationActions>(null);

  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  });

  const listenToScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    setIsScroll(winScroll >= 120);
  };

  useEffect(() => {
    if (projectActions.projectId) {
      history.push(`/editor/redact/${projectActions.projectId}`);
    }
  }, [projectActions.projectId, projectActions.fetchProjectTrigger]);

  const saveProject = async () => {
    const {
      name,
      detectFace,
      transcription,
      detectLP,
      detectWeapon,
      file,
      description,
      confidence,
      audioRedaction,
      videoRedaction,
      videoRedactionType,
    } = formObject;
    if (name && file) {
      setIsModified(true);

      const formData = new FormData();
      try {
        if (idleTimer) {
          idleTimer.reset();
          idleTimer.pause();
        }

        setLoadingMessage([
          "Uploading file to storage. Do not leave the page.",
        ]);
        const videoUploadName =
          "upload_" +
          new Date().getTime() +
          "." +
          getExtensionFromMimeType(file.type);

        const cloudInfo = appConfig.IsCloudStorage
          ? await getUploadData(api)
          : undefined;
        if (cloudInfo) {
          await cloudUpload(
            cloudInfo.accountUrl,
            cloudInfo.videoUploadToken,
            cloudInfo.videoContainerName,
            videoUploadName,
            file,
            uploadToServer
          );
        } else {
          await localUpload(videoUploadName, file, uploadToServer);
        }

        formData.append("ProjectMedia", videoUploadName);

        if (selectedThumbnail) {
          setLoadingMessage([
            "Uploading thumbnail to storage. Do not leave the page.",
          ]);
          const thumbnailName =
            "upload_" +
            new Date().getTime() +
            "." +
            getExtensionFromMimeType(selectedThumbnail?.blob.type);

          if (cloudInfo) {
            await cloudUpload(
              cloudInfo.accountUrl,
              cloudInfo.thumbnailUploadToken,
              cloudInfo.thumbnailContainerName,
              thumbnailName,
              selectedThumbnail?.blob,
              uploadThumbnailToServer
            );
          } else {
            await localUpload(
              thumbnailName,
              selectedThumbnail?.blob,
              uploadThumbnailToServer
            );
          }

          formData.append("ProjectThumbnail", thumbnailName);
        }

        setLoadingMessage([
          "Uploading project data to storage. Do not leave the page.",
        ]);
        formData.append("Name", name);
        formData.append("DetectFace", detectFace.toString());
        formData.append("Transcribe", transcription.toString());
        formData.append("AudioRedaction", audioRedaction.toString());
        formData.append("VideoRedaction", videoRedaction.toString());

        formData.append("VideoRedactionType", videoRedactionType.toString());

        formData.append("DetectLicensePlate", detectLP.toString());
        formData.append("DetectWeapon", detectWeapon.toString());
        formData.append(
          "Confidence",
          Math.min(Math.max(0.1, confidence || 0.3, 0.9)).toString()
        );

        formData.append("GroupId", workspace);
        formData.append("Description", description);
        if (userPreferences && userPreferences.folderId) {
          formData.append("FolderId", userPreferences.folderId);
        }

        projectActions.create(formData);

        setIsModified(false);
        setLoadingMessage(undefined);
      } catch (error) {
        console.warn(error);
        setLoadingMessage(undefined);
        toast(`Failed to save project due to an error. Try again.`, {
          type: "error",
          autoClose: false,
        });
      }
    } else {
      const errors = [...error];
      if (!name) errors.push("name");
      if (!file) errors.push("file");
      setError(errors);
      toast(`Please fill all mandatory(*) fields!`, {
        type: "error",
        autoClose: false,
      });
    }

    async function cloudUpload(
      accountUrl: string,
      uploadToken: string,
      containerName: string,
      uploadName: string,
      fileContent: any,
      backupUploadFunc: Function
    ) {
      try {
        await uploadToStorage(
          accountUrl + uploadToken,
          containerName,
          uploadName,
          fileContent
        );
      } catch (error) {
        //try backup upload
        console.warn(
          "Failed to upload direct to storage. Attempting backup method",
          error
        );
        await backupUploadFunc(api, uploadName, fileContent);
      }
    }

    async function localUpload(
      uploadName: string,
      fileContent: any,
      uploadFunc: Function
    ) {
      await uploadFunc(api, uploadName, fileContent);
    }
  };

  const handleSaveWithVerification = () => {
    setVerificationActions({
      run: saveProject,
      onFinished: () => {
        setVerificationActions(null);
      },
    });
  };

  const handleOnChange = (name: string, value: any) => {
    let useDefaultProjectName = false;
    if (name === "file") {
      if (value.type === "video/mp4") {
        setShowPreview(true);
      } else {
        setShowPreview(false);
      }
      const url = URL.createObjectURL(value);
      setPreviewURL(url);

      if (!formObject.name?.trim() || formObject.name === formObject.file?.name) {
        useDefaultProjectName = true;
      }
    }

    let update = { ...formObject, [name]: value };
    if (name === PROJECT_OPTIONS.VIDEO_REDACTION) {
      if (value === false) {
        update.detectFace = false;
        update.videoRedactionType = null;
      } else {
        update.videoRedactionType = VIDEO_REDACTION_TYPE.BLUR;
      }
    }

    if (name === PROJECT_OPTIONS.DETECT_FACE) {
      if (value === true) {
        update.videoRedaction = true;
        update.videoRedactionType = VIDEO_REDACTION_TYPE.BLUR;
      }
    }

    if (name === PROJECT_OPTIONS.TRANSCRIBE) {
      update.transcription = !update.transcription;
    }

    if (useDefaultProjectName) {
      update.name = update.file?.name;
    }

    setData(update);
    let errors = [...error];
    errors = errors.filter((err) => err !== name);
    setError(errors);
    setIsModified(true);
  };

  const {
    name,
    description,
    detectFace,
    transcription,
    videoRedaction,
    audioRedaction,
    videoRedactionType,
  } = formObject;

  const handleBack = () => {
    history.push("/projects?page=1");
  };

  return (
    <DeviceVerificationWrapper
      onModalClose={handleBack}
      actions={verificationActions}
    >
      <RedactionStatusWrapper
        canLeave={!isModified}
        loadingMessages={projectActions.loadingMessages ?? loadingMessage}
      >
        <div className="app-page-inner">
          <div className="create-header">
            <div className="left-wrap">
              <div className="icon-wrapper" onClick={handleBack}>
                <ArrowBackIos />
              </div>
              <Typography gutterBottom variant="h5" component="div">
                Create Project
              </Typography>
            </div>
            <Stack
              direction="row"
              spacing={2}
              className={isScroll ? "btn-fixed-top" : ""}
            >
              <Button
                variant="contained"
                onClick={handleSaveWithVerification}
                className={`btn-submit`}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={handleBack}
                className={`btn-cancel`}
              >
                Cancel
              </Button>
            </Stack>
          </div>
          <Box className="create-project">
            <form action="">
              <InputLabel
                className="project-label"
                shrink
                style={{ marginTop: 0, marginBottom: 0, top: 10 }}
              >
                Upload Video<span> *</span>
              </InputLabel>
              <input
                type="file"
                className="form-item choose-file"
                name="file"
                accept="video/*,audio/*"
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.files[0])
                }
              />
              {error.includes("file") && (
                <div>
                  <span className="error-text">Upload video is required</span>
                </div>
              )}
              {previewURL && !showPreview && (
                <div>
                  <span className="no-preview">
                    Preview not available. this video will be automatically
                    converted to .mp4
                  </span>
                </div>
              )}
              {previewURL && showPreview && 
                  <VideoPreviewAndThumbnailSelector url={previewURL}
                  setSelectedThumbnail={setSelectedThumbnail}
                  />
              }
              <div className="form-item project-form-left">
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{ marginTop: "25px" }}
                >
                  <InputLabel
                    className="project-label"
                    shrink
                    htmlFor="project"
                  >
                    Project Name<span> *</span>
                  </InputLabel>
                  <BootstrapInput
                    placeholder="Enter project name"
                    className="project-input"
                    id="project"
                    name="name"
                    value={name}
                    onChange={(e) =>
                      handleOnChange(e.target.name, e.target.value)
                    }
                  />
                  {error.includes("name") && (
                    <span className="error-text">Project name is required</span>
                  )}
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    className="project-label"
                    shrink
                    htmlFor="description"
                  >
                    Description
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    className="project-description"
                    placeholder="Enter description"
                    id="description"
                    name="description"
                    multiline
                    rows={2}
                    value={description}
                    onChange={(e) =>
                      handleOnChange(e.target.name, e.target.value)
                    }
                  />
                </FormControl>
              </div>
              <div className="form-item project-form-right">
                <InputLabel
                  className="project-label"
                  shrink
                  style={{ marginBottom: 0, top: 12 }}
                >
                  Enable Options
                </InputLabel>
                <Tooltip
                  title={
                    canTranscribeWorkspace
                      ? "Transcribe this video"
                      : "Transcription not enabled for workspace"
                  }
                >
                  <FormControlLabel
                    className="project-checkbox"
                    control={
                      <Checkbox
                        checked={transcription}
                        disabled={!canTranscribeWorkspace}
                        onChange={(e) =>
                          handleOnChange(e.target.name, e.target.checked)
                        }
                        className="chk-box-color"
                        name="transcribe"
                      />
                    }
                    label="Transcription"
                  />
                </Tooltip>
                <Tooltip
                  title={
                    canRedactWorkspace
                      ? "Enable audio redaction for this project"
                      : "Redaction not enabled for workspace"
                  }
                >
                  <FormControlLabel
                    className="project-checkbox"
                    control={
                      <Checkbox
                        checked={audioRedaction}
                        disabled={!canRedactWorkspace}
                        onChange={(e) =>
                          handleOnChange(e.target.name, e.target.checked)
                        }
                        className="chk-box-color"
                        name="audioRedaction"
                      />
                    }
                    label="Audio Redaction"
                  />
                </Tooltip>
                <Tooltip
                  title={
                    canRedactWorkspace
                      ? "Enable video redaction for this project"
                      : "Redaction not enabled for workspace"
                  }
                >
                  <FormControlLabel
                    className="project-checkbox"
                    control={
                      <Checkbox
                        checked={videoRedaction}
                        disabled={!canRedactWorkspace}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.checked);
                        }}
                        className="chk-box-color"
                        name="videoRedaction"
                      />
                    }
                    label="Video Redaction"
                  />
                </Tooltip>
                <Tooltip
                  title={
                    canRedactWorkspace
                      ? "Auto detect and redact faces that appear in the video"
                      : "Redaction not enabled for workspace"
                  }
                >
                  <FormControlLabel
                    className="project-checkbox"
                    control={
                      <Checkbox
                        checked={detectFace}
                        disabled={!canRedactWorkspace}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.checked);
                        }}
                        className="chk-box-color"
                        name="detectFace"
                      />
                    }
                    label="Auto Redact Faces"
                  />
                </Tooltip>
              </div>

              <div className="form-item project-form-right">
                <Tooltip
                  title={
                    videoRedaction && canRedactWorkspace
                      ? ""
                      : "Enable Video Redaction to select"
                  }
                >
                  <InputLabel
                    id="video-redaction-type-label"
                    className="project-label"
                    shrink
                    disabled={!videoRedaction}
                    style={{ marginBottom: 0, top: 12 }}
                  >
                    Video Redaction Type
                  </InputLabel>
                </Tooltip>
                <Tooltip
                  title={
                    videoRedaction && canRedactWorkspace
                      ? ""
                      : "Enable Video Redaction to select"
                  }
                >
                  <FormControl className="project-checkbox">
                    <RadioGroup
                      aria-labelledby="video-redaction-type-label"
                      defaultValue={VIDEO_REDACTION_TYPE.BLUR}
                      name="videoRedactionType"
                      value={videoRedactionType}
                      className="radio-buttons"
                      onChange={(e) =>
                        handleOnChange(e.target.name, e.target.value)
                      }
                    >
                      <FormControlLabel
                        value={VIDEO_REDACTION_TYPE.BLUR}
                        control={
                          <Radio
                            disabled={!videoRedaction}
                            className="chk-box-color"
                          />
                        }
                        label="Blur"
                      />
                      <FormControlLabel
                        value={VIDEO_REDACTION_TYPE.PIXELATED}
                        control={
                          <Radio
                            disabled={!videoRedaction}
                            className="chk-box-color"
                          />
                        }
                        label="Pixelated"
                      />
                    </RadioGroup>
                  </FormControl>
                </Tooltip>
              </div>
            </form>
          </Box>
        </div>
      </RedactionStatusWrapper>
    </DeviceVerificationWrapper>
  );
};

export default CreateProject;
