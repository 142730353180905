import { IApi } from "../../types/apiTypes";
import { ApiResponse } from "../../types/apiResponse";
import {
  IWorkspace,
  IRole,
  IUser,
  IUserIdentity,
  IUserExistingEmail,
  IGroup,
  IGroupPayload,
  IAdminGroup,
  IWorkSpaceSummary,
  ICompanyRequest,
  IPartnerRequest,
} from "../../types/workspace";
import { reject } from "lodash";

export const getUsers = (
  api: IApi,
  page: number,
  pageSize: number,
  keyword: string,
  id: string
): Promise<ApiResponse<IWorkspace[]>> => {
  const url = `group/${id}/members?page=${page}&pageSize=${pageSize}&keyword=${keyword}`;
  return api.get(url).then((result: ApiResponse<IWorkspace[]>) => {
    return result;
  });
};




export const fetchRoles = (api: IApi): Promise<IRole[]> => {
  const url = `app-role/list/roles`;
  return api.get(url).then((result: ApiResponse<IRole[]>) => {
    return result.data;
  });
};

export const fetchUserExistingEmails = (
  api: IApi,
  groupId: string,
  searchTerm: string
): Promise<IUserExistingEmail[]> => {
  const url = `user/list/emails/${groupId}?searchTerm=${searchTerm}`;
  return api.get(url).then((result: ApiResponse<IUserExistingEmail[]>) => {
    return result.data;
  });
};

export const fetchUserEmails = (
  api: IApi,
  searchTerm: string
): Promise<IUserExistingEmail[]> => {
  const url = `user/list/emails?searchTerm=${searchTerm}`;
  return api.get(url).then((result: ApiResponse<IUserExistingEmail[]>) => {
    return result.data;
  });
};

export const addUser = (
  api: IApi,
  params: IUser,
  groupId: string
): Promise<ApiResponse<string>> => {
  const url = `group/${groupId}/add-member`;
  return api
    .post(url, params)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const addExistedUser = (
  api: IApi,
  groupId: string,
  email: string
): Promise<string> => {
  const url = `group/${groupId}/add-member/${email}`;
  return api.post(url, {}).then((result: ApiResponse<string>) => {
    return result.data;
  });
};

export const updateUser = (
  api: IApi,
  params: IUser,
  groupId: string
): Promise<string> => {
  const url = `group/${groupId}/update/member`;
  return api
    .post(url, params)
    .then((result: ApiResponse<string>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const deleteUser = (
  api: IApi,
  id: string,
  groupId: string
): Promise<string> => {
  const url = `group/${groupId}/remove-member/${id}`;
  return api.remove(url).then((result: ApiResponse<string>) => {
    return result.data;
  });
};

export const getWorkspace = (api: IApi): Promise<IUserIdentity> => {
  const url = `identity/user`;
  return api.get(url).then((result: ApiResponse<IUserIdentity>) => {
    return result?.data;
  });
};

export const setDefaultWorkspace = (
  api: IApi,
  defaultGroupId: string
): Promise<ApiResponse<IWorkSpaceSummary>> => {
  return api
    .post("identity/default/group", {
      defaultGroupId,
    })
    .then((result: ApiResponse<IWorkSpaceSummary>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getGroups = (
  api: IApi,
  page: number,
  pageSize: number,
  keyword: string
): Promise<ApiResponse<IGroup[]>> => {
  const url = `group/list?page=${page}&pageSize=${pageSize}&keyword=${keyword}`;
  return api.get(url).then((result: ApiResponse<IGroup[]>) => {
    return result;
  });
};

export const addGroup = (
  api: IApi,
  params: IGroupPayload
): Promise<ApiResponse<IAdminGroup>> => {
  const url = `group/create`;
  return api
    .post(url, params)
    .then((result: ApiResponse<IAdminGroup>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const updateGroup = (
  api: IApi,
  params: IGroupPayload,
  id: string
): Promise<ApiResponse<string>> => {
  const url = `group/${id}/update`;
  return api
    .post(url, params)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const deleteWorkspace = (api: IApi, id: string): Promise<string> => {
  const url = `group/${id}`;
  return api.remove(url).then((result: ApiResponse<string>) => {
    return result.data;
  });
};

export const getGroupUsers = (api: IApi, id: string): Promise<IAdminGroup> => {
  const url = `group/${id}`;
  return api.get(url).then((result: ApiResponse<IAdminGroup>) => {
    return result?.data;
  });
};

export const updateLicenseKey = (api: IApi, id: string): Promise<string> => {
  const url = `group/${id}/license-key`;
  return api.post(url).then((result: ApiResponse<string>) => {
    return result?.data;
  });
};

export const getCompanyInfo = (
  api: IApi,
  id: string,
  params: ICompanyRequest
): Promise<ApiResponse<string>> => {
  const url = `group/${id}/get-company-info`;
  return api
    .post(url, params)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getPartnerInfo = (
  api: IApi,
  id: string,
  params: IPartnerRequest
): Promise<ApiResponse<string>> => {
  const url = `group/${id}/get-partner-info`;
  return api
    .post(url, params)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};
