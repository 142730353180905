import { FrameItem, UserDefinedFrameObject } from "../../types/frame";

export const getFrameTimeSegment = (frameTime: number) => Math.floor((frameTime ?? 0) / 60);

export const getClosestFrameIndex = (frames: FrameItem[], timestamp: number, startIndex: number, endIndex: number): number => {

    if (startIndex === endIndex)
    {
        return [Math.max(0, startIndex - 1), Math.min(startIndex + 1, frames.length - 1), startIndex].reduce((prev, curr) => {

            return Math.abs(frames[curr].bufPts - timestamp) <
            Math.abs(frames[prev].bufPts - timestamp)
            ? curr
            : prev;
        });
    }

    const midPoint = Math.floor((startIndex + endIndex) / 2);
    if (frames[midPoint].bufPts > timestamp)
        return getClosestFrameIndex(frames, timestamp, startIndex, Math.max(startIndex, midPoint - 1));

    if (frames[midPoint].bufPts < timestamp)
        return getClosestFrameIndex(frames, timestamp, Math.min(midPoint + 1, endIndex), endIndex);

    return midPoint;
}

export const findClosestFrame = (
    frames: UserDefinedFrameObject[],
    videoTime: number
  ): UserDefinedFrameObject | null => {

    if (!frames || frames.length === 0) {
        return;
    }

    return frames.reduce((closestFrame: UserDefinedFrameObject | null, frame: UserDefinedFrameObject) => {
      const frameStartTime = frame.startTimeSec;
      const frameEndTime = frame.endTimeSec;
      const currentClosestStartTime = closestFrame?.startTimeSec ?? Infinity;
      const currentClosestEndTime = closestFrame?.endTimeSec ?? -Infinity;
  
      if (frameStartTime <= videoTime && videoTime <= frameEndTime) {
        // The video time is within the frame's start and end time
        if (
          Math.abs(videoTime - frameStartTime) < Math.abs(videoTime - currentClosestStartTime) ||
          Math.abs(videoTime - frameEndTime) < Math.abs(videoTime - currentClosestEndTime)
        ) {
          // The frame's start or end time is closer to the video time than the previous closest frame
          return frame;
        }
      } else if (frameStartTime > videoTime && frameStartTime < currentClosestStartTime) {
        // The frame's start time is after the video time, but closer than the previous closest frame
        return frame;
      }
  
      return closestFrame;
    }, null);
  };

export const isMsTimeWithinFrameDuration = (frame: UserDefinedFrameObject, videoTime: number): boolean => {
  
  if (!frame) {
      return false;
  }

  const maxDifference = 0.02;
  //convert to milliseconds
  const start = frame.startTimeSec ?? 0;
  const end = frame.endTimeSec ?? 0;

  return start <= videoTime && videoTime <= end;
};

export const getNextFrameTimeSec = (timeSec: number, frameRate: number) => {
  const frameNum = timeSec * frameRate;
  const nextFrameTime = (frameNum + 1) / frameRate;
  return nextFrameTime;
}

export const getPreviousFrameTimeSec = (timeSec: number, frameRate: number) => {
  const frameNum = timeSec * frameRate;
  const nextFrameTime = (Math.max(0, frameNum - 1)) / frameRate;
  return nextFrameTime;
}