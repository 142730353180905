import { useContext, useEffect, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import RedactEditorContext from "../context/RedactEditorContext";
import { RedactEditorState } from "../context/RedactEditorContext/types";
import { crossMultiply } from "../services/functions/coordinates";
import { generateFrames, getNewObjectIdAndSequence, createUserDetectedObject } from "../services/functions/detectedObject";
import useFrameTime from "./useFrameTime";
import DETECTION_TYPE from "../enums/detectionType";
import usePointerDraw from "./usePointerDraw";

const useCanvasEvents = () => {

  const state = useContext<RedactEditorState>(RedactEditorContext);
  const stateRef = useRef<RedactEditorState>();
  useEffect(() => {
    stateRef.current = state;
  }, [state])

  const frameTimeRef = useFrameTime();
  useEffect(() => {
    if (state.canvas) {
      stateRef.current = state;
      bindEvents();
    }
  }, [state.canvas])

  usePointerDraw(state.canvas, (rect: fabric.Rect) => {
    const { detectedObjects, deletedObjects } = stateRef.current;
    const {objectId, sequence } = getNewObjectIdAndSequence(detectedObjects, deletedObjects);

    const object = createUserDetectedObject(
      objectId,
      sequence,
      frameTimeRef.current,
      rect,
      stateRef.current?.canvas,
      stateRef.current?.videoPlayer
    );

    stateRef.current.updateDetectedObjectList([object], []);
  });

  const bindEvents = () => {
    const { canvas } = stateRef?.current;
    canvas.on("mouse:down", (event) => {
      if (event.target) {
        // open property tab
        const object = event.target;
        setObjectId(object);
      }
    })

    canvas.on("object:modified", (event) => {

      //check if the current time intersects with any existing user defined frames
      //if it does, update the end time to match the frame. else create a new frame as usual
      const { objectMap, videoPlayer, updateDetectedObjectList } = stateRef.current
      const objectId = event.target.data.objectId;
      if (!objectId) {
        return;
      }

      const target = { ...objectMap.get(objectId) };
      if (!target) {
        return;
      }

      const startTime = frameTimeRef.current;
      const sourceHeight = videoPlayer.videoHeight();
      const sourceWidth = videoPlayer.videoWidth();

      const obj: fabric.Object = event.target;
      const newFrame = generateFrames(
        objectId,
        crossMultiply(canvas.width, sourceWidth, obj.left),
        crossMultiply(canvas.height, sourceHeight, obj.top),
        crossMultiply(canvas.width, sourceWidth, obj.scaleX !== 1 ? obj.scaleX * obj.width : obj.width),
        crossMultiply(canvas.height, sourceHeight, obj.scaleY !== 1 ? obj.scaleY * obj.height : obj.height),
        0,
        startTime
      )[0];

      //get intersecting frames if they exist
      const currentFrames = [...target.frames];
      let intersectedIndex = currentFrames.findIndex((range) => range.startTimeSec <= startTime && range.endTimeSec >= startTime);
      if (intersectedIndex > -1) {
        //set the end time to a single frame behind
        const fps = stateRef.current.frameRate;
        const currentFrameNum = Math.round(startTime * fps);
        const previousFrameNum = currentFrameNum - 1;
        const previousIntersectEndTime = currentFrames[intersectedIndex].endTimeSec;
        const newIntersectEndTime = previousFrameNum/fps;

        newFrame.endTimeSec = previousIntersectEndTime; //review this for UX validity
        if (previousFrameNum < 0 || newIntersectEndTime < currentFrames[intersectedIndex].startTimeSec) {
          //replace intersect with new frame
          currentFrames[intersectedIndex] = newFrame;
        } else {
          //add intersect after new frame
          currentFrames[intersectedIndex].endTimeSec = newIntersectEndTime;
          currentFrames.splice(intersectedIndex + 1, 0, newFrame);
        }
      } else {
        currentFrames.push(newFrame)
      }

      const clone = { ...target, frames: currentFrames };
      if (clone.detectionType !== DETECTION_TYPE.MANUAL) {
        //auto track non manual objects
        clone.trackingInfo = {
          version: uuidv4(),
          startTimeSec: startTime
        }
      }
      updateDetectedObjectList([clone], []);
    })
  }

  const setObjectId = (object: fabric.Object) => {
    state.setCurrentObjectId(object.data.objectId);
  };
}

export default useCanvasEvents;