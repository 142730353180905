import { useEffect } from "react";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

import {
  InteractionType,
  IPublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";

import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";

import "./App.scss";
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from "./AppRoutes";
import LicenseKeyAuthenticationWrapper from "./LicenseKeyAuthenticationWrapper";
import { appConfig } from "./appConfig";

interface AppProps {
  msalInstance: IPublicClientApplication;
}

function App({ msalInstance }: AppProps) {
  const authRequest = {
    ...loginRequest,
  };

  useEffect(() => {
    handleRedirect()
  }, []);

  const handleRedirect = () => {
    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
      if (event.eventType === EventType.LOGIN_FAILURE) {
        msalInstance.loginRedirect();
      }
    });
  }
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      {appConfig.IsLicenseKeyAuth &&
        <LicenseKeyAuthenticationWrapper>
          <AppRoutes />
        </LicenseKeyAuthenticationWrapper>}

      {appConfig.IsCloudAuth &&
        <MsalProvider instance={msalInstance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
          >
            <AppRoutes />
          </MsalAuthenticationTemplate>
        </MsalProvider>}
    </ThemeProvider>
  );
}

export default App;