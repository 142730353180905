import React from 'react'
import { DetectedObject } from '../../types/object'
import { RedactEditorState } from './types'
import { Transcription } from '../../types/transcription';
import PROJECT_FEATURE_STATE from '../../enums/projectFeatureState';
import { v4 as uuidv4 } from 'uuid';

export const defaultEditorContextState: RedactEditorState = {
    objectMap: new Map<string, DetectedObject>(),
    detectedObjects: [],
    frames: [],
    frameVersions: [],
    trackingList: [],
    trackingSession: uuidv4(),
    audioPeakUrls: [],
    transcribeUrl: '',
    folderId: undefined,
    description: '',
    videoRedactionType: '',
    name: '',
    originalFileExtension: '',
    confidence: 0.5,
    projectId: null,
    focusedItem: undefined,
    deletedObjects: [],
    toggleCanvasRefresh: false,
    isModified: false,
    playerTime: 0,
    frameRate: 0,
    frameCount: 0,
    frameHeight: 0,
    frameWidth: 0,
    sidePanelSize: 0,
    selectedTranscription: undefined,
    transcriptionUpdates: [],
    transcriptionState: PROJECT_FEATURE_STATE.DISABLED,
    videoRedactionState: PROJECT_FEATURE_STATE.DISABLED,
    audioRedactionState: PROJECT_FEATURE_STATE.DISABLED,
    setProject: (id, project) => { console.log(`default state called with projectId ${id}`) },
    setCurrentObjectId: (objectId: string) => console.log(`default state called with setCurrentObject ${objectId}`),
    updateDetectedObjectList: (objects) => { console.log(`default state called with detected object list ${objects}`) },
    updateTranscription: (updates) => {},
    setAudioChannels: (channels) => { console.log(`default state called with Channels object list ${channels}`) },
    setCurrentAudioSegment: (channelId, segmentId) => { console.log(`default state called with empty values`)},
    setCanvas: (canvas) => {console.log(`default state called with empty values`)},
    setVideoPlayer: (player, element) => {console.log(`default state called with empty values`)},
    setFrames: (frames) => {console.log(`default state called with empty values`)},
    updateFrameVersions: (updateObjects, deleteObjects, versions) => {},
    setMultiFocusObjects: (objects: DetectedObject[]) => {},
    resetModifiedStatus: () => {},
    resetState: () => {},
    setSidePanelSize: (value: number) => {},
    setSelectedTranscription: (value: Transcription) => {},
    undoChanges: () => {},
    updateTrackingList: (items) => {}
}

const RedactEditorContext = React.createContext(defaultEditorContextState)

export default RedactEditorContext