import { Button, FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from "react";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import { DetectedObject } from "../../types/object";
import DeleteModal from '../../components/DeleteModal';
import DetectedObjectCard from "./../detectedObjectCard";
import { mergeObjects } from "./helper";
import useApi from "../../hooks/useApi";

interface MultiObjectEditorProps {
    objects: DetectedObject[]
}

const MultiObjectEditor = ({ objects }: MultiObjectEditorProps) => {

    const state = useContext<RedactEditorState>(RedactEditorContext);
    const { videoPlayer, objectMap, setMultiFocusObjects, updateDetectedObjectList } = state;
    const [redact, setRedact] = useState<boolean>(true); 
    const [open, setOpen] = useState(false);
    const [objectList, setObjectList] = useState<DetectedObject[]>(objects);

    const { api } = useApi();

    useEffect(() => {

        if (objectList && objectList.length > 0) {
            setRedact(objectList.every(x => x.redact));
        }

    }, [objectList, objectList?.length]);

    useEffect(() => {
        const updatedList: DetectedObject[] = [];
        for (let i = 0; i < objects.length; i++) {
            const entry = objectMap.get(objects[i].objectId);
            if (entry) {
                updatedList.push({...entry})
            }
        }
        
        setObjectList(updatedList);
    }, [objects?.length, objectMap])

    const toggleRedact = () => {
        const clone = [...objectList];
        for (let i = 0; i < clone.length; i++) {
            clone[i].redact = !redact;
        }

        updateDetectedObjectList(clone, []);
    }

    const deleteObjects = () => {
        updateDetectedObjectList([], objectList);
    }

    const toggleDialog = () => {
        setOpen(!open)
    }

    const handleObjectClick = (obj: DetectedObject) => () => {
        if(videoPlayer) {
            videoPlayer.currentTime(Math.min(obj.startTime + 0.1, obj.endTime - 0.1));
        }
    }

    const handleRemoveSelection = (obj: DetectedObject) => () => {
        setMultiFocusObjects(objectList.filter(x => x.objectId !== obj.objectId));
    }

    return (<>
        {objectList && 
        <>
            <section className="project-detect-card" style={{ position: "sticky", top: 120, zIndex: 1 }}>
                <Stack direction={"row"} spacing={2} flexWrap="wrap">
                     <Tooltip title="Toggle Redaction">
                        <FormControlLabel
                            control={<Switch checked={redact} onChange={(e => toggleRedact())} />}
                            label="Redact"
                        />
                    </Tooltip>
                    <Tooltip title="Merge all selected objects">
                        <Button
                            onClick={() => {mergeObjects(api, objectList, state)}}
                            variant="contained"
                            style={{ backgroundColor: '#6c5ecf' }}
                        >
                            Merge
                        </Button>
                    </Tooltip>
                    <Tooltip title="Delete redacted object(s)">
                        <Button
                            onClick={() => {setOpen(true)}}
                            variant="contained"
                            style={{ backgroundColor: '#ff4040' }}
                        >
                            Delete
                        </Button>
                    </Tooltip>
                    <Tooltip title="Clear selection">
                        <Button
                            onClick={() => {setMultiFocusObjects([])}}
                            variant="contained"
                            style={{ backgroundColor: '#6c6c7f' }}
                        >
                            Clear
                        </Button>
                    </Tooltip>
                </Stack>
            </section>
            <Stack direction="row" justifyContent="center" flexWrap="wrap" spacing={2}>
                {objectList.map(obj => 
                    <div style={{ width: 200, margin: 10, position: 'relative' }} key={obj.objectId} onClick={handleObjectClick(obj)}>
                        <Button variant="contained" onClick={handleRemoveSelection(obj)}
                            style={{ 
                                backgroundColor: '#ff4040',
                                minWidth: 0,
                                fontSize: 14,
                                margin: 0,
                                padding: 0,
                                borderRadius: '50%',
                                position: 'absolute',
                                right: 0
                            }}
                        >
                            <CloseIcon style={{ width: 20, height: 20 }} />
                        </Button>
                        <DetectedObjectCard key={obj.objectId} {...obj} />
                    </div>
                )}
            </Stack>
        </>
        }
        {
            <DeleteModal 
                open={open} 
                onClose={toggleDialog} 
                onConfirm={() => deleteObjects()}
                title='Are you sure?'
                contentText={ "Are you sure you want to delete all the selected objects?" }
            />
        }
    </>);
    
}

export default MultiObjectEditor;