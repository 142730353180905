import { useEffect, useRef, useState } from "react";
import useApi from "../useApi";
import { HubConnectionState } from "@microsoft/signalr";
import { v4 as uuidv4 } from 'uuid';
import { AppConnection } from "../../types/notification";
import { getNotificationDetails } from "../../services/api/notificationApi";
import { appConfig } from "../../appConfig";

type EventSubscription = {
    methodName: string,
    handlers: any[]
}

const useOfflineNotifications = (): AppConnection => {

    const { api, apiToken } = useApi();
    const callbackRefs = useRef<EventSubscription[]>([]);
    //connection id in this case is created just to satisfy the variable requirement in the App Connection interface 
    const connectionIdRef = useRef<string>(uuidv4());
    const [connection, setConnection] = useState<AppConnection>();

    const lastFetchTime = useRef<Date>(new Date());

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (apiToken && !connection && appConfig.IsLicenseKeyAuth) {
            setConnection({
                invoke,
                on,
                off,
                connectionId: connectionIdRef.current,
                //TODO: Convert to app defined enums instead of using signalR definitions
                state: apiToken && apiToken.length > 0 ? HubConnectionState.Connected : HubConnectionState.Connecting
            });

            const triggerGetNotifications = async () => {
                await getNotifications();
                setTimeout(triggerGetNotifications, 5000);
            }

            triggerGetNotifications();
        }

        return () => {
            if (interval) {
                clearTimeout(interval);
            }
        }
    }, [apiToken]);

    const getNotifications = async () => {

        const notifications = await getNotificationDetails(api, lastFetchTime.current);
        if (!notifications || notifications.length === 0) {
            return;
        }

        lastFetchTime.current = new Date(notifications[notifications.length - 1].created);
        notifications.forEach((notification) => {
            //TODO: Pass last fetch time to get notification api call instead
            const targetMethod = callbackRefs.current.find(x => x.methodName === notification.type);
            if (targetMethod && targetMethod.handlers.length > 0) {
                targetMethod.handlers.forEach(h => h(notification));
            }
        });
    }

    const invoke = (command:string, params?:any) => {
        console.log('invoked ' + command + ' with params ', params);
    }

    const on = (methodName, handler) => {
        //use classic callback subscription
        const clone = [...callbackRefs.current];
        var methodIndex = clone.findIndex(x => x.methodName === methodName);
        if (methodIndex === -1) {
            clone.push({
                methodName,
                handlers: [handler]
            });
        } else {
            clone[methodIndex].handlers.push(handler);
        }

        callbackRefs.current = clone;
    }

    const off = (methodName: string) => {
        const methodIndex = callbackRefs.current.findIndex(x => x.methodName === methodName);
        if (methodIndex === -1) {
            return;
        }

        if (!callbackRefs.current[methodIndex].handlers || callbackRefs.current[methodIndex].handlers.length === 0) {
            return;
        }

        const clone = [...callbackRefs.current];
        clone[methodIndex].handlers = [];
        callbackRefs.current = clone;

    }

    return connection;

}

export default useOfflineNotifications