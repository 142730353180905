import React, { useContext, useEffect, useState } from "react";
import LoadingScreen from "../shared/LoadingScreen";
import AppContext from "../../context/AppContext";
import { DeviceUUID } from "device-uuid";
import { toast } from "react-toastify";
import { getDeviceVerification } from "../../services/api/deviceApi";
import useApi from "../../hooks/useApi";
import DeviceVerificationModal from "./form/DeviceVerificationModal";
import { IDeviceVerificationActions } from "../../types/device";

export interface IDeviceVerificationWrapper {
  children: React.ReactNode;
  onModalClose: () => void;
  actions?: IDeviceVerificationActions;
}

export const DeviceVerificationWrapper = ({
  children,
  onModalClose,
  actions,
}: IDeviceVerificationWrapper) => {
  const { workspace, enableMfa, seatCount, email } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const { api, apiToken } = useApi();
  const [deviceVerified, setDeviceVerified] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (actions && actions.run) {
      verifyDevice();
    }
  }, [actions]);

  useEffect(() => {
    if (apiToken && workspace && !deviceVerified) {
      verifyDevice();
    }
  }, [enableMfa, apiToken, workspace]);

  const verifyDevice = async () => {
    if (enableMfa === false) {
      setDeviceVerified(true);
      setIsLoading(false);
      if (actions) {
        actions.run();
      }
      return;
    }

    setIsLoading(true);
    const deviceId = getDeviceUUID();
    var verified = await getDeviceVerification(api, workspace, deviceId);
    setDeviceVerified(verified);
    setIsLoading(false);

    if (!verified) {
      toast("Failed to verify your device.", { type: "error" });
      setOpenModal(true);
      return;
    }

    if (actions) {
      actions.run();
    }
  };

  const handleOnValid = () => {
    setDeviceVerified(true);
    setOpenModal(false);

    if (actions && actions.run) {
      actions.run();
    }
    if (actions && actions.onFinished) {
      actions.onFinished();
    }
  };

  const handleOnClose = () => {
    setOpenModal(false);
    if (actions && actions.onClose) {
      actions.onClose();
    } else {
      onModalClose();
    }
    if (actions && actions.onFinished) {
      actions.onFinished?.();
    }
  };

  const getDeviceUUID = (): string => {
    var du = new DeviceUUID().parse();
    var dua = [
      du.platform,
      du.os,
      du.isKindleFire,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
      du.browser,
      du.isOpera,
      du.isIE,
      du.isEdge,
      du.isIECompatibilityMode,
      du.isSafari,
      du.isFirefox,
      du.isWebkit,
      du.isChrome,
      du.isKonqueror,
      du.isOmniWeb,
      du.isSeaMonkey,
      du.isFlock,
      du.isAmaya,
      du.isPhantomJS,
      du.isEpiphany,
      du.source,
      email,
    ];
    var uuid = du.hashMD5(dua.join(":"));
    return uuid;
  };
  return (
    <>
      {isLoading && <LoadingScreen messages={["verifying device..."]} />}
      {openModal && (
        <DeviceVerificationModal
          open={openModal}
          onClose={handleOnClose}
          onValid={handleOnValid}
          workspace={workspace}
          seatCount={seatCount}
          deviceId={getDeviceUUID()}
        />
      )}
      {deviceVerified && children}
    </>
  );
};

export default DeviceVerificationWrapper;
