import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  List,
  ListItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Stack,
  Tooltip,
  LinearProgress
} from "@mui/material";
import _ from "lodash";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { DetectedObject } from "../../types/object";
import { BootstrapInput } from "./../InfoNameInput";
import DeleteModal from '../../components/DeleteModal';
import useApi from "../../hooks/useApi";
import { FocusedDetectedObject } from "../../types/focusedObjects";
import { ObjectTrackingInfo } from "../../types/tracking";
import DETECTION_TYPE from "../../enums/detectionType";
import useFrameTime from "../../hooks/useFrameTime";
import { splitObject } from "./helper";

interface UserDefinedObjectInfoProps {
  focusedObject: FocusedDetectedObject;
}

const UserDetectedObjectInfo = ({ focusedObject }: UserDefinedObjectInfoProps) => {
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const {
    objectMap,
    updateDetectedObjectList,
    setCurrentObjectId
  } = state;

  const [currentObject, setCurrentObject] = useState<DetectedObject>();
  const [open, toggleDialog] = useState(false);
  const { api } = useApi();
  const frameTimeRef = useFrameTime();

  useEffect(() => {
    if (focusedObject?.objectId && objectMap) {
      const target = objectMap.get(focusedObject.objectId);
      if (target) {
        setCurrentObject({ ...target, });
      }
    }
  }, [focusedObject]);

  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout>();
  const updateObjectValue = (key: string, value: any) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const clone = { ...currentObject, [key]: value };
    setDebounceTimer(setTimeout(() => {
      updateDetectedObjectList([clone], []);
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        setDebounceTimer(undefined);
      }
    }, 300));
    setCurrentObject(clone);
  };

  const handleDelete = () => {
    updateDetectedObjectList([], [currentObject]);
    setCurrentObject(undefined);
    setCurrentObjectId(undefined);
  }

  const handleSplit = async () => {
    await splitObject(api, currentObject, frameTimeRef.current, state);
  }

  const handleDontAsk = (shouldAsk: boolean) => {
    localStorage.setItem('dontShowDeleteAlertUserObject', shouldAsk.toString())
  }

  const handleDeleteConfirmation = () => {
    const dontShowDialog = localStorage.getItem('dontShowDeleteAlertUserObject');
    if (dontShowDialog === 'false' || !dontShowDialog) {
      toggleDialog(true)
    }
    else if (dontShowDialog === 'true') {
      handleDelete()
    }
  }

  const handleTrackObject = () => {

    const trackingTime = frameTimeRef.current;
    const newTrackingInfo: ObjectTrackingInfo = {
      version: uuidv4(),
      startTimeSec: trackingTime
    };

    updateObjectValue('trackingInfo', newTrackingInfo);
  }

  const handleCancelTrackObject = () => {
    updateObjectValue('trackingInfo', undefined);
  }

  return (
    <>
      {focusedObject && (
        <section className="project-detect-card">
          <h2 className="page-title">Object {currentObject?.sequence}</h2>
          <List dense disablePadding>
            <ListItem>
              <FormControl
                fullWidth
                variant="standard"
                className="form-wrapper"
              >
                <InputLabel
                  className="project-label"
                  shrink
                  htmlFor="framename"
                >
                  Name
                </InputLabel>
                <BootstrapInput
                  className="project-input"
                  id="framename"
                  value={currentObject?.name || ""}
                  onChange={(e) => updateObjectValue("name", e.target.value)}
                />
              </FormControl>
            </ListItem>
            <ListItem style={{ marginTop: 20 }}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  className="project-label"
                  shrink
                  htmlFor="description"
                >
                  Description
                </InputLabel>
                <TextField
                  variant="outlined"
                  className="project-description"
                  placeholder="Describe Detected Object"
                  id="description"
                  multiline
                  rows={2}
                  value={currentObject?.description || ""}
                  onChange={(e) =>
                    updateObjectValue("description", e.target.value)
                  }
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <Stack direction='row' spacing={2}>
                <Tooltip title="Delete the object">
                  <Button
                    onClick={handleDeleteConfirmation}
                    variant="contained"
                    style={{ backgroundColor: '#ff4040' }}
                  >
                    Delete
                  </Button>
                </Tooltip>
                <Tooltip title="Split this object at the current time">
                    <Button
                      onClick={handleSplit}
                      variant="contained"
                      style={{ backgroundColor: '#6c5ecf' }}
                    >
                      Split
                    </Button>
                </Tooltip>
                {
                  (!currentObject?.trackingInfo && currentObject?.detectionType === DETECTION_TYPE.MANUAL) &&
                    <Tooltip title="Automatically track this object on the video.">
                      <Button
                        onClick={handleTrackObject}
                        variant="contained"
                        style={{ backgroundColor: '#6C5ECF' }}
                      >
                        Track
                      </Button>
                    </Tooltip>
                }
                {
                  (currentObject?.trackingInfo) && 
                    <>
                      <Stack justifyContent={"space-between"}>
                        <p style={{ margin: 0, fontSize: 12 }}>Tracking</p>
                        <LinearProgress color="primary" />
                      </Stack>
                      <Tooltip title="Cancel the current tracking operation for this object.">
                        <Button
                          onClick={handleCancelTrackObject}
                          variant="contained"
                          style={{ backgroundColor: '#6C5ECF' }}
                        >
                          Cancel
                        </Button>
                      </Tooltip>
                    </>
                }
              </Stack>
            </ListItem>
          </List>
        </section>
      )}
      <DeleteModal
        open={open}
        onClose={() => toggleDialog(false)}
        handleDontAsk={handleDontAsk}
        isDontAsk
        onConfirm={handleDelete}
        title='Are you sure?'
        contentText={`Are you sure you want to delete object ${currentObject?.sequence}?`}
      />
    </>
  );
};

export default UserDetectedObjectInfo;
