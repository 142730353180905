import { IApi } from "../../types/apiTypes"

export interface TrackingRequest {
    objectId: number
    top: number
    left: number
    height: number
    width: number
    startTime: number
    endTime: number
    versionId: string
    sessionId: string
}

export const trackObject = (api: IApi, projectId: string, payload: TrackingRequest) => {

    return api.post(`redact/${projectId}/track`, payload);
}