import { useEffect, useState, useContext } from "react";
import "./index.scss";
import { Select, MenuItem, FormControl } from "@mui/material";
import AppContext from "../../context/AppContext";
import useApi from "../../hooks/useApi";
import { setDefaultWorkspace } from "../../services/api/workspaceApi";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingScreen from "../shared/LoadingScreen";

const AppWorkSpace = () => {
  const { api, apiToken } = useApi();
  const { setWorkspace, workspace, workspaceOption, isAcessAdmin } =
    useContext(AppContext);
  const [selected, setSelected] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading workspace");

  useEffect(() => {
    setSelected(workspace);
  }, [workspace]);

  const handleChange = (value: string) => {
    const selectedWorkspace = workspaceOption.find(({ id }) => id === value);
    if (!workspace) {
      return;
    }

    const isAcessWorkspace = selectedWorkspace.groupRole === "Admin";
    const workspaceName = selectedWorkspace.displayName;
    history.push("/projects");
    //set default workspace in api
    if (apiToken) {
      setLoadingMessage(`Loading workspace ${workspaceName}...`);
      setIsLoading(true);
      setDefaultWorkspace(api, selectedWorkspace.id)
        .then((response) => {
          if (!response.success) {
            toast(response.message, { type: "error" });
          } else {
            const workspaceData = {
              workspace: value,
              workspaceName: workspaceName,
              isAcessWorkspace: isAcessWorkspace,
              isAcessAdmin: isAcessAdmin,
              redact: response.data.redact,
              transcribe: response.data.transcribe,
              addProjectFromWebApp: response.data.addProjectFromWebApp,
              enableMfa: response.data.enableMfa,
              seatCount: response.data.seatCount,
              integratedPlatform: response.data.integratedPlatform,
            };

            setWorkspace(workspaceData);
          }
        })
        .catch((e) => {
          toast(e?.message ?? `Something went wrong`, {
            type: "error",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <FormControl fullWidth>
        <Select
          className="select-workspace"
          value={selected}
          onChange={(e) => handleChange(e.target.value)}
        >
          {workspaceOption?.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.displayName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {isLoading && <LoadingScreen messages={[loadingMessage]} />}
    </>
  );
};

export default AppWorkSpace;
