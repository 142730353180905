import { useCallback, useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputBase } from "@mui/material";
import styled from "@emotion/styled";
import _ from "lodash";
import useApi from "../../../hooks/useApi";
import AppContext from "../../../context/AppContext";
import { ApiResponse } from "../../../types/apiResponse";
import { IWorkspace } from "../../../types/workspace";
import LoadingSpinner from "../../shared/LoadingScreen/index";
import Table from "../../Table/Table";
import { usersTableHeaders } from "../TableHeader";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete-icon.svg";
import AddEditUserModal from "./AddEditUserModal";
import DeleteModal from "../../DeleteModal";
import { getUsers, deleteUser } from "../../../services/api/workspaceApi";
import { toast } from "react-toastify";


const BootstrapInput = styled(InputBase)(({ }) => ({}));

const UserList = () => {
    const [count, setCount] = useState(0);
    const { api, apiToken } = useApi();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { workspace, workspaceName } = useContext(AppContext);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState("");
    const [usersData, setUsersData] = useState(null);
    const [fetchData, setFetchData] = useState(false);
    const [openModal, toggleModal] = useState(false);
    const [openDeleteModal, toggleDeleteModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);


    useEffect(() => {
        fetchUsersData();
    }, [apiToken, page, pageSize, fetchData, workspace])

    const handleSearch = useCallback(_.debounce((val: any) => {
        fetchUsersData(val);
    }, 1000),
        [apiToken]
    );

    const fetchUsersData = (searchValue?: string) => {
        if (apiToken && workspace) {
            setIsLoading(true);
            getUsers(api, page + 1, pageSize, searchValue ?? search, workspace)
                .then((result: ApiResponse<IWorkspace[]>) => {
                    setUsersData(result);
                    setCount(result.totalCount);
                    setIsLoading(false);
                })
                .catch((e) => {
                    console.error(e);
                    setIsLoading(false);
                });
        }
    };

    const getTableData = () => {
        return usersData?.data?.length
            ? usersData.data?.map((row: any, index: number) => {
                return {
                    "s/n": page * pageSize + index + 1,
                    id: row.id,
                    fullName: <span>{row?.fullName}</span>,
                    roleName: (
                        <div className={row?.roleName === "Admin" ? "adminRoles" : "memberRoles"}>
                            {row?.roleName || "N/A"}
                        </div>
                    ),
                    email: row?.email,
                    action: (
                        <div>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleDeleteModal(true);
                                    setEditData(row);
                                }}
                                aria-label="delete"
                                className="btn-delete btn"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ),
                };
            })
            : [];
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (row) => {
        setIsEdit(true);
        toggleModal(true);
        setEditData(usersData.data.find((item) => item.id === row.id));
    };

    const handleOnCloseModal = () => {
        setIsEdit(false);
        toggleModal(false);
    };

    const handleFetch = () => {
        setFetchData(!fetchData);
    };

    const handleDelete = () => {
        toggleDeleteModal(false);
        setIsLoading(true);
        deleteUser(api, editData.id, workspace)
            .then(() => {
                toast("User deleted successfully", { type: "success" });
                handleFetch();
            })
            .catch((e) => {
                setIsLoading(false);
                toast(e?.message ?? "Something went wrong while delete user", {
                    type: "error",
                });
            });
        setEditData(null);
    };


    return (
        <div className="user-wrapper">
            <div className="user-header">
                <div className="left-wrapper">
                    <div className="total-wrapper">
                        <span>{count || 0} Users</span>
                    </div>
                    <div className="project-search-wrapper">
                        <div className="project-search-icn">
                            <IconButton aria-label="search" className="project-icon">
                                <SearchIcon />
                            </IconButton>
                        </div>
                        <BootstrapInput
                            className="project-search"
                            placeholder="Search"
                            onChange={(e) => {
                                handleSearch(e.target.value);
                                setSearch(e.target.value);
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        onClick={() => toggleModal(true)}
                        variant="contained"
                        className="add-button btn btn-primary"
                    >
                        Add User
                    </Button>
                </div>
            </div>

            <div className="table-wrapper">
                {isLoading ? (
                    <LoadingSpinner messages={["Loading..."]} />
                ) : (
                    <Table
                        tableHeaders={usersTableHeaders}
                        rows={getTableData()}
                        page={page}
                        pageSize={pageSize}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        count={count}
                        handleRowClick={handleRowClick}
                    />
                )}
            </div>
            {
                openModal && (
                    <AddEditUserModal
                        open={openModal}
                        isEdit={isEdit}
                        onClose={handleOnCloseModal}
                        editData={editData}
                        fetchData={handleFetch}
                        workspace={workspace}
                        workspaceName={workspaceName}
                    />
                )
            }
            {
                <DeleteModal
                    open={openDeleteModal}
                    onClose={() => toggleDeleteModal(false)}
                    onConfirm={handleDelete}
                    title="Delete User"
                    contentText={`Are you sure you want to delete ${editData?.fullName}?`}
                />
            }
        </div>
    )
}

export default UserList;