import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import { AppState } from "../../context/AppContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import NOTIFICATION_TYPE from "../../enums/notificationType";
import PROJECT_FEATURE_STATE from "../../enums/projectFeatureState";
import PROJECT_STATUS from "../../enums/projectStatus";
import useApi from "../../hooks/useApi";
import { enableVideoRedaction } from "../../services/api/projectApi";
import {
  ProjectFeatureStateUpdate,
  RedactProgressNotification,
} from "../../types/notification";
import { ProjectFeatureState } from "../../types/project";
import LoadingSpinner from "../shared/LoadingSpinner";

import "./style.scss";
import EnableFeatureButton from "../shared/EnableFeatureButton";

const VideoRedactionFeatureLoader = () => {
  const { api } = useApi();
  const { notification } = useContext<AppState>(AppContext);
  const { projectId, videoRedactionState } =
    useContext<RedactEditorState>(RedactEditorContext);
  const [featureState, setFeatureState] = useState<ProjectFeatureState>();
  const [detectFace, setDetectFace] = useState<boolean>(true);

  const progressMessage = useRef<string>("");
  const percentage = useRef<number>(0);

  useEffect(() => {
    setFeatureState(videoRedactionState);
  }, [videoRedactionState]);

  useEffect(() => {
    switch (notification?.type) {
      case NOTIFICATION_TYPE.PROGRESS:
        notifyProgress(notification.data as RedactProgressNotification);
        break;
      case NOTIFICATION_TYPE.FEATURE_STATE_UPDATE:
        notifyStateUpdate(notification.data as ProjectFeatureStateUpdate);
        break;
      default:
        break;
    }
  }, [notification]);

  const notifyStateUpdate = (update: ProjectFeatureStateUpdate) => {
    if (
      update.projectId === projectId &&
      update.feature === "video_redaction"
    ) {
      setFeatureState(update.state);
    }
  };

  const notifyProgress = (progress: RedactProgressNotification) => {
    const isVideoRedaction =
      progress.status === PROJECT_STATUS.REDACTION_QUEUED ||
      progress.status === PROJECT_STATUS.REDACTION_STARTED ||
      progress.status === PROJECT_STATUS.REDACTION_SUCCESS ||
      progress.status === PROJECT_STATUS.REDACTION_FAILED;

    if (
      progress.projectId !== projectId ||
      !isVideoRedaction ||
      (featureState !== PROJECT_FEATURE_STATE.IN_PROGRESS &&
        featureState !== PROJECT_FEATURE_STATE.QUEUED)
    ) {
      return;
    }

    if (progress.status !== PROJECT_STATUS.REDACTION_QUEUED) {
      percentage.current = Math.round(progress.percentComplete ?? 0);
      progressMessage.current = progress.stage;
    } else {
      percentage.current = 0;
      progressMessage.current = "Project is in a processing queue";
    }
  };

  const handleEnableVideoRedaction = async () => {
    await enableVideoRedaction(api, projectId, detectFace);
    setFeatureState(PROJECT_FEATURE_STATE.PENDING);
  };

  const FeatureDisabled = () => (
    <>
      <p>Video Redaction is not enabled for this project.</p>
      <Stack direction="row" spacing={2} alignItems="center">
        <EnableFeatureButton onClick={handleEnableVideoRedaction}>
          Click To Enable
        </EnableFeatureButton>
        <FormControlLabel
          className="project-checkbox"
          control={
            <Checkbox
              checked={detectFace}
              onChange={(e) => setDetectFace(e.target.checked)}
              className="chk-box-color"
              name="detectFace"
            />
          }
          label="Auto Redact Faces"
        />
      </Stack>
    </>
  );

  const FeatureLoading = () => (
    <Stack spacing={2} direction="row" alignItems="center">
      <LoadingSpinner />
      <p>
        Enabling Video Redaction:{" "}
        <span>
          {progressMessage.current} {percentage.current}%
        </span>
      </p>
    </Stack>
  );

  const EnableFeatureFailed = () => (
    <>
      <p>Failed to enable Video Redaction for this project.</p>
      <EnableFeatureButton onClick={handleEnableVideoRedaction}>
        Click To Enable
      </EnableFeatureButton>
    </>
  );

  const EnableFeatureSucceeded = () => (
    <>
      <p>Video Redaction Enabled: Reload the page to start using the feature</p>
      <EnableFeatureButton onClick={() => window.location.reload()}>
        Reload
      </EnableFeatureButton>
    </>
  );

  switch (featureState) {
    case PROJECT_FEATURE_STATE.DISABLED:
      return FeatureDisabled();
    case PROJECT_FEATURE_STATE.FAILED:
      return EnableFeatureFailed();
    case PROJECT_FEATURE_STATE.SUCCESS:
      return EnableFeatureSucceeded();
    case PROJECT_FEATURE_STATE.IN_PROGRESS:
    case PROJECT_FEATURE_STATE.PENDING:
    case PROJECT_FEATURE_STATE.QUEUED:
      return FeatureLoading();
    default:
      return <></>;
  }
};

export default VideoRedactionFeatureLoader;
