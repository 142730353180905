import React, { useEffect, useState, useContext, useRef, ChangeEvent, useMemo, } from "react";
import {
  Box, FormControl, MenuItem, InputLabel, TextField, Grid
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./index.scss"
import { IconButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import InputMask from 'react-input-mask';
import { convertToMilliseconds, convertToTimeString, getDuration, getSpeakerAbbreviation, getSpeakerColor, getTimeString } from "../helper";
import { usePopper } from "react-popper";
import { RedactEditorState } from "../../../context/RedactEditorContext/types";
import { Duration, Speaker, Transcription, TranscriptionStatus } from "../../../types/transcription";
import useFrameTime from "../../../hooks/useFrameTime";
import RedactEditorContext from "../../../context/RedactEditorContext";
import { v4 as uuidv4 } from 'uuid';

interface IRawTranscript {
  id?: string,
  sentence?: string
  startTime?: string,
  endTime?: string,
  speakerId?: string,
  status?: TranscriptionStatus
}

interface IProps {
  transcription?: Transcription,
  isNew?: boolean,
  onCloseForm: () => void,
  onSubmit: (transcription: Transcription) => void,
  transcriptionSpeakers: { [id: string]: Speaker; },
  onAddSpeaker: (speaker: Speaker) => void,
}

const ReactTranscriptionForm: React.FC<IProps> = ({
  transcription = null,
  isNew = false,
  onCloseForm,
  onSubmit,
  transcriptionSpeakers,
  onAddSpeaker,
}) => {
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { videoPlayer, sidePanelSize, } = state;

  const [timeManuallyChanged, setTimeManuallyChanged] = useState<boolean>(false);
  const [frameTimeChanged, setFrameTimeChanged] = useState<boolean>(false);
  const [durationInputName, setDurationInputName] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isDurationMenuOpen, setIsDurationMenuOpen] = useState(false);
  const [isSpeakerMenuOpen, setIsSpeakerMenuOpen] = useState(false);
  const [duration, setDuration] = useState<Duration>();
  const [newSpeakerName, setNewSpeakerName] = useState("");
  const [popperElement, setPopperElement] = useState(null);
  const [data, setData] = useState<IRawTranscript>({
    sentence: transcription?.sentence,
    startTime: convertToTimeString(transcription?.start ?? ((videoPlayer?.currentTime() ?? 0) * 1000)),
    endTime: convertToTimeString(transcription?.end ?? ((videoPlayer?.currentTime() ?? 0) * 1000)),
    speakerId: transcription?.speakerId,
    id: transcription?.id ?? uuidv4().toString(),
    status: transcription?.status ?? TranscriptionStatus.Custom
  });

  const popperWrapperRef = useRef<HTMLDivElement>();
  const formContainerRef = useRef<HTMLDivElement>();

  const { styles, attributes, update } = usePopper(anchorEl, popperElement, {
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ["top", "bottom"]
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'bottom-start'
  });

  useEffect(() => {
    setData({
      ...data,
      [durationInputName]: getTimeString(duration)
    })
  }, [JSON.stringify(duration)])

  useEffect(() => {
    if (convertToMilliseconds(data.startTime) > convertToMilliseconds(data.endTime)) {
      setData({
        ...data,
        endTime: data.startTime
      })
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (isDurationMenuOpen) {
      update();
    }
  }, [sidePanelSize]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popperWrapperRef.current && !popperWrapperRef.current.contains(event.target)) {
        setIsDurationMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popperWrapperRef]);

  const updateTranscriptionPosition = ((frameTime) => {
    if (!timeManuallyChanged && frameTimeChanged) {
      setData({
        ...data,
        startTime: convertToTimeString((frameTime ?? 0) * 1000),
        endTime: convertToTimeString((frameTime ?? 0) * 1000)
      });
    }
    setFrameTimeChanged(true)
  });
  useFrameTime(updateTranscriptionPosition)

  const handleChangeData = (propertyName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (propertyName == "startTime" || propertyName == "endTime") {
      setTimeManuallyChanged(true);
      if (propertyName === durationInputName) {
        setDuration(getDuration(data[propertyName]))
      }
    }
    setData({
      ...data,
      [propertyName]: event.target.value
    });
  };

  const changeSpeaker = (event: SelectChangeEvent) => {
    if (event.target.value) {
      setData({
        ...data,
        speakerId: event.target.value
      });
    }

    setIsSpeakerMenuOpen(false);
  };

  const transformData = () => {
    let newData = {
      start: convertToMilliseconds(data.startTime) + ((transcription?.start ?? 0) % 1000),
      end: convertToMilliseconds(data.endTime) + ((transcription?.end ?? 0) % 1000),
      sentence: data.sentence,
      speakerId: data.speakerId,
      id: data.id,
      status: data.status
    };
    onSubmit({
      ...newData,
      original: JSON.parse(JSON.stringify(newData))
    });
    onCloseForm();
  }

  const addSpeaker = () => {
    if (newSpeakerName) {
      let newValues = {
        id: uuidv4(),
        name: newSpeakerName,
        abbreviation: getSpeakerAbbreviation(newSpeakerName),
        color: getSpeakerColor(Object.values(transcriptionSpeakers).length),
        rightAligned: (Object.keys(transcriptionSpeakers).length) % 2 !== 0
      };
      onAddSpeaker(newValues);
      
      setData({
        ...data,
        speakerId: newValues.id
      });
      setIsSpeakerMenuOpen(false);
      setNewSpeakerName("");
    }
  }

  const openDurationMenu = (inputName: string, event: React.MouseEvent<HTMLDivElement>) => {
    let newStatus = anchorEl != event.currentTarget ? true : !isDurationMenuOpen;
    if (newStatus) {
      setAnchorEl(event.currentTarget);
    }
    setIsDurationMenuOpen(newStatus);
    setDurationInputName(inputName);
    setDuration(getDuration(data[inputName]))
  }

  const updateDuration = (type: string, event: ChangeEvent<HTMLInputElement>) => {
    setDuration({
      ...duration,
      [type]: event.target.value
    });
  }

  const header = useMemo(() => isNew ? "Add New" : "Editing", [isNew]);

  return (
    <Box className='transcription-form' ref={formContainerRef}>
      <div ref={popperWrapperRef}>
        {(data?.status === undefined || data?.status === TranscriptionStatus.Custom) && isDurationMenuOpen && <div ref={setPopperElement} className="popper-box" style={styles.popper} {...attributes.popper}>
          <div className="arrow" style={styles.arrow} />
          <Box className="content durations">
            <div>
              <input value={duration?.hours ?? 0} onChange={(e) => updateDuration('hours', e)} type="number" min="0" step="1" /> Hours
            </div>
            <div>
              <input value={duration?.minutes ?? 0} onChange={(e) => updateDuration('minutes', e)} type="number" min="0" max="60" step="1" /> Minutes
            </div>
            <div>
              <input value={duration?.seconds ?? 0} onChange={(e) => updateDuration('seconds', e)} type="number" min="0" max="60" step="1" /> Seconds
            </div>
            <div style={{ display: "none" }}>
              <input value={duration?.milliseconds ?? 0} onChange={(e) => updateDuration('milliseconds', e)} type="number" min="0" max="1000" step="1" /> Milliseconds
            </div>
          </Box>
        </div>}
      </div>
      <Grid container>
        <Grid item xs={12} className="form-header">
          <h3>{header}</h3>
          <div className="buttons">
            {/* <Button className="editButton redact" onClick={() => setSelectedTranscription(transcription)} >
              Redact
            </Button> */}
            <IconButton className="editButton confirm" onClick={() => transformData()} >
              <CheckIcon />
            </IconButton>
            <IconButton className="editButton cancel" onClick={() => onCloseForm()} >
              <ClearIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={4} className="time">
          <InputLabel>Start Time:</InputLabel>
          {data.status !== 'custom' &&
            <span style={{ color: "#ABAEB0", fontSize: "17px" }}>{convertToTimeString(transcription.start)}</span>}
          {data.status === 'custom' && <FormControl fullWidth className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary open-duration"
            onClick={(e) => openDurationMenu("startTime", e)}>
            <InputMask
              readOnly={data?.status !== TranscriptionStatus.Custom}
              mask="99:99:99"
              className="MuiInputBase-input MuiOutlinedInput-input"
              value={data?.startTime}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeData('startTime', event)}>
            </InputMask>
            <span className="description">hh mm ss</span>
          </FormControl>}
        </Grid>
        <Grid item xs={8}>
          <InputLabel>Speaker</InputLabel>
          <FormControl fullWidth variant="outlined">
            <Select
              key={data?.speakerId}
              onClick={() => setIsSpeakerMenuOpen(!isSpeakerMenuOpen)}
              open={isSpeakerMenuOpen}
              value={data?.speakerId}
              onChange={changeSpeaker}>
              {Object.values(transcriptionSpeakers).map((s: Speaker) => <MenuItem value={s.id}><span>{s.name}</span></MenuItem>)}
              <div
                onClick={(e) => e.stopPropagation()}
                style={{ padding: "0 10px", display: "flex", marginTop: "5px" }}>
                <input
                  type="text"
                  className="add-speakers-input"
                  placeholder="Add Speaker"
                  value={newSpeakerName}
                  onKeyDownCapture={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setNewSpeakerName(e.target.value)}
                />
                <button
                  className="add-speakers-button" onClick={addSpeaker}>
                  Add
                </button>
              </div>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Text:</InputLabel>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              type="text"
              multiline
              maxRows={3}
              value={data?.sentence}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeData('sentence', event)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReactTranscriptionForm;