import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

export const Dropdown = (props) => {
    const { options, onChange, selected, placeHolder } = props
    return (
        <div className='select-input identifiertype-dropdown'>
            <FormControl fullWidth>
                <Select
                    value={selected?.label ? selected.label : placeHolder ? placeHolder : ''}
                    onChange={(e) => onChange('identifierType', e.target.value)}
                >
                    {
                        placeHolder &&
                        <MenuItem disabled value={placeHolder}>
                            <span style={{ color: "#b3b2bd", fontSize: '14px', opacity: 0.5 }}>{placeHolder}</span>
                        </MenuItem>
                    }
                    {
                        options.map((option) => {
                            return (<MenuItem value={option.label}>{option.label}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
        </div>
    );
}
