import { CircularProgress, InputLabel } from "@material-ui/core";
import { Box, Grid, Button, FormControl, Modal } from "@mui/material";
import { FC, useEffect, useState, useContext, useMemo } from "react";
import "../../../pages/workspace/index.scss";
import { Dropdown } from "../../../components/Select/SelectMfaDevice";
import useApi from "../../../hooks/useApi";
import AppContext from "../../../context/AppContext";
import { authenticateWorkspaceDevice, fetchWorkspaceDevices, requestDeviceMfaCode } from "../../../services/api/deviceApi";
import { IAuthenticateDevicePayload, IGroupDevice, IIdentifierType } from "../../../types/device";
import { toast } from "react-toastify";
import DeviceModal from "../../../components/Workspace/DeviceList/DeviceModal";

import { BootstrapInput } from "../../../components/InfoNameInput";
import validator from "validator";
import { getDeviceIdentifierTypes, getDeviceUUID } from "../../../services/functions/devicesAuth";

interface IProps {
    onClose: () => void,
    open: boolean,
    onValid: () => void,
    workspace: string,
    seatCount: number,
    deviceId: string
}

interface IFormData {
    name: string;
    identifier: string;
    identifierType: string;
}

const DeviceVerifyModal: FC<IProps> = ({
    onClose,
    open,
    onValid,
    workspace,
    seatCount,
    deviceId
}) => {
    const initialData = {
        name: '',
        identifier: '',
        identifierType: '',
    }
    const { api, apiToken } = useApi();
    const [mfaDevices, setMfaDevices] = useState([]);
    const [formObject, setFormObject] = useState<IFormData>(initialData);
    const [existingMfaDeviceRequestLoading, setExistingMfaDeviceRequestLoading] = useState(false);
    const [existingMfaDevice, setExistingMfaDevice] = useState("");
    const [error, setError] = useState(null);
    const [openMfaCodeModal, setOpenMfaCodeModal] = useState(false);
    const [identifierTypes, setIdentifierTypes] = useState<IIdentifierType[]>([]);
    const [mfaCode, setMfaCode] = useState("");
    const [verifyMfaDevice, setVerifyMfaDevice] = useState("");
    const [newMfaDeviceRequestLoading, setNewMfaDeviceRequestLoading] = useState(false);
    const [deviceAuthenticating, setDeviceAuthenticating] = useState(false);

    useEffect(() => {
        if (apiToken) {
            const types = getDeviceIdentifierTypes(api, apiToken);
            setIdentifierTypes(types);
            fetchWorkspaceDevices(api, workspace).then((result: IGroupDevice[]) => {
                setMfaDevices(result.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.name}, ${item.identifierType}`
                    }
                }));
            });
        }
    }, [workspace, apiToken]);

    const onChangeHandle = (name, value) => {

        if (name === 'existingMfaDevice') {
            setExistingMfaDevice(value);
        } else {
            setFormObject({ ...formObject, [name]: value });
            const errors = error;
            errors && errors[name] && delete errors[name];
            setError(errors);
        }
    }

    const onCodeChangeHandle = (value: string) => {
        setMfaCode(value.toUpperCase());
        const errors = error;
        errors && errors['mfaCode'] && delete errors['mfaCode'];
        setError(errors);
    }

    const handleExistingDeviceMfaRequest = () => {
        var errors: any = validateFields(true);
        if (errors.existingMfaDevice) {
            setError(errors);
        } else {
            setExistingMfaDeviceRequestLoading(true);
            var payload = {
                id: existingMfaDevice,
                workspaceId: workspace,
                deviceId: deviceId
            };
            requestDeviceMfaCode(api, payload)
                .then((response) => {
                    if (!response.success) {
                        toast(response.data, { type: "error" });
                    } else {
                        toast(response.data, { type: "success" })
                        setVerifyMfaDevice(existingMfaDevice);
                        setOpenMfaCodeModal(true);
                    }
                })
                .catch((e) => {
                    toast(e?.message ?? `Something went wrong while requesting code`, {
                        type: "error",
                    });
                })
                .finally(() => {
                    setExistingMfaDeviceRequestLoading(false);
                });
        }
    }

    const handleNewDeviceMfaRequest = () => {
        var errors: any = validateFields();
        if (errors.name || errors.identifier || errors.identifierType) {
            setError(errors);
        } else {
            if (mfaDevices.length + 1 > seatCount) {
                toast("Workspace devices can't exceed seat count, please contact a site admin.", { type: "error" })
            } else {
                setNewMfaDeviceRequestLoading(true);
                var payload = {
                    ...formObject,
                    workspaceId: workspace,
                    deviceId: deviceId
                }
                requestDeviceMfaCode(api, payload)
                    .then((response) => {
                        if (!response.success) {
                            toast(response.data, { type: "error" });
                        } else {
                            setOpenMfaCodeModal(true);
                            toast(response.data, { type: "success" });
                        }
                    })
                    .catch((e) => {
                        toast(e?.message ?? `Something went wrong while requesting code`, {
                            type: "error",
                        });
                    })
                    .finally(() => {
                        setNewMfaDeviceRequestLoading(false);
                    })
            }
        }
    }

    const validateFields = (isExistingMfaDevice?: boolean) => {
        const errors: any = {};
        if (isExistingMfaDevice && !existingMfaDevice) {
            errors.mfaDeviceId = "Select device";
        }

        if (formObject.identifierType === 'Email' && !validator.isEmail(formObject.identifier)) {
            errors.identifier = "Enter valid email";
        } else if (formObject.identifierType === 'Phone' && !validator.isMobilePhone(formObject.identifier)) {
            errors.identifier = "Enter valid phone";
        }
        return errors;
    }

    const validateCode = () => {
        const errors: any = {};
        if (!mfaCode) {
            errors.mfaCode = "Enter Authentication Code";
        }
        return errors;
    }

    const options = identifierTypes?.map((type) => {
        return { value: type.id, label: type.displayName }
    });

    const header = useMemo(() => openMfaCodeModal ? "Enter Code" : "Or Add a New Device", [openMfaCodeModal]);

    const isFormSubmitDisabled = useMemo(() => {
        return newMfaDeviceRequestLoading ||
            !formObject.name ||
            !formObject.identifier ||
            !formObject.identifierType
    }, [formObject]);

    const authenticateDevice = () => {
        var errors = validateCode();
        if (errors.mfaCode) {
            setError(errors);
        } else {
            setDeviceAuthenticating(true);
            let payload: IAuthenticateDevicePayload = {
                code: mfaCode,
                workspaceId: workspace
            }
            if (verifyMfaDevice) {
                payload.id = verifyMfaDevice;
                payload.deviceId = deviceId;
            } else {
                payload.name = formObject.name;
                payload.deviceId = deviceId;
                payload.identifier = formObject.identifier;
                payload.identifierType = formObject.identifierType;
            }
            authenticateWorkspaceDevice(api, payload)
                .then((result => {
                    if (!result.success) {
                        toast(result.data, { type: 'error' })
                    } else {
                        toast(result.data, { type: "success" })
                        onValid();
                    }
                }))
                .catch((e) => {
                    toast(e?.message ?? `Something went wrong while requesting code`, {
                        type: "error",
                    });
                })
                .finally(() => {
                    setDeviceAuthenticating(false);
                })
        }
    }

    return (
        <Modal open={open} onClose={onClose} className="user-modal">
            <Box className="content">
                <p></p>
                <h5 className="modal-title">Device Authentication</h5>
                <p className="modal-subtitle">This browser is not recognized please register it by authenticating</p>
                <Grid container
                    className="create-project"
                    rowSpacing={0}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 12 }}
                >
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel className="" shrink>Existing MFA Devices</InputLabel>
                            <Dropdown
                                options={mfaDevices}
                                onChange={onChangeHandle}
                                placeHolder={"Select Device"}
                                selected={mfaDevices?.find(
                                    (item) => item.value === existingMfaDevice
                                )}
                            >
                            </Dropdown>
                        </FormControl>
                    </Grid>

                    <Button
                        onClick={handleExistingDeviceMfaRequest}
                        variant="contained"
                        className="send-code-btn btn btn-primary"
                        disabled={
                            !existingMfaDevice || existingMfaDeviceRequestLoading || error?.exisintgMfaDevice || openMfaCodeModal
                        }
                    >
                        {existingMfaDeviceRequestLoading && (
                            <CircularProgress
                                size={18}
                                color="inherit"
                                style={{ marginRight: 8 }}
                            />
                        )}
                        Send Code
                    </Button>
                </Grid>
                <h5 className="modal-title">{header}</h5>
                {!openMfaCodeModal && (
                    <>
                        <Grid
                            container
                            className="create-project"
                            rowSpacing={0}
                            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 12 }}
                        >
                            <DeviceModal
                                errors={error}
                                formObject={formObject}
                                isEdit={false}
                                onChangeHandle={onChangeHandle}
                                options={options}
                            />
                        </Grid>
                        <div>
                            <Button
                                onClick={handleNewDeviceMfaRequest}
                                variant="contained"
                                className="btn btn-primary"
                                disabled={isFormSubmitDisabled}
                            >
                                {newMfaDeviceRequestLoading &&
                                    <CircularProgress
                                        size={18}
                                        color="inherit"
                                        style={{ marginRight: 8 }} />}
                                Save
                            </Button>
                        </div>
                    </>
                )}
                {openMfaCodeModal && (
                    <>
                        <Grid
                            container
                            className="create-project"
                            rowSpacing={0}
                        >
                            <Grid item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <BootstrapInput
                                        placeholder="Code"
                                        className="project-input"
                                        id="mfaCode"
                                        name="mfaCode"
                                        value={mfaCode}
                                        onChange={(e) => onCodeChangeHandle(e.target.value)}
                                        required
                                    />
                                    {error?.mfaCode && <span className="error-msg">{error.mfaCode}</span>}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div>
                            <Button
                                onClick={authenticateDevice}
                                variant="contained"
                                className="btn btn-primary"
                                disabled={
                                    !mfaCode
                                }
                            >
                                {deviceAuthenticating &&
                                    <CircularProgress
                                        size={18}
                                        color="inherit"
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Authenticate
                            </Button>
                        </div>
                    </>
                )}

            </Box>
        </Modal >
    )
}

export default DeviceVerifyModal;