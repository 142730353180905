import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Box,
  InputLabel,
  Button,
  FormControl,
  Grid,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  fetchRoles,
  addUser,
  updateUser,
  addExistedUser,
  fetchUserExistingEmails,
  fetchUserEmails,
} from "../../../services/api/workspaceApi";
import { IRole, IUser, IUserExistingEmail } from "../../../types/workspace";
import useApi from "../../../hooks/useApi";
import { Dropdown } from "../../../components/Select/Select";
import { BootstrapInput } from "../../../components/InfoNameInput";
import "../../../pages/workspace/index.scss";
import _ from "lodash";
import { toast } from "react-toastify";
import validator from "validator";
import UserModal from "./UserModal";
import ConfirmationModal from "../../ConfirmationModal";

interface IProps {
  open: boolean;
  onClose: () => void;
  isEdit: boolean;
  editData?: any;
  fetchData?: () => void;
  workspace?: string;
  workspaceName?: string;
  handleAddUser?: (data: any) => void;
  handleEditUser?: (data: any, key: number) => void;
  isAdminWorkspace?: boolean;
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
  password?: string;
  currentRoleName: string;
}

const AddEditUserModal: React.FC<IProps> = ({
  open,
  onClose,
  isEdit,
  editData,
  fetchData,
  workspace,
  workspaceName,
  handleAddUser,
  handleEditUser,
  isAdminWorkspace,
}) => {
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    roleName: "",
    password: "",
    currentRoleName: ""
  };
  const [formObject, setData] = useState<IFormData>(initialData);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState(null);
  const { api, apiToken } = useApi();
  const [existingUser, setExistingUser] = useState("");
  const [existingUserOptions, setExistingUserOptions] = useState(null);
  const [existingLoading, setExistingLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);


  useEffect(() => {
    const cachedRoles = localStorage.getItem('roles');
    if (cachedRoles) {
      setRoles(JSON.parse(cachedRoles));
    } else if (apiToken) {
      fetchRoles(api).then((result: IRole[]) => {
        setRoles(result);
        localStorage.setItem('roles', JSON.stringify(result));
      });
    }
  }, [apiToken]);

  useEffect(() => {
    if (isEdit) {
      setData({
        firstName: editData.firstName,
        lastName: editData.lastName,
        email: editData.email,
        roleName: editData.roleName,
        currentRoleName: editData.roleName
      });
    }
  }, [isEdit]);

  useEffect(() => {
    fetchEmail();
  }, [apiToken, isEdit, workspace]);

  const fetchEmail = (serachValue?: string) => {
    if (apiToken && !isEdit && serachValue) {
      if (isAdminWorkspace && !workspace) {
        fetchUserEmails(api, serachValue ?? "").then(
          (result: IUserExistingEmail[]) => {
            setExistingUserOptions(result);
          }
        );
      } else if (workspace) {
        fetchUserExistingEmails(api, workspace, serachValue ?? "").then(
          (result: IUserExistingEmail[]) => {
            setExistingUserOptions(result);
          }
        );
      }
    }
  };

  const options = roles?.map((role) => {
    return { value: role.id, label: role.displayName };
  });

  const onChangeHandle = (
    name: string,
    value: any,
    isExistingEmail?: boolean
  ) => {
    if (isExistingEmail) {
      setExistingUser(value);
    } else {
      setData({ ...formObject, [name]: value });
    }
    const errors = error;
    errors && errors[name] && delete errors[name];
    setError(errors);
  };

  const validateFields = (isExistingEmail?: boolean) => {
    let errors: any = {};
    if (isExistingEmail && !validator.isEmail(existingUser)) {
      errors.existingUser = "Enter valid email!";
    } else {
      if (!isEdit && formObject.password.trim().length < 8) {
        errors.password = "Password must have at least 8 characters!";
      }
    }
    return errors;
  };

  const handleAddExisting = () => {
    const errors: any = validateFields(true);
    if (errors.existingUser) {
      setError(errors);
    } else {
      setExistingLoading(true);
      if (isAdminWorkspace) {
        // delete data?.id;
        handleAddUser?.({
          email: existingUser,
          roleName: "Member",
          addedByEmail: true,
        });
        setExistingLoading(false);
        handleClose();
        return;
      }
      addExistedUser(api, workspace, existingUser)
        .then(() => {
          setExistingLoading(false);
          toast("User added successfully", { type: "success" });
          handleClose();
          fetchData?.();
        })
        .catch((e) => {
          setExistingLoading(false);
          toast(e?.message ?? "Something went wrong while add existing user", {
            type: "error",
          });
          handleClose();
        });
    }
  };

  const handleSave = () => {
    const errors: any = validateFields();
    if (errors.email || errors.password || errors.firstName || errors.lastName) {
      setError(errors);
      return;
    }

    if (formObject.roleName === "Admin" && formObject.roleName !== formObject.currentRoleName) {
      setOpenConfirmModal(true);
    } else {
      onSubmit();
    }
  }

  const onSubmit = () => {
    setIsLoading(true);
    let params: IUser = {
      email: formObject.email,
      firstName: formObject.firstName,
      lastName: formObject.lastName,
      roleName: formObject.roleName,
    };

    if (isEdit) {
      onUpdateUser(params);
    } else {
      onAddUser(params);
    }
  };

  const onUpdateUser = (params) => {
    params.id = editData?.id;
    if (isAdminWorkspace) {
      handleEditUser?.(params, editData?.key);
      setIsLoading(false);
      handleClose();
      return;
    }
    updateUser(api, params, workspace)
      .then(() => {
        setIsLoading(false);
        handleClose();
        toast("User updated successfully", { type: "success" });
        fetchData?.();
      })
      .catch((e) => {
        setIsLoading(false);
        toast(`Something went wrong while update user`, { type: "error" });
        handleClose();
      });
  }

  const onAddUser = (params) => {
    params.password = formObject.password;
    if (isAdminWorkspace) {
      handleAddUser?.(params);
      setIsLoading(false);
      handleClose();
      return;
    }
    addUser(api, params, workspace)
      .then((response) => {
        if (!response.success && response.statusCode === 1) {
          setError({ email: response.message });
        } else {
          handleClose();
          fetchData?.();
          toast("User added successfully", { type: "success" });
        }
        setIsLoading(false);
      })
      .catch(() => {
        toast("Something went wrong while add user", { type: "error" });
        setIsLoading(false);
      });
  }

  const handleClose = () => {
    setData(initialData);
    setExistingUser("");
    onClose();
  };

  const generatePassword = () => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    onChangeHandle("password", result);
  };

  const header = useMemo(() => !isEdit ? "Or Add a New User" : "Edit User", [isEdit]);

  const isFormSubmitDisabled = useMemo(() => {
    return isLoading ||
      !formObject.firstName ||
      !formObject.lastName ||
      !formObject.roleName ||
      (!isEdit && (!formObject.email || !formObject.password))
  }, [formObject, isEdit]);

  return (
    <Modal open={open} onClose={handleClose} className="user-modal">
      <Box className="content">
        {!isEdit && (
          <div>
            <h5 className="modal-title">Add Existing User</h5>
            <Grid container alignItems="center">
              <Grid item xs={6} className="create-project">
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Email
                  </InputLabel>
                  <BootstrapInput
                    placeholder="Enter Existing Email"
                    className="project-input"
                    id="existingUser"
                    name="existingUser"
                    value={existingUser}
                    onChange={(e) =>
                      onChangeHandle("existingUser", e.target.value, true)
                    }
                  />
                  {error?.existingUser && (
                    <span className="error-msg">{error.existingUser}</span>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="center">
                <Button
                  onClick={handleAddExisting}
                  variant="contained"
                  className="btn btn-primary"
                  disabled={
                    !existingUser || existingLoading || error?.existingUser
                  }
                >
                  {existingLoading && (
                    <CircularProgress
                      size={18}
                      color="inherit"
                      style={{ marginRight: 8 }}
                    />
                  )}
                  Add
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        <h5 className="modal-title">{header}</h5>
        <Grid
          container
          className="create-project"
          rowSpacing={0}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 12 }}
        >
          <UserModal
            errors={error}
            formObject={formObject}
            isEdit={isEdit}
            onChangeHandle={onChangeHandle}
            options={options}
          />
          {!isEdit && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Password
                  </InputLabel>
                  <BootstrapInput
                    className="project-input"
                    id="password"
                    type={passwordType}
                    placeholder="Password"
                    value={formObject.password}
                    onChange={(e) => onChangeHandle("password", e.target.value)}
                    required
                  />
                  {passwordType === "password" && (
                    <VisibilityOffIcon
                      className="icon-eye"
                      onClick={() => setPasswordType("text")}
                    />
                  )}
                  {passwordType === "text" && (
                    <VisibilityIcon
                      className="icon-eye"
                      onClick={() => setPasswordType("password")}
                    />
                  )}
                  {error?.password && (
                    <span className="error-msg">{error.password}</span>
                  )}
                </FormControl>
              </Grid>
              <div>
                <Button
                  onClick={generatePassword}
                  variant="contained"
                  className="btn btn-primary btn-autogenerate"
                >
                  Autogenerate
                </Button>
              </div>
            </>
          )}
        </Grid>
        <div>
          <Button
            onClick={handleSave}
            variant="contained"
            className="btn btn-primary"
            disabled={isFormSubmitDisabled}
          >
            {isLoading && (
              <CircularProgress
                size={18}
                color="inherit"
                style={{ marginRight: 8 }}
              />
            )}{" "}
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            className="btn btn-danger"
          >
            Cancel
          </Button>
        </div>
        {
          openConfirmModal &&
          <ConfirmationModal
            open={openConfirmModal}
            onClose={() => { setOpenConfirmModal(false) }}
            onConfirm={onSubmit}
            title="Workspace Admin"
            contentText={`You are about to make this user an admin for ${workspaceName}`}
          />
        }
      </Box>
    </Modal>
  );
};

export default AddEditUserModal;
