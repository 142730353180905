import { useCallback, useContext, useEffect, useRef, useState } from "react";

import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "videojs-flash";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/fantasy/index.css";
import "videojs-seek-buttons";

import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import "./videoPlayer.scss";
import AppContext from "../../context/AppContext";
import { IUserPreferencePayload } from "../../types/user";
import { saveUserPreferences } from "../../services/api/userApi";
import useApi from "../../hooks/useApi";
import { debounce } from "lodash";
import { updateMatchingProperties } from "../../utils/object-util";

type VideoPlayerPrefs = {
  playbackSpeed: number;
  volume: number;
  mute: boolean;
};

const VideoPlayer = () => {
  const {
    videoUrl,
    thumbnailUrl,
    originalFileExtension,
    frameRate,
    setVideoPlayer,
  } = useContext<RedactEditorState>(RedactEditorContext);
  const { userPreferences, setUserPreferences } = useContext(AppContext);

  const videoOptions: VideoJsPlayerOptions = {
    muted: false,
    autoplay: false,
    loop: false,
    controls: true,
    preload: "none",
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    },
    responsive: true,
    fluid: false,
    height: 500,
    playbackRates: [0.1, 0.5, 1, 2, 5],
    sources: [
      {
        src: videoUrl,
        type: `video/${originalFileExtension}`,
      },
    ],
    techOrder: ["html5", "flash"],
    plugins: {
      seekButtons: {
        forward: parseFloat((1 / frameRate).toFixed(4)),
        back: parseFloat((1 / frameRate).toFixed(4)),
      },
    },
  };

  /*Video JS */
  const playerRef = useRef<VideoJsPlayer>();
  const videoRef = useRef(null);
  const preferences = useRef<VideoPlayerPrefs>();
  const { api, apiToken } = useApi();

  useEffect(() => {
    if (userPreferences && !preferences.current) {
      preferences.current = {
        playbackSpeed: userPreferences.editorPlaybackSpeed,
        volume: userPreferences.editorVolume,
        mute: userPreferences.editorMuted,
      };
    }
  }, [userPreferences]);

  useEffect(() => {
    let player;
    // make sure Video.js player is only initialized once
    if (videoUrl && videoUrl.length > 0) {
      if (!playerRef.current && apiToken) {
        const videoElement = document.createElement("video-js");
        videoElement.classList.add(
          "video-js",
          "vjs-big-play-centered",
          "vjs-theme-fantasy"
        );
        videoRef.current.appendChild(videoElement);

        player = playerRef.current = videojs(videoElement, videoOptions, () => {
          handlePlayerReady(player);
        });
      }
    }
  }, [videoUrl, apiToken]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  const handleSaveUserPreferences = useCallback(async () => {
    if (!playerRef.current || !apiToken) return;

    const payload: IUserPreferencePayload = {
      editorVolume: parseFloat(playerRef.current.volume().toFixed(2)),
      editorMuted: playerRef.current.muted(),
      editorPlaybackSpeed: playerRef.current.playbackRate(),
    };

    preferences.current = {
      volume: payload.editorVolume,
      mute: payload.editorMuted,
      playbackSpeed: payload.editorPlaybackSpeed,
    };

    const updatedUserPreferences = updateMatchingProperties(
      userPreferences,
      payload
    );
    setUserPreferences(updatedUserPreferences);
    saveUserPreferences(api, payload);
  }, [playerRef.current, apiToken]);

  const debouncedSaveUserPreferences = useCallback(
    debounce(handleSaveUserPreferences, 500),
    [apiToken]
  );

  const applyPreferences = useCallback(() => {
    playerRef.current.muted(
      preferences.current?.mute ?? playerRef.current.muted()
    );
    playerRef.current.volume(
      preferences.current?.volume ?? playerRef.current.volume()
    );
    playerRef.current.playbackRate(
      preferences.current?.playbackSpeed ?? playerRef.current.playbackRate()
    );
  }, [preferences, playerRef.current]);

  const handlePlayerReady = async (player: VideoJsPlayer) => {
    playerRef.current = player;
    if (thumbnailUrl) {
      player.poster(thumbnailUrl);
    }

    const videoElement = videoRef.current.getElementsByTagName(
      "video"
    )[0] as HTMLVideoElement;
    videoElement.setAttribute("crossOrigin", "anonymous");
    player.on("play", applyPreferences);
    player.on("volumechange", debouncedSaveUserPreferences);
    player.on("ratechange", debouncedSaveUserPreferences);
    setVideoPlayer(player, videoElement);

    player.on("dispose", () => {
      setVideoPlayer(undefined, undefined);
    });
  };

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoPlayer;
