import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { EnhancedTableHead } from "./EnhancedTableHead";
import { HeadCell } from "../../types/table";
import "./table.scss";

const CustomTable = ({
  tableHeaders,
  rows,
  page,
  pageSize,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
  handleRowClick,
}: any) => {
  return (
    <Box sx={{ width: "100%" }}>
      <div className="myTable">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table stickyHeader>
              <EnhancedTableHead headCells={tableHeaders} />
              <TableBody>
                {rows?.map((row: any) => {
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleRowClick?.(row)}
                    >
                      {tableHeaders?.length &&
                        tableHeaders.map((item: HeadCell, index: number) => (
                          <TableCell key={index} align="center">
                            <div>{row[item.id]}</div>
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {pageSize && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={count}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
    </Box>
  );
};

export default CustomTable;
