import { fabric } from "fabric";
import { useEffect } from "react";
import { randomColor } from "../utils/redact-util"

type usePointerDrawCallback =  (rect: fabric.Rect) => void

const usePointerDraw = (canvas: fabric.Canvas, callBack: usePointerDrawCallback) => {
    let mouseDown = false;
    let dragged = false;
    let rect: fabric.Rect, origX: number, origY: number;

    useEffect(() => {
        if (canvas) {
            canvas.on('mouse:down', function (o) {
                if (canvas.getActiveObject()) {
                    return;
                }

                mouseDown = true;
                var pointer = canvas.getPointer(o.e);
                origX = pointer.x;
                origY = pointer.y;
                var pointer = canvas.getPointer(o.e);
                rect = new fabric.Rect({
                    left: origX,
                    top: origY,
                    originX: 'left',
                    originY: 'top',
                    width: pointer.x - origX,
                    height: pointer.y - origY,
                    angle: 0,
                    fill: randomColor(0.5),
                    transparentCorners: false
                });
                canvas.add(rect);
            });

            canvas.on('mouse:move', function (o) {
                if (!mouseDown) return;
                
                dragged = true;
                canvas.selection = false;
                var pointer = canvas.getPointer(o.e);

                if (origX > pointer.x) {
                    rect.set({ left: Math.abs(pointer.x) });
                }
                if (origY > pointer.y) {
                    rect.set({ top: Math.abs(pointer.y) });
                }

                rect.set({ width: Math.abs(origX - pointer.x) });
                rect.set({ height: Math.abs(origY - pointer.y) });


                canvas.renderAll();
            });

            canvas.on('mouse:up', function (o) {
                canvas.selection = true;
                mouseDown = false;
                if (!rect) {
                    return;
                }

                canvas.remove(rect);

                if (!dragged) {
                    return;
                }

                dragged = false;
                //create detected object and associated frames
                callBack(rect);
                rect = undefined;
            });
        }
    }, [canvas]);
}

export default usePointerDraw