import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import { IApi } from "../types/apiTypes";
import useInterval from "./useInterval";
import { getLicenseKeyAccessToken } from "../services/functions/licenseKeyAuth";
import { appConfig } from "../appConfig";

const useApi = (): { apiToken?: string, api: IApi } => {

    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState<string>();

    useEffect(() => {
        if (appConfig.IsCloudAuth) {
            getToken();
        }
    }, [instance, accounts])

    useEffect(() => {
        if (appConfig.IsLicenseKeyAuth) {
            const licenseKeyToken = getLicenseKeyAccessToken();
            if (licenseKeyToken) {
                setAccessToken(licenseKeyToken);
            }
        }
    }, [])

    useInterval(() => {
        //get a new token every 15 minutes to prevent token expiry
        //license key token refresh is being handled in the licen key auth wrapper
        if (appConfig.IsCloudAuth) {
            getToken();
        }
    }, 900000)
    const getToken = () => {
        if (instance && accounts && accounts.length > 0) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            instance.acquireTokenSilent(request).then(async (response) => {
                if (response.accessToken.length < 1) {
                    setAccessToken(undefined);
                } else {
                    setAccessToken(response.accessToken);
                }
            }).catch(async (e) => {
                await instance.acquireTokenRedirect(request);
            });
        }
    }

    const handleApiError = (error) => {
        return new Promise((resolve, reject) => {
            if (error.name === 'AbortError') {
                console.info("[Cancelled] ", error);
                resolve(undefined);
            } else {
                console.error("[ERROR] ", error);
                reject(error);
            }
        });
    }

    const remove = (url: string) => {
        return fetch(`${process.env.REACT_APP_API}/${url}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(response => {
            return response.ok
        }).catch(handleApiError)
    }


    const get = (url: string) => {
        return fetch(`${process.env.REACT_APP_API}/${url}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(async response => {
            if (!response.ok) {
                return handleApiError(response)
            }

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json()
            }
        }).catch(handleApiError)
    }

    const getFile = (url: string) => {

        return fetch(`${process.env.REACT_APP_API}/${url}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                return response.blob()
            }
        }).catch(handleApiError)
    }

    const post = (url: string, payload?: any) => {
        return fetch(`${process.env.REACT_APP_API}/${url}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: payload ? JSON.stringify(payload) : null,
        }).then(async (response) => {
            if (response && !response.ok) {
                const responseJson: any = await response.json()
                return handleApiError(responseJson)
            }

            if (response) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json()
                }
            }
        }).catch(handleApiError)
    }

    const request = (url: string, type: string, payload: any) => {
        return fetch(`${process.env.REACT_APP_API}/${url}`, {
            method: type,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: payload ? JSON.stringify(payload) : null,
        }).then(async (response) => {
            if (response && !response.ok) {
                const responseJson: any = await response.json()
                return handleApiError(responseJson)
            }

            if (response) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json()
                }
            }
        }).catch(handleApiError)
    }

    const postForm = (url: string, payload: FormData) => {

        return fetch(`${process.env.REACT_APP_API}/${url}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: payload,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return handleApiError(response)
        }).catch(handleApiError)
    }

    const api: IApi = {
        get, post, postForm, getFile, remove, request
    }

    return {
        apiToken: accessToken,
        api
    }
}

export default useApi