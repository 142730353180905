import React, { useContext, useState, useEffect, useRef, } from "react";
import {
  TextField,
  IconButton,
} from "@mui/material";
import "./index.scss"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { RedactEditorState } from "../../../context/RedactEditorContext/types";
import RedactEditorContext from "../../../context/RedactEditorContext";
import { Box, } from "@material-ui/core";
import { usePopper } from "react-popper";
import { capitalizeFirstLetter, convertToTimeString, getSpeakerAbbreviation, getSpeakerColor } from "../helper";
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_SPEAKER, SearchMatch, Speaker, Transcription, } from "../../../types/transcription";
import TRANSCRIPTION_TYPE from "../../../enums/transcriptionTypes";

interface IProps {
  transcript: Transcription,
  onRemoveTranscript: (id: string) => void,
  onEditTranscript: (transcript: Transcription) => void,
  onAddSpeaker: (speaker: Speaker) => void,
  onEditSpeaker: (speaker: Speaker) => void,
  transcriptionType: string,
  openedEditModule: string,
  setOpenedEditModuleId: React.Dispatch<React.SetStateAction<string>>,
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>,
  transcriptionSpeakers: { [id: string]: Speaker; },
  frameTime: number,
  first: boolean,
  searchText?: string,
  searchMatch?: SearchMatch
}

const ReactTranscriptionChat: React.FC<IProps> = ({
  transcript,
  transcriptionType,
  onRemoveTranscript,
  onEditTranscript,
  onAddSpeaker,
  onEditSpeaker,
  openedEditModule,
  setOpenedEditModuleId,
  setIsFormOpen,
  transcriptionSpeakers,
  frameTime,
  first,
  searchText,
  searchMatch,
}) => {
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { videoPlayer, sidePanelSize, setSelectedTranscription } = state;

  const [data, setData] = useState<Transcription>({
    ...transcript,
    original: transcript,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [renamingSpeakerId, setRenamingSpeakerId] = useState(undefined);
  const [isSpeakerMenuOpen, setIsSpeakerMenuOpen] = useState(false);
  const [editingSpeakerName, setEditingSpeakerName] = useState("");
  const [editingSpeakerAbbreviation, setEditingSpeakerAbbreviation] = useState("");
  const [isCurrentPlaying, setIsCurrentlyPlaying] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [newSpeakerName, setNewSpeakerName] = useState("");
  const [addedSpeaker, setAddedSpeaker] = useState<Speaker>(null);

  const popperWrapperRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const openSpeakerMenuRef = useRef<HTMLButtonElement>();

  const { styles, attributes, update } = usePopper(anchorEl, popperElement, {
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ["bottom-start", "bottom-end", "top-start", "top-end"]
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'bottom-start'
  });

  useEffect(() => {
    if (frameTime >= data.start && frameTime <= data.end) {
      setIsCurrentlyPlaying(true);
    }
  }, [])

  useEffect(() => {
    if (openedEditModule !== data.id) {
      setIsSpeakerMenuOpen(false);
    }
  }, [openedEditModule]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popperWrapperRef.current && !popperWrapperRef?.current?.contains(event.target) && !openSpeakerMenuRef?.current?.contains(event.target)) {
        if (isSpeakerMenuOpen && openedEditModule === data.id) {
          setOpenedEditModuleId(undefined);
        }
        setIsSpeakerMenuOpen(false);
      }
    }
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [popperWrapperRef]);

  useEffect(() => {
    if (isSpeakerMenuOpen && openedEditModule === data.id) {
      update();
    }
  }, [sidePanelSize]);

  useEffect(() => {
    if (frameTime >= data.start && frameTime <= data.end) {
      setIsCurrentlyPlaying(true);
    } else {
      setIsCurrentlyPlaying(false);
    }
  }, [frameTime, data]);

  useEffect(() => {
    if(addedSpeaker != null){
      onChangeSpeaker(addedSpeaker.id)
      setAddedSpeaker(null)
    }

  }, [transcriptionSpeakers])

  const onSaveEdit = (transcript: Transcription) => {
    onEditTranscript(transcript);
    setIsSpeakerMenuOpen(false);
    setOpenedEditModuleId(undefined);
  }

  const onChangeSpeaker = (speakerId: string) => {
    let newTranscript = {
      ...transcript,
      speakerId: speakerId
    };
    setData(newTranscript);

    onSaveEdit(newTranscript);
  }

  const openSpeakerMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isSpeakerMenuOpen) {
      setIsFormOpen(false);
      setAnchorEl(event.currentTarget);
      setOpenedEditModuleId(data.id);
      setIsSpeakerMenuOpen(true);
    } else {
      setOpenedEditModuleId(undefined);
      setIsSpeakerMenuOpen(false);
    }
  }

  const openEdit = () => {
    setIsSpeakerMenuOpen(false);
    setOpenedEditModuleId(data.id);
    setIsFormOpen(true);
  }

  const addSpeaker = () => {
    if (newSpeakerName) {
      let newValues = {
        id: uuidv4().toString(),
        name: newSpeakerName,
        abbreviation: getSpeakerAbbreviation(newSpeakerName),
        color: getSpeakerColor(Object.values(transcriptionSpeakers).length),
        rightAligned: (Object.keys(transcriptionSpeakers).length) % 2 !== 0
      };
      setNewSpeakerName("");
      setAddedSpeaker(newValues)
      onAddSpeaker(newValues);
    }
  }

  const editSpeaker = (id: string) => {
    if (editingSpeakerName) {
      let newValues = {
        ...transcriptionSpeakers[id] ?? DEFAULT_SPEAKER,
        id,
        name: editingSpeakerName,
        abbreviation: editingSpeakerAbbreviation,
      };
      onEditSpeaker(newValues);
      setEditingSpeakerName("");
      setRenamingSpeakerId("");
    }
  }

  const openRenameSpeaker = (id: string) => {
    setEditingSpeakerName(transcriptionSpeakers[id].name);
    setEditingSpeakerAbbreviation(transcriptionSpeakers[id].abbreviation);
    setRenamingSpeakerId(id);
  }

  const getHighlightedText = (text) => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
    let matchesCount = -1;
    return <span> {parts.map((part, i) => {
      if (part.toLowerCase() === searchText?.toLowerCase()) matchesCount++;
      return <span key={i} style={part.toLowerCase() === searchText?.toLowerCase() ?
        {
          backgroundColor: searchMatch?.transcriptionId === transcript.id && searchMatch?.index === matchesCount ? "orange" : "yellow",
          color: "black"
        } : {}}>
        {part}
      </span>
    })
    } </span>;
  }

  return (
    <>
      {transcriptionType === TRANSCRIPTION_TYPE.BASIC &&
        <>
          {!first && !!data.newPage && <br />}
          <span
            onClick={() => videoPlayer?.currentTime(data.start / 1000)}
            className={`basic-text ${isCurrentPlaying ? "current" : ""}`} >
            {getHighlightedText(data.sentence.trim() + ' ')}
          </span>
        </>}

      {transcriptionType !== TRANSCRIPTION_TYPE.BASIC &&
        <>

          <div ref={popperWrapperRef}>
            {isSpeakerMenuOpen && openedEditModule === data.id && <div ref={setPopperElement} className="popper-box" style={styles.popper} {...attributes.popper}>

              <div className="arrow" style={styles.arrow} />
              <Box className="content">
                <div>
                  SPEAKERS
                </div>
                {Object.values(transcriptionSpeakers).map((speaker: Speaker) => {
                  return <div key={speaker.id}>
                    <button
                      className="speaker-bubble small"
                      style={{ backgroundColor: speaker?.color, border: 0, cursor: "pointer" }}
                      onClick={() => onChangeSpeaker(speaker.id)}
                    >{speaker?.abbreviation}</button>

                    {renamingSpeakerId !== speaker.id &&
                      <>
                        <div style={{ width: "calc(100% - 80px)", display: "flex", alignItems: "center" }}>
                          <span style={{ cursor: "pointer" }} onClick={() => onChangeSpeaker(speaker.id)}>{speaker.name}</span>
                          {speaker.id === transcriptionSpeakers[data.speakerId]?.id && <CheckIcon style={{ marginLeft: "5px" }} className="current" />}
                        </div>

                        <IconButton style={{ color: "#808191" }} onClick={() => openRenameSpeaker(speaker.id)} >
                          <EditIcon />
                        </IconButton>
                      </>}

                    {renamingSpeakerId === speaker.id &&
                      <>
                        <TextField
                          variant="outlined"
                          type="text"
                          placeholder="Add Speaker"
                          className="edit-speaker"
                          value={editingSpeakerName}
                          onChange={(e) => { setEditingSpeakerName(e.target.value); setEditingSpeakerAbbreviation(getSpeakerAbbreviation(e.target.value)); }}
                          style={{ marginTop: "5px" }}
                        />
                        <TextField
                          variant="outlined"
                          type="text"
                          placeholder="Abbreviation"
                          className="edit-speaker"
                          value={editingSpeakerAbbreviation}
                          onChange={(e) => setEditingSpeakerAbbreviation(e.target.value)}
                          style={{ marginTop: "5px" }}
                        />
                        <div className="actions">
                          <IconButton style={{ color: "green" }} onClick={() => editSpeaker(speaker.id)} >
                            <CheckIcon />
                          </IconButton>
                          <IconButton style={{ color: "#808191" }} onClick={() => setRenamingSpeakerId(undefined)} >
                            <ClearIcon />
                          </IconButton>
                        </div>
                      </>
                    }
                  </div>
                })}
                <div style={{ marginTop: "5px" }}>
                  <input
                    type="text"
                    className="add-speakers-input"
                    placeholder="Add Speaker"
                    value={newSpeakerName}
                    onChange={(e) => setNewSpeakerName(e.target.value)}
                  />
                  <button
                    className="add-speakers-button" onClick={addSpeaker}>
                    Add
                  </button>
                </div>
              </Box>
            </div>}
          </div>
          <div
            className={`row ${(transcriptionSpeakers[data.speakerId] ?? DEFAULT_SPEAKER).rightAligned ? "right" : "left"}`}
            ref={containerRef}>
            <>
              <div className="speaker">
                <button
                  ref={openSpeakerMenuRef}
                  className="speaker-bubble" style={{ backgroundColor: (transcriptionSpeakers[data.speakerId] ?? DEFAULT_SPEAKER).color, cursor: "pointer" }}
                  onClick={(e) => openSpeakerMenu(e)}>
                  {(transcriptionSpeakers[data.speakerId] ?? DEFAULT_SPEAKER).abbreviation}
                </button>

              </div>
              <div className="transcript">
                <div className={`sentence ${isCurrentPlaying ? "current" : ""}`} id={`chat-${data.start}`}>
                  <div onClick={() => setTimeout(() => { videoPlayer?.currentTime(data.start / 1000) }, 200)} onDoubleClick={() => setSelectedTranscription(data)} className="text">
                    {getHighlightedText(data.sentence)}
                  </div>
                  <div className="actions">
                    <IconButton style={{ color: "#808191" }} onClick={() => openEdit()} >
                      <EditOutlinedIcon />
                    </IconButton>
                    <IconButton style={{ color: "#F75858" }} onClick={() => onRemoveTranscript(data.id)} >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="time">{convertToTimeString(data.start)}
                  {
                    data.status !== "original" && <>
                      <div className="vertical-line" style={{ height: "1em", backgroundColor: "#BACBD5" }}></div> {capitalizeFirstLetter(data.status)}
                    </>
                  }</div>

              </div>
            </>
          </div>
        </>}
    </>
  );
};

export default ReactTranscriptionChat;
