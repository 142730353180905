import { IApi } from "../../types/apiTypes";
import { ApiResponse } from "../../types/apiResponse";
import {
  IUser,
  IUserPayload,
  IUserPreference,
  IUserPreferencePayload,
} from "../../types/user";

export const getUsers = (
  api: IApi,
  page: number,
  pageSize: number,
  keyword: string
): Promise<ApiResponse<IUser[]>> => {
  const url = `user/list?page=${page}&pageSize=${pageSize}&keyword=${keyword}`;
  return api.get(url).then((result: ApiResponse<IUser[]>) => {
    return result;
  });
};

export const addUser = (
  api: IApi,
  params: IUserPayload
): Promise<ApiResponse<string>> => {
  const url = `user/create`;
  return api
    .post(url, params)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const updateUser = (
  api: IApi,
  params: IUserPayload,
  id: string
): Promise<string> => {
  const url = `user/${id}/update`;
  return api
    .post(url, params)
    .then((result: ApiResponse<string>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const deleteUser = (api: IApi, id: string): Promise<string> => {
  const url = `user/${id}/delete`;
  return api.remove(url).then((result: ApiResponse<string>) => {
    return result.data;
  });
};

export const saveUserPreferences = (
  api: IApi,
  params: IUserPreferencePayload
): Promise<IUserPreference> => {
  const url = `identity/preferences`;
  return api
    .post(url, params)
    .then((result: ApiResponse<IUserPreference>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getUserPreferences = (api: IApi): Promise<IUserPreference> => {
  const url = `identity/preferences`;
  return api
    .get(url)
    .then((result: ApiResponse<IUserPreference>) => {
      return result.data;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};
