import { CardContent, Card } from "@mui/material";
import DETECTION_TYPE from "../../enums/detectionType";
import { DetectionType } from "../../types/object";
import "./DetectedObjectCard.scss"

interface ComponentProps {
  objectId: string,
  sequence: number,
  color?: string,
  thumbnailUrl?: string,
  detectionType: DetectionType
  name?: string
}

const DetectedObjectCard = ({ sequence, color, thumbnailUrl, detectionType, name }: ComponentProps) => {

  const getThumbnailImage = (): string => {
    if (!thumbnailUrl) {
      return "https://via.placeholder.com/150"
    }

    return thumbnailUrl
  }

  return (
    <Card className="detected-block">
      <div className="preview-img">
      {(detectionType === DETECTION_TYPE.AUTO && thumbnailUrl) &&
          <img src={getThumbnailImage()}/>
      }
      </div>
      <div
        style={{
          backgroundColor: color,
          color: "rgb(255, 255, 255)",
          padding: "5px",
          width: "100%",
        }}
      ></div>
      <div className="detail-section">
        Object No.: <kbd>{sequence}</kbd>
        <div>Name: <kbd>{name || 'N/A'}</kbd></div>
      </div>
    </Card>
  );
}

export default DetectedObjectCard