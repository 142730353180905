import { FC } from "react";
import "./index.scss";

type Props = {
    messages: string[];
};

const LoadingScreen: FC<Props> = ({messages}) => {

    return (
        <div>
            <div className="over-style">
                <div className="app-loader">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <div style={{padding: 10, textAlign: "center" }}>
                    {messages.map((msg) => <p key={msg}>{msg}</p>)}
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;
