import { useContext } from "react"
import AudioSegmentDetails from "./AudioSegmentDetails"
import DetectedInfo from "./DetectedInfo"
import UserDetectedObjectInfo from "./UserDetectedObjectInfo"
import RedactEditorContext from "../../context/RedactEditorContext"
import { RedactEditorState } from "../../context/RedactEditorContext/types"
import DETECTION_TYPE from "../../enums/detectionType"
import { FocusedAudioSegment, FocusedDetectedObject, FocusedMultiObjects, FocusedROI } from "../../types/focusedObjects"
import MultiObjectEditor from "./MultiObjectEditor"

export enum FocusedItemType {
    DetectedObject,
    AudioSegment,
    RegionOfInterest
}

const RedactPropertyTab = () => {

    const { focusedItem } = useContext<RedactEditorState>(RedactEditorContext)

    const getTab = () => {

        if (!focusedItem) {
            return <></>
        }

        if ((focusedItem as FocusedAudioSegment).segmentId) {
            return <AudioSegmentDetails />
        }

        const focusedObjects = (focusedItem as FocusedMultiObjects)?.objects;
        if (focusedObjects) {
            return <MultiObjectEditor objects={focusedObjects} />
        }

        const detectedObject = focusedItem as FocusedDetectedObject;
        if (detectedObject.objectId) {
            return detectedObject.detectionType === DETECTION_TYPE.AUTO ? 
                <DetectedInfo objectId={detectedObject.objectId} /> :
                <UserDetectedObjectInfo focusedObject={detectedObject} />
        }

        return <></>
    }

    return <>
        {getTab()}
    </>
}

export default RedactPropertyTab