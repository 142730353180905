import { HubConnectionState } from "@microsoft/signalr";
import { useContext, useEffect } from "react";
import AppContext from "../../context/AppContext";
import { AppState } from "../../context/AppContext/types";
import NOTIFICATION_TYPE from "../../enums/notificationType";
import { ProjectFeatureStateUpdate, ProjectStatusUpdate, RedactProgressNotification } from "../../types/notification";
import useApi from "../useApi";

const useProjectNotifications = () => {

    const { apiToken } = useApi();
    const { hubConnection, setLatestNotification } = useContext<AppState>(AppContext);

    useEffect(() => {

        return () => {
            if (hubConnection?.state === HubConnectionState.Connected) {
                hubConnection.off("NotifyStatusUpdate");
                hubConnection.off("NotifyRedactionProgress");
                hubConnection.off("NotifyFeatureStateUpdate");
            }
        }
    }, []);

    useEffect(() => {
        if (hubConnection?.state === HubConnectionState.Connected && apiToken) {
            bindCallbacks();
        }
    }, [apiToken, hubConnection?.state, hubConnection?.connectionId]);

    const bindCallbacks = () => {
        if (hubConnection && hubConnection.state === HubConnectionState.Connected) {

            hubConnection.off("NotifyStatusUpdate");
            hubConnection.off("NotifyRedactionProgress");
            hubConnection.off("NotifyFeatureStateUpdate");

            hubConnection.on("NotifyStatusUpdate", (update: ProjectStatusUpdate) => {
                setLatestNotification({
                    type: NOTIFICATION_TYPE.STATUS_UPDATE,
                    data: update
                });
            });

            hubConnection.on("NotifyFeatureStateUpdate", (update: ProjectFeatureStateUpdate) => {

                setLatestNotification({
                    type: NOTIFICATION_TYPE.FEATURE_STATE_UPDATE,
                    data: update
                });
            });
    
            hubConnection.on("NotifyRedactionProgress", (progress: RedactProgressNotification) => {

                setLatestNotification({
                    type: NOTIFICATION_TYPE.PROGRESS,
                    data: progress
                });
            });
        }
    }

}

export default useProjectNotifications