import { HeadCell } from '../../types/table';

export const tableWorkspaceHeaders: readonly HeadCell[] = [
    {
        id: 's/n',
        label: '#',
    },
    {
        id: 'displayName',
        label: 'Name',
        sort: 'DisplayName',
    },
    {
        id: 'status',
        label: 'Status',
        sort: 'Status',
    },
    {
        id: 'totalMembers',
        label: 'Member',
        sort: 'TotalMembers',
    },
    {
        id: 'createdDateTime',
        label: 'Date Created',
        sort: 'CreatedDateTime',
    },
    {
        id: 'action',
        label: "",
    }
];

export const tableUserHeaders: readonly HeadCell[] = [
    {
        id: 's/n',
        label: '#',
    },
    {
        id: 'fullName',
        label: 'Name',
        sort: 'fullName',
    },
    {
        id: 'roleName',
        label: 'App Role',
        sort: 'roleName',
    },
    {
        id: 'email',
        label: 'Email Address',
        sort: 'email',
    },
    {
        id: 'action',
        label: "",
    }
];

export const workspaceSettingHeaders: readonly HeadCell[] = [
    {
        id: 's/n',
        label: '#',
    },
    {
        id: 'fullName',
        label: 'Name',
    },
    {
        id: 'role',
        label: 'Workspace Role',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'admin',
        label: 'Admin',
    },
    {
        id: 'action',
        label: "",
    }
];

export const workspaceDeviceHeaders: readonly HeadCell[] = [
    {
        id: 's/n',
        label: '#'
    },
    {
        id: 'name',
        label: 'Name'
    },
    {
        id: 'identifier',
        label: 'Identifier'
    },
    {
        id: 'identifierType',
        label: 'Identifier Type'
    },
    {
        id: 'action',
        label: ""
    }
]