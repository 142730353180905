import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  Tooltip,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

import "./index.scss";
import VideoBlobPreview from "../VideoBlobPreview";
import { FC, useEffect, useRef, useState } from "react";
import { VideoThumbnail } from "../VideoThumbnailSelector/types";

interface Props {
  url?: string;
  setSelectedThumbnail: (thumb: VideoThumbnail) => void;
  isEdit?: boolean;
}

const getVideoCovers = (
  url: string,
  seekTo: number = 0.0
): Promise<VideoThumbnail[]> => {
  return new Promise((resolve, reject) => {
    const timestamps = [];
    const MAX_THUMBS = 12;

    // load the url to a hidden video player
    const thumbs: Array<VideoThumbnail> = [];
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", url);
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file: -> " + ex);
    });

    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }

      //🐛 delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        const duration = videoPlayer.duration;
        for (let i = 0; i < MAX_THUMBS; ++i) {
          timestamps.push((duration * i) / 10);
        }
        const i = setInterval(() => {
          videoPlayer.currentTime = timestamps[timestamps.length - 1];
          timestamps.pop();
          if (timestamps.length === 0) {
            clearInterval(i);
          }
        }, 300);
      }, 200);

      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

        // extract the image data from the canvas
        ctx.canvas.toBlob((blob) => {
          if (blob != null) {
            const dataURI = ctx.canvas.toDataURL("image/jpeg");
            thumbs.push({ url: dataURI, blob: blob });
          }

          // resolve the promise with the image data once all has ben seeked
          if (timestamps.length === 0) {
            resolve(thumbs);
          }
        }, "image/jpeg");
      });
    });
  });
};

const VideoPreviewAndThumbnailSelector: FC<Props> = ({
  url,
  setSelectedThumbnail,
  isEdit,
}) => {
  const [thumbnails, setThumbnails] = useState<VideoThumbnail[]>([]);
  const [currentThumb, setCurrentThumbnail] = useState<VideoThumbnail>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputImage, setInputImage] = useState(null);
  const imageInputRef = useRef(null);

  useEffect(() => {
    if (currentThumb) {
      setSelectedThumbnail(currentThumb);
    }
    if (inputImage) {
      setSelectedThumbnail(inputImage);
    }
  }, [currentThumb, inputImage]);

  useEffect(() => {
    if (url) {
      setLoading(true);
      getVideoCovers(url, 2.5)
        .then((thumbs) => {
          setThumbnails(thumbs);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  const handleUploadThumb = async (value: any) => {
    const url = URL.createObjectURL(value);
    await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        setInputImage({
          index: -1,
          url: url,
          blob: blob,
        });
        setCurrentThumbnail(null);
      })
      .catch(() => {
        return;
      });
  };

  const getThumbnails = () => {
    const len = inputImage ? 5 : 6;
    return thumbnails.slice(0, len);
  }

  return (
    <div className="grid-container">
      <div className="video-preview">
        <VideoBlobPreview url={url} />
      </div>

      {url && (
        <>
          <div className="input-thumbnail">
            <Tooltip title="Browse to Upload Custom Thumbnail">
              <Button
                id="upload-project-thumbnail"
                variant="contained"
                component="label"
              >
                <UploadIcon />
                Upload Thumbnail
                <input
                  type="file"
                  className="form-item choose-file"
                  name="file"
                  accept="image/*"
                  onChange={(e) => handleUploadThumb(e.target.files[0])}
                  ref={imageInputRef}
                  hidden
                />
              </Button>
            </Tooltip>

            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress className="thumbnailLoader" />
              </Box>
            )}
          </div>
          {!loading && (
            <>
              {thumbnails.length == 0 && (
                <div className="label">
                  <p>
                    No video thumbnail options to display. Please upload a
                    custom thumbnail.
                  </p>
                </div>
              )}

              {inputImage && inputImage?.url && (
                <Grid container className="thumbnail-container">
                  <img
                    src={inputImage?.url}
                    alt="Thumb"
                    className="thumbnail-item"
                  />
                  <div className="thumbnail-selected" />
                </Grid>
              )}
              {getThumbnails().map((img, i) => {
                return (
                  <Grid container key={i} className="thumbnail-container">
                    <img
                      onClick={(e) => {
                        // somehow some URL's are the same especially if a frame spans multiple minutes & its short
                        // ..so added an index to set the current click img
                        img.index = i;
                        setCurrentThumbnail(img);
                        if (imageInputRef?.current?.value) {
                          imageInputRef.current.value = "";
                        }
                        setInputImage(null);
                      }}
                      className="thumbnail-item"
                      // height={150}
                      // width={200}
                      src={img.url}
                      srcSet={img.url}
                      alt={"thumbnail " + i}
                      loading="lazy"
                    />
                    {currentThumb?.index === i && (
                      <div className="thumbnail-selected" />
                    )}
                  </Grid>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VideoPreviewAndThumbnailSelector;
