import { FormControl, Grid, InputLabel, formControlClasses } from "@mui/material";
import { BootstrapInput } from "../../../components/InfoNameInput";
import "../../../pages/workspace/index.scss";
import { Dropdown } from "../../../components/Select/SelectIdentifierType";
import { useMemo } from "react";


interface IProps {
    onChangeHandle: (name: string, value: any) => void,
    formObject: IFormData,
    options: { value: string, label: string }[],
    isEdit: boolean,
    errors: any
}

interface IFormData {
    name: string;
    identifier: string;
    identifierType: string;
}

const DeviceModal: React.FC<IProps> = ({
    onChangeHandle,
    formObject,
    options,
    errors = {}
}) => {

    const identifierPlacHolder = useMemo(() => {
        if (formObject?.identifierType === 'Email') {
            return "e.g address@example.com";
        } else if (formObject?.identifierType === 'Phone') {
            return "e.g +1(999) 999-9999"
        }
        return "Identifier"
    }, [formObject.identifierType])

    return (
        <>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                    <InputLabel className="" shrink>
                        Name
                    </InputLabel>
                    <BootstrapInput
                        placeholder="Name"
                        className="project-input"
                        id="name"
                        name="name"
                        value={formObject.name}
                        onChange={(e) => onChangeHandle("name", e.target.value)}
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                    <InputLabel shrink>Identifier Type</InputLabel>
                    <Dropdown
                        options={options}
                        onChange={onChangeHandle}
                        placeHolder={"Select Identifier Type"}
                        selected={options?.find(
                            (item) => item.label === formObject.identifierType)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                    <InputLabel className="" shrink>
                        Identifier
                    </InputLabel>
                    <BootstrapInput
                        placeholder={identifierPlacHolder}
                        className="project-input"
                        id="identifier"
                        name="identifier"
                        value={formObject.identifier}
                        onChange={(e) => onChangeHandle("identifier", e.target.value)}
                        required
                    />
                    {errors?.identifier && <span className="error-msg">{errors.identifier}</span>}
                </FormControl>
            </Grid>
        </>
    );
};

export default DeviceModal;