import React from "react"
import { HubConnectionState } from "@microsoft/signalr"
import { IconButton, Link, Menu, Stack, Typography, Button } from "@mui/material"

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import NotificationsIcon from "@mui/icons-material/Notifications";
import MarkreadIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from "react"
import AppContext from "../../context/AppContext"
import { AppState } from "../../context/AppContext/types"
import useApi from "../../hooks/useApi"
import { getNotifications, markAsRead, deleteNotification, clearNotifications } from "../../services/api/notificationApi"
import { NotificationData } from "../../types/notification"
import "./AppNotification.scss"

const AppNotification = () => {

    const { apiToken, api } = useApi();
    const { hubConnection, } = useContext<AppState>(AppContext)
    const [notifications, setNotifications] = useState<Array<NotificationData>>([]);
    const [notificationInterval, setNotificationInterval] = useState<any>(null)

    useEffect(() => {
        if(notificationInterval) {
            clearInterval(notificationInterval);
        }
        const interval = setInterval(updateNotifications, 30000);
        setNotificationInterval(interval)
    }, [])

    useEffect(() => {
        if (hubConnection?.state === HubConnectionState.Connected && apiToken) {
            hubConnection.off('Notify');
            hubConnection.on('Notify', () => {
                updateNotifications()
            });
        }
    }, [apiToken, hubConnection?.state, hubConnection?.connectionId])

    useEffect(() => {
        updateNotifications()
    }, [apiToken])

    const updateNotifications = () => {
        if (apiToken) {
            getNotifications(api).then((data: NotificationData[]) => {
                setNotifications(data)
            })
        }
    }

    const markRead = (id: string) => {
        markAsRead(api, [id])
        const clone = [...notifications]
        const index = clone.findIndex(x => x.id === id)
        if (index > -1) {
            clone[index].read = true
            setNotifications(clone)
        }
    }

    const handleDelete = (id: string) => {
        deleteNotification(api, id)
        const clone = notifications.filter(x => x.id !== id)
        setNotifications(clone)
    }

    const handleClearAll = () => {
        clearNotifications(api)
        setNotifications([])
    }

    const markAllRead = () => {
        const clone = [...notifications]
        const unread = clone.filter(x => !x.read).map(x => x.id)
        if (unread.length < 1) {
            return
        }

        markAsRead(api, unread)
        clone.forEach(x => {
            x.read = true
        })
        setNotifications(clone)
    }

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <div style={{ position: 'relative' }}>
                        <IconButton aria-label="notification" className="navbar-icon" {...bindTrigger(popupState)}>
                            <NotificationsIcon />
                        </IconButton>
                        <div style={{
                            height: 10,
                            width: 10,
                            backgroundColor: "#ff0000",
                            borderRadius: "50%",
                            position: "absolute",
                            top: '20%',
                            left: '50%',
                            display: notifications.some(x => x.read === false) ? 'block' : 'none'
                        }} />
                    </div>
                    <Menu {...bindMenu(popupState)}
                        className="notification-menu"
                        onClose={(e) => {
                            popupState.close()
                            markAllRead();
                        }}
                    >
                        {notifications.length > 0 ?
                            <Stack className="wrapper">
                                <Button className="clear-all" variant="outlined" fullWidth onClick={handleClearAll}>
                                    Clear all
                                </Button>
                                <div className="list">
                                    {notifications.map((x) =>
                                        <div key={x.id} className={`notification-item ${!x.read && "unread"}`}>
                                            <Link href="#" onClick={() => markRead(x.id)}>
                                                <div className='message'>
                                                    {x.read ? <MarkreadIcon /> : <MarkunreadIcon />}
                                                    <Typography
                                                        style={{ margin: 7, fontSize: 14 }}>
                                                        {x.message}
                                                    </Typography>
                                                </div>
                                                <div className="time">
                                                    <Typography>
                                                        {new Date(x.lastUpdated).toDateString() + ' - ' + new Date(x.lastUpdated).toLocaleTimeString()}
                                                    </Typography>

                                                </div>
                                            </Link>
                                            <CloseIcon className='delete-icon' onClick={() => handleDelete(x.id)} />
                                        </div>

                                    )}
                                </div>
                            </Stack> :
                            <Typography className="empty" >
                                You have no new notifications
                            </Typography>
                        }
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>)
}

export default AppNotification