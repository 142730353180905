import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  InputLabel,
  Button,
  FormControl,
  Grid,
  CircularProgress,
  Stack,
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

import {
  addUser,
  updateUser,
} from "../../../services/api/userApi";
import { Dropdown } from "../../../components/Select/Select";
import { IGroupSummary, IUserPayload } from "../../../types/user";
import useApi from "../../../hooks/useApi";
import { BootstrapInput } from "../../InfoNameInput";
import '../../../pages/admin/Admin.scss';
import validator from 'validator';
import { toast } from 'react-toastify';
import ConfirmationModal from "../../ConfirmationModal";
import { formatWithOptions } from "util";
import DeleteModal from "../../DeleteModal";
import { IconButton, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { inherits } from "util";
import styles from './userStyle.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  isEdit: boolean;
  editData: any;
  fetchData: () => void;
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  appRoleName: string;
  currentAppRoleName: string
  groups: Array<IGroupSummary>;
  deleteGroups?: Array<string>
}

const roleOptions = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Member' }
]

const AddEditUserAdmin: React.FC<IProps> = ({
  open,
  onClose,
  isEdit,
  editData,
  fetchData,
}) => {
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    appRoleName: "",
    currentAppRoleName: "",
    groups: []
  };
  const [formObject, setData] = useState<IFormData>(initialData);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [error, setError] = useState(null);
  const [openDeleteWorkspaceModal, setOpenDeleteWorkspaceModal] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<IGroupSummary>(undefined);

  const { api } = useApi();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setData({
        firstName: editData.firstName,
        lastName: editData.lastName,
        email: editData.email,
        appRoleName: editData.appRoleName,
        currentAppRoleName: editData.appRoleName,
        groups: editData.groups
      });
    }
  }, [isEdit]);

  const onChangeHandle = (name, value) => {
    setData({ ...formObject, [name]: value });
    const errors = error;
    errors && errors[name] && delete errors[name];
    setError(errors)
  };

  const validateFields = () => {
    let errors: any = {};
    if (!validator.isEmail(formObject.email)) {
      errors.email = 'Enter valid email!';
    }
    if (!isEdit && formObject.password.trim().length < 8) {
      errors.password = 'Password must have at least 8 characters!';
    }
    return errors
  }

  const handleSave = () => {
    const errors: any = validateFields();
    if (errors.email || errors.password || errors.firstName || errors.lastName || errors.appRoleName) {
      setError(errors)
      return;
    }

    if (formObject.appRoleName === "Admin" && formObject.currentAppRoleName != formObject.appRoleName) {
      setOpenConfirmModal(true);
    }
    else {
      onSubmit();
    }
  }

  const onSubmit = () => {
    setLoading(true);
    let params: IUserPayload = {
      email: formObject.email,
      firstName: formObject.firstName,
      lastName: formObject.lastName,
      appRoleName: formObject.appRoleName,
    };
    if (isEdit) {
      params.id = editData.id;
      params.deleteGroups = formObject.deleteGroups; 
      updateUser(api, params, editData.id)
        .then(() => {
          setLoading(false);
          handleClose();
          toast('User updated successfully', { type: 'success' });
          fetchData();
        })
        .catch((e) => {
          toast(`Something went wrong while update user`, { type: 'error' });
          setLoading(false);
          handleClose();
        });
    } else {
      params.password = formObject.password;
      addUser(api, params)
        .then((response) => {
          if (!response.success && response.statusCode === 1) {
            setError({ email: response.message })
          } else {
            handleClose();
            fetchData();
            toast('User added successfully', { type: 'success' });
          }
          setLoading(false);
        })
        .catch(() => {
          toast(
            'Something went wrong while add user',
            { type: 'error' }
          );
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setData(initialData);
    onClose();
  };

  const handleDelete = () => {

    const updatedGroups = formObject.groups.filter(group => group.id !== selectedWorkspace.id);
  
    setData(prevFormData => ({
      ...prevFormData,
      groups: updatedGroups,
      deleteGroups: [
        ...(prevFormData.deleteGroups || []),
        selectedWorkspace.id,
      ],
    }));

    setOpenDeleteWorkspaceModal(false);
  };

  const generatePassword = () => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    onChangeHandle('password', result)
  };

  return (
    <Modal open={open} onClose={handleClose} className="user-modal">
      <Box className="content">
        {!isEdit ? (
          <h5 className="modal-title">Add a New User</h5>
        ) : (
          <h5 className="modal-title">Edit User</h5>
        )}
        <Grid
          container
          className="create-project"
          rowSpacing={0}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 12 }}
        >
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel className="" shrink>
                First Name
              </InputLabel>
              <BootstrapInput
                placeholder="First Name"
                className="project-input"
                id="firstName"
                name="firstName"
                value={formObject.firstName}
                onChange={(e) => onChangeHandle("firstName", e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel className="" shrink>
                Last Name
              </InputLabel>
              <BootstrapInput
                placeholder="Last Name"
                className="project-input"
                id="lastName"
                name="lastName"
                value={formObject.lastName}
                onChange={(e) => onChangeHandle("lastName", e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel className="" shrink>
                Email Address
              </InputLabel>
              <BootstrapInput
                className="project-input"
                id="email"
                placeholder="Email Address"
                value={formObject.email}
                onChange={(e) => onChangeHandle("email", e.target.value)}
                required
                disabled={isEdit}
                autoComplete={"off"}
              />
              {error?.email && <span className="error-msg">{error.email}</span>}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel shrink>
              Role
            </InputLabel>
            <Dropdown
              options={roleOptions}
              onChange={(name, value) => onChangeHandle('appRoleName', value)}
              placeHolder={'Select Role'}
              selected={roleOptions?.find(
                (item) => item.label === formObject.appRoleName
              )}
            />
          </Grid>
          {!isEdit && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel className="" shrink>
                    Password
                  </InputLabel>
                  <BootstrapInput
                    className="project-input"
                    id="password"
                    type={passwordType}
                    placeholder="Password"
                    value={formObject.password}
                    onChange={(e) => onChangeHandle("password", e.target.value)}
                    required
                    autoComplete={"new-password"}
                  />
                  {passwordType === 'password' && <VisibilityOffIcon className="icon-eye" onClick={() => setPasswordType('text')} />}
                  {passwordType === 'text' && <VisibilityIcon className="icon-eye" onClick={() => setPasswordType('password')} />}
                  {error?.password && <span className="error-msg">{error.password}</span>}
                </FormControl>
              </Grid>
              <div>
                <Button
                  onClick={generatePassword}
                  variant="contained"
                  className="btn btn-primary btn-autogenerate"
                >
                  Autogenerate
                </Button>
              </div>
            </>
          )}

          {isEdit && (
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <InputLabel className="" shrink>
                Workspaces
              </InputLabel>

              <Table>
                <TableBody>
                  {formObject.groups.length > 0 ? (
                    formObject.groups.map((workspace) => (
                      <TableRow key={workspace.id} className={styles['workspace-table-row']}>
                        <TableCell className={styles['noBorderCell']}>
                          {workspace.displayName}
                        </TableCell>
                        <TableCell className={styles['actionCell']}>
                          <CloseIcon
                            className='delete-icon'
                            onClick={() => {
                              setOpenDeleteWorkspaceModal(true);
                              setSelectedWorkspace(workspace);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className={styles['workspace-table-row']}>
                      <TableCell colSpan={2} className={styles['noBorderCell']}>
                        User is not part of any workspace
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
        <div>
          <Button
            onClick={handleSave}
            variant="contained"
            className="btn btn-primary"
            disabled={
              !formObject.firstName ||
              !formObject.lastName ||
              !formObject.email ||
              !formObject.appRoleName ||
              (!isEdit && !formObject.password) ||
              loading
            }
          >
            {loading && (
              <CircularProgress
                size={18}
                color="inherit"
                style={{ marginRight: 8 }}
              />
            )}
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            className="btn btn-danger"
          >
            Cancel
          </Button>
        </div>
        {
          openConfirmModal &&
          <ConfirmationModal
            open={openConfirmModal}
            onClose={() => setOpenConfirmModal(false)}
            onConfirm={onSubmit}
            title="System Admin"
            contentText="You are about to make this user a system-wide admin"
          />
        }

        <DeleteModal
          open={openDeleteWorkspaceModal}
          onClose={() => setOpenDeleteWorkspaceModal(false)}
          onConfirm={handleDelete}
          title="Remove Workspace"
          confirmText="Agree"
          contentText={`You are about to remove this user from "${selectedWorkspace?.displayName}", Changes will take effect after the Save button has been clicked.`}
        />

      </Box>

    </Modal>
  );
};

export default AddEditUserAdmin;
