import { useContext, useEffect, useRef, } from "react"
import RedactEditorContext from "../context/RedactEditorContext";
import { RedactEditorState } from "../context/RedactEditorContext/types";

type FrameTimeCallback = (frameTime: number) => void

const useFrameTime = (callback?: FrameTimeCallback) => {

    const savedCallback = useRef<FrameTimeCallback>();
    const state = useContext<RedactEditorState>(RedactEditorContext);
    const frameTime = useRef<number>();

    const stateRef = useRef(state);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        //force a refresh of subscribers to the hook
        if (savedCallback.current && state.videoPlayer && state.videoElement && state.canvas) {
            savedCallback.current(frameTime.current);
        }
    }, [state.toggleCanvasRefresh]);

    useEffect(() => {
        if (state.videoPlayer && state.videoElement && state.canvas) {
            // @ts-ignore
            if (HTMLVideoElement.prototype.requestVideoFrameCallback) {
                // @ts-ignore
                state.videoElement.requestVideoFrameCallback(updateVideoFrameCallback);
            } else if (window.requestAnimationFrame) {
                state.videoPlayer.on("play", () => window.requestAnimationFrame(updateAnimationFrameCallback));
                state.videoPlayer.on("seeked", () => window.requestAnimationFrame(updateAnimationFrameCallback));
            }
            else {
                console.error("your browser doesn't support this API yet");
            }
        }

    }, [state.videoPlayer, state.videoElement, state.canvas])

    const updateVideoFrameCallback = (timestamp, metadata) => {
        state.videoElement.requestVideoFrameCallback(updateVideoFrameCallback);
        let mediaTime = metadata.mediaTime;
        if (state.videoPlayer && state.videoPlayer.paused()) {
            mediaTime = state.videoPlayer.currentTime();
        }

        setFrameTime(mediaTime);
        if (savedCallback.current) {
            savedCallback.current(mediaTime);
        }
    }

    const updateAnimationFrameCallback = () => {
        const currentTime = stateRef.current.videoPlayer?.currentTime() ?? 0;
        if (!stateRef.current.videoPlayer?.paused() && !stateRef.current.videoPlayer?.ended()) {
            window.requestAnimationFrame(updateAnimationFrameCallback);
        }

        setFrameTime(currentTime);
        if (savedCallback.current) {
            savedCallback.current(currentTime);
        }
    }

    const setFrameTime = (timestamp: number) => {
        const msTime = timestamp * 1000;
        const frameNum = Math.round((msTime / 1000) * stateRef.current.frameRate);
        const fTime = Math.round((frameNum * 1000) / Math.max(stateRef.current.frameRate, 1))/1000;
        frameTime.current = fTime;
    }

    if (frameTime.current == null) {
        const currentTime = stateRef.current.videoPlayer?.currentTime() ?? 0;
        setFrameTime(currentTime);
    }

    return frameTime;
}

export default useFrameTime