import {ApiResponse} from "../../types/apiResponse";
import {IApi} from "../../types/apiTypes";
import {FrameSegment } from "../../types/frame";

export const getFrames = (api: IApi, projectId: string, segment:number, version: string|undefined, session: string): Promise<FrameSegment> => {

    let url = `metadata/${projectId}/frame/${segment}?session=${session}`;
    if (version) {
        url += "&version=" + version;
    }

    return api.get(url).then((result: ApiResponse<FrameSegment>) => {
        if (!result || !result.data) {
            console.warn("[GetFrameError]: no frames for this project!")
            return undefined;
        }
        return result.data;
    })
}

export const setVersion = (api: IApi, projectId: string, version: string|undefined, session: string): Promise<void> => {
    let url = `metadata/${projectId}/frame/tracking/${session}`;
    if (version) {
        url += "?version=" + version;
    }

    return api.get(url);
}

export type SwapPayload = {
    startTimeSec: number,
    endTimeSec: number,
    version: string,
    session: string,
    fromObjectId: string,
    toObjectId: string
}

export const swapFrameObjects = (api: IApi, projectId: string, payload: SwapPayload) => {
    let url = `metadata/${projectId}/object/swap`;
    return api.post(url, payload);
}

export type MergePayload = {
    defaultObjectId: string,
    objectIds: string[],
    startTimeSec: number,
    endTimeSec: number,
    version: string,
    session: string
}

export const mergeFrameObjects = (api: IApi, projectId: string, payload: MergePayload) => {
    let url = `metadata/${projectId}/object/merge`;
    return api.post(url, payload);
}