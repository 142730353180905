import { useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import { getUserPreferences } from "../services/api/userApi";
import { IUserPreference } from "../types/user";

const useUser = (): IUserPreference => {
  const { api, apiToken } = useApi();
  const [userPreferences, setUserPreferences] = useState<IUserPreference>();

  useEffect(() => {
    if (!apiToken) return;
    getUserPreferences(api).then((data: IUserPreference) =>
      setUserPreferences(data)
    );
  }, [apiToken]);

  return userPreferences;
};

export default useUser;
