import { useCallback, useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputBase } from "@mui/material";
import styled from "@emotion/styled";
import _ from "lodash";
import useApi from "../../../hooks/useApi";
import AppContext from "../../../context/AppContext";
import { ApiResponse } from "../../../types/apiResponse";
import { IGroupDevice, IGroupDevicePayload } from "../../../types/device";
import LoadingSpinner from "../../shared/LoadingScreen/index";
import Table from "../../Table/Table";
import { workspaceDeviceHeaders } from "../TableHeader";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete-icon.svg";
import DeleteModal from "../../DeleteModal";
import { getDevices, addDevice, updateDevice, deleteDevice } from "../../../services/api/deviceApi";
import { toast } from "react-toastify";
import AddEditDeviceModal from "./AddEditDeviceModal";


const BootstrapInput = styled(InputBase)(({ }) => ({}));

const UserList = () => {
    const [count, setCount] = useState(0);
    const { api, apiToken } = useApi();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { workspace, isAcessWorkspace } = useContext(AppContext);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState("");
    const [devicesData, setDevicesData] = useState(null);
    const [fetchData, setFetchData] = useState(false);
    const [openModal, toggleModal] = useState(false);
    const [openDeleteModal, toggleDeleteModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);


    useEffect(() => {
        fetchDevicesData();
    }, [apiToken, page, pageSize, fetchData, workspace])

    const handleSearch = useCallback(_.debounce((val: any) => {
        fetchDevicesData(val);
    }, 1000),
        [apiToken]
    );

    const fetchDevicesData = (searchValue?: string) => {
        if (apiToken && workspace) {
            setIsLoading(true);
            getDevices(api, page + 1, pageSize, searchValue ?? search, workspace)
                .then((result: ApiResponse<IGroupDevice[]>) => {
                    setDevicesData(result);
                    setCount(result.totalCount);
                    setIsLoading(false);
                })
                .catch((e) => {
                    console.error(e);
                    setIsLoading(false);
                })
        }
    }
    const getTableData = () => {
        return devicesData?.data?.length
            ? devicesData.data?.map((row: IGroupDevice, index: number) => {
                return {
                    "s/n": page * pageSize + index + 1,
                    id: row.id,
                    key: row.id,
                    name: <span>{row?.name}</span>,
                    identifier: row.identifier ? row.identifier : "",
                    identifierType: row.identifierType ? row.identifierType : "",
                    action: (
                        <div>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleDeleteModal(true);
                                    setEditData(row);
                                }}
                                aria-label="delete"
                                className="btn-delete btn"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )
                }
            })
            : [];
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (row) => {
        setIsEdit(true);
        toggleModal(true);
        setEditData(devicesData.data.find((item) => item.id === row.id));
    };

    const handleOnCloseModal = () => {
        setIsEdit(false);
        toggleModal(false);
    };

    const handleFetch = () => {
        setFetchData(!fetchData);
    };

    const handleDelete = () => {
        toggleDeleteModal(false);
        setIsLoading(true);
        deleteDevice(api, editData.id)
            .then(() => {
                toast("Device deleted successfully", { type: "success" });
                handleFetch();
            })
            .catch((e) => {
                setIsLoading(false);
                toast(e?.message ?? "Something went wrong while deleting device", {
                    type: "error",
                });
            });
        setEditData(null);
    };

    const handleEditDevice = (formData, id) => {
        setIsLoading(true);
        const payload: IGroupDevicePayload = {
            id: editData.id,
            name: formData.name,
            identifier: formData.identifier,
            identifierType: formData.identifierType,
            groupId: workspace
        };
        updateDevice(api, payload)
            .then((result) => {
                if (!result.success) {
                    toast(result.message, { type: "error" })
                } else {
                    toast(result.data, { type: "success" })
                    toggleModal(false);
                    setEditData(null);
                    handleFetch();
                }
            })
            .catch((e) => {
                toast(e?.message ?? "Something went wrong while adding device", { type: "error" });
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleAddDevice = (formData) => {
        setIsLoading(true);
        const payload: IGroupDevicePayload = {
            name: formData.name,
            identifier: formData.identifier,
            identifierType: formData.identifierType,
            groupId: workspace,
        };
        addDevice(api, payload)
            .then((result) => {
                if (!result.success) {
                    toast(result.message, { type: "error" })
                } else {
                    toast(result.data, { type: "success" })
                    toggleModal(false);
                    handleFetch();
                }
            })
            .catch((e) => {
                toast(e?.message ?? "Something went wrong while adding device", { type: "error" });
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <div className="user-wrapper">
            <div className="user-header">
                <div className="left-wrapper">
                    <div className="total-wrapper">
                        <span>{count || 0} Devices</span>
                    </div>
                    <div className="project-search-wrapper">
                        <div className="project-search-icn">
                            <IconButton aria-label="search" className="project-icon">
                                <SearchIcon />
                            </IconButton>
                        </div>
                        <BootstrapInput
                            className="project-search"
                            placeholder="Search"
                            onChange={(e) => {
                                handleSearch(e.target.value);
                                setSearch(e.target.value);
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        onClick={() => toggleModal(true)}
                        variant="contained"
                        className="add-button btn btn-primary"
                    >
                        Add Device
                    </Button>
                </div>
            </div>

            <div className="table-wrapper">
                {isLoading ? (
                    <LoadingSpinner messages={["Loading..."]} />
                ) : (
                    <Table
                        tableHeaders={workspaceDeviceHeaders}
                        rows={getTableData()}
                        page={page}
                        pageSize={pageSize}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        count={count}
                        handleRowClick={handleRowClick}
                    />
                )}
            </div>
            {
                openModal && (
                    <AddEditDeviceModal
                        open={openModal}
                        isEdit={isEdit}
                        onClose={handleOnCloseModal}
                        editData={editData}
                        handleEditDevice={handleEditDevice}
                        handleAddevice={handleAddDevice}
                        workspace={workspace}
                        isAdminWorkspace={isAcessWorkspace}
                    />
                )
            }
            {
                <DeleteModal
                    open={openDeleteModal}
                    onClose={() => toggleDeleteModal(false)}
                    onConfirm={handleDelete}
                    title="Delete Device"
                    contentText={`Are you sure you want to delete ${editData?.name}?`}
                />
            }
        </div>
    )
}

export default UserList;