import { ApiResponse } from "../../types/apiResponse";
import { IApi } from "../../types/apiTypes"

import { BlobServiceClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";

interface MediaUploadTokens {
    accountUrl: string,
    videoUploadToken: string,
    thumbnailUploadToken: string,
    videoContainerName: string,
    thumbnailContainerName: string
}

export const getUploadData = (api: IApi): Promise<MediaUploadTokens> => {

    return api.get(`cloud/tokens/upload`).then((response: ApiResponse<MediaUploadTokens>) => {
        return response.data;
    });
}

export const uploadToStorage = async (url: string, container: string, blobName: string, content:any) => {
    const blobServiceClient = new BlobServiceClient(url);
    const containerClient = blobServiceClient.getContainerClient(container);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const options: BlockBlobParallelUploadOptions = { 
        blobHTTPHeaders: { blobContentType: content.type },
        maxSingleShotSize: 100000000 //100MB threshold
    };
    const uploadBlobResponse = await blockBlobClient.uploadData(content, options);
}

export const uploadToServer = async (api: IApi, fileName: string, file: any) => {
    const formData = new FormData();
    formData.append("name", fileName);
    formData.append("file", file);

    await api.postForm(`media/upload`, formData);
}

export const uploadThumbnailToServer = async (api: IApi, fileName: string, file: any) => {
    const formData = new FormData();
    formData.append("name", fileName);
    formData.append("file", file);

    await api.postForm(`media/upload-thumbnail`, formData);
}