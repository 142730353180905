import { useContext, useEffect, useRef, useState } from "react";
import RedactEditorContext from "../context/RedactEditorContext";
import { RedactEditorState } from "../context/RedactEditorContext/types";
import { drawDetected } from "../services/functions/detectedObject";
import { findClosestFrame, getClosestFrameIndex, getFrameTimeSegment, isMsTimeWithinFrameDuration } from "../services/functions/frames";
import { FocusedDetectedObject, FocusedMultiObjects } from "../types/focusedObjects";
import useFrameTime from "./useFrameTime";
import { DetectedObject } from "../types/object";
import { FrameObject, UserDefinedFrameObject } from "../types/frame";
import { DrawCoordinates } from "../types/coordinates";

const useUpdateCanvas = () => {

    const state = useContext<RedactEditorState>(RedactEditorContext);
    const stateRef = useRef<RedactEditorState>();
    const [currentSegment, setCurrentSegment] = useState<number>();
    const [segmentObjects, setSegmentObjects] = useState<DetectedObject[]>();

    useEffect(() => {
        stateRef.current = state;
    }, [state])

    //it is important that this function and all subsequent functions should come after state ref update
    const frameTimeRef = useFrameTime((frameTime) => {
        const segment = getFrameTimeSegment(frameTime);
        if (segment !== currentSegment) {
            setCurrentSegment(segment);
        }
        
        updateCanvas(frameTime);
    })

    useEffect(() => {
        //rerender canvas with latest segment objects when there is an update so that previous segment data is not used
        updateCanvas(frameTimeRef.current);
    }, [segmentObjects])

    useEffect(() => {

        if (currentSegment === undefined) {
            return;
        }

        const objs = state.detectedObjects.filter(x => {
            const startSegment = getFrameTimeSegment(x.startTime);
            const endSegment = getFrameTimeSegment(x.endTime);

            return startSegment <= currentSegment && currentSegment <= endSegment
        })

        setSegmentObjects(objs);
    }, [state.objectMap, currentSegment])

    const updateCanvas = async (videoTime: number) => {

        const {
            videoPlayer,
            canvas,
            videoElement,
            frames,
            focusedItem
        } = stateRef.current;

        if (!videoPlayer || !canvas || !videoElement) {
            return
        }

        if (!videoPlayer.hasStarted()) {
            canvas.clear();
            return;
        }

        //approximate time to account for floating point precision
        videoTime = parseFloat(videoTime.toFixed(3));
        deleteHandler(); // clear everything before updating canvas to avoid flicker

        let frameObjects: FrameObject[] = [];
        const focusedIds = getFocusedIds(focusedItem);
        if (frames.length > 0) {
            const closestFrameIndex = getClosestFrameIndex(frames, videoTime, 0, frames.length - 1);
            const closestFrame = frames[closestFrameIndex];
            const frameTimeDiff = Math.abs(videoTime - closestFrame.bufPts);
            if (closestFrame && frameTimeDiff < 0.02) {
                frameObjects = closestFrame.objects;
            }
        }

        if (!segmentObjects) {
            return;
        }

        segmentObjects.forEach(obj => {
            const matchingFrameObj = frameObjects.find(x => x.objectId === obj.objectId);
            
            const coordinates: DrawCoordinates = {
                sourceHeight: videoPlayer.videoHeight(),
                sourceWidth: videoPlayer.videoWidth(),
                height: matchingFrameObj?.height,
                width: matchingFrameObj?.width,
                top: matchingFrameObj?.top,
                left: matchingFrameObj?.left
            };

            const closestMatchingUserFrame = findClosestFrame(obj.frames, videoTime);
            const overrideWithUserFrame = isMsTimeWithinFrameDuration(closestMatchingUserFrame, videoTime);
            // if (obj.objectId == '6') {
            //     console.log('id', obj.objectId, 'closestMatchingUserFrame', closestMatchingUserFrame, 'overrideWithUserFrame', overrideWithUserFrame, 'videoTime', videoTime)
            // }
            if (closestMatchingUserFrame && (!matchingFrameObj || overrideWithUserFrame)) {
                //draw the object on the screen using user def frame coordinates
                coordinates.height = closestMatchingUserFrame.height;
                coordinates.width = closestMatchingUserFrame.width;
                coordinates.left = closestMatchingUserFrame.left;
                coordinates.top = closestMatchingUserFrame.top;
            }

            //approximate time to account for floating point precision
            const startTime = parseFloat(obj.startTime.toFixed(3));
            const endTime = parseFloat(obj.endTime.toFixed(3));

            const endDiff = parseFloat(Math.abs(videoTime - endTime).toFixed(3));
            const startDiff = parseFloat(Math.abs(videoTime - startTime).toFixed(3));
            const isOutsideTimeRange = ((startTime > videoTime) && (startDiff > 0.001) || ((endTime < videoTime) && (endDiff > 0.001)));
            if (isOutsideTimeRange) {
                return;
            }

            const rect = drawDetected(videoTime, coordinates, obj, canvas);
            if (!rect) {
                return;
            }
            
            canvas.add(...rect);
            if (focusedIds.indexOf(obj.objectId) > -1) {
                canvas.setActiveObject(rect[0]);
            }
        });

        canvas.renderAll();
    };

    const deleteHandler = (deleteObjectId?: string) => {
        const {
            videoPlayer,
            canvas,
            deletedObjects
        } = stateRef.current;

        if (!canvas) {
            return;
        }

        const canvasObjs = canvas.getObjects();
        canvasObjs.forEach((obj: fabric.Object) => {
            let canDelete = false;
            if (deleteObjectId && obj?.data?.objectId === deleteObjectId) {
                canDelete = true;
            } else if (!deleteObjectId && obj?.data?.objectId) {
                const timeDiff = Math.abs(videoPlayer.currentTime() - parseFloat(obj?.data?.drawTime ?? 1));
                if (timeDiff > 0.01 || (deletedObjects || []).indexOf(obj?.data?.objectId) >= 0) {
                    canDelete = true;
                }
            }

            if (canDelete) {
                canvas.remove(obj);
            }
        });
    };

    const getFocusedIds = (focusedItem): string[] => {
        if (!focusedItem) {
            return [];
        }

        const currentFocus = (focusedItem as FocusedDetectedObject);
        if (currentFocus.objectId) {
            return [currentFocus.objectId]
        } else {
            const batchItems = (focusedItem as FocusedMultiObjects);
            if (batchItems && batchItems.objects && batchItems.objects.length > 0) {
                return batchItems.objects.map(x => x.objectId);
            } 
        }

        return [];
    }
}

export default useUpdateCanvas;