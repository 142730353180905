export type TranscribeJsonResponse = {
    Transcriptions: Array<{
        Id?: string
        Start: number,
        End: number,
        Sentence: string,
        SpeakerId?: string,
        Status?: number
    }>,
    Speakers: Array<{
        Id?: string
        Name: string,
        Abbreviation: string,
    }>
}

export type TranscribeResponse = {
    transcriptions: Array<TranscriptionResponse>,
    speakers: Array<SpeakerResponse>
}

export type TranscriptionResponse = {
    id?: string
    start: number,
    end: number,
    sentence: string,
    speakerId?: string,
    status?: TranscriptionStatus
}

export type SpeakerResponse = {
    id?: string
    name: string,
    abbreviation: string,
}

export type Transcription = {
    id?: string,
    sentence: string,
    start: number,
    end: number,
    original?: Transcription,
    speakerId?: string,
    status?: TranscriptionStatus,
    newPage?: boolean,
    pageIndex?: number,
    startPos?: number,
    endPos?: number,
}

export enum TranscriptionStatus {
    Original = "original",
    Custom = "custom",
    Edited = "edited"
}

export type Speaker = {
    id?: string,
    name: string,
    abbreviation: string,
    color?: string,
    rightAligned?: boolean,
}

export type Duration = {
    hours?: number,
    minutes?: number,
    seconds?: number,
    milliseconds?: number
}

export type TranscriptionChange = {
    changeId: string,
    action: TranscriptionChangeAction,
    transcription?: TranscriptionResponse,
    speaker?: SpeakerResponse
}

export type SearchMatch = {
    transcriptionId: string,
    index: number
}

export enum TranscriptionChangeAction {
    Add = 0,
    Update = 1,
    Remove = 2
}

export const DEFAULT_SPEAKER: Speaker = {
    name: "?",
    abbreviation: "?",
    rightAligned: false,
    color: "#6C6C7E"
}