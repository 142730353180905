import { Button, CircularProgress } from "@material-ui/core";
import { Box, Grid, Modal } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { IDevice } from "../../../types/device";
import DeviceModal from "./DeviceModal";
import useApi from "../../../hooks/useApi";
import "../../../pages/workspace/index.scss";
import validator from "validator";
import { getDeviceIdentifierTypes } from "../../../services/functions/devicesAuth";

interface IProps {
    open: boolean;
    onClose: () => void;
    isEdit: boolean;
    editData?: any;
    workspace?: string;
    handleAddevice?: (data: any) => void;
    handleEditDevice?: (data: any, key: number) => void;
    isAdminWorkspace?: boolean;
}

interface IFormData {
    name: string;
    identifier: string;
    identifierType: string;
}

const AddEditDeviceModal: React.FC<IProps> = ({
    open,
    onClose,
    isEdit,
    editData,
    workspace,
    handleAddevice,
    handleEditDevice,
    isAdminWorkspace,
}) => {
    const initialData = {
        name: "",
        identifier: "",
        identifierType: ""
    };

    const [formObject, setData] = useState<IFormData>(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [identifierTypes, setIdentifierTypes] = useState([]);
    const { api, apiToken } = useApi();

    useEffect(() => {
        if (apiToken) {
            const types = getDeviceIdentifierTypes(api, apiToken);
            setIdentifierTypes(types);
        }
    }, [apiToken]);

    useEffect(() => {
        if (isEdit) {
            setData({
                name: editData.name,
                identifier: editData.identifier,
                identifierType: editData.identifierType
            })
        }
    }, [isEdit]);

    const handleClose = () => {
        setData(initialData);
        onClose();
    }

    const onSubmit = () => {
        const errors: any = validateFields();
        if (errors.name || errors.identifier || errors.identifierType) {
            setError(errors);
        } else {
            setIsLoading(true);
            let params: IDevice = {
                name: formObject.name,
                identifier: formObject.identifier,
                identifierType: formObject.identifierType
            };

            if (isEdit) {
                onUpdateDevice(params);
            } else {
                onAddDevice(params);
            }
        }
    }

    const onUpdateDevice = (params) => {
        params.id = editData?.id;
        if (isAdminWorkspace) {
            handleEditDevice?.(params, editData?.key);
            setIsLoading(false);
            handleClose();
            return;
        }
    }

    const onAddDevice = (params) => {
        if (isAdminWorkspace) {
            handleAddevice?.(params);
            setIsLoading(false);
            handleClose();
            return;
        }
    }

    const validateFields = () => {
        let errors: any = {};
        if (formObject.identifierType === 'Email' && !validator.isEmail(formObject.identifier)) {
            errors.identifier = "Enter valid email";
        } else if (formObject.identifierType === 'Phone' && !validator.isMobilePhone(formObject.identifier)) {
            errors.identifier = "Enter valid phone"
        }
        return errors;
    }

    const isFormSubmitDisabled = useMemo(() => {
        return isLoading ||
            !formObject.name ||
            !formObject.identifier ||
            !formObject.identifierType;
    }, [formObject]);

    const options = identifierTypes?.map((type) => {
        return { value: type.id, label: type.displayName };
    });

    const onChangeHandle = (
        name: string,
        value: any
    ) => {
        setData({ ...formObject, [name]: value });
        const errors = error;
        errors && errors[name] && delete errors[name];
        setError(errors);
    };

    const header = useMemo(() => !isEdit ? "Add a New Device" : "Edit Device", [isEdit]);

    return (
        <Modal open={open} onClose={handleClose} className="user-modal">
            <Box className="content">
                <h5 className="modal-title">{header}</h5>
                <Grid
                    container
                    className="create-project"
                    rowSpacing={0}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 12 }}
                >
                    <DeviceModal
                        errors={error}
                        formObject={formObject}
                        isEdit={isEdit}
                        onChangeHandle={onChangeHandle}
                        options={options}
                    />
                </Grid>
                <div>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        className="btn btn-primary"
                        disabled={isFormSubmitDisabled}
                    >
                        {isLoading && (
                            <CircularProgress
                                size={18}
                                color="inherit"
                                style={{ marginRight: 8 }}
                            />
                        )}{" "}
                        Save
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="btn btn-danger">
                        Cancel
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}

export default AddEditDeviceModal;