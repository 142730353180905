import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import "./navbar.scss";
import {
  AppBar,
  Toolbar,
  Stack,
  List,
  ListItem,
  MenuItem,
  Menu,
  Avatar,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { Logout, Person } from "@mui/icons-material";
import DummyAvatar from "../../assets/images/dummy-profile.png";
import AppNotification from "../../components/AppNotification";
import AppWorkSpace from "../../components/AppWorkspace";

import GearsIcon  from "../../assets/images/Gears.svg";
import PeopleIcon from "../../assets/images/People.svg";
import ProjectIcon from "../../assets/images/ProjectIcon.svg";
import EquatureLogo from '../../../src/assets/images/EquatureLogo.svg'

import AppContext from "../../context/AppContext";
import { clearLicenseKey } from "../../services/functions/licenseKeyAuth";
import { appConfig } from "../../appConfig";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const history = useHistory();
  const { accounts } = useMsal();
  const [user, setUser] = useState<any>()
  const { instance } = useMsal();
  const { isAcessAdmin, isAcessWorkspace, workspace, workspaceOption} = useContext(AppContext); 

  const selectedWorkspace = useMemo(() => workspaceOption?.find(w => w.id === workspace)?.displayName, [workspace,workspaceOption ])

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setUser({
        name: accounts[0].name,
        email: accounts[0].username,
      })
    }
  }, [accounts])

  useEffect(() => {
    if(isAcessAdmin === undefined && isAcessWorkspace === undefined) return
    if(!isAcessAdmin && location.pathname === '/admin') {
        history.push('/projects')
    } else if((!isAcessWorkspace && !isAcessAdmin) && location.pathname === '/workspace') {
        history.push('/projects')
    }
},[isAcessAdmin, isAcessWorkspace]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    if(appConfig.IsCloudAuth) {
      instance.logoutRedirect().catch((e) => {
        console.error(e);
      });
    } else {
      clearLicenseKey();
      window.location.reload();
    }
  };

  return (
    <AppBar position="static" className="app-navbar">
      <Link className="logo" href="/projects?page=1">
          <img src={EquatureLogo} alt="Equature Logo" />
      </Link>
      <Toolbar
        variant="dense"
        className="navbar-action"
      >
        <Stack direction="row" alignItems="center" className="menu-list" spacing={2}>
          <NavLink style={{ textDecoration: "none" }} to="/projects?page=1">
            <ListItem>
              <ListItemIcon>
                <img src={ProjectIcon} className="icn" />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </NavLink>
          {
            isAcessWorkspace &&
            <NavLink style={{ textDecoration: "none" }} to="/workspace">
              <ListItem>
                <ListItemIcon>
                  <img src={PeopleIcon} className="icn" />
                </ListItemIcon>
                <ListItemText primary="Workspace" />
              </ListItem>
            </NavLink>
          }
          {
            isAcessAdmin &&
            <NavLink style={{ textDecoration: "none" }} to="/admin">
              <ListItem>
                <ListItemIcon>
                  <img src={GearsIcon} className="icn" />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
            </NavLink>
          }
        </Stack>
      </Toolbar>
      <Toolbar
        variant="dense"
        className="navbar-action"
        style={{
          flexGrow: 1,
          minWidth: 0
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="end" style={{ marginLeft: 'auto'}}>
          { !appConfig.IsLicenseKeyAuth && <AppWorkSpace /> }
          <AppNotification />
          <div>
            <List component="nav" aria-label="Device settings" sx={{ p: 0 }}>
              <ListItem
                className="navbar-profile"
                button
                id="lock-button"
                aria-haspopup="listbox"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickListItem}
              >
                <Avatar
                  sx={{ width: 40, height: 40, bgcolor: "var(--iconColorFill)" }}
                  alt=""
                  src={DummyAvatar}
                />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "lock-button",
                role: "listbox",
              }}
              className="profile-menu"
            >
              <MenuItem>
                <ListItemIcon>
                  <Person fontSize="medium" />
                </ListItemIcon>
                <span className="truncate">
                  {appConfig.IsLicenseKeyAuth ? selectedWorkspace : user?.name }
                </span>
              </MenuItem>
              <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <Logout fontSize="medium" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
