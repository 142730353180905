import { Handles, Rail, Slider, Ticks, Tracks } from "react-compound-slider";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { FocusedDetectedObject, FocusedMultiObjects } from "../../types/focusedObjects";
import { Button, Stack, Tooltip } from "@mui/material";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import useFrameTime from "../../hooks/useFrameTime";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { DetectedObject } from "../../types/object";
import { IntervalItem, mapAndSortIntervals, updateObjectDuration } from "./helper";

import './index.scss'

interface ActiveInterval {
    id: string,
    handler: string
}

interface EditorRefs {
    zoom: number,
    scale: IScale,
    isSliding: boolean,
    sliderValue: number,
    videoElement: HTMLVideoElement
}

interface IScale {
    length: () => number,
    timestamp: (frame: number) => number,
    frame: (timestamp: number) => number
}

const TimelineEditor = () => {

    const scale: IScale = {
        length: () => {
            if (state.frameCount === 0) return 0;

            if (zoomLevel[zoom] === "milliseconds") {
                return state.frameCount;
            }
            
            const normalizedFps = Math.round(state.frameRate);
            const normalizedLength = Math.round(state.frameCount / normalizedFps) ?? 0;
            if (zoomLevel[zoom] === "seconds") {
                return normalizedLength;
            }

            //totalpossible tick count is gotten from calculating the normalized length
            const totalPossibleTickCount = Math.round(containerRef.current?.offsetWidth/10);
            if (normalizedLength < totalPossibleTickCount) {
                return normalizedLength;
            }

            //get largest multiple of 5 that will give a value less than or equal to totalPossibleTickCount
            //get a totalPossibleCount that is a factor of 5
            const factorOfFiveTickCount = Math.floor(totalPossibleTickCount/5) * 5;
            const divisor = normalizedLength / factorOfFiveTickCount;
            const factorFiveDivisor = Math.ceil(divisor/5) * 5;
            return Math.round(normalizedLength/factorFiveDivisor) + 1;
        },
        timestamp: (frame: number) => {
            if (zoomLevel[zoom] === "milliseconds") {
                return Math.round((frame * 1000) / state.frameRate);
            }

            const normalizedFps = Math.round(state.frameRate);
            const normalizedLength = Math.round(state.frameCount / normalizedFps);
            
            const totalPossibleTickCount = Math.round(containerRef.current?.offsetWidth/10);
            if (zoomLevel[zoom] === "seconds" || normalizedLength < totalPossibleTickCount) {
                //this is the most array length that returns no remainder (ln % nFPS === 0) 
                const multipleLength = normalizedLength * normalizedFps;
                let convertedFrame = (multipleLength * Math.min(frame, normalizedLength)) / normalizedLength;
                return Math.round((convertedFrame * 1000) / state.frameRate);
            }

            const factorOfFiveTickCount = Math.floor(totalPossibleTickCount/5) * 5;
            const divisor = normalizedLength / factorOfFiveTickCount;
            const factorFiveDivisor = Math.ceil(divisor/5) * 5;
            return frame * factorFiveDivisor * 1000;
        },
        frame: (timestamp: number) => {
            const actualFrame = Math.round((timestamp / 1000) * state.frameRate);
            if (zoomLevel[zoom] === "milliseconds") {
                return actualFrame;
            }

            const normalizedFps = Math.round(state.frameRate);
            const normalizedLength = Math.round(state.frameCount / normalizedFps);
            
            const totalPossibleTickCount = Math.round(containerRef.current?.offsetWidth/10);
            if (zoomLevel[zoom] === "seconds" || normalizedLength < totalPossibleTickCount) {
                //this is the most array length that returns no remainder (ln % nFPS === 0) 
                const multipleLength = normalizedLength * normalizedFps;
                //cross multiplication is used to calculate the final value based on the multiple/normalized length
                const closestMultiple = Math.floor(actualFrame / normalizedFps) * normalizedFps;
                return (closestMultiple * normalizedLength) / multipleLength;
            }

            const factorOfFiveTickCount = Math.floor(totalPossibleTickCount/5) * 5;
            const divisor = normalizedLength / factorOfFiveTickCount;
            const factorFiveDivisor = Math.ceil(divisor/5) * 5;
            return Math.floor((timestamp/1000)/factorFiveDivisor);
        }
    }

    const intervalHeight = 15;

    const containerHeight = useRef<number>(70);
    const containerRef = useRef<HTMLDivElement>();
    const scrollRef = useRef<HTMLDivElement>();
    const handleRef = useRef<HTMLDivElement>();
    const timelineSectionRef = useRef<HTMLDivElement>();

    const zoomLevel = ["milliseconds", "seconds", "all"];

    const [zoom, setZoom] = useState<number>(2);
    const [intervals, setIntervals] = useState<IntervalItem[]>([]);
    const [activeInterval, setActiveInterval] = useState<ActiveInterval>();
    const [isSliding, setIsSliding] = useState<boolean>(false);
    const [sliderValue, setSliderValue] = useState<number>(0);

    const [triggerRulerUpdate, setTriggerRulerUpdate] = useState<number>(0);
    const [triggerScrollUpdate, setTriggerScrollUpdate] = useState<number>(0);

    const [tempUpdatedObject, setTempUpdatedObject] = useState<DetectedObject>();

    const state = useContext<RedactEditorState>(RedactEditorContext);

    const getRefs = (): EditorRefs => {
        return {
            zoom, isSliding, scale, sliderValue, videoElement: state.videoElement
        }
    }
    const editorRefs = useRef<EditorRefs>();
    editorRefs.current = getRefs();

    const updateRulerBasedOnZoom = () => {
        if (zoom < 2) {
            return;
        }
        
        setTriggerRulerUpdate(triggerRulerUpdate + 1);
    }

    const updateTimeline = ((frameTime) => {
        const { isSliding, scale, sliderValue, videoElement } = editorRefs.current;
        updateRulerBasedOnZoom();
        if (isSliding || scale.timestamp(sliderValue) / 1000 === parseFloat(state.videoPlayer?.currentTime().toFixed(3))) {
            return;
        }

        setSliderValue(scale.frame(frameTime * 1000));
    })

    useFrameTime(updateTimeline)

    useEffect(() => {
        if (state.detectedObjects) {
            //create new/delete intervals
            const { detectedObjects } = state;
            const newInterval: IntervalItem[] = mapAndSortIntervals(detectedObjects);
            updateIntervals(newInterval);
        }
    }, [state.detectedObjects])

    useEffect(() => {
        if (state.videoPlayer) {
            //ensure slider value maintains the same relative position across zoom changes
            const frame = scale.frame(state.videoPlayer.currentTime() * 1000);
            setSliderValue(frame);
        }

        if (intervals.length > 0) {
            updateIntervals(intervals);
        }
    }, [zoom])

    useEffect(() => {
        if (state.videoPlayer && (!state.videoPlayer.paused() || !isSliding)) {
            centerSlider();
        }
    }, [sliderValue])

    const intervalRef = useRef<IntervalItem[]>([]);
    useEffect(() => {
        intervalRef.current = intervals
    }, [intervals])

    useEffect(() => {
        if (state.canvas) {
            state.canvas.on("mouse:dblclick", (event) => {
                const objectId = event.target.data.objectId;
                if (!objectId) {
                    return;
                }

                const target = intervalRef.current.find(x => x.id === objectId.toString());
                if (!target) {
                    return;
                }

                scrollToInterval(target);
            })
        }
    }, [state.canvas])

    useEffect(() => {
        if (!activeInterval && tempUpdatedObject) {
            const previous = state.objectMap.get(tempUpdatedObject.objectId);
            updateObjectDuration(tempUpdatedObject, previous, state.updateDetectedObjectList);
            setTempUpdatedObject(undefined);
        }
    }, [activeInterval])

    const isInHorizontalView = (targetLeft: number): { isBeforeView: boolean, isAfterView: boolean, isInView: boolean} => {
        if (!containerRef.current) {
            return undefined;
        }

        const containerTotal = containerRef.current.clientWidth + containerRef.current.scrollLeft;

        const isBeforeView = (containerTotal - targetLeft) > containerRef.current.clientWidth;
        const isAfterView = targetLeft > containerTotal;

        return {
            isBeforeView,
            isAfterView,
            isInView: (!isBeforeView && !isAfterView)
        };
    }

    const centerSlider = () => {
        if (!containerRef.current || !handleRef.current || activeInterval) {
            return;
        }
        const handlerLeft = handleRef.current.offsetLeft;
        const containerTotal = containerRef.current.clientWidth + containerRef.current.scrollLeft;
        const { isAfterView, isInView } = isInHorizontalView(handlerLeft);
        if (!isInView) {
            //get the difference between the handler and container, center it to a starting point.
            const pixelDiff = Math.abs(containerTotal - handlerLeft)
            const centerPoint = pixelDiff + (containerRef.current.clientWidth / 2);
            containerRef.current.scrollBy(centerPoint * ((isAfterView) ? 1 : -1), 0);
        }
    }

    const handleSliderChange = (newValue: number[]) => {
        const value = newValue[0];

        if (value === sliderValue || sliderValue > scale.length() || isNaN(value)) { //
            return;
        }

        const { videoPlayer } = state;
        if (videoPlayer) {
            videoPlayer.currentTime(scale.timestamp(value) / 1000);
        }
        setSliderValue(value)
    }

    const updateIntervals = (intervalParams: IntervalItem[]) => {

        const scaleLength = scale.length();
        const step = (scaleLength * 10) > containerRef.current?.offsetWidth ? 1 : Math.ceil((scaleLength * 10) / (containerRef.current?.offsetWidth || 1));
        const multiple = (100 / (scaleLength - 1 / step));
        const updatedIntervals: IntervalItem[] = [];
        intervalParams.forEach((x, index) => {
            //multiply by percent step
            //end time of an interval is set to the video end if the object is being tracked, else it is set to its actual end time
            const endVal = x.loadingStart >= 0 ? state.videoPlayer?.duration() * 1000 : x.end;
            const loadingLeft = x.loadingStart >= 0 ? scale.frame(x.loadingStart) * multiple : -1;
            const endLeft = scale.frame(endVal) * multiple;

            const startLeft = scale.frame(x.start) * multiple;

            const timeOverlapCount = index;
            updatedIntervals.push({ ...x, startLeft, endLeft, loadingLeft, timeOverlapCount });
        });

        containerHeight.current = (intervalParams.length * intervalHeight) + 70;
        setIntervals(updatedIntervals);
    }

    const getClickedValue = (e) => {
        //same function as handle dragging
        const scaleLength = scale.length();
        const rect = containerRef?.current.getBoundingClientRect();
        const step = (scaleLength * 10) > containerRef.current?.offsetWidth ? 1 : Math.ceil((scaleLength * 10) / (containerRef.current?.offsetWidth || 1));
        let newLeft = (100 * Math.max(1, ((e.pageX - rect.left) + containerRef.current?.scrollLeft))) / containerWidth
        const multiple = (100 / (scaleLength - 1 / step));
        let n = newLeft + (multiple / 2);
        newLeft = n - (n % multiple);
        const newValue = Math.round(newLeft / multiple);
        return newValue;
    }

    const handleTimelineClick = (e) => {
        
        const clickedValue = getClickedValue(e);
        const { videoPlayer } = state;
        if (videoPlayer) {
            videoPlayer.currentTime(scale.timestamp(clickedValue) / 1000);
        }
        setSliderValue(clickedValue);
    }

    const handleIntervalClick = (obj: IntervalItem) => (e) => {
        state.setCurrentObjectId(obj.id);
        const clickedValue = getClickedValue(e);
        const clickedTime = scale.timestamp(clickedValue);
        const isWithinDuration = clickedTime >= obj.start && clickedTime <= obj.end;
        if (isWithinDuration)
            return;

        const startDiff = Math.abs(clickedTime - obj.start);
        const endDiff = Math.abs(clickedTime - obj.end);

        const selectedTime = (startDiff > endDiff ? obj.end - 10 : obj.start + 10);
        const newValue = scale.frame(selectedTime);
        setSliderValue(newValue);
        if (state.videoPlayer) {
            state.videoPlayer.currentTime(selectedTime/1000);
        }
        e.stopPropagation();
    }

    const handleIntervalDoubleClick = (obj: IntervalItem) => (e) => {
        const clickedValue = getClickedValue(e);
        const clickedTime = scale.timestamp(clickedValue);
        const isWithinDuration = clickedTime >= obj.start && clickedTime <= obj.end;
        if (!isWithinDuration)
            return;

        const { objectMap, updateDetectedObjectList } = state;

        const targetObj = objectMap.get(obj.id);
        if (targetObj) {
            updateDetectedObjectList([
                { ...targetObj, endTime: clickedTime/1000 }
            ], []);
        }
    }

    const handleDragging = (e) => {

        if (!activeInterval) {
            return;
        }

        e.preventDefault();

        const clone: IntervalItem[] = intervals.map(x => ({ ...x }));
        const index = clone.findIndex(x => x.id === activeInterval.id);
        if (index < 0) {
            return;
        }

        const scaleLength = scale.length();
        const rect = containerRef?.current.getBoundingClientRect();
        const step = (scaleLength * 10) > containerRef.current?.offsetWidth ? 1 : Math.ceil((scaleLength * 10) / (containerRef.current?.offsetWidth || 1));
        let newLeft = (100 * Math.max(1, ((e.pageX - rect.left) + containerRef.current?.scrollLeft))) / containerWidth
        const multiple = (100 / (scaleLength - 1 / step));
        let n = newLeft + (multiple / 2);
        newLeft = n - (n % multiple);
        const newValue = Math.floor(newLeft / multiple);
        setSliderValue(newValue)
        if (scaleLength < newValue) {
            return;
        }

        switch (activeInterval.handler) {
            case "start":
                if ((clone[index].endLeft - newLeft) < multiple) {
                    return;
                }

                const newStart = Math.min(Math.max(0, scale.timestamp(newValue)), scale.timestamp(scaleLength - 1));
                const startLeft = Math.min(Math.max(0, newLeft), 100);
                if ((clone[index].end - newStart) < 0) {
                    clone[index].start = clone[index].end;
                    clone[index].startLeft = clone[index].endLeft;
                } else {
                    clone[index].start = newStart;
                    clone[index].startLeft = startLeft;
                }
                break;
            case "end":
                if ((newLeft - clone[index].startLeft) < multiple) {
                    return;
                }

                const newEnd = Math.min(Math.max(0, scale.timestamp(newValue)), scale.timestamp(scaleLength - 1));
                const endLeft = Math.min(Math.max(0, newLeft), 100);

                if ((newEnd - clone[index].start) < 0) {
                    clone[index].end = clone[index].start;
                    clone[index].endLeft = clone[index].startLeft;
                } else {
                    clone[index].end = newEnd;
                    clone[index].endLeft = endLeft;
                }
                break;
        }

        const { videoPlayer, objectMap } = state;
        if (videoPlayer) {
            videoPlayer.currentTime(scale.timestamp(newValue) / 1000);
        }

        let targetObj = tempUpdatedObject;
        if (targetObj?.objectId !== clone[index].id) {
            targetObj = objectMap.get(clone[index].id);
        }
        
        if (targetObj) {
            setTempUpdatedObject({ ...targetObj, startTime: clone[index].start / 1000, endTime: clone[index].end / 1000 });
            setIntervals(clone);
        }
    }

    const ruler = (cWidth: number) => {

        if (!cWidth)
            return <></>;

        const step = (scale.length() * 10) > containerRef.current?.offsetWidth ? 1 : Math.ceil((scale.length() * 10) / (containerRef.current?.offsetWidth || 1));

        const formatTickValue = (tickIndex, tickValue) => {
            if (tickIndex % 5 === 0) {

                if (zoomLevel[zoom] === "seconds") {
                    const display = Math.floor(tickValue)
                        if (display < 60) {
                            return Math.floor(display) + 's'
                        }

                        const minute = Math.floor(display / 60)
                        const seconds = display % 60
                        return `${minute}:${seconds}m`
                }

                const msDisplay = scale.timestamp(tickValue);
                if (msDisplay < 60000) {
                    const secVal = Math.floor(msDisplay / 1000)
                    const msVal = msDisplay % 1000
                    return zoomLevel[zoom] === "milliseconds"? `${secVal}:${msVal}s` : `${secVal}s`;

                } else {
                    const minuteVal = Math.floor(msDisplay / 60000);
                    const minuteMs = msDisplay % 60000;
                    const secondsVal = Math.floor(minuteMs / 1000);
                    const secondsMs = minuteMs % 1000;

                    return zoomLevel[zoom] === "milliseconds"? `${minuteVal}:${secondsVal}.${secondsMs}m` : `${minuteVal}:${secondsVal}m`;
                }
            }
        }

        const getHandleLabel = (value) => {
            if (isNaN(value)) {
                return 0;
            }
            
            if (zoomLevel[zoom] !== "all") {
                return formatTickValue(0, value);
            }

            const currentTime = Math.floor(state.videoPlayer?.currentTime() ?? 0);
            if (currentTime < 60) {
                return `${currentTime}s`;
            }

            const minuteVal = Math.floor(currentTime/60);
            const seconds = currentTime % 60;
            return `${minuteVal}:${seconds}`;
        }

        const Handle = ({
            handle: { id, value, percent },
            getHandleProps
        }) => {

            const labelValue = getHandleLabel(value);
            const pixelValue = (containerWidth * percent)/100
            const labelLength = labelValue.toString().length;
            let labelLeft = 0;
            if ((containerWidth - pixelValue) < 50) {
                labelLeft = -1 * (8 + (6 * labelLength));;
            }

            return (
                <div
                    ref={handleRef}
                    style={{
                        left: `${percent}%`,
                        position: 'absolute',
                        marginLeft: -1.25,
                        zIndex: 1,
                        width: 2.5,
                        top: 0,
                        height: containerHeight.current - 35,
                        border: 0,
                        textAlign: 'center',
                        cursor: 'pointer',
                        backgroundColor: '#49408B',
                        color: '#333',
                    }}
                    {...getHandleProps(id)}
                >
                    <div style={{ position: 'absolute', fontSize: 9, fontWeight: 600, marginTop: -12, left: labelLeft, color: '#c8c8cf' }}>
                        {labelValue}
                    </div>
                </div>
            )
        }

        const Track = ({ source, target, getTrackProps }) => {
            return (
                <div
                    style={{
                        position: 'absolute',
                        height: 3,
                        zIndex: 1,
                        backgroundColor: '#6A5CCA',
                        cursor: 'pointer',
                        left: `${source.percent}%`,
                        width: `${target.percent - source.percent}%`,
                    }}
                    {...getTrackProps() /* this will set up events if you want it to be clickable (optional) */}
                />
            )
        }

        const Tick = ({ tick, tickIndex, count }) => {
            return (
                <div>
                    <div
                        style={{
                            position: 'absolute',
                            marginLeft: -0.5,
                            marginTop: 5,
                            width: 1,
                            height: (tickIndex % 5 === 0) ? 12 : 8,
                            backgroundColor: 'silver',
                            left: `${tick.percent}%`,
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            marginTop: 15,
                            fontSize: 8,
                            textAlign: 'center',
                            marginLeft: `${-(100 / count) / 2}%`,
                            width: `${100 / count}%`,
                            left: `${tick.percent}%`,
                        }}
                    >
                        {formatTickValue(tickIndex, tick.value)}
                    </div>
                </div>
            )
        }

        const sliderStyle = {  // Give the slider some width
            position: 'absolute',
            width: '100%',
            height: 70,
            top: timelineSectionRef.current.scrollTop
        }

        const railStyle = {
            position: 'absolute',
            width: '100%',
            height: 3,
            backgroundColor: '#9A92D3',
        } as React.CSSProperties;

        const tickerRange: number[] = getTickerRange();
        const scaleLength = scale.length();

        return (
            <div style={{
                position: 'absolute',
                width: containerWidth
            }}>
                <Slider
                    rootStyle={sliderStyle}
                    domain={[0, scaleLength - 1]}
                    values={[sliderValue]}
                    step={step}
                    onChange={handleSliderChange}
                    onSlideStart={() => setIsSliding(true)}
                    onSlideEnd={() => setIsSliding(false)}
                >
                    <Rail>
                        {({ getRailProps }) => (
                            <div style={railStyle} {...getRailProps()} />
                        )}
                    </Rail>
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                    <Ticks
                        values={Array.from({ length: Math.max(0, scaleLength - 1) }, (x, i) => i * step)}>
                        {({ ticks }) => (
                            <div className="slider-ticks">
                                {ticks.slice(tickerRange[0], tickerRange[1]).map((tick, index) => (
                                    <Tick key={tick.id} tickIndex={index + tickerRange[0]} tick={tick} count={ticks.length} />
                                ))}
                            </div>
                        )}
                    </Ticks>
                </Slider>
            </div>)
    }

    const renderIntervals = (obj: IntervalItem) => {

        const handleStyle = {
            height: '100%',
            width: 3,
            minWidth: 3,
            background: obj.color,
            cursor: 'e-resize',
            zIndex: 2
        } as React.CSSProperties;

        const startPixelValue = (containerWidth * obj.startLeft)/100;
        const endPixelValue = (containerWidth * obj.endLeft)/100
        const intervalWidth = endPixelValue - startPixelValue;
        const labelLength = obj.sequence.toString().length;
        let labelLeft = 0;
        if ((intervalWidth/labelLength) < 10) {

            if ((containerWidth - endPixelValue) > 50) {
                labelLeft = intervalWidth + 3
            } else {
                labelLeft = -1 * (8 + (6 * labelLength));
            }
        }

        const labelStyle = {
            position: 'absolute',
            margin: 'auto',
            fontSize: 10,
            fontWeight: 900,
            color: '#fff',
            left: labelLeft
        } as CSSProperties

        const loadingBarStyle = {
            position: 'absolute',
            top: 0,
            left: (containerWidth * obj.loadingLeft)/100 - startPixelValue,
            right: 0,
            height: '100%',
            opacity: 0.5,
          } as CSSProperties;
        

        return <div key={obj.id}
            style={{
                overflow: 'visible',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                height: intervalHeight,
                width: Math.max(0, (obj.endLeft - obj.startLeft)) + "%",
                left: obj.startLeft + "%",
                top: (obj.timeOverlapCount * intervalHeight) + 30
            }}
            onClick={handleIntervalClick(obj)}
            onDoubleClick={handleIntervalDoubleClick(obj)}>
            <div style={{ ...handleStyle }} className="interval-start" draggable={true} onMouseDown={() => { setActiveInterval({ id: obj.id, handler: "start" }) }}></div>
            <div 
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '80%',
                    textAlign: 'center',
                    userSelect: 'none',
                    cursor: 'pointer',
                    background: obj.color,
                    opacity: 0.5,
                    zIndex: 1
                }}>
                <p style={{...labelStyle}}> {obj.sequence} </p>
                {obj.loadingStart >= 0 && <div className="loading-bar" style={{ ...loadingBarStyle }}></div>}
            </div>
            <div style={{ ...handleStyle }} className="interval-end" draggable={true} onMouseDown={() => setActiveInterval({ id: obj.id, handler: "end" })}></div>
        </div>
    }

    const selectZoomLevel = () => {
        const zoomLevelDisplay = (zoom + 1) + "x";
        const zoomButtonStyle = {
            margin: "4px 2px",
            backgroundColor: "#6c6c7f",
            padding: 0,
            fontSize: 10,
            minWidth: 35
        };

        return <Stack direction="row" spacing={2} 
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            style={{ background: '#1f1d2b', zIndex: 1 }}
            top={timelineSectionRef.current?.scrollTop ?? 0}>
            <p style={{ margin: 0, fontSize: 13, color: '#c4c4c4' }}>{zoomLevelDisplay}</p>
            <Tooltip title="zoom out">
                <Button variant="contained" style={{...zoomButtonStyle}} disabled={(zoom >= zoomLevel.length - 1)}
                    onClick={() => setZoom(Math.min(zoom + 1, zoomLevel.length - 1))}
                >
                    <ZoomOut style={{ width: 20 }}/>
                </Button>
            </Tooltip>
            <Tooltip title="zoom in">
                <Button variant="contained" style={{...zoomButtonStyle}} disabled={(zoom <= 0)}
                    onClick={() => setZoom(Math.max(zoom - 1, 0))}
                >
                    <ZoomIn  style={{ width: 20 }} />
                </Button>
            </Tooltip>
        </Stack>
        
        
    }

    const getTickerRange = () => {
        const startPercent = 100 * (containerRef.current?.scrollLeft / containerWidth);
        const endPercent = 100 * ((containerRef.current?.clientWidth + containerRef.current?.scrollLeft) / containerWidth);

        const length = scale.length();
        const startIndex = length * (startPercent / 100);
        const endIndex = length * (endPercent / 100);

        return [
            Math.min(Math.max(0, Math.floor(startIndex)), length),
            Math.min(Math.max(0, Math.ceil(endIndex)), length)
        ]
    }

    const getVisibleIndex = (): [number, number] => {
        if (!containerRef.current) {
            return [0, 0];
        }

        const visibleHeight = timelineSectionRef.current.offsetHeight - containerRef.current.offsetTop;
        const maxVisibleItems = Math.round(visibleHeight / intervalHeight);
        //use math.floor to prevent items from being hidden prematurely
        const hiddenItemCount = Math.floor(timelineSectionRef.current.scrollTop/intervalHeight);
    
        const startIndex = Math.min(Math.max(0, hiddenItemCount), intervals.length); 
        const endIndex = Math.min(Math.max(0, hiddenItemCount + maxVisibleItems), intervals.length);
        return [startIndex, endIndex]
    }

    const scrollToInterval = (interval: IntervalItem) => {
        if (!containerRef.current || !interval) {
            return;
        }

        //vertical scroll only because horizontal scroll is handled by slider change/time change
        const [startIndex, endIndex]: [number, number] = getVisibleIndex();
        const intervalIndex = interval.timeOverlapCount;
        if ( intervalIndex >= startIndex && intervalIndex <= endIndex) {
            return;
        }

        timelineSectionRef.current.scrollTop = intervalIndex * intervalHeight;
    }

    const virtualizeVerticalList = () => {

        if (!containerRef.current) {
            return intervals;
        }

        const [startIndex, endIndex]: [number, number] = getVisibleIndex();
        const result = intervals.slice(startIndex, endIndex);
        return result;
    }

    const handleObjectButtonClick = (index, objId) => (e: React.MouseEvent<HTMLButtonElement>) => {

        let isMultiSelect = e.ctrlKey || e.shiftKey;
        if (isMultiSelect) {

            
            if (e.shiftKey) {
                //get the last selected item
                let lastfocusedItem : DetectedObject
                const currentFocus = (state.focusedItem as FocusedDetectedObject);
                if (currentFocus && currentFocus.objectId) {
                    lastfocusedItem = state.detectedObjects.find(x => x.objectId === currentFocus.objectId)    ;
                }
                
                if (!lastfocusedItem) {
                    const batchItems = (state.focusedItem as FocusedMultiObjects);
                    if (batchItems && batchItems.objects && batchItems.objects.length > 0) {
                        lastfocusedItem = batchItems.objects[batchItems.objects.length - 1];
                    }
                }

                if (lastfocusedItem && lastfocusedItem.objectId !== objId) {

                    const lastItemIndex = intervals.findIndex(x => x.id === lastfocusedItem.objectId);
                    state.detectedObjects.findIndex(x => x.objectId === lastfocusedItem.objectId);
                    const batchIndexes = lastItemIndex > index ? [index, lastItemIndex] : [lastItemIndex, index];
                    const selectedIntervals = intervals.slice(batchIndexes[0], batchIndexes[1] + 1).map(x => state.objectMap.get(x.id));
                    state.setMultiFocusObjects(selectedIntervals);
                } else {
                    isMultiSelect = false;
                }
                
            } else if (e.ctrlKey) {
                const batchItems = (state.focusedItem as FocusedMultiObjects);
                if (batchItems && batchItems.objects && batchItems.objects.length > 0) {
                    const exists = batchItems.objects.find(x => x.objectId === objId);
                    if (exists) {
                        state.setMultiFocusObjects([...batchItems.objects.filter(x => x.objectId !== objId)]);
                    } else {
                        const newList = batchItems.objects.concat([state.objectMap.get(intervals[index].id)]);
                        state.setMultiFocusObjects(newList);
                    }
                }
                else {
                    const currentFocus = (state.focusedItem as FocusedDetectedObject);
                    if (currentFocus && currentFocus.objectId && currentFocus.objectId !== objId) {
                        const previousFocusItem = state.detectedObjects.find(x => x.objectId === currentFocus.objectId);
                        state.setMultiFocusObjects([previousFocusItem, state.objectMap.get(intervals[index].id)]);
                    } else {
                        isMultiSelect = false;
                    }
                }
            }
        }

        if (!isMultiSelect) {
            state.setCurrentObjectId(objId);
        }
        
        if (state.videoPlayer && intervals[index]) {
            const currentTime = state.videoPlayer.currentTime() * 1000;
            if (intervals[index].start <= currentTime && intervals[index].end >= currentTime) {
                //to refresh canvas
                state.videoPlayer.currentTime(state.videoPlayer.currentTime());
                return;
            }
            const startTime = intervals[index]?.start + 10;
            state.videoPlayer.currentTime(startTime/ 1000);
        }
    }

    const isFocusedInterval = (interval: IntervalItem) => {
        const focusedObject = state.focusedItem as FocusedDetectedObject;
        if (focusedObject && focusedObject.objectId === interval.id) {
            return focusedObject.objectId === interval.id;
        } else {
            const multiFocus = state.focusedItem as FocusedMultiObjects;
            if (!multiFocus || !multiFocus.objects || multiFocus.objects.length <= 0) {
                return false;
            }

            return multiFocus.objects.findIndex(x => x.objectId === interval.id) >= 0;
        }
    }

    const containerWidth = useMemo(() => {
        return containerRef.current?.offsetWidth >= scale.length() * 5 ? containerRef.current?.offsetWidth - 20 : containerRef.current?.offsetWidth * Math.ceil((scale.length() * 10) / (containerRef.current?.offsetWidth || 1));
    }, [containerRef.current?.offsetWidth, zoom])

    const timelineRuler = useMemo(() => ruler(containerRef.current?.offsetWidth), [triggerRulerUpdate, containerRef.current?.offsetWidth, zoom, sliderValue, triggerScrollUpdate]);
    const visibleIntervals = useMemo(() => virtualizeVerticalList(), [zoom, triggerRulerUpdate, containerRef.current?.offsetWidth, zoom, sliderValue, intervals, triggerScrollUpdate]);
    const zoomLevelSelector = useMemo(() => selectZoomLevel(), [zoom, triggerScrollUpdate]);

    return (<>
        {state.frameCount < 1 ? 
            <p>No Video Frames Detected for Project.</p> :
            <div ref={timelineSectionRef} onScroll={() => setTriggerScrollUpdate(triggerScrollUpdate + 1)} style={{
                display: 'flex',
                alignItems: 'flex-start',
                overflow: 'auto',
                height: '100%'
            }}>
                <div style={{ width: 100, position: 'relative', marginRight: 5 }}>
                    {zoomLevelSelector}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        marginTop: 40
                    }}>
                        {visibleIntervals.map(x => <Button variant="outlined" 
                        onClick={handleObjectButtonClick(x.timeOverlapCount, x.id)}
                        style={{
                            margin: 0,
                            color: '#c4c4c4',
                            padding: 0,
                            fontSize: 10,
                            height: 15,
                            position: 'absolute',
                            top: (x.timeOverlapCount * intervalHeight) + 42,
                            borderColor: isFocusedInterval(x) ? "#fff" : undefined

                        }}>
                            {x.sequence}
                        </Button>)}
                    </div>
                </div>
                <div style={{ width: '100%', minWidth: 0, position: 'relative' }}>
                    <div ref={scrollRef}
                        style={{
                            width: '100%',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            position: 'absolute',
                            zIndex: 4,
                            background: '#1f1d2b',
                            top: timelineSectionRef.current?.scrollTop ?? 0
                        }} 
                        onScroll={(e) => { containerRef.current.scrollLeft = scrollRef.current.scrollLeft }}>
                        <div style={{ width: containerWidth, height: 1 }}></div>
                    </div>
                    <div style={{
                        width: '100%',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        marginBottom: 15,
                        padding: '10px 5px',
                    }} ref={containerRef}
                        onScroll={() => {
                            setTriggerRulerUpdate(triggerRulerUpdate + 1)
                            scrollRef.current.scrollLeft = containerRef.current.scrollLeft
                        }}
                        onClick={handleTimelineClick}
                    >
                        <div style={{ 
                            width: containerWidth,
                            height: containerHeight.current,
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                            onMouseUp={() => setActiveInterval(undefined)}
                            onMouseLeave={() => setActiveInterval(undefined)}
                            onMouseMove={handleDragging}
                        >
                            {timelineRuler}
                            {visibleIntervals.map((x) => renderIntervals(x))}
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
}

export default TimelineEditor;