import {useEffect, useRef} from "react";
import videojs, {VideoJsPlayer, VideoJsPlayerOptions} from "video.js";
import "./VideoBlobPreview.scss"

interface VideoBlobPreviewProps {
    url: string
}

const VideoBlobPreview = ({url}: VideoBlobPreviewProps) => {
    const playerRef = useRef<any>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        let player: VideoJsPlayer = null
        const videoOptions: VideoJsPlayerOptions = {
            controls: true,
            responsive: true,
            fluid: false,
            height: 500,
            width: 500,
            sources: [{
                src: url,
                type: 'video/mp4',
            }]
        }
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;
            player = (playerRef.current = videojs(
                videoElement,
                videoOptions,
                () => {
                    onPlayerReady(player);
                }
            ));
        } else {
            // you can update player here [update player through props]
            player = playerRef.current;
            player.src(videoOptions.sources);
        }
    }, [url]);

    const onPlayerReady = (player: VideoJsPlayer) => {
        playerRef.current = player;
    };


    return (
        <div className="video-blob-preview" data-vjs-player="">
            <video
                ref={videoRef}
                className="video-js create-preview"
            />
        </div>
    )
}

export default VideoBlobPreview;