export const updateMatchingProperties = (obj1: any, obj2: any) => {
  return {
    ...obj1,
    ...Object.keys(obj1).reduce((obj, key) => {
      if (key in obj2) {
        obj[key] = obj2[key];
      }
      return obj;
    }, {}),
  };
};
