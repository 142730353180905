
enum PROJECT_STATUS {
    PENDING =  "pending",
    REDACTION_STARTED =  "redactionStarted",
    REDACTION_SUCCESS =  "redactionSuccess",
    REDACTION_FAILED  =  "redactionFailed",
    REDACTION_QUEUED = "redactionQueued",
    FILE_UPLOAD_IN_PROGRESS = "fileUploadInProgress",
    FILE_UPLOAD_COMPLETED = "fileUploadCompleted",
    FILE_UPLOAD_FAILED = "fileUploadFailed",
    FILE_UPLOAD_QUEUED = "fileUploadQueued",
    FILE_CONVERSION_IN_PROGRESS = "fileConversionInProgress",
    FILE_CONVERSION_COMPLETED = "fileConversionCompleted",
    FILE_CONVERSION_FAILED = "fileConversionFailed",
    FILE_CONVERSION_QUEUED = "fileConversionQueued",
    AUDIO_WAVE_GENERATION_STARTED = "audioWaveGenerationStarted",
    AUDIO_WAVE_GENERATION_SUCCESS = "audioWaveGenerationSuccess",
    AUDIO_WAVE_GENERATION_FAILED = "audioWaveGenerationFailed",
    AUDIO_WAVE_GENERATION_QUEUED = "audioWaveGenerationQueued",
    TRANSCRIPTION_STARTED = "transcriptionStarted",
    TRANSCRIPTION_SUCCESS = "transcriptionSuccess",
    TRANSCRIPTION_FAILED = "transcriptionFailed",
    TRANSCRIPTION_QUEUED = "transcriptionQueued",
    UPLOAD_TO_REMOTE_IN_PROGRESS = "uploadToRemoteInProgress",
    UPLOAD_TO_REMOTE_SUCCESS = "uploadToRemoteSuccess",
    UPLOAD_TO_REMOTE_FAILED = "uploadToRemoteFailed",
    UPLOAD_TO_REMOTE_QUEUED = "uploadToRemoteQueued",
}

export default PROJECT_STATUS