import { Button, LinearProgress, Stack } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import { AppState } from "../../context/AppContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import NOTIFICATION_TYPE from "../../enums/notificationType";
import PROJECT_FEATURE_STATE from "../../enums/projectFeatureState";
import PROJECT_STATUS from "../../enums/projectStatus";
import useApi from "../../hooks/useApi";
import { transcribeProject } from "../../services/api/projectApi";
import {
  ProjectFeatureStateUpdate,
  ProjectStatusUpdate,
  RedactProgressNotification,
} from "../../types/notification";
import { ProjectFeatureState } from "../../types/project";
import LoadingSpinner from "../shared/LoadingSpinner";

import "./style.scss";
import EnableFeatureButton from "../shared/EnableFeatureButton";

const TranscriptionFeatureLoader = () => {
  const { api } = useApi();
  const { notification } = useContext<AppState>(AppContext);
  const { projectId, transcriptionState } =
    useContext<RedactEditorState>(RedactEditorContext);
  const [featureState, setFeatureState] = useState<ProjectFeatureState>();

  const progressMessage = useRef<string>("");
  const percentage = useRef<number>(0);

  useEffect(() => {
    setFeatureState(transcriptionState);
  }, [transcriptionState]);

  useEffect(() => {
    switch (notification?.type) {
      case NOTIFICATION_TYPE.PROGRESS:
        notifyProgress(notification.data as RedactProgressNotification);
        break;
      case NOTIFICATION_TYPE.FEATURE_STATE_UPDATE:
        notifyStateUpdate(notification.data as ProjectFeatureStateUpdate);
        break;
      default:
        break;
    }
  }, [notification]);

  const notifyStateUpdate = (update: ProjectFeatureStateUpdate) => {
    if (update.projectId === projectId && update.feature === "transcription") {
      setFeatureState(update.state);
    }
  };

  const notifyProgress = (progress: RedactProgressNotification) => {
    const isTranscription =
      progress.status === PROJECT_STATUS.TRANSCRIPTION_QUEUED ||
      progress.status === PROJECT_STATUS.TRANSCRIPTION_STARTED ||
      progress.status === PROJECT_STATUS.TRANSCRIPTION_SUCCESS ||
      progress.status === PROJECT_STATUS.TRANSCRIPTION_FAILED;

    if (progress.projectId !== projectId || !isTranscription) {
      return;
    }

    if (progress.status !== PROJECT_STATUS.TRANSCRIPTION_QUEUED) {
      percentage.current = Math.round(progress.percentComplete ?? 0);
      progressMessage.current = progress.stage;
    } else {
      percentage.current = 0;
      progressMessage.current = "Project is in a processing queue";
    }
  };

  const handleEnableTranscription = async () => {
    await transcribeProject(api, projectId);
    setFeatureState(PROJECT_FEATURE_STATE.PENDING);
  };

  const FeatureDisabled = () => (
    <>
      <p>Transcription is not enabled for this project.</p>
      <EnableFeatureButton onClick={handleEnableTranscription}>
        Click To Enable
      </EnableFeatureButton>
    </>
  );

  const FeatureLoading = () => (
    <Stack
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <p>
        Transcribing Project:{" "}
        <span>
          {progressMessage.current} {percentage.current}%
        </span>
      </p>
      <LoadingSpinner />
    </Stack>
  );

  const EnableFeatureFailed = () => (
    <>
      <p>Failed to enable Transcription for this project.</p>
      <EnableFeatureButton onClick={handleEnableTranscription}>
        Click To Enable
      </EnableFeatureButton>
    </>
  );

  const EnableFeatureSucceeded = () => (
    <>
      <p>Transcription Enabled: Reload the page to start using the feature</p>
      <EnableFeatureButton onClick={() => window.location.reload()}>
        Reload
      </EnableFeatureButton>
    </>
  );

  switch (featureState) {
    case PROJECT_FEATURE_STATE.DISABLED:
      return FeatureDisabled();
    case PROJECT_FEATURE_STATE.FAILED:
      return EnableFeatureFailed();
    case PROJECT_FEATURE_STATE.SUCCESS:
      return EnableFeatureSucceeded();
    case PROJECT_FEATURE_STATE.IN_PROGRESS:
    case PROJECT_FEATURE_STATE.PENDING:
    case PROJECT_FEATURE_STATE.QUEUED:
      return FeatureLoading();
    default:
      return <></>;
  }
};

export default TranscriptionFeatureLoader;
