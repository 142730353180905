import { ApiResponse } from "../../types/apiResponse";
import { IApi } from "../../types/apiTypes";
import {
  IAuthenticateDevicePayload,
  IGroupDevice,
  IGroupDevicePayload,
  IRequestMfaCodePayload,
} from "../../types/device";
import { IIdentifierType } from "../../types/device";

export const requestDeviceMfaCode = (
  api: IApi,
  payload: IRequestMfaCodePayload
): Promise<ApiResponse<string>> => {
  const url = `mfa/request-code`;
  return api
    .post(url, payload)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getDeviceVerification = (
  api: IApi,
  groupId: string,
  deviceId: string
): Promise<boolean> => {
  return api
    .get(`identity/device-verification/${groupId}/${deviceId}`)
    .then((result: ApiResponse<boolean>) => {
      if (result) {
        return result.data;
      }
    })
    .catch((error) => {
      console.log("Device Verification Error: ", error);
      return false;
    });
};

export const authenticateWorkspaceDevice = (
  api: IApi,
  payload: IAuthenticateDevicePayload
): Promise<ApiResponse<IGroupDevice>> => {
  const url = "mfa/verify";
  return api
    .post(url, payload)
    .then((result: ApiResponse<IGroupDevice>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const getDevices = (
  api: IApi,
  page: number,
  pageSize: number,
  keyword: string,
  id: string
): Promise<ApiResponse<IGroupDevice[]>> => {
  const url = `device/${id}/device-list?page=${page}&pageSize=${pageSize}&keyword=${keyword}`;
  return api.get(url).then((result: ApiResponse<IGroupDevice[]>) => {
    return result;
  });
};

export const addDevice = (
  api: IApi,
  payload: IGroupDevicePayload
): Promise<ApiResponse<string>> => {
  return api
    .post("device/create", payload)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const updateDevice = (
  api: IApi,
  payload: IGroupDevicePayload
): Promise<ApiResponse<string>> => {
  return api
    .post("device/update", payload)
    .then((result: ApiResponse<string>) => {
      return result;
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
};

export const deleteDevice = (
  api: IApi,
  id: string
): Promise<ApiResponse<string>> => {
  const url = `device/${id}/delete`;
  return api.remove(url).then((result: ApiResponse<string>) => {
    return result;
  });
};

export const fetchIdentifierTypes = (api: IApi): Promise<IIdentifierType[]> => {
  // todo: replace with api call
  return Promise.resolve<IIdentifierType[]>([
    {
      id: "email",
      description: "",
      displayName: "Email",
    },
    {
      id: "phone",
      description: "",
      displayName: "Phone",
    },
  ]);
};

export const fetchWorkspaceDevices = (
  api: IApi,
  id: string
): Promise<IGroupDevice[]> => {
  const url = `device/${id}/devices`;
  return api.get(url).then((result: ApiResponse<IGroupDevice[]>) => {
    return result.data;
  });
};
