import React, { useContext, useState, useEffect, useRef, } from "react";
import {
  TextField,
  IconButton,
} from "@mui/material";
import "./index.scss"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { RedactEditorState } from "../../../context/RedactEditorContext/types";
import RedactEditorContext from "../../../context/RedactEditorContext";
import { Box, } from "@material-ui/core";
import { usePopper } from "react-popper";
import { capitalizeFirstLetter, convertToTimeString, getSpeakerAbbreviation, getSpeakerColor } from "../helper";
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_SPEAKER, SearchMatch, Speaker, Transcription, } from "../../../types/transcription";
import TRANSCRIPTION_TYPE from "../../../enums/transcriptionTypes";
import { VideoJsPlayer } from "video.js";

interface IProps {
  transcript: Transcription,
  transcriptionSpeakers: { [id: string]: Speaker; },
  frameTime: number,
  first: boolean,
  searchText?: string,
  searchMatch?: SearchMatch,
  videoPlayer?: VideoJsPlayer
}

const RedactTranscriptionChatSimple: React.FC<IProps> = ({
  transcript,
  transcriptionSpeakers,
  frameTime,
  first,
  searchText,
  searchMatch,
  videoPlayer
}) => {
  const state = useContext<RedactEditorState>(RedactEditorContext);

  const [data, setData] = useState<Transcription>({
    ...transcript,
    original: transcript,
  });

  const [isCurrentPlaying, setIsCurrentlyPlaying] = useState(false);
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (frameTime >= data.start && frameTime <= data.end) {
      setIsCurrentlyPlaying(true);
    }
  }, [])

  useEffect(() => {
    if (frameTime >= data.start && frameTime <= data.end) {
      setIsCurrentlyPlaying(true);
    } else {
      setIsCurrentlyPlaying(false);
    }
  }, [frameTime, data]);

  const getHighlightedText = (text) => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
    let matchesCount = -1;
    return <span> {parts.map((part, i) => {
      if (part.toLowerCase() === searchText?.toLowerCase()) matchesCount++;
      return <span key={i} style={part.toLowerCase() === searchText?.toLowerCase() ?
        {
          backgroundColor: searchMatch?.transcriptionId === transcript.id && searchMatch?.index === matchesCount ? "orange" : "yellow",
          color: "black"
        } : {}}>
        {part}
      </span>
    })
    } </span>;
  }

  return (
        <>
          <div
            className={`row ${(transcriptionSpeakers[data.speakerId] ?? DEFAULT_SPEAKER).rightAligned ? "right" : "left"}`}
            ref={containerRef}>
            <>
              <div className="speaker">
                <button
                  className="speaker-bubble" style={{ backgroundColor: (transcriptionSpeakers[data.speakerId] ?? DEFAULT_SPEAKER).color, cursor: "pointer" }}
                  >
                  {(transcriptionSpeakers[data.speakerId] ?? DEFAULT_SPEAKER).abbreviation}
                </button>

              </div>
              <div className="transcript">
                <div className={`sentence ${isCurrentPlaying ? "current" : ""}`} id={`chat-${data.start}`}>
                  <div onClick={() => setTimeout(() => { videoPlayer?.currentTime(data.start / 1000) }, 200)} className="text">
                    {getHighlightedText(data.sentence)}
                  </div>
                </div>
                <div className="time">{convertToTimeString(data.start)}
                  {
                    data.status !== "original" && <>
                      <div className="vertical-line" style={{ height: "1em", backgroundColor: "#BACBD5" }}></div> {capitalizeFirstLetter(data.status)}
                    </>
                  }</div>

              </div>
            </>
          </div>
        </>
  );
};

export default RedactTranscriptionChatSimple;
