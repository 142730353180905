const classTypes = ["Face"];

const detectionMarks = [
    {
      value: 0.1,
      label: 'Very low',
    },
    {
      value: 0.3,
      label: 'Low',
    },
    {
      value: 0.5,
      label: 'Medium',
    },
    {
      value: 0.7,
      label: 'High',
    },
    {
      value: 0.9,
      label: 'Very high',
    },
  ];

const randomColor = (alpha: number) => {
    return (
        'rgba(' +
        [
            ~~(Math.random() * 255),
            ~~(Math.random() * 255),
            ~~(Math.random() * 255),
            alpha || 1
        ] +
        ')'
    );
}

const randomColorHex = () => {
  const random = Math.floor(Math.random() * 16777215).toString(16);
  var rgb = parseInt(random, 16);   // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff;  // extract red
  var g = (rgb >>  8) & 0xff;  // extract green
  var b = (rgb >>  0) & 0xff;  // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  // var s = new Option().style;
  // s.color = "#" + random;
  const isValidColor = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test("#" + random);

  if (luma < 40 || !isValidColor) {
      // pick a different colour
      return randomColorHex();
  }

  return "#" + random;
}

const audioContext = AudioContext && new AudioContext();
var mediaGain: GainNode;

const disposeAudioContext = async () => {
  if (mediaGain) {
    mediaGain.disconnect();
    mediaGain = undefined;
  }
}

const createMediaGain = (player: HTMLMediaElement) => {
  if (!mediaGain) {
    mediaGain = audioContext.createGain();
    const source = audioContext.createMediaElementSource(player);
    source.connect(mediaGain);
    mediaGain.connect(audioContext.destination);
    audioContext.resume();
  }
}

const beep = (duration: number) => {
  const osc = audioContext.createOscillator();
  const gain = audioContext.createGain();
  osc.connect(gain);
  gain.connect(audioContext.destination);
  gain.gain.value = 5 / 10;
  osc.start(audioContext.currentTime);
  osc.stop(audioContext.currentTime + duration / 1000);
}

const muteAudio = (player: HTMLMediaElement) => {
  createMediaGain(player);
  if (mediaGain.gain.value === 0) {
    return;
  }

  mediaGain.gain.value = 0;
}

const unmuteAudio = (player: HTMLMediaElement) => {
  createMediaGain(player);
  if (mediaGain.gain.value > 0) {
    return;
  }
  mediaGain.gain.value = player.volume;
}

const caseWithSpace = (value: string) => {
  if (!value || value.length === 0) {
    return '';
  }

  return value
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function(str){ return str.toUpperCase(); })
}

export { classTypes, detectionMarks, randomColor, randomColorHex, beep, muteAudio, unmuteAudio, disposeAudioContext, caseWithSpace };
