import { ApiResponse } from "../../types/apiResponse";
import { IApi } from "../../types/apiTypes";
import { NotificationData, OnPremNotificationData } from "../../types/notification";

export const getNotifications = (api: IApi): Promise<NotificationData[]> => {

    return api.get('notification/list').then((result: ApiResponse<NotificationData[]>) => {
        return result?.data || []
    })
}

export const markAsRead = (api: IApi, notificationIds: string[]) => {
    return api.post(`notification/read`, {
        notifications: notificationIds
    })
}

export const deleteNotification = (api: IApi, notificationId: string) => {
    return api.remove(`notification/${notificationId}/delete`)
}

export const clearNotifications = (api: IApi) => {
    return api.remove(`notification/clear`)
}

export const getNotificationDetails = (api: IApi, start: Date): Promise<OnPremNotificationData[]> => {
    return api.get(`notification/list/events?start=${start.toUTCString()}`)
        .then((result: ApiResponse<OnPremNotificationData[]>) => {
            return result.data || [];
        });
}