import React, { useContext, useEffect, useState, useRef } from "react";

import VideoCanvas from "../../components/VideoCanvas";
import VideoPlayer from "../../components/VideoPlayer";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";

import "./editProject.scss";
import RedactControlButton from "../../components/RedactControlButton";
import RedactionStatusWrapper from "../../components/RedactionStatusWrapper";
import useRedactProjectActions, { IRedactProjectActions } from "../../hooks/useRedactProjectActions";
import useTrackingNotification from "../../hooks/notifications/useTrackingNotification";
import useCanvasEvents from "../../hooks/useCanvasEvents";
import useUpdateCanvas from "../../hooks/useUpdateCanvas";
import useApi from "../../hooks/useApi";
import { getProject } from "../../services/api/projectApi";
import { ProjectDetail } from "../../types/project";
import { AppState } from "../../context/AppContext/types";
import AppContext from "../../context/AppContext";
import EDITOR_TAB from "../../enums/editorTab";
import RedactEditorTabs from "../../components/RedactEditorTabs";
import Split from 'react-split'
import RedactAudio from "../../components/RedactAudio";
import TimelineEditor from "../../components/TimelineEditor";
import PROJECT_FEATURE_STATE from "../../enums/projectFeatureState";
import VideoRedactionFeatureLoader from "../../components/ProjectFeatureLoaders/VideoRedactionFeatureLoader";
import AudioRedactionFeatureLoader from "../../components/ProjectFeatureLoaders/AudioRedactionFeatureLoader";
import useProjectNotifications from "../../hooks/notifications/useProjectNotification";
import useFrames from "../../hooks/useFrames";
import useTracking from "../../hooks/useTracking";
import DeviceVerificationWrapper from "../../components/DeviceVerificationWrapper";
import { useHistory } from "react-router-dom";
import { IDeviceVerificationActions } from "../../types/device";

const RedactEditor: React.FC = () => {
  const { idleTimer, redact } = useContext<AppState>(AppContext);
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { setSidePanelSize, sidePanelSize } = state;
  const { isModified, audioPeakUrls, setProject } = state;
  const history = useHistory();
  const [verificationActions, setVerificationActions] = useState<IDeviceVerificationActions>(null);

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useProjectNotifications();
  const projectActions = useRedactProjectActions();
  const { api, apiToken } = useApi();

  const [activeEditorTab, setActiveEditorTab] = useState<EDITOR_TAB>(EDITOR_TAB.TRANSCRIPTION);
  useEffect(() => {
    if (state.focusedItem) {
      setActiveEditorTab(EDITOR_TAB.OBJECT_PROPERTY)
    }
  }, [state.focusedItem])

  const [fetchProjectMsg, setFetchProjectMsg] = useState<string>('Fetching project...')
  const [sideBarMinSize, setSideBarMinSize] = useState<number>(window.innerWidth * 0.3);
  const projectActionRef = useRef<IRedactProjectActions>()
  const targetRef = useRef<any>();

  const sidePanelRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (projectActions) {
      projectActionRef.current = projectActions;
    }
  }, [projectActions])

  useEffect(() => {
    if (!sidePanelSize && sidePanelRef?.current) {
      setSidePanelSize(sidePanelRef.current.clientWidth)
    }
  }, [sidePanelRef.current])

  useEffect(() => {
    if (!sidePanelSize && targetRef?.current) {
      setSideBarMinSize(Math.max(targetRef.current.offsetWidth * 0.3, 365));
    }
  }, [sidePanelRef.current, targetRef.current])

  useEffect(() => {

    function handleKeyDown(event) {
      //undo trigger.
      //TODO: Create keyboard shortcuts module/hook
      if ((event.ctrlKey || event.metaKey) && event.key === "z") {
        event.preventDefault();
        stateRef.current.undoChanges();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    function updateSize() {
      if (sidePanelRef?.current?.clientWidth) setSidePanelSize(sidePanelRef.current.clientWidth)
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener('resize', updateSize);
      state?.resetState();
    }
  }, []);

  useEffect(() => {
    if (targetRef.current) {
      setSideBarMinSize(Math.max(targetRef.current.offsetWidth * 0.3, 365));
    }
  }, [targetRef, sidePanelSize])

  useEffect(() => {
    if (idleTimer) {

      if (projectActions.loadingMessages && projectActions.loadingMessages.length > 0) {
        idleTimer.reset();
        idleTimer.pause();
      } else {
        idleTimer.resume();
      }
    }
  }, [projectActions.loadingMessages])

  useEffect(() => {
    const { projectId } = projectActions;
    if (projectId && apiToken) {
      getProject(api, projectId).then((project: ProjectDetail) => {
        if (!project) {
          throw 'Failed to fetch project';
        }

        setProject(projectId, project);
        setFetchProjectMsg(undefined);
      })
        .catch(() => {
          alert('There was an issue fetching your project. Please try again or contact a site admin.');
          setFetchProjectMsg('Failed to fetch project');
        })
    }
  }, [projectActions.projectId, projectActions.fetchProjectTrigger, apiToken]);


  useFrames();
  useTracking();
  useTrackingNotification();
  useCanvasEvents();
  useUpdateCanvas();

  const onDrag = () => setSidePanelSize(sidePanelRef.current.clientWidth);

  const handleOnModalClose = () => {
    history.push("/projects?page=1");
  };

  const handleUpdate = (callBack: Function) => {
    setVerificationActions({
      run: callBack, onFinished: () => {
        setVerificationActions(null);
      }
    });
  }

  const loadingMessages = fetchProjectMsg ? [fetchProjectMsg].concat(projectActions.loadingMessages) : projectActions.loadingMessages;
  return (
    <DeviceVerificationWrapper
      onModalClose={handleOnModalClose}
      actions={verificationActions}
    >
      <RedactionStatusWrapper
        canLeave={(projectActions.canLeave && !isModified)}
        loadingMessages={loadingMessages}
        status={projectActions.status}
        redactAction={projectActions.redact}
        retryAction={projectActions.retryCreate}
        deleteAction={projectActions.remove}
      >
        {(!fetchProjectMsg) &&
          <div className={`app-page-inner edit-project${sidePanelSize < 430 ? ' mobile-side-panel' : ''}`}>
            <div className="edit-project-inner" ref={targetRef}>
              <Split
                className="split"
                sizes={[70, 30]}
                minSize={[0, sideBarMinSize]}
                gutterSize={20}
                snapOffset={30}
                dragInterval={1}
                onDrag={onDrag}
                direction="horizontal"
                cursor="col-resize">

                <section className="video-canvas-wrapper" style={{ maxWidth: 'calc(100% - 365px)' }}>
                  <div className="video-canvas">
                    <VideoCanvas />
                    <VideoPlayer />
                  </div>
                  {redact === undefined && <p>Fetching Workspace Redaction Status...</p>}
                  {redact === false && <p>Redaction feature is not enabled for this workspace, please contact administrator.</p>}
                  {redact === true && <div className="editor-timeline-container" style={{ minHeight: 0, height: '20%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    {state.audioRedactionState !== PROJECT_FEATURE_STATE.SUCCESS ?
                      <div style={{ height: 100, paddingBottom: 10 }}>
                        <AudioRedactionFeatureLoader />
                      </div> :
                      <>
                        {(audioPeakUrls.length > 0) &&
                          <div style={{ height: 100 }}>
                            <RedactAudio />
                          </div>
                        }
                      </>}
                    {<div style={{ minHeight: 0 }}>
                      {state.videoRedactionState === PROJECT_FEATURE_STATE.SUCCESS ?
                        <TimelineEditor /> : <VideoRedactionFeatureLoader />}
                    </div>}
                  </div>

                  }
                </section>
                <section className="editor-sidebar" ref={sidePanelRef} style={{ minWidth: '365px' }}>
                  <div className="control-section" style={{ zIndex: 1 }}>
                    <RedactControlButton
                      onUpdate={handleUpdate}
                      actions={projectActions}
                      setEditorTab={setActiveEditorTab}
                    />
                  </div>
                  <div className="tabs-section">
                    <RedactEditorTabs activeTab={activeEditorTab} projectActions={projectActions} setActiveEditorTab={setActiveEditorTab} />
                  </div>
                </section>
              </Split>

            </div>
          </div>
        }
      </RedactionStatusWrapper>
    </DeviceVerificationWrapper >
  );
};

export default RedactEditor;
