import { createContext } from "react";
import { ProjectNotification } from "../../types/notification";
import { AppState, WorkspaceInfo } from "./types";
import { IRedactProjectFolder } from "../../types/redactProjectFolder";
import { IUserPreference } from "../../types/user";

const defaultValue: AppState = {
    setWorkspace: (workspace: WorkspaceInfo) => {},
    setReloadWorkspace: (reload: boolean) => {},
    setLatestNotification: (notification: ProjectNotification) => {},
    setUserPreferences: (userPreferences: IUserPreference)  => {}
}
const AppContext = createContext(defaultValue)
export default AppContext
