export type AppConfig = {
    AuthMethod: "cloud" | "license";
    NotifyMethod: "event" | "polling";
    StorageMethod: "cloud" | "local"

    NotifyWithEvent: boolean;
    NotifyWithPolling: boolean;

    IsCloudAuth: boolean;
    IsLicenseKeyAuth: boolean;

    IsCloudStorage: boolean;
    IsLocalStorage: boolean;
}

export const appConfig: AppConfig = {
    AuthMethod: process.env.REACT_APP_AUTH_METHOD?.trim().toLowerCase() as "cloud" | "license",
    NotifyMethod: process.env.REACT_APP_NOTIFY_METHOD?.trim().toLowerCase() as "event" | "polling",
    StorageMethod: process.env.REACT_APP_STORAGE_METHOD?.trim().toLowerCase() as "cloud" | "local",

    NotifyWithEvent: process.env.REACT_APP_NOTIFY_METHOD?.trim().toLowerCase() === "event",
    NotifyWithPolling: process.env.REACT_APP_NOTIFY_METHOD?.trim().toLowerCase() === "polling",

    IsCloudAuth: process.env.REACT_APP_AUTH_METHOD?.trim().toLowerCase() === "cloud",
    IsLicenseKeyAuth: process.env.REACT_APP_AUTH_METHOD?.trim().toLowerCase() === "licensekey",

    IsCloudStorage: process.env.REACT_APP_STORAGE_METHOD?.trim().toLowerCase() === "cloud",
    IsLocalStorage: process.env.REACT_APP_STORAGE_METHOD?.trim().toLowerCase() === "local"
}