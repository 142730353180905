import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, FormControl } from "@mui/material";
import _, { find, findIndex, first, last } from "lodash";
import "./index.scss";
import ReactTranscriptionChat from "./RedactTranscriptionChat";
import VerticalChatIcon from "@mui/icons-material/FormatListBulleted";
import AddIcon from "@mui/icons-material/Add";
import BasicChatIcon from "@mui/icons-material/FormatAlignLeft";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlined from "@mui/icons-material/KeyboardArrowUpOutlined";
import { IconButton } from "@mui/material";
import TRANSCRIPTION_TYPE from "../../enums/transcriptionTypes";
import {
  Speaker,
  Transcription,
  TranscriptionChangeAction,
  TranscriptionChange,
  TranscriptionStatus,
  DEFAULT_SPEAKER,
  SearchMatch,
  TranscriptionResponse,
} from "../../types/transcription";
import ReactTranscriptionForm from "./RedactTranscriptionForm";
import { convertToTimeString, formatTranscriptList } from "./helper";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { CSVLink } from "react-csv";
import { Grid, Input, Tooltip } from "@material-ui/core";

import Popper from "@mui/material/Popper";
import { toast } from "react-toastify";

import { getTranscriptions } from "../RedactTranscriptionTab/helper";
import { ContentCopy } from "@mui/icons-material";

enum CHANGE_TYPE {
  TEXT,
  SPEAKER,
}
interface TranscriptionHistory {
  changeId: string;
  changeType: CHANGE_TYPE;
  action: TranscriptionChangeAction;
  dataId: string;
  data: Transcription | Speaker | undefined;
}

const RedactTranscriptionTab: React.FC = () => {
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { transcriptionUpdates } = state;
  const { sidePanelSize, projectId } = state;

  //variable below is used to track changes for undo event.
  const [history, setHistory] = useState<TranscriptionHistory[]>([]);

  const [transcriptionChat, setTranscriptionChat] = useState<Transcription[]>(
    []
  );
  const [transcriptionSpeakers, setTranscriptionSpeakers] = useState<{
    [id: string]: Speaker;
  }>({});
  const [transcriptionType, setTranscriptionType] = useState(
    TRANSCRIPTION_TYPE.VERTICAL
  );
  const [pages, setPages] = useState<Transcription[]>([]);
  const [triggerRefresh, setTriggerRefresh] = useState<number>(0);
  const [currentFrameTime, setCurrentFrameTime] = useState<number>(undefined);
  const [pageHeight, setPageHeight] = useState<number>(undefined);
  const [openedEditModule, setOpenedEditModuleId] = useState(undefined);

  const [isDownloadOpen, setIsDownloadOpen] = React.useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState<string>(undefined);
  const [currentSearchIndex, setCurrentSearchIndex] =
    useState<number>(undefined);
  const [currentSearchMatches, setCurrentSearchMatches] = useState<
    SearchMatch[]
  >([]);

  const scrollableContainerRef = useRef<HTMLElement>();
  const containerRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLElement>();
  const anchorDownloadRef = useRef<HTMLDivElement>(null);
  const downloadBoxRef = useRef<HTMLDivElement>();
  const sidebarHeaderRef = useRef<HTMLDivElement>();
  const textCanvasRef = useRef<HTMLCanvasElement>();

  //ref is being used here to ensure frametime is being updated
  //change was made to this snippet because useframe time does not work for audio only videos.
  const setFrameTimeRef = useRef(setCurrentFrameTime);
  useEffect(() => {
    if (state.videoPlayer) {
      state.videoPlayer.on("timeupdate", () => {
        setFrameTimeRef.current(state.videoPlayer.currentTime() * 1000);
      });
    }

    return () => {
      state.videoPlayer?.off("timeupdate");
    };
  }, [state.videoPlayer]);

  useEffect(() => {
    if (!state.transcribeUrl) {
      return;
    }

    setHistory([]);
    getTranscriptions(
      state.transcribeUrl,
      setTranscriptionSpeakers,
      (result: Transcription[]) =>
        setTranscriptionChat(formatTranscriptList(result))
    );

    updatePageHeight();

    const defaultResizeFn = window.onresize;
    window.onresize = () => {
      updatePageHeight();
      setTriggerRefresh(Math.random());
    };

    return () => {
      window.onresize = defaultResizeFn;
    };
  }, [state.transcribeUrl]);

  useEffect(() => {
    rollbackHistory();
  }, [transcriptionUpdates]);

  useEffect(() => {
    if (scrollableContainerRef.current) {
      const firstChild = scrollableContainerRef.current.children[0];
      if (!firstChild) {
        return;
      }

      sidebarHeaderRef.current = firstChild as HTMLDivElement;
    }
  }, [scrollableContainerRef?.current]);

  useEffect(() => {
    if (isFormOpen) {
      setIsSearchOpen(false);
    }
  }, [isFormOpen]);

  useEffect(() => {
    if (!isSearchOpen) {
      setSearchText(undefined);
    } else {
      setIsFormOpen(false);
      setOpenedEditModuleId(undefined);
    }
    updatePageHeight();
  }, [isSearchOpen]);

  useEffect(() => {
    paginateTranscription();
    calculateTextDimensions();

    if (containerRef.current) {
      setTriggerRefresh(triggerRefresh + 1);
    }
  }, [
    containerRef.current,
    transcriptionChat,
    sidePanelSize,
    pageHeight,
    headerRef?.current?.offsetWidth,
  ]);

  useEffect(() => {
    checkSearchMatches();
  }, [currentSearchIndex]);

  useEffect(() => {
    if (currentSearchMatches.length > 0 && !currentSearchIndex) {
      setCurrentSearchIndex(0);
    }
    scrollToSearch();
  }, [currentSearchMatches, currentSearchIndex]);

  useEffect(() => {
    if (isSearchOpen && currentSearchMatches.length > 0) {
      scrollToSearch();
    } else {
      scrollToIndex();
    }
  }, [transcriptionType, sidePanelSize]);

  useEffect(() => {
    scrollToIndex();
  }, [currentFrameTime]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        downloadBoxRef.current &&
        !downloadBoxRef.current.contains(event.target)
      ) {
        setIsDownloadOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [downloadBoxRef]);

  const exportable = useMemo(() => {
    return transcriptionChat.map((t) => {
      const speaker = transcriptionSpeakers[t.speakerId] ?? DEFAULT_SPEAKER;
      return [
        speaker.name,
        speaker.abbreviation,
        convertToTimeString(t.start),
        t.sentence,
        t.status,
      ];
    });
  }, [transcriptionChat, transcriptionSpeakers]);

  const scrollToSearch = () => {
    if (
      currentSearchIndex < 0 ||
      currentSearchIndex >= currentSearchMatches.length
    )
      return;

    if (
      scrollableContainerRef?.current &&
      currentSearchMatches.length > 0 &&
      searchText
    ) {
      let item = find(
        transcriptionChat,
        (t) =>
          t.id === currentSearchMatches[currentSearchIndex]?.transcriptionId
      );
      state.videoPlayer.currentTime(item.start / 1000);
    }
  };

  const scrollToIndex = () => {
    if (scrollableContainerRef?.current) {
      let item = transcriptionChat.find(
        (t) => currentFrameTime >= t.start && currentFrameTime < t.end
      );
      if (!item) return;
      if (transcriptionType === TRANSCRIPTION_TYPE.BASIC) {
        scrollableContainerRef.current.scrollTo(
          0,
          Math.max(0, item?.pageIndex * pageHeight)
        );
      } else {
        scrollableContainerRef.current.scrollTo(0, item.startPos);
      }
    }
  };

  const exportText = () => {
    const fileData = transcriptionChat
      .map((t) => {
        const speaker = transcriptionSpeakers[t.speakerId] ?? DEFAULT_SPEAKER;
        return `[${speaker.name}|${convertToTimeString(t.start)}|${
          t.status
        }]\n${t.sentence}`;
      })
      .join("\n\n");
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${projectId}-transcription.txt`;
    link.href = url;
    link.click();
  };

  const addChange = (change: TranscriptionChange) => {
    let transcriptionChanges = [...transcriptionUpdates];
    transcriptionChanges.push(change);
    state.updateTranscription(transcriptionChanges);
  };

  const checkSearchMatches = () => {
    let result = transcriptionChat
      .filter((t) =>
        t.sentence
          ?.toLocaleLowerCase()
          .includes(searchText?.toLocaleLowerCase())
      )
      .flatMap((t) =>
        (
          t.sentence
            ?.toLocaleLowerCase()
            .match(new RegExp(searchText?.toLocaleLowerCase(), "g")) || []
        ).map((m, i) => {
          return {
            transcriptionId: t.id,
            index: i,
          };
        })
      );
    if (currentSearchIndex >= result.length) {
      setCurrentSearchIndex(0);
    } else if (currentSearchIndex < 0) {
      setCurrentSearchIndex(result.length - 1);
    } else {
      setCurrentSearchMatches(result);
    }
  };

  const updatePageHeight = () => {
    if (!scrollableContainerRef.current) {
      getScrollContainer();
    }

    let tabsHeight = 145;
    setPageHeight(
      scrollableContainerRef?.current?.clientHeight -
        tabsHeight -
        headerRef?.current?.clientHeight
    );
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (currentSearchIndex === 0) {
      checkSearchMatches();
    } else if (event.target.value) {
      setCurrentSearchIndex(0);
    }
  };

  const getScrollContainer = () => {
    if (!containerRef.current) {
      return;
    }

    let parent: HTMLElement = containerRef.current.parentElement;
    for (let i = 0; i < 5; i++) {
      if (parent) {
        if (parent.className == "editor-sidebar") {
          parent.onscroll = () => {
            setTriggerRefresh(Math.random());
          };

          scrollableContainerRef.current = parent;
        } else {
          parent = parent.parentElement;
        }
      }
    }
  };

  const onCancelForm = () => {
    setOpenedEditModuleId(undefined);
    setIsFormOpen(false);
  };

  const rollbackHistory = () => {
    const latestHistoryEntry = history[history.length - 1];
    const latestChangeEntry =
      transcriptionUpdates[transcriptionUpdates.length - 1];

    if (latestHistoryEntry?.changeId === latestChangeEntry?.changeId) {
      return;
    }

    const targetHistoryIndex =
      history.findIndex((x) => x.changeId === latestChangeEntry?.changeId) + 1;
    //apply history rollback
    const version = history[targetHistoryIndex];
    if (version.changeType === CHANGE_TYPE.TEXT) {
      const versionData = version.data as Transcription;
      let transcriptClone = [...transcriptionChat];

      switch (version.action) {
        case TranscriptionChangeAction.Add:
          //remove from transcription list
          transcriptClone = transcriptClone.filter(
            (x) => x.id !== version.dataId
          );
          break;
        case TranscriptionChangeAction.Update:
          //update with previous version
          transcriptClone = [
            ...transcriptClone.filter((x) => x.id !== version.dataId),
            versionData,
          ];
          break;
        case TranscriptionChangeAction.Remove:
          //re-add to transcirption list
          transcriptClone.push(versionData);
          break;
        default:
          break;
      }

      setTranscriptionChat(formatTranscriptList(transcriptClone));
    } else if (version.changeType === CHANGE_TYPE.SPEAKER) {
      const versionData = version.data as Speaker;
      let speakerListClone = { ...transcriptionSpeakers };
      switch (version.action) {
        case TranscriptionChangeAction.Add:
          //remove from speaker list
          delete speakerListClone[version.dataId];
          break;
        case TranscriptionChangeAction.Update:
          speakerListClone[version.dataId] = versionData;
          break;
      }

      setTranscriptionSpeakers(speakerListClone);
    }

    //update history
    const historyClone = [...history];
    historyClone.splice(
      targetHistoryIndex,
      historyClone.length - targetHistoryIndex
    );
    setHistory(historyClone);
  };

  const updateTranscription = (
    action: TranscriptionChangeAction,
    previous: Transcription,
    update: Transcription,
    status: TranscriptionStatus
  ) => {
    //if previous exists but update doesn't, it is a delete op
    //if previous doesn't exist but update does, it is an add op
    //if both exists, it is an update op

    //create version id for change
    const changeId = uuidv4();
    const historyClone = [
      ...history,
      {
        changeId,
        action,
        dataId: update?.id ?? previous?.id,
        changeType: CHANGE_TYPE.TEXT,
        data: previous,
      },
    ];

    setHistory(historyClone);

    let updateData: TranscriptionResponse;
    if (action === TranscriptionChangeAction.Add || action === TranscriptionChangeAction.Update) {
      updateData = { ...update, status };
    } else if (action === TranscriptionChangeAction.Remove) {
      updateData = { ...previous, status };
    }
    addChange({
      changeId,
      action,
      transcription: updateData,
    });
  };

  const onAddTranscript = (transcript: Transcription) => {
    if (!transcript.sentence || !transcript.speakerId) {
      toast(`Can't add transcription with empty required fields`, {
        type: "error",
      });
      return;
    }

    const newTranscript = { ...transcript, status: TranscriptionStatus.Custom };
    const transcriptUpdate = [...transcriptionChat, newTranscript];
    setTranscriptionChat(formatTranscriptList(transcriptUpdate));
    updateTranscription(
      TranscriptionChangeAction.Add,
      undefined,
      newTranscript,
      TranscriptionStatus.Custom
    );
  };

  const onEditTranscript = (transcript: Transcription) => {
    if (!transcript.sentence) {
      toast(`Can't change transcription to have empty sentence`, {
        type: "error",
      });
      return;
    }

    const status =
      transcript.status === TranscriptionStatus.Custom
        ? TranscriptionStatus.Custom
        : TranscriptionStatus.Edited;
    const newTranscript = { ...transcript, status };
    const index = transcriptionChat.findIndex((x) => x.id === newTranscript.id);
    const transcriptUpdate = [...transcriptionChat];
    const previousEntry = { ...transcriptUpdate[index] };
    transcriptUpdate[index] = newTranscript;
    setTranscriptionChat(formatTranscriptList(transcriptUpdate));
    updateTranscription(
      TranscriptionChangeAction.Update,
      previousEntry,
      newTranscript,
      status
    );
  };

  const onRemoveTranscript = (id: string) => {
    const index = transcriptionChat.findIndex((x) => x.id === id);
    if (index >= 0) {
      const target = { ...transcriptionChat[index] };
      const transcriptUpdate = [
        ...transcriptionChat.filter((x) => x.id !== id),
      ];
      setTranscriptionChat(transcriptUpdate);
      updateTranscription(
        TranscriptionChangeAction.Remove,
        target,
        undefined,
        undefined
      );
    }
  };

  const updateSpeaker = (
    action: TranscriptionChangeAction,
    previous: Speaker,
    update: Speaker
  ) => {
    const changeId = uuidv4();
    const historyClone = [
      ...history,
      {
        changeId,
        action,
        dataId: update?.id ?? previous?.id,
        changeType: CHANGE_TYPE.SPEAKER,
        data: previous,
      },
    ];

    setHistory(historyClone);

    addChange({
      changeId,
      action,
      speaker: update,
    });
  };

  const updateSpeakerList = (
    speaker: Speaker,
    action: TranscriptionChangeAction
  ) => {
    const currentSpeakers = { ...transcriptionSpeakers };
    currentSpeakers[speaker.id] = speaker;
    setTranscriptionSpeakers(currentSpeakers);
    updateSpeaker(action, undefined, speaker);
  };

  const onAddSpeaker = (speaker: Speaker) => {
    updateSpeakerList(speaker, TranscriptionChangeAction.Add);
  };

  const onEditSpeaker = (speaker: Speaker) => {
    updateSpeakerList(speaker, TranscriptionChangeAction.Update);
  };

  const getTranscriptContentKey = (
    transcript: Transcription,
    index: number
  ) => {
    return (
      transcript.id +
      transcript.sentence +
      transcript.start +
      transcript.end +
      transcript.speakerId +
      index
    );
  };

  const paginateTranscription = () => {
    let rowHeight = 26;
    let rowsPerPage = Math.floor(pageHeight / rowHeight);
    let currentRowWidth = 0;
    let currentPageRows = 1;
    let currentPage = 0;
    let pages = [];

    const font = "400 17px SF Pro Text";
    const canvasCtx = textCanvasRef.current.getContext("2d");
    canvasCtx.font = font;
    const spaceSize = canvasCtx.measureText(" ").width;

    transcriptionChat.forEach((t, ti) => {
      let words = t.sentence.trim().split(" ");
      t.newPage = false;
      words.forEach((w, wi) => {
        const currentWordDim = canvasCtx.measureText(w);
        if (
          currentRowWidth + currentWordDim.width >
          headerRef?.current?.clientWidth - 30
        ) {
          //break to next line
          currentPageRows++;
          currentRowWidth = currentWordDim.width + spaceSize;
          if (currentPageRows > rowsPerPage) {
            currentPageRows = 1;
            t.newPage = true;
            currentPage++;
          }
        } else {
          currentRowWidth += currentWordDim.width + spaceSize;
        }
      });
      t.pageIndex = currentPage;
      if (pages.length <= currentPage) {
        pages.push([t]);
      } else {
        pages[currentPage].push(t);
      }
    });

    setPages(pages);
  };

  const calculateTextDimensions = () => {
    //todo: constant values should be derived from actual style values
    const rowHeight = 20;
    const timeStampHeight = 30;
    const textHeightPadding = 12;
    const textContainerBottomMargin = 10;

    const speakerBubbleWidth = 50;
    const transcriptRowPadding = 30;
    const transcriptBubbleMargins = 30;
    const transcriptEditControlContainerWidth = 62;
    const textWidthPadding = 28;
    const font = "400 17px SF Pro Text";

    const textContainerWidth =
      headerRef?.current?.offsetWidth -
      (speakerBubbleWidth +
        textWidthPadding +
        transcriptRowPadding +
        transcriptBubbleMargins +
        transcriptEditControlContainerWidth);
    const canvasCtx = textCanvasRef.current.getContext("2d");
    canvasCtx.font = font;
    let totalHeight = 0;

    const spaceSize = canvasCtx.measureText(" ").width;
    transcriptionChat.forEach((t) => {
      const words = t.sentence.trim().split(" ");
      let currentRowWidth = 0;

      let rowCount = 1;
      words.forEach((w, wi) => {
        const currentWordDim = canvasCtx.measureText(w);
        if (currentRowWidth + currentWordDim.width > textContainerWidth) {
          //break to next line
          rowCount++;
          currentRowWidth = currentWordDim.width + spaceSize;
        } else {
          currentRowWidth += currentWordDim.width + spaceSize;
        }
      });

      t.startPos = totalHeight;
      const itemHeight =
        rowCount * rowHeight +
        timeStampHeight +
        textHeightPadding +
        textContainerBottomMargin;
      totalHeight += itemHeight;
      t.endPos = totalHeight;
    });
  };

  const virtualizeList = (): {
    top: number;
    listHeight: number;
    list: Transcription[];
  } => {
    if (!containerRef.current) {
      return { top: 0, listHeight: 0, list: [] };
    }

    if (!scrollableContainerRef.current) {
      getScrollContainer();
    }

    const scrollableContainer =
      scrollableContainerRef.current || containerRef.current;

    if (transcriptionChat.length === 0) {
      return {
        top: 0,
        listHeight: 0,
        list: transcriptionChat,
      };
    }

    if (transcriptionType === TRANSCRIPTION_TYPE.BASIC) {
      let hiddenItemCount = Math.floor(
        scrollableContainer.scrollTop / pageHeight
      );
      let startIndex = Math.min(
        Math.max(0, hiddenItemCount),
        transcriptionChat.length
      );
      let endIndex = Math.min(
        Math.max(0, hiddenItemCount + 3),
        transcriptionChat.length
      );

      const scrollBasedList = _.flatMap(pages.slice(startIndex, endIndex));
      return {
        top: hiddenItemCount * pageHeight,
        listHeight: pageHeight * pages.length,
        list: scrollBasedList,
      };
    }

    const container = containerRef.current;
    const visibleHeight =
      scrollableContainer.offsetHeight - container.offsetTop;

    let lastVisible = last(
      transcriptionChat.filter(
        (t) => t.startPos < scrollableContainer.scrollTop
      )
    );
    if (!lastVisible) lastVisible = first(transcriptionChat);
    let startIndex = findIndex(
      transcriptionChat,
      (t) => t.id == lastVisible?.id
    );
    let endIndex = findIndex(
      transcriptionChat,
      (t) => t.startPos > scrollableContainer.scrollTop + visibleHeight
    );

    if (endIndex === -1) endIndex = transcriptionChat.length;
    endIndex = Math.min(endIndex + 1, transcriptionChat.length);

    const scrollBasedList = transcriptionChat.slice(startIndex, endIndex);
    const totalHeaderHeight =
      sidebarHeaderRef.current?.offsetHeight + headerRef?.current.offsetHeight;

    return {
      top: lastVisible.startPos,
      listHeight: last(transcriptionChat).endPos + totalHeaderHeight,
      list: scrollBasedList,
    };
  };
  const { top, listHeight, list } = useMemo(
    () => virtualizeList(),
    [triggerRefresh]
  );

  const copyTranscript = () => {
    navigator.clipboard.writeText(
      transcriptionChat
        .map((transcript) => {
          return transcript.sentence;
        })
        .join("")
    ).then(() => {
      toast("Transcript copied to clipboard.", {
        type: "success",
        autoClose: 1500
      })
    });
  };

  return (
    <div ref={containerRef}>
      <Box
        className="settings-container header-container"
        style={{ top: sidebarHeaderRef.current?.offsetHeight }}
        ref={headerRef}
      >
        <div className="header">
          <h2 className="title">Transcription</h2>
          <div className="chat-options">
            <Tooltip title="Search text">
              <IconButton
                size="small"
                onClick={() => {
                  setIsSearchOpen(!isSearchOpen);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add new">
              <IconButton
                size="small"
                disabled={isFormOpen && openedEditModule === ""}
                onClick={() => {
                  setOpenedEditModuleId("");
                  setIsFormOpen(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <div className="vertical-line"></div>
            <Tooltip title="Download transcription">
              <div style={{ display: "flex" }} ref={anchorDownloadRef}>
                <IconButton
                  size="small"
                  onClick={() => setIsDownloadOpen((prevOpen) => !prevOpen)}
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Popper
              sx={{ zIndex: 2, width: "100px" }}
              open={isDownloadOpen}
              ref={downloadBoxRef}
              anchorEl={anchorDownloadRef.current}
            >
              <div className="download-arrow">
                <div></div>
              </div>
              <Box className="download-menu">
                <CSVLink
                  filename={`${projectId}-transcription.csv`}
                  data={exportable}
                  onClick={() => setIsDownloadOpen(false)}
                  headers={[
                    "Speaker Name",
                    "Speaker Abbreviation",
                    "Start",
                    "Spoken Text",
                    "Status",
                  ]}
                >
                  CSV
                </CSVLink>
                <button
                  onClick={() => {
                    exportText();
                    setIsDownloadOpen(false);
                  }}
                >
                  Text
                </button>
              </Box>
            </Popper>
            <div className="vertical-line"></div>
            <Tooltip title="Chat view mode">
              <IconButton
                size="small"
                disabled={transcriptionType === TRANSCRIPTION_TYPE.VERTICAL}
                onClick={() => {
                  setTranscriptionType(TRANSCRIPTION_TYPE.VERTICAL);
                  setTriggerRefresh(Math.random());
                }}
              >
                <VerticalChatIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Text view mode">
              <IconButton
                size="small"
                disabled={transcriptionType === TRANSCRIPTION_TYPE.BASIC}
                onClick={() => {
                  setTranscriptionType(TRANSCRIPTION_TYPE.BASIC);
                  setTriggerRefresh(Math.random());
                }}
              >
                <BasicChatIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy to clipboard">
              <IconButton size="small" onClick={copyTranscript}>
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {isSearchOpen && (
          <>
            <Grid container className="search">
              <Grid item xs={12} className="search-header">
                <h3>Search</h3>
              </Grid>
              <Grid item xs={12} className="search-container">
                <FormControl>
                  <Input
                    type="text"
                    value={searchText}
                    autoFocus
                    endAdornment={
                      <button
                        className="default-button"
                        onClick={() => setSearchText("")}
                      >
                        Clear
                      </button>
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeSearch(event)
                    }
                  />
                </FormControl>
                <div className="search-actions">
                  <IconButton
                    onClick={() =>
                      setCurrentSearchIndex(currentSearchIndex - 1)
                    }
                  >
                    <KeyboardArrowUpOutlined />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      setCurrentSearchIndex(currentSearchIndex + 1)
                    }
                  >
                    <KeyboardArrowDownOutlined />
                  </IconButton>
                  <IconButton onClick={() => setIsSearchOpen(false)}>
                    <ClearIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </>
        )}

        {isFormOpen && (
          <ReactTranscriptionForm
            key={`form-${openedEditModule}`}
            isNew={openedEditModule === ""}
            onSubmit={
              openedEditModule === "" ? onAddTranscript : onEditTranscript
            }
            onAddSpeaker={onAddSpeaker}
            onCloseForm={onCancelForm}
            transcriptionSpeakers={{ ...transcriptionSpeakers }}
            transcription={{
              ...transcriptionChat.find((x) => x.id === openedEditModule),
            }}
          ></ReactTranscriptionForm>
        )}
      </Box>
      {(!isFormOpen || isSearchOpen || openedEditModule !== "") && (
        <Box style={{ background: "#252836" }}>
          <hr />
        </Box>
      )}
      <Box
        className="chat-container settings-container"
        style={{ height: listHeight, position: "relative" }}
      >
        <canvas style={{ position: "absolute" }} ref={textCanvasRef} />
        <div
          className={`${transcriptionType}`}
          style={{
            position: "absolute",
            top: top,
            width: "100%",
            background: "#252836",
          }}
        >
          {list.map((transcript, i) => {
            return (
              <ReactTranscriptionChat
                key={getTranscriptContentKey(transcript, i)}
                transcript={transcript}
                onRemoveTranscript={onRemoveTranscript}
                onEditTranscript={onEditTranscript}
                transcriptionType={transcriptionType}
                openedEditModule={openedEditModule}
                setOpenedEditModuleId={setOpenedEditModuleId}
                transcriptionSpeakers={transcriptionSpeakers}
                onAddSpeaker={onAddSpeaker}
                onEditSpeaker={onEditSpeaker}
                frameTime={currentFrameTime}
                first={i === 0}
                searchText={searchText}
                searchMatch={currentSearchMatches[currentSearchIndex]}
                setIsFormOpen={setIsFormOpen}
              />
            );
          })}
        </div>
      </Box>
    </div>
  );
};

export default RedactTranscriptionTab;
