import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { RedactEditorState } from "../../context/RedactEditorContext/types";
import { MergePayload, SwapPayload, mergeFrameObjects, swapFrameObjects } from "../../services/api/frameApi";
import { getNewObjectIdAndSequence } from "../../services/functions/detectedObject";
import { IApi } from "../../types/apiTypes";
import { DetectedObject } from "../../types/object";
import DETECTION_TYPE from '../../enums/detectionType';
import { UserDefinedFrameObject } from '../../types/frame';
import { getNextFrameTimeSec, getPreviousFrameTimeSec } from '../../services/functions/frames';

export const splitObject = async (api: IApi, obj: DetectedObject, timeSec: number, state: RedactEditorState) => {

    document.body.style.cursor = 'wait';
    //update object id, sequence and set frames
    const newFirstSplitEndTime = getPreviousFrameTimeSec(timeSec, state.frameRate);
    // console.log('split target', obj, timeSec, newFirstSplitEndTime);
    const userFrameIntersectIndex = obj.frames.findIndex((range) => range.startTimeSec <= obj.endTime && range.endTimeSec >= obj.endTime);
    const {objectId, sequence } = getNewObjectIdAndSequence(state.detectedObjects, state.deletedObjects);
    const firstObj = {...obj, endTime: newFirstSplitEndTime, frames: _.cloneDeep(obj.frames) };
    const secondObj = {...obj, startTime: timeSec, frames: _.cloneDeep(obj.frames), sequence, objectId: objectId.toString() };

    //update any intersecting frames
    if (userFrameIntersectIndex > -1) {
        firstObj.frames.splice(userFrameIntersectIndex + 1);
        firstObj.frames[userFrameIntersectIndex].endTimeSec = newFirstSplitEndTime;
        secondObj.frames.splice(0, userFrameIntersectIndex);
        secondObj.frames[0].startTimeSec = timeSec;
    }

    // console.log('split result', firstObj, secondObj);

    //update frames
    const swapPayload: SwapPayload = {
        version: uuidv4(),
        session: state.trackingSession,
        fromObjectId: firstObj.objectId,
        toObjectId: secondObj.objectId,
        startTimeSec: secondObj.startTime,
        endTimeSec: secondObj.endTime
    }

    await swapFrameObjects(api, state.projectId, swapPayload);
    state.updateFrameVersions([firstObj, secondObj], [], [...state.frameVersions, {
        version: swapPayload.version,
        startTime: swapPayload.startTimeSec,
        endTime: swapPayload.endTimeSec
    }]);
    
    document.body.style.cursor = 'auto';
}

export const mergeObjects = async (api: IApi, objects: DetectedObject[], state: RedactEditorState) => {
    document.body.style.cursor = 'wait';
    const { minStartTime, maxEndTime, smallestObjectId, smallestSequence } = objects.reduce(
        (acc, obj) => {
          const { startTime, endTime, objectId, sequence } = obj;
      
          // Update minStartTime if the current object's start time is smaller
          if (startTime < acc.minStartTime) {
            acc.minStartTime = startTime;
          }
      
          // Update maxEndTime if the current object's end time is larger
          if (endTime > acc.maxEndTime) {
            acc.maxEndTime = endTime;
          }
      
          // Update smallestObjectId if the current object's objectId is smaller
          const parsedId = parseInt(objectId);
          if (parsedId < acc.smallestObjectId) {
            acc.smallestObjectId = parsedId;
          }
      
          // Update smallestSequence if the current object's sequence is smaller
          if (sequence < acc.smallestSequence) {
            acc.smallestSequence = sequence;
          }
      
          return acc;
        },
        {
          minStartTime: Infinity,
          maxEndTime: -Infinity,
          smallestObjectId: Infinity,
          smallestSequence: Infinity,
        }
      );
      
    // Use reduce to sort objects by startTime
    const sortedObjects = objects.reduce((acc: DetectedObject[], obj: DetectedObject) => {
        // Find the index where the current object should be inserted based on startTime
        const insertIndex = acc.findIndex((item) => obj.startTime < item.startTime);
    
        // If the insertIndex is -1, it means the current object has the largest startTime so far
        // In that case, push the current object to the end of the accumulator array
        if (insertIndex === -1) {
        acc.push({ ...obj });
        }
        // Otherwise, splice the current object at the insertIndex to maintain the sorted order
        else {
        acc.splice(insertIndex, 0, { ...obj });
        }
    
        return acc;
    }, []);

    let detectionType = DETECTION_TYPE.MANUAL;
    if (objects.some(x => x.detectionType === DETECTION_TYPE.AUTO)) {
        detectionType = DETECTION_TYPE.AUTO;
    } else if (objects.some(x => x.detectionType === DETECTION_TYPE.HYBRID)) {
        detectionType = DETECTION_TYPE.HYBRID;
    }

    // console.log('merge targets', sortedObjects);
    const objectId = smallestObjectId.toString();
    const mergedObj: DetectedObject = {
        ...sortedObjects[0],
        startTime: minStartTime,
        endTime: maxEndTime,
        sequence: smallestSequence,
        objectId: objectId,
        frames: mergeFrames(objectId, smallestSequence, objects, state.frameRate),
        thumbnailUrl: objects.find(x => x.thumbnailUrl && x.thumbnailUrl.length > 0)?.thumbnailUrl,
        detectionType
    };

    // console.log('merged obj', mergedObj);

    //update frames if there's any to be updated
    //update in state
    const toDelete = sortedObjects.filter(x => x.objectId !== mergedObj.objectId);
    const mergePayload: MergePayload = {
        startTimeSec: mergedObj.startTime,
        endTimeSec: mergedObj.endTime,
        defaultObjectId: mergedObj.objectId,
        objectIds: sortedObjects.map(x => x.objectId),
        version: uuidv4(),
        session: state.trackingSession
    };

    await mergeFrameObjects(api, state.projectId, mergePayload);

    state.updateFrameVersions([mergedObj], toDelete, [...state.frameVersions, {
        version: mergePayload.version,
        startTime: mergePayload.startTimeSec,
        endTime: mergePayload.endTimeSec
    }]);

    document.body.style.cursor = 'auto';
}

const mergeFrames = (objectId: string, sequence: number, detectedObjects: DetectedObject[], frameRate: number): UserDefinedFrameObject[] => {
    const mergedFrames: UserDefinedFrameObject[] = [];

    const sortedRecords: UserDefinedFrameObject[] = detectedObjects
        .flatMap((detectedObj) =>
            detectedObj.frames.map((frame) => ({
                ...frame,
                objectId: detectedObj.objectId,
                sequence: detectedObj.sequence,
            }))
        )
        .sort((a, b) => {
            if (a.startTimeSec !== b.startTimeSec) {
                return a.startTimeSec - b.startTimeSec;
            } else {
                return a.sequence - b.sequence;
            }
        });

    let previousEndTimeSec = -Infinity;

    for (const record of sortedRecords) {
        const currentRecord = {...record, sequence, objectId };
        if (currentRecord.startTimeSec <= previousEndTimeSec) {
            currentRecord.startTimeSec = getNextFrameTimeSec(mergedFrames[mergedFrames.length - 1].endTimeSec, frameRate); //Start it at the next frame
        }

        if (currentRecord.startTimeSec <= currentRecord.endTimeSec) { //ensure start and end time does not overlap
            mergedFrames.push(currentRecord);
            previousEndTimeSec = currentRecord.endTimeSec;
        }
    }

    return mergedFrames;
}