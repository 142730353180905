
import React, { useEffect, useReducer, useRef} from "react";
import { VideoJsPlayer } from "video.js";
import RedactEditorContext, { defaultEditorContextState } from "..";
import { AudioChannel } from "../../../types/audio";
import { FrameItem, FrameVersion } from "../../../types/frame";
import { DetectedObject, ROIObject } from "../../../types/object";
import { FocusedAudioSegment, FocusedDetectedObject, FocusedMultiObjects, FocusedROI } from "../../../types/focusedObjects";
import { ProjectDetail, ProjectFeatureState, ProjectSettingsData } from "../../../types/project";
import { RedactEditorState } from "../types";
import { Transcription, TranscriptionChange } from "../../../types/transcription";
import { CurrentTrackedItem } from "../../../types/tracking";
import { handleUpdateObjectList } from "./helper";

interface RedactEditorContextProviderProps {
  children: React.ReactNode;
}

interface SetProjectDetailsParams {
  projectId: string
  objects: Array<DetectedObject>
  projectName: string
  videoRedactionType: string
  confidence: number
  frameRate: number
  frameCount: number
  frameHeight: number
  frameWidth: number
  audioChannels: Array<AudioChannel>
  projectDesc?: string
  detectFace?: boolean
  transcribe?: boolean
  detectWeapon?: boolean
  detectLicensePlate?: boolean
  thumbnailUrl?: string
  videoUrl?: string
  audioPeakUrls: string[]
  previewUrl?: string
  transcribeUrl?: string
  folderId?: string
  originalFileExtension: string
  selectedTranscription?: Transcription
  transcriptionState: ProjectFeatureState
  videoRedactionState: ProjectFeatureState
  audioRedactionState: ProjectFeatureState
}

const RedactEditorReducer: React.Reducer<
  RedactEditorState,
  Partial<RedactEditorState>
> = (state, action) => {
  return { ...state, ...action };
};

const RedactEditorContextProvider: React.FC<RedactEditorContextProviderProps>
  = ({ children }): React.ReactElement => {

    const history = useRef<Partial<RedactEditorState>[]>([]);
    const updateHistory = (newVersion: Partial<RedactEditorState>) => {
      const historyEntry: Partial<RedactEditorState> = {};
      if (stateRef.current.videoPlayer) {
        historyEntry.playerTime = stateRef.current.videoPlayer.currentTime();
      }

      Object.keys(newVersion).forEach(key => {
        historyEntry[key] = stateRef.current[key];
      });

      // console.log('new state', newVersion, 'history ', historyEntry)
      //cache the current video time so the app can rewind to it during an undo
      // historyEntry.currentFrameTime = stateRef.current.currentFrameTime;
      history.current.push(historyEntry);
    }

    const undoChanges = () => {
      if (history.current.length > 0) {
        const newState: Partial<RedactEditorState> = {};
        const latestEntry = history.current[history.current.length - 1];
        Object.keys(latestEntry).forEach(key => {
          newState[key] = latestEntry[key];
        });

        if (stateRef.current.videoPlayer) {
          stateRef.current.videoPlayer.currentTime(latestEntry.playerTime);
        }
        // console.log('history rollback', newState);

        history.current = history.current.slice(0, -1);
        dispatch(newState);
      }
    };

    const setProject = (id: string, project: ProjectDetail) => {
      setProjectDetails({...project,
        projectId: id,
        objects: project.detectedObjects,
        projectName: project.name,
        projectDesc: project.description,
        videoRedactionType: project.videoRedactionType
      });
    };

    const setFrames = (frames: Array<FrameItem>) => {
      const newState: Partial<RedactEditorState> = {};
      newState.frames = frames;
      newState.toggleCanvasRefresh = !stateRef.current.toggleCanvasRefresh;
      dispatch(newState);
    };

    const updateFrameVersions = (updateObjects: DetectedObject[], deleteObjects: DetectedObject[], versions: FrameVersion[]) => {
      const { detectedObjects, deletedObjects, objectMap } = handleUpdateObjectList(stateRef.current, updateObjects, deleteObjects);
      const newState: Partial<RedactEditorState> = {
        detectedObjects,
        deletedObjects,
        objectMap,
        toggleCanvasRefresh: !stateRef.current.toggleCanvasRefresh,
        frameVersions: versions,
        isModified: true
      };

      updateHistory(newState);
      dispatch(newState);
    }

    const setProjectDetails = (params: SetProjectDetailsParams) => {
      const newState: Partial<RedactEditorState> = {};
      //set existing project id to avoid a refresh loop
      newState.projectId = params.projectId
      newState.detectedObjects = params.objects
      newState.description = params.projectDesc || ''
      newState.videoRedactionType = params.videoRedactionType || ''
      newState.thumbnailUrl = params.thumbnailUrl
      newState.name = params.projectName || ''
      newState.originalFileExtension = params.originalFileExtension
      newState.channels = params.audioChannels || []
      newState.confidence = params.confidence
      newState.detectFace = params.detectFace
      newState.detectLicensePlate = params.detectLicensePlate
      newState.detectWeapon = params.detectWeapon
      newState.transcriptionUpdates = []
      newState.deletedObjects = []
      newState.focusedItem = undefined
      newState.isModified = false
      newState.frameRate = params.frameRate;
      newState.frameCount = params.frameCount;
      newState.frameHeight = params.frameHeight;
      newState.frameWidth = params.frameWidth;
      newState.previewUrl = params.previewUrl;
      newState.transcribeUrl = params.transcribeUrl;
      newState.folderId = params.folderId;
      newState.videoUrl = params.videoUrl;
      newState.audioPeakUrls = params.audioPeakUrls;
      newState.selectedTranscription = params.selectedTranscription;
      newState.toggleCanvasRefresh = !state.toggleCanvasRefresh;
      newState.audioRedactionState = params.audioRedactionState;
      newState.transcriptionState = params.transcriptionState;
      newState.videoRedactionState = params.videoRedactionState;

      if (state.canvas) {
        state.canvas.clear();
      }

      newState.objectMap = new Map<string, DetectedObject>();
      params.objects.forEach((x) => {
        newState.objectMap.set(x.objectId, x);
      });

      dispatch(newState)
    }

    const updateDetectedObjectList = (updateObjects: DetectedObject[], deleteObjects: DetectedObject[]) => {

      const { detectedObjects, deletedObjects, objectMap, focusedItem } = handleUpdateObjectList(stateRef.current, updateObjects, deleteObjects);
      const newState: Partial<RedactEditorState> = { detectedObjects, deletedObjects, objectMap, focusedItem };
      newState.toggleCanvasRefresh = !stateRef.current.toggleCanvasRefresh;
      if ((updateObjects && updateObjects.length > 0) || (deleteObjects && deleteObjects.length > 0)) {
        newState.isModified = true;
      }

      updateHistory(newState);
      dispatch(newState);
    };

    const updateTranscription = (updates: TranscriptionChange[]) => {

      const newState: Partial<RedactEditorState> = {};
      newState.transcriptionUpdates = (!updates || updates.length === 0)? [] : [...updates];
      newState.isModified = true;
      updateHistory(newState);
      dispatch(newState);
    }

    const updateTrackingList = (items: CurrentTrackedItem[]) => {
      const newState: Partial<RedactEditorState> = {
        trackingList: [...items]
      };

      dispatch(newState);
    }

    const setProjectSetting = (settings: ProjectSettingsData) => {
      let newState: Partial<RedactEditorState> = {};
      newState = { ...newState, ...settings };
      updateHistory(newState);
      dispatch(newState);
    };

    const setCurrentObjectId = (objectId: string) => {
      const newState: Partial<RedactEditorState> = {};
      const target = stateRef.current.objectMap.get(objectId);
      if (target) {
        newState.focusedItem = {...target} as FocusedDetectedObject;
      } else {
        newState.focusedItem = undefined;
      }
      
      dispatch(newState);
    };

    const setAudioChannels = (channels: Array<AudioChannel>) => {
      const newState: Partial<RedactEditorState> = {
        channels,
        isModified: true
      }
      updateHistory(newState);
      dispatch(newState)
    }

    const setVideoPlayer = (videoPlayer: VideoJsPlayer, videoElement: HTMLVideoElement) => {
      const newState: Partial<RedactEditorState> = { videoPlayer, videoElement }
      dispatch(newState)
    }

    const setCanvas = (canvas: fabric.Canvas) => {
      const newState: Partial<RedactEditorState> = { canvas }
      dispatch(newState)
    }

    const setCurrentAudioSegment = (channelId: string, segmentId: string) => {
      const newState: Partial<RedactEditorState> = {
        focusedItem: {
          channelId,
          segmentId
        } as FocusedAudioSegment
      }
      dispatch(newState)
    }

    const setMultiFocusObjects = (objects: DetectedObject[]) => {

      const newState: Partial<RedactEditorState> = {};
      if (!objects || objects.length <= 0) {
        newState.focusedItem = undefined;
      } else {
        newState.focusedItem = { objects } as FocusedMultiObjects
      }

      newState.toggleCanvasRefresh = !stateRef.current.toggleCanvasRefresh;
      updateHistory(newState);
      dispatch(newState)
    }

    const setSelectedTranscription = (value: Transcription) => {
      const newState: Partial<RedactEditorState> = {};
      newState.selectedTranscription = value;
      dispatch(newState)
    }

    const setSidePanelSize = (value: number) => {
      const newState: Partial<RedactEditorState> = {};
      newState.sidePanelSize = value;
      dispatch(newState)
    }

    const resetState = () => {
      dispatch(initialState);
    }

    const resetModifiedStatus = () => {
      const newState: Partial<RedactEditorState> = {};
      newState.isModified = false;
      dispatch(newState);
    }

    const initialState: RedactEditorState = {...defaultEditorContextState,
      setFrames,
      setCanvas,
      setVideoPlayer,
      setProject,
      updateDetectedObjectList,
      updateTranscription,
      updateTrackingList,
      updateFrameVersions,
      setCurrentObjectId,
      setProjectSetting,
      setAudioChannels,
      setCurrentAudioSegment,
      setMultiFocusObjects,
      resetModifiedStatus,
      resetState, 
      setSidePanelSize,
      setSelectedTranscription,
      undoChanges
    }
    
    const [state, dispatch] = useReducer(RedactEditorReducer, initialState);
    const stateRef = useRef(state);
    useEffect(() => {
      stateRef.current = state
    }, [state])

    return (
      <RedactEditorContext.Provider value={state}>
        {children}
      </RedactEditorContext.Provider>
    );
  };

export default RedactEditorContextProvider
