import { DetectedObject } from "../../types/object"
import { fabric } from "fabric";
import { VideoJsPlayer } from "video.js";
import DETECTION_TYPE from "../../enums/detectionType";
import { crossMultiply } from "./coordinates";
import { DrawCoordinates } from "../../types/coordinates";
import { UserDefinedFrameObject } from "../../types/frame";

export const saveObject = (
    obj: DetectedObject,
    objects: Array<DetectedObject>): Array<DetectedObject> => {
        const targetObjectIndex = objects.findIndex(x => x.objectId === obj.objectId)
        /* if(targetObjectIndex) will not work when our findIndex will be 0 so that's why I removed that condition*/
        if (targetObjectIndex > -1) {
            const temp = [...objects]
            temp[targetObjectIndex] = obj
            return temp
        }
        return objects
}

export const getDistinctObjects = (list: DetectedObject[]): DetectedObject[] => {

    const distinct: DetectedObject[] = []

    list.forEach(x => {
        if (!x.duplicates || x.duplicates.length === 0) {
            distinct.push(x)
        } else {
            const targetId = parseInt(x.objectId)
            const parsedValues = x.duplicates.map(num => parseInt(num))
            parsedValues.push(targetId)
            const smallestId = Math.min(...parsedValues)

            if (smallestId === targetId) {
                distinct.push(x)
            }
        }
    })

    return distinct
}

export const getDistinctObjectId = (obj: DetectedObject): number => {

    const targetId = parseInt(obj.objectId)
    if (!obj.duplicates || obj.duplicates.length === 0) {
        return targetId
    }

    const duplicates: number[] = [...obj.duplicates] as Array<any>
    const smallestId = Math.min(...duplicates)
    return smallestId < targetId ? smallestId : targetId
}

  export const createUserDetectedObject = (
    objectId: number,
    sequence: number,
    currentTime: number,
    input: fabric.Rect,
    canvas: fabric.Canvas,
    videoPlayer: VideoJsPlayer
  ): DetectedObject => {
    const videoDuration = videoPlayer.duration();
    const sourceHeight = videoPlayer.videoHeight();
    const sourceWidth = videoPlayer.videoWidth();
  
    const startTime = currentTime;
    let endTime = currentTime; //parseFloat((currentTime + 1).toFixed(2));
    if (endTime > videoDuration) {
      endTime = startTime + (videoDuration - startTime);
    }
  
    const data: DetectedObject = {
      objectId: objectId.toString(),
      sequence: sequence,
      name: "",
      description: "",
      startTime,
      endTime,
      redact: true,
      detectionType: DETECTION_TYPE.MANUAL,
      color: input.fill as string
    };
  
    data.frames = generateFrames(
      data.objectId,
      crossMultiply(canvas.width, sourceWidth, input.left),
      crossMultiply(canvas.height, sourceHeight, input.top),
      crossMultiply(canvas.width, sourceWidth, input.width),
      crossMultiply(canvas.height, sourceHeight, input.height),
      0,
      startTime
    );
  
    return data;
  };
  
  export const generateFrames = (
    id: string,
    left: number,
    top: number,
    width: number,
    height: number,
    angle: number,
    startTime: number
  ) : UserDefinedFrameObject[] => {
    let frames:UserDefinedFrameObject[]  = [{
      classId: '0',
      height,
      startTimeSec: startTime,
      endTimeSec:  startTime,
      width,
      top,
      left,
      angle,
      objectId: id,
    }];
    return frames;
  };
  
  export const getVerboseText = (name, sequence) => {
    if (name && name.length > 0) {
      return `${name}`;
    }
  
    return `S/N: ${sequence}`;
  };

  export const drawDetected = (
    drawTime: number,
    coords: DrawCoordinates,
    targetObj: DetectedObject,
    canvas: fabric.Canvas
  ): fabric.Object[] => {
    const { width, height, top, left, sourceHeight, sourceWidth } = coords;
    
    const lockAdjustment = targetObj.trackingInfo && drawTime > targetObj.trackingInfo.startTimeSec;
    const color = lockAdjustment ? "#A9A9A9" : targetObj?.color ?? "#6C5ECF";
    const box = new fabric.Rect({
      left: crossMultiply(sourceWidth, canvas.width, left),
      top: crossMultiply(sourceHeight, canvas.height, top),
      width: crossMultiply(sourceWidth, canvas.width, width),
      height: crossMultiply(sourceHeight, canvas.height, height),
      strokeWidth: 3,
      stroke: color,
      opacity: 0.5,
      rx: 2,
      ry: 2,
      padding: 0,
      fill: targetObj?.redact ? color : "transparent",
      lockRotation: true,
      lockScalingFlip: true,
      lockMovementX: lockAdjustment,
      lockMovementY: lockAdjustment,
      lockScalingX: lockAdjustment,
      lockScalingY: lockAdjustment,
      lockSkewingX: lockAdjustment,
      lockSkewingY: lockAdjustment,
      
      data: { ...targetObj, drawTime },
    });
  
    box.toObject = (function (toObject) {
      return function (propertiesToInclude) {
        return fabric.util.object.extend(
          toObject.call(this, propertiesToInclude),
          {
            objectId: targetObj.objectId,
            detectionType: targetObj.detectionType
          }
        );
      };
    })(box.toObject);
  
  
    const text = new fabric.Text(
      getVerboseText(targetObj.name, targetObj.sequence),
      {
        fontFamily: "SF Pro Text",
        fontSize: 11,
        padding: 0,
        fontWeight: "bold",
        textAlign: "left",
        originX: "center",
        originY: "center",
        fill: color,
        stroke: "transparent",
        left: box.left + 10,
        top: box.top - 10,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingFlip: true,
        lockScalingX: true,
        lockScalingY: true,
        selectable: false,
        hasControls: false,
        data: { ...targetObj }
      }
    );
  
    canvas.getObjects().forEach((obj: fabric.Object) => {
      if (parseInt(obj?.data?.objectId ?? -1) === parseInt(targetObj.objectId)) {
        canvas.remove(obj);
      }
    });
  
    return [box, text];
  };

  export const getNewObjectIdAndSequence = (currentObjects: DetectedObject[], deletedObjectIds: string[]) : { objectId: number, sequence: number } => {
    const combined = (currentObjects.map(x => parseInt(x.objectId)) || []).concat(deletedObjectIds.map(x => parseInt(x)));
    const objectId = currentObjects.length <= 0 ? 0 : Math.max(...combined) + 1;
    const sequence = currentObjects.length <= 0 ? 0 : Math.max(...currentObjects.map(x => x.sequence) || []) + 1;

    return ({
      sequence,
      objectId,
    })
  }