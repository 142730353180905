import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useEffect, useState } from "react"
import { Prompt, useHistory } from "react-router-dom"
import PROJECT_STATUS from "../../enums/projectStatus"
import { ProjectStatus } from "../../types/project"
import LoadingSpinner from "../shared/LoadingScreen"

import './index.scss'
import { caseWithSpace } from "../../utils/redact-util"
export interface IRedactStatusWrapperProps {
    loadingMessages: string[]
    canLeave: boolean
    children: React.ReactNode
    status?: ProjectStatus
    redactAction?: () => void
    retryAction?: () => void
    deleteAction?: () => void
}

const RedactStatusWrapper = ({
    canLeave,
    loadingMessages,
    children,
    status,
    redactAction,
    retryAction,
    deleteAction
}: IRedactStatusWrapperProps) => {

    const history = useHistory();
    const [openModal, setOpenModal] = useState<boolean>();
    useEffect(() => {

        switch (status) {
            case PROJECT_STATUS.PENDING:
            case PROJECT_STATUS.REDACTION_QUEUED:
            case PROJECT_STATUS.REDACTION_FAILED:
            case PROJECT_STATUS.REDACTION_SUCCESS:
            case PROJECT_STATUS.FILE_CONVERSION_QUEUED:
            case PROJECT_STATUS.FILE_CONVERSION_FAILED:
            case PROJECT_STATUS.FILE_CONVERSION_COMPLETED:
            case PROJECT_STATUS.FILE_UPLOAD_FAILED:
            case PROJECT_STATUS.FILE_UPLOAD_QUEUED:
            case PROJECT_STATUS.UPLOAD_TO_REMOTE_FAILED:
            case PROJECT_STATUS.UPLOAD_TO_REMOTE_QUEUED:
                setOpenModal(true);
                break;
            default:
                setOpenModal(false);
                break;

        }
    }, [status]);

    const getActionDialog = () => {
        switch (status) {
            case PROJECT_STATUS.REDACTION_FAILED:
                return <>
                    <DialogTitle id="alert-dialog-title">
                        Redaction Failed
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The redaction process for this project has failed. this could be due to a number of reasons.
                            You can retry it or reach out to the Redact Studio support team.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => redactAction()}>Retry</Button>
                        <Button onClick={() => setOpenModal(false)} style={{ background: "#6c6c7f" }}>Close</Button>
                    </DialogActions>
                </>
            case PROJECT_STATUS.FILE_CONVERSION_FAILED:
            case PROJECT_STATUS.FILE_UPLOAD_FAILED:
            case PROJECT_STATUS.UPLOAD_TO_REMOTE_FAILED:
                return <>
                    <DialogTitle id="alert-dialog-title">
                    {caseWithSpace(status)}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This project cannot be edited because it failed to be processed by the system.
                            You can retry it or reach out to the Redact Studio support team if this continues to happen.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => retryAction()}>Retry</Button>
                        <Button onClick={() => deleteAction()} style={{ background: "#ff4040" }}>Delete</Button>
                        <Button onClick={() => setOpenModal(false)} style={{ background: "#6c6c7f" }}>Close</Button>
                    </DialogActions>
                </>
            case PROJECT_STATUS.PENDING:
            case PROJECT_STATUS.REDACTION_QUEUED:
            case PROJECT_STATUS.FILE_CONVERSION_QUEUED:
            case PROJECT_STATUS.FILE_UPLOAD_QUEUED:
            case PROJECT_STATUS.UPLOAD_TO_REMOTE_QUEUED:
                return <>
                    <DialogTitle id="alert-dialog-title">
                    {caseWithSpace(status)}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This project is waiting to be processed. It should be ready shortly.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => window.location.reload()}>Refresh</Button>
                        <Button onClick={() => history.push(`/projects`)} style={{ background: "#6c6c7f" }}>Back</Button>
                    </DialogActions>
                </>
            case PROJECT_STATUS.FILE_CONVERSION_COMPLETED:
                return <>
                    <DialogTitle id="alert-dialog-title">
                        File Conversion Complete
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Your project file has been standardized and will soon be uploaded to our servers for further processing.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => window.location.reload()}>Refresh</Button>
                        <Button onClick={() => history.push(`/projects`)} style={{ background: "#6c6c7f" }}>Back</Button>
                    </DialogActions>
                </>
            case PROJECT_STATUS.REDACTION_SUCCESS:
                return <>
                    <DialogTitle id="alert-dialog-title">
                        Redaction Complete
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Your project has been redacted and the result will soon be saved in a secure storage for your review.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => window.location.reload()}>Refresh</Button>
                        <Button onClick={() => history.push(`/projects`)} style={{ background: "#6c6c7f" }}>Back</Button>
                        <Button onClick={() => setOpenModal(false)} style={{ background: "#6c6c7f" }}>Close</Button>
                    </DialogActions>
                </>
            default:
                return <></>
        }
    }

    return (
        <>
            {(status && !loadingMessages) &&
                <Dialog
                    open={openModal}
                    className="detect-alert-dialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 5 }}
                >
                    {getActionDialog()}
                </Dialog>
            }

            {loadingMessages && (
                <LoadingSpinner messages={loadingMessages} />
            )}

            <Prompt when={!canLeave} message="Are you sure you want to leave the page, you will lose your changes?" />

            {children}
        </>
    )
}

export default RedactStatusWrapper