import { useEffect, useState } from "react"
import useApi from "../hooks/useApi";
import { getWorkspace } from "../services/api/workspaceApi";
import { IUserIdentity } from "../types/workspace";
import { AppState } from "../context/AppContext/types"

const useWorkspace = (reloadWorkspace: boolean): Partial<AppState> => {
    const { api, apiToken } = useApi();
    const [state, setState] = useState<Partial<AppState>>()
    useEffect(() => {
        if (!apiToken) return
        getWorkspace(api).then((data: IUserIdentity) => {
            if (data?.groups?.length) {
                let groupIndex = data.groups.findIndex(x => x.id === data.defaultGroup);
                if (groupIndex < 0) {
                    groupIndex = 0;
                }
                
                const newState: Partial<AppState> = {
                    workspace: data.groups[groupIndex].id,
                    isAcessWorkspace: data.groups[groupIndex].groupRole === 'Admin',
                    workspaceOption: data.groups,
                    workspaceName: data.groups[groupIndex].displayName,
                    isAcessAdmin: data.appRoles.includes("Admin"),
                    redact: data.redact,
                    transcribe: data.transcribe,
                    addProjectFromWebApp: data.addProjectFromWebApp,
                    enableMfa: data.enableMfa,
                    seatCount: data.seatCount,
                    integratedPlatform: data.integratedPlatform,
                    externalId: data.externalId,
                    externalName: data.externalName,
                    email: data.email
                }
                setState(newState)
            } else {
                const newState: Partial<AppState> = {
                    workspaceOption:[],
                    email: data.email,
                    isAcessAdmin: data.appRoles.includes("Admin")
                }
                setState(newState)
            }
        })
    }, [apiToken, reloadWorkspace])

    return state;
}

export default useWorkspace;