import { IApi } from "../../types/apiTypes";
import { ICreateRedactProjectFolder, IRedactProjectFolder } from "../../types/redactProjectFolder";

export const getFolderList = (api: IApi, workspaceId: string) => {
    const url = `folder/list/${workspaceId}`
    return api.get(url)
}

export const createFolder = (api: IApi,  payload: ICreateRedactProjectFolder) => {
    return api.post('folder/create', payload)
        .then((_) => {
            return true
        });
}

export const updateFolder = (api: IApi, payload: IRedactProjectFolder) => {
    return api.post(`folder/${payload.id}/update`, payload).then((_) => {
        return true;
    })
}

export const deleteFolder = (api: IApi, folderId: string, payload: { }) => {
    const url = `folder/${folderId}/delete`
    return api.post(url, payload)
}

export const moveProject = (api: IApi, payload: {}) => {
    const url = `folder/move`
    return api.post(url, payload)
}
