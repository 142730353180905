import _ from 'lodash';
import { RedactEditorState } from "../types";
import { DetectedObject } from '../../../types/object';
import { FocusedDetectedObject, FocusedMultiObjects } from '../../../types/focusedObjects';

export const handleUpdateObjectList = (state: RedactEditorState, updates: DetectedObject[], deletes: DetectedObject[]) => {
    const clone = _.cloneDeep(state.detectedObjects);
    const mapClone = new Map<string, DetectedObject>(state.objectMap);
    const deleteListClone = [...state.deletedObjects];

    //handle delete list first so updates can override changes
    for (let i = 0; i < deletes.length; i++) {
        const index = clone.findIndex(x => x.objectId === deletes[i].objectId);
        if (index > -1) {
            clone.splice(index, 1);
            mapClone.delete(deletes[i].objectId);
            const deleteIndex = deleteListClone.indexOf(deletes[i].objectId);
            if (deleteIndex < 0) {
              deleteListClone.push(deletes[i].objectId);
            }
        }
    }

    //handle updates second
    for (let i = 0; i < updates.length; i++) {

        const payload = { ...updates[i], modified: true };
        const index = clone.findIndex(x => x.objectId === payload.objectId);
        if (index >= 0) {
            clone[index] = payload;
            mapClone.set(payload.objectId, payload);
        } else {
          clone.push(payload);
          mapClone.set(payload.objectId, payload);

          const deleteIndex = deleteListClone.indexOf(payload.objectId);
          if (deleteIndex >= 0) {
            deleteListClone.splice(deleteIndex, 1);
          }
        }
    }

    let focusedItem: FocusedDetectedObject | FocusedMultiObjects | undefined;
    if (updates.length === 1) {
        focusedItem = clone.find(x => x.objectId === updates[0].objectId) as FocusedDetectedObject;
    } else if (updates.length > 1) {
        focusedItem = { objects: _.cloneDeep(updates) } as FocusedMultiObjects;
    }

    return {
        detectedObjects: clone,
        deletedObjects: deleteListClone,
        objectMap: mapClone,
        focusedItem
    }
}