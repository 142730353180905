import React from "react";
import {
    InputLabel,
    FormControl,
    Grid,
} from "@mui/material";
import { Dropdown } from "../../../components/Select/Select";
import { BootstrapInput } from "../../../components/InfoNameInput";
import "../../../pages/workspace/index.scss";
import _ from "lodash";

interface IProps {
    onChangeHandle: (name: string, value: any, isExistingEmail?: boolean) => void,
    formObject: IFormData,
    options: { value: string, label: string }[],
    isEdit: boolean,
    errors: any
}

interface IFormData {
    firstName: string;
    lastName: string;
    email: string;
    roleName: string;
    password?: string;
}

const UserModal: React.FC<IProps> = ({
    onChangeHandle,
    formObject,
    options,
    isEdit,
    errors = {}
}) => {

    return (
        <>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                    <InputLabel className="" shrink>
                        First Name
                    </InputLabel>
                    <BootstrapInput
                        placeholder="First Name"
                        className="project-input"
                        id="firstName"
                        name="firstName"
                        value={formObject.firstName}
                        onChange={(e) => onChangeHandle("firstName", e.target.value)}
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                    <InputLabel className="" shrink>
                        Last Name
                    </InputLabel>
                    <BootstrapInput
                        placeholder="Last Name"
                        className="project-input"
                        id="lastName"
                        name="lastName"
                        value={formObject.lastName}
                        onChange={(e) => onChangeHandle("lastName", e.target.value)}
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                    <InputLabel className="" shrink>
                        Email Address
                    </InputLabel>
                    <BootstrapInput
                        className="project-input"
                        id="email"
                        placeholder="Email Address"
                        value={formObject.email}
                        onChange={(e) => onChangeHandle("email", e.target.value)}
                        required
                        disabled={isEdit}
                    />
                    {errors?.email && <span className="error-msg">{errors.email}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <InputLabel shrink>Role</InputLabel>
                <Dropdown
                    options={options}
                    onChange={onChangeHandle}
                    placeHolder={"Select Role"}
                    selected={options?.find(
                        (item) => item.label === formObject.roleName
                    )}
                />
            </Grid>
        </>
    );
};

export default UserModal;
