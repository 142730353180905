import { HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions } from "@microsoft/signalr"
import { useEffect, useState } from "react"
import useApi from "../useApi"
import { AppConnection } from "../../types/notification"
import { appConfig } from "../../appConfig"

const useHubConnection = (): AppConnection => {
    const [hubConnection, setHubConnection] = useState<AppConnection>()
    const { apiToken } = useApi()

    useEffect(() => {
        if(appConfig.NotifyWithEvent) {
            connectToServer();
        }
    }, [apiToken])

    const connectToServer = () => {
        if (apiToken && (!hubConnection  || hubConnection.state !== HubConnectionState.Connected)) {

            const options: IHttpConnectionOptions = {
                accessTokenFactory: () => {
                    return apiToken
                }
            }

            // create a new connection on start
            const connection = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_API}/redactsocket`, options)
                .withAutomaticReconnect()
                .build()
    
            connection.start().then(result => {
                setHubConnection({
                    invoke: (command, params?) => connection?.invoke(command, params),
                    on: (methodName, handler) => {
                        connection?.on(methodName, handler)},
                    off: (methodName) => connection?.off(methodName),
                    connectionId: connection?.connectionId,
                    state: connection?.state
                })
            })
            .catch((err) => console.log('failed to establish websocket notification', err))

            connection.onclose((e) => {
                console.log('connection closed. will reconnect')
                connectToServer();
            });
        }
    }

    return hubConnection
}

export default useHubConnection