import { Fragment, useState, useContext } from "react";
import { Modal, Button, Tooltip, Divider } from "@mui/material";
import { Box } from "@mui/system";
import SaveIcon from "@mui/icons-material/Save";
import CategoryIcon from "@mui/icons-material/Category";
import PreviewIcon from "@mui/icons-material/Preview";
import SettingsIcon from "@mui/icons-material/Settings";
import { Download } from "@mui/icons-material";
import TranscriptionIcon from "@mui/icons-material/FormatAlignJustify";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import RedactPreview from "../RedactPreview";
import { IRedactProjectActions } from "../../hooks/useRedactProjectActions";
import EDITOR_TAB from "../../enums/editorTab";

import "./index.scss";
import useApi from "../../hooks/useApi";
import { IUserPreferencePayload } from "../../types/user";
import { saveUserPreferences } from "../../services/api/userApi";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { updateMatchingProperties } from "../../utils/object-util";

export interface RedactControlButtonProps {
  actions: IRedactProjectActions;
  setEditorTab: (tab: EDITOR_TAB) => void;
  onUpdate: (callBack: Function) => void;
}

const RedactControlButton = ({
  actions,
  setEditorTab,
  onUpdate,
}: RedactControlButtonProps) => {
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { isModified, previewUrl, transcribeUrl } = state;
  const [preview, setPreview] = useState<boolean>(false);
  const { api } = useApi();
  const { userPreferences, setUserPreferences } = useContext(AppContext);

  const buttonStyle = {
    margin: "4px 2px",
    backgroundColor: "#6c6c7f",
    padding: "6px 8px",
  };

  const handleSaveUserPreferences = (payload: IUserPreferencePayload) => {
    const updatedUserPreference = updateMatchingProperties(userPreferences, payload);
    setUserPreferences(updatedUserPreference);
    saveUserPreferences(api, payload);
  };

  return (
    <>
      {preview && (
        <Modal
          open={preview}
          onClose={() => setPreview(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="project-preview">
            <RedactPreview
              url={previewUrl}
              transcribeUrl={transcribeUrl}
              handleSaveUserPreferences={handleSaveUserPreferences}
            />
          </Box>
        </Modal>
      )}
      <div className="video-control">
        <div className="project-header-name">{state.name}</div>
        <Divider />
        <div className="control-button-wrap">
          <>
            <Tooltip title="Save/Apply Changes">
              <span>
                <Button
                  variant="contained"
                  disabled={!isModified}
                  style={{ ...buttonStyle }}
                  onClick={() => {
                    onUpdate(async () => {
                      const updateResult = await actions.update(state, true);
                      if (updateResult) {
                        state.resetModifiedStatus();
                      }
                    });
                  }}
                >
                  <SaveIcon />
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      backgroundColor: "#ff0000",
                      borderRadius: "50%",
                      position: "absolute",
                      top: -5,
                      left: 37,
                      display: isModified ? "block" : "none",
                    }}
                  />
                </Button>
              </span>
            </Tooltip>
          </>
          <Tooltip title="Preview">
            <Button
              onClick={() => setPreview(true)}
              variant="contained"
              style={{ ...buttonStyle }}
              disabled={!previewUrl || previewUrl.length == 0}
            >
              <PreviewIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download video">
            <Button
              variant="contained"
              onClick={() => actions.download()}
              style={{ ...buttonStyle }}
            >
              <Download />
            </Button>
          </Tooltip>
          <Tooltip title="Settings">
            <Button
              variant="contained"
              onClick={() => setEditorTab(EDITOR_TAB.SETTINGS)}
              style={{ ...buttonStyle }}
            >
              <SettingsIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Object List">
            <Button
              onClick={() => setEditorTab(EDITOR_TAB.OBJECT_LIST)}
              variant="contained"
              style={{ ...buttonStyle }}
            >
              <CategoryIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Transcription">
            <Button
              variant="contained"
              onClick={() => setEditorTab(EDITOR_TAB.TRANSCRIPTION)}
              style={{ ...buttonStyle }}
            >
              <TranscriptionIcon />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "0.95em",
          position: "sticky",
          zIndex: 1002,
          backgroundColor: "#1F1D2B",
        }}
      ></div>
    </>
  );
};

export default RedactControlButton;
