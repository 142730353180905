import React, { useState, useEffect,useCallback, useContext } from 'react';
import '../../../pages/admin/Admin.scss';
import {IconButton, Button, InputBase} from "@mui/material";
import {Search} from "@mui/icons-material";
import styled from "@emotion/styled";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete-icon.svg";
import DeleteModal from "../../DeleteModal";
import useApi from "../../../hooks/useApi";
import { getGroups, deleteWorkspace } from "../../../services/api/workspaceApi"
import { IGroup } from "../../../types/workspace";
import { ApiResponse } from "../../../types/apiResponse";
import LoadingSpinner from "../../shared/LoadingScreen/index";
import Table from "../../Table/Table";
import { tableWorkspaceHeaders } from '../TableHeader';
import _ from "lodash";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from "../../../context/AppContext";

const BootstrapInput = styled(InputBase)(({}) => ({}));
interface IProps {
    reloadWorkspaces?: number
}
const Workspace = ({ reloadWorkspaces }: IProps) => {
    const history = useHistory();
    const {reloadWorkspace, setReloadWorkspace} = useContext(AppContext); 
    const { api, apiToken } = useApi();
    const [groups, setGroups] = useState(null);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [reload, setReload] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchGroupsData()
      }, [apiToken, page, pageSize, reload, searchTerm, reloadWorkspaces]);

    const fetchGroupsData = () => {
        if (apiToken) {
          setIsLoading(true);
          getGroups(api, page + 1, pageSize, searchTerm)
            .then((result: ApiResponse<IGroup[]>) => {
                setGroups(result);
                setCount(result.totalCount);
                setIsLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsLoading(false);
            });
        }
      }

    const handleSearch = useCallback(
        _.debounce((val: any) => {
            setPage(0);
            setSearchTerm(val);
        }, 1000),
    [apiToken]);

    const getTableData = () => {
    return groups?.data?.length
        ? groups.data?.map((row: any, index: number) => {
            return {
            "s/n": page * pageSize + index + 1,
            id: row.id,
            displayName: row.displayName,
            status: (
                <div
                    className={
                        row?.status === "Active" ? "adminRoles" : "memberRoles"
                    }
                    >
                    {row?.status || "N/A"}
                </div>
            ),
            totalMembers: row.totalMembers,
            createdDateTime: new Date(row.createdDateTime).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric' }),
            action: (
                <div>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDeleteModal(true);
                      setEditData(row);
                    }}
                    aria-label="delete"
                    className="btn-delete btn"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ),
            };
        })
        : [];
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
        ) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAdd = () => {
        history.push('/admin/workspace/create')
    }

    const handleRowClick = (data) => {
        history.push(`/admin/workspace/create/${data.id}`)
    }

    const handleDelete = () => {
        setOpenDeleteModal(false);
        deleteWorkspace(api, editData.id)
          .then(() => {
            setReload(reload+1)
            toast('Workspace deleted successfully',{ type: 'success' });
            setReloadWorkspace(!reloadWorkspace);
          })
          .catch((e) => {
            console.error(e);
            toast('Something went wrong while delete workspace',{ type: 'error' });
          });
        setEditData(null);
    };

    return(
        <div className="user-wrapper">
            <div className="user-header">
                <div className="left-wrapper">
                    <div className="total-wrapper">
                        <span>{groups?.totalCount || 0} Workspace</span>
                    </div>
                    <div className="project-search-wrapper">
                        <div className="project-search-icn">
                            <IconButton aria-label="search" className="project-icon">
                                <Search />
                            </IconButton>
                        </div>
                        <BootstrapInput
                            className="project-search"
                            placeholder="Search"
                            onChange={(e) => {
                                handleSearch(e.target.value);
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        onClick={handleAdd}
                        variant="contained"
                        className="add-button btn btn-primary"
                    >
                        Add Workspace
                    </Button>
                </div>
            </div>
            <div className="table-wrapper">
                {isLoading ? (
                    <LoadingSpinner messages={["Loading..."]} />
                    ) : (
                    <Table
                        tableHeaders={tableWorkspaceHeaders}
                        rows={getTableData()}
                        page={page}
                        pageSize={pageSize}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        count={count}
                        handleRowClick={handleRowClick}
                    />
                )}
            </div>
            {
               openDeleteModal && 
               <DeleteModal
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onConfirm={handleDelete}
                    title="Delete User"
                    contentText={`Are you sure you want to delete ${editData?.displayName}?`}
                />
            }
        </div>
    )
}

export default Workspace;