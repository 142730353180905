import { useEffect, useState, } from "react"
import { InputLabel, FormControl, TextField, Button } from "@mui/material";
import "./authenticate.scss"
import EquatureRedactLogo from '../../../src/assets/images/Equature Redact Logo.svg'
import useApi from "../../hooks/useApi";
import { authenticate } from "../../services/api/authApi";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/shared/LoadingScreen/index";

interface RouteParams {
  projectId?: string;
  licenseKey?: string;
}

const LicenseKeyAuthentication = () => {
  const history = useHistory();
  const { api } = useApi();
  const [licenseKey, setLicenseKey] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const routeParams = useParams<RouteParams>();
  useEffect(() => {
    if (routeParams?.licenseKey) {
      setLicenseKey(routeParams?.licenseKey);
      authenticateLicense(routeParams?.licenseKey);
    }
  }, [routeParams?.projectId]);

  const handleAuthentication = async () => {
    authenticateLicense(licenseKey);
  };

  const authenticateLicense = async (key) => {
    setIsLoading(true);
    const token = await authenticate(api, key);
    if (token) {

      if (routeParams?.projectId) {
        history.push(`/editor/redact/${routeParams?.projectId}`);
      } else {
        history.push('/');
      }
      //TODO: temp fix. This is used to force page to redirect because no other route exists
      window.location.reload();
    } else {
      setIsLoading(false);
      toast('Invalid License Key!', { type: 'error' });
    }
  }

  return (
    <div className="authenticate" >
      {isLoading && <LoadingSpinner messages={['Authenticating License Key']} />}
      <ToastContainer />
      <img src={EquatureRedactLogo} alt="Equature Redact Logo" />
      <h2>Sign in with your license key</h2>
      <InputLabel>License Key</InputLabel>
      <FormControl fullWidth variant="outlined">
        <TextField
          variant="outlined"
          type="text"
          value={licenseKey}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLicenseKey(event.target.value)}
        />
      </FormControl>
      <Button onClick={handleAuthentication} className="signin">Sign in</Button>
    </div>)
}

export default LicenseKeyAuthentication
