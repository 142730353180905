import { useContext, useEffect, useState } from "react";
import useApi from "./useApi";
import { RedactEditorState } from "../context/RedactEditorContext/types";
import RedactEditorContext from "../context/RedactEditorContext";
import { getFrames, setVersion } from "../services/api/frameApi";
import { toast } from "react-toastify";
import { FrameSegment, FrameVersion } from "../types/frame";
import DbRepository from "../database/dbRepository";
import useFrameTime from "./useFrameTime";

//ensure worker is executed once per project load
//ensure database is initialized once per project load
//begin fetching frames in the background via a webworker
//check to see if frames exist for a particular time segment and fetch a new one if it doesn't
//undo a particular frame version by deleting its affected segments from the browser database so they can be refetched
const useFrames = () => {

    const { projectId, frameVersions, trackingSession, setFrames }= useContext<RedactEditorState>(RedactEditorContext);
    const { api, apiToken } = useApi();
    const [latestVersion, setLatestVersion] = useState<FrameVersion>();
    const [repository, setRepository] = useState<DbRepository>();
    const [currentSegment, setCurrentSegment] = useState<number>();

    const [worker, setWorker] = useState<Worker>();

    useEffect(() => {
        //create worker to fetch frame
        if (!worker) {
            setWorker(new Worker(process.env.REACT_APP_DOMAIN + '/workers/streamFrameWorker.js'));
        }

        return () => {

            if (worker) {
                worker.terminate();
            }
        }
    }, [])

    useEffect(() => {

        //fetch initial frame data
        if (apiToken && projectId && worker && !repository) {
            loadInitial();
        }

    }, [projectId, apiToken, worker])

    useEffect(() => {

        //catch undo functionality and handle frame version updates.
        //handle initial frame load (no latest version and version list empty)
        //handle undo frame (latest version exists but not in list)
        //handle frame update from tracking (no latest version or it exists and is in list)

        handleVersionUpdate();
    }, [frameVersions])

    useFrameTime(async (frameTime) => {

        if (repository) {
            let currentFrameTime = frameTime ?? 0;
            const frameDataSegment = Math.floor(currentFrameTime / 60);
            if (currentSegment !== frameDataSegment)  {
                setCurrentSegment(frameDataSegment);
                await loadSegment(frameDataSegment);
            }
        }
    })

    const handleVersionUpdate = async () => {
        if (frameVersions && repository) {
            let rollbackToVersion = latestVersion !== undefined && !frameVersions.some(v => v.version === latestVersion.version);
            let newLatestVersion = frameVersions.length > 0 ? frameVersions[frameVersions.length - 1] : undefined;
            const segmentsToClear = rollbackToVersion ? latestVersion : newLatestVersion;
            
            setLatestVersion(newLatestVersion);
            if (rollbackToVersion) {
                await setVersion(api, projectId, segmentsToClear.version, trackingSession);
            }
            
            if (segmentsToClear) {
                const startSegment = Math.floor(segmentsToClear.startTime / 60);
                const lastSegment = Math.floor(segmentsToClear.endTime / 60);
                
                for (let i = startSegment; i < lastSegment + 1; i++) {
                    //remove affected segments from storage so the latest versions can be refetched when needed
                    await repository.delete(i);
                }

                if (currentSegment >= startSegment && currentSegment <= lastSegment) {
                    //force hook to retrieve update the current frame set it has loaded into the context
                    await loadSegment(currentSegment, newLatestVersion?.version);
                }
            }

        }
    }

    const loadInitial = async () => {
        worker.onmessage = (message) => {
            if (message.data === 0) {
                //once initial segment is loaded from the worker, connect to the repo to start using frame data
                setRepository(new DbRepository('equatureStudioDB', 'frameDataStore', 'segment'));
            }
        }
        
        //load frames
        worker.postMessage({
            url: `${process.env.REACT_APP_API}/metadata/${projectId}/frame`,
            accessToken: apiToken
        });
    }

    const loadSegment = async (segment: number, version?: string) => {
        if (!repository) {
            return;
        }

        const cachedSegmentData: FrameSegment = await repository.getById(segment);
        if (cachedSegmentData) {
            setFrames(cachedSegmentData.frames);
            return;
        }

        const fetchNotificationId = toast('Updating Video Frames, Hang On!', { type: 'info', autoClose: false });
        const requestVersion = version ?? latestVersion?.version;
        const apiFrameData = await getFrames(api, projectId, segment, requestVersion, trackingSession);
        toast.dismiss(fetchNotificationId)
        toast('Project Frames Updated', { type: 'success', autoClose: 700 });
        if (apiFrameData) {
            await repository.update(apiFrameData);
            setFrames(apiFrameData.frames);
        }
    }

}

export default useFrames;
