import React, { MutableRefObject, useCallback, useContext, useEffect, useRef, useState, } from "react";
import { Region, WaveForm, WaveSurfer } from "wavesurfer-react";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline";
import RedactEditorContext from "../../../context/RedactEditorContext";
import { RedactEditorState } from "../../../context/RedactEditorContext/types";

import "./AudioRedaction.scss";
import { AudioChannel, AudioSegment } from "../../../types/audio";
import { LinearProgress } from "@mui/material";

type IProps = {
    channelData: AudioChannel,
    updateChannel: (channel: AudioChannel) => void,
    addRef: (ref: MutableRefObject<WaveSurfer>) => void
}

const AudioRedaction: React.FC<IProps> = ({ channelData, updateChannel, addRef }) => {
    const { audioPeakUrls, setCurrentAudioSegment, selectedTranscription } = useContext<RedactEditorState>(RedactEditorContext);
    const [ready, setReady] = useState(true);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);

    const [channel, setChannel] = useState<AudioChannel>(channelData);

    const wavesurferRef = useRef<WaveSurfer>();
    //important. this snippet handles a long standing wavesurfer bug which creates duplicate regions on drag
    const regionDuplicateRef = useRef<any>();

    const options = {
        id: "waveform-" + channel.id,
        waveColor: '#555555',
        progressColor: "#999999",
        cursorColor: "#9f44b4",
        cursorWidth: 3,
        height: 50,
        scrollParent: true,
        responsive: true,
        fillParent: true,
        backend: 'MediaElement',
        normalize: false,
        pixelRatio: 1,
        zoom: 100
    }

    const plugins = [
        {
            plugin: RegionsPlugin,
            options: { dragSelection: true, color: 'rgba(191, 191, 191, 0.5)' }
        },
        {
            plugin: TimelinePlugin,
            options: {
                container: "#timeline-" + channel.id,
                primaryFontColor: '#fff',
                secondaryFontColor: '#fff'
            }
        },
    ]

    useEffect(() => {
        window.addEventListener("focus", drawWave);
        return () => {
            window.removeEventListener("focus", drawWave);
        }
    }, [])

    const channelRef = useRef(channel)
    useEffect(() => {
        channelRef.current = channel;
    }, [channel])

    useEffect(() => {
        setChannel(channelData);
    }, [channelData])

    const handleError = useCallback((e) => {
        if (e) {
            console.error("[WaveSurfer Error]: ", e)
            setError(e)
        }
    }, [error])

    const setProgressCB = useCallback((p) => {
        setProgress(p)
    }, [progress])

    const regionCreatedHandler = region => {
        if (!channelRef.current || channelRef.current.segments.findIndex(x => x.id == region.id) > -1) {
            return
        }

        region.id = "redact-" + Math.random().toString(36).substring(2, 5)

        //important. this snippet handles a long standing wavesurfer bug which creates duplicate regions on drag
        regionDuplicateRef.current = region

        const clone = { ...channelRef.current }
        clone.segments = [
            ...clone.segments,
            {
                startTime: region.start,
                endTime: region.end,
                id: region.id,
                mute: true,
                blip: false
            }
        ]

        setChannel(clone);
    }

    // when a region is dragged
    const handleRegionUpdate = (region) => {
        //TODO: Fix Region Not Focused
        const clone = { ...channelRef.current }
        clone.segments = clone.segments.map(x => {
            if (x.id === region.id) {
                return ({
                    startTime: region.start,
                    endTime: region.end,
                    id: region.id,
                    mute: x.mute,
                    blip: x.blip
                });
            }

            return {...x}
        });

        updateChannel(clone)
        setCurrentAudioSegment(channelRef.current.id.toString(), region.id)
    }

    //TODO: Update this function to download and draw image only when the audio wave is visible
    const drawWave = () => {
        if (audioPeakUrls.length > 0) {
            const canvases = wavesurferRef.current.drawer.canvases;
            for (let i = 0; i < canvases.length; i++) {
                const img = new Image();
                img.addEventListener('load', () => {
                    canvases[i].waveCtx.drawImage(img, 0, 0, canvases[i].wave.width, 50);
                }, false);
                img.src = audioPeakUrls[i];
            }
        }
    }

    const handleWSMount = useCallback(
        waveSurfer => {
            wavesurferRef.current = waveSurfer;
            if (wavesurferRef.current && channel.url) {
                wavesurferRef.current.drawer.drawWave = function (peaks, channelIndex, start, end) {
                };

                wavesurferRef.current.load(channel.url, [1, -1, 1, -1, 1, -1]);
                wavesurferRef.current.on('error', handleError);
                wavesurferRef.current.on('loading', setProgressCB);
                wavesurferRef.current.on("region-created", regionCreatedHandler);
                wavesurferRef.current.on("region-update-end", handleRegionUpdate);
                wavesurferRef.current.on('region-click', (region: Region, e) => {
                    e.stopPropagation();
                    wavesurferRef.current.setMute(false)
                    setCurrentAudioSegment(channelRef.current.id.toString(), region.id)
                    region.play()
                });

                wavesurferRef.current.on("ready", () => {
                    wavesurferRef.current.zoom(100);
                    addRef(wavesurferRef)
                    drawWave();
                });

                if (window) {
                    (window as any).surferidze = wavesurferRef.current;
                }
            }
        },
        [regionCreatedHandler, channel]
    );

    const getColor = (segment: AudioSegment) => {

        if (segment.blip) {
            return 'rgba(108, 94, 207, 0.5)'
        }

        if (segment.mute) {
            return 'rgba(182, 47, 49, 0.5)'
        }

        return 'rgba(191, 191, 191, 0.5)'
    }

    const scrollableContainerRef = useRef<HTMLElement>();
    const containerRef = useRef<HTMLDivElement>();
    const getScrollContainer = () => {
        if (!containerRef.current) {
            return;
        }
        scrollableContainerRef.current = containerRef.current.querySelector('wave');
    }

    const scrollTo = (start: number) => {
        getScrollContainer();

        scrollableContainerRef.current.scroll(start*100 - scrollableContainerRef.current.clientWidth / 2, 0);
    }

    return (
        <div style={{ margin: '5px 0', position: 'relative' }} ref={containerRef}>
            {(!ready && !error) && (
                <div style={{ position: 'absolute', width: '100%', top: 0, height: 40, background: '#1F1D2B', zIndex: 3,  }}>
                    <LinearProgress variant="indeterminate" value={progress}/>
                    <p className="wavesurfer-hint">Loading Audio Waveforms...</p>
                </div>
            )}
            {!(audioPeakUrls.length) && (
                <div style={{marginTop: 20}}>This project has no audio source.</div>
            )}
            {audioPeakUrls.length &&
                <WaveSurfer
                    plugins={plugins} onMount={handleWSMount}>
                    <WaveForm {...options}>
                        {(channel?.segments || []).map(regionProps => {
                            return <Region
                                key={regionProps.id}
                                id={regionProps.id}
                                start={regionProps.startTime}
                                end={regionProps.endTime}
                                color={getColor(regionProps)}
                                data={{
                                    mute: false,
                                    blip: false
                                }}
                            />
                        })}
                    </WaveForm>
                    <div id={"timeline-" + channel.id} />
                </WaveSurfer>
            }
            {/* {(ready && !error) && (<p className="wavesurfer-hint">click and drag to add region</p>)} */}
        </div>
    );
}

export default AudioRedaction