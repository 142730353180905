import { useEffect, useState, useContext, useCallback, useRef } from "react";
import {
  Grid,
  Stack,
  Button,
  Modal,
  Box,
  IconButton,
  InputBase,
  Tooltip,
  FormControl,
} from "@mui/material";
import RedactProjectSummary from "../../components/RedactProjectSummary";
import { IRedactProjectSummary } from "../../types/redactProject";
import RedactPreview from "../../components/RedactPreview";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Pagination from "@mui/material/Pagination";
import "./projects.scss";
import useApi from "../../hooks/useApi";
import {
  getProjectList,
  deleteProject,
  getProjectStatuses,
} from "../../services/api/projectApi";
import LoadingSpinner from "../../components/shared/LoadingScreen/index";
import AppContext from "../../context/AppContext";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import Folders from "../../components/ProjectFolders";
import { moveProject } from "../../services/api/folderApi";
import useInterval from "../../hooks/useInterval";
import { IUserPreferencePayload } from "../../types/user";
import { saveUserPreferences } from "../../services/api/userApi";
import { updateMatchingProperties } from "../../utils/object-util";

const BootstrapInput = styled(InputBase)(({ theme }) => ({}));

interface IGetProjectResult {
  success: boolean;
  totalPages: number;
  data: Array<IRedactProjectSummary>;
}

const Projects = () => {
  const history = useHistory();

  const {
    workspace,
    workspaceOption,
    addProjectFromWebApp,
    userPreferences,
    setUserPreferences,
  } = useContext(AppContext);
  const [projects, setProjects] = useState<Array<IRedactProjectSummary>>([]);
  const [currentPreview, setCurrentPreview] = useState<string>();
  const [currentTranscribeUrl, setCurrentTranscribeUrl] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingMessages, setLoadingMessages] = useState<string[]>([
    "Loading Workspace..",
  ]);
  const query = new URLSearchParams(useLocation().search);
  const pageUrl = query.get("page");
  const foldersRef = useRef(null);
  const projectsRef = useRef(null);

  useEffect(() => {
    reloadFoldersSize();
  }, [foldersRef, projectsRef, projects]);

  useEffect(() => {
    const defaultResizeFn = window.onresize;
    window.onresize = () => {
      reloadFoldersSize();
    };

    return () => {
      window.onresize = defaultResizeFn;
    };
  }, []);

  const { apiToken, api } = useApi();
  useEffect(() => {
    if (pageUrl) {
      setPage(Number(pageUrl));
    }
  }, [pageUrl]);

  useEffect(() => {
    //render the proper message when the page has loaded and there's no workspace to be found
    if (workspaceOption && workspaceOption.length === 0) {
      setIsLoading(false);
    }
  }, [workspaceOption]);

  useEffect(() => {
    if (apiToken && workspace) {
      fetchProjectsList();
    }
  }, [page, apiToken, workspace, userPreferences, searchString]);

  useEffect(() => {
    setPage(1);
    handlePagination(null, 1);
  }, [workspace]);

  const handlePagination = (event, value) => {
    history.push(`/projects?page=${value}`);
  };

  const fetchProjectsList = () => {
    setIsLoading(true);
    setLoadingMessages(["Getting projects.."]);
    const currentFolderId = userPreferences.folderId;
    getProjectList(api, workspace, searchString, page, currentFolderId)
      .then((result: IGetProjectResult) => {
        if (result) {
          setProjects(result.data);
          setPageCount(result.totalPages);
          if (result.data.length === 0 && result.totalPages < page) {
            history.push(`/projects?page=${Math.max(1, result.totalPages)}`);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeProject = (projectId: string) => {
    setIsLoading(true);
    setLoadingMessages(["Deleting Project..."]);
    deleteProject(api, projectId).then(() => {
      fetchProjectsList();
      setIsLoading(false);
      toast("Project deleted successfully", { type: "success" });
    });
  };

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      setPage(1);
      setSearchString(val);
    }, 1000),
    [apiToken]
  );

  useInterval(async () => {
    if (projects && projects.length > 0) {
      const projectIds = projects.map((item) => {
        return item.projectId;
      });
      const updates = await getProjectStatuses(api, workspace, projectIds);
      if (updates) {
        const projectUpdates = projects.map((item) => {
          const match = updates.find((x) => x.projectId === item.projectId);
          const status = match ? match.status : item.status;
          return { ...item, status };
        });
        setProjects(projectUpdates);
      }
    }
  }, 60000);

  const showDefaultMessage = () => {
    if (workspaceOption) {
      if (workspaceOption.length === 0) {
        //user has no workspace assigned
        return (
          <p>
            You don't belong to any workspace, please contact support for
            further assistance.
          </p>
        );
      }

      if (!projects || projects.length === 0) {
        return <p>No Projects Found for this Workspace.</p>;
      }
    }

    //workspaces haven't loaded yet
    return <></>;
  };

  const handleMoveProject = (folderId, projectId) => {
    return moveProject(api, {
      folderId,
      projectId,
    }).then(() => {
      fetchProjectsList();
    });
  };

  const reloadFoldersSize = () => {
    if (foldersRef.current && projectsRef.current) {
      const element2Height = projectsRef.current.offsetHeight;
      const headerAndPaginationSize = 180;
      foldersRef.current.style.height =
        element2Height > 100
          ? `${element2Height + headerAndPaginationSize}px`
          : "100vh";
    }
  };

  const handleSaveUserPreferences = async (payload: IUserPreferencePayload) => {
    const updatedUserPreferences = updateMatchingProperties(
      userPreferences,
      payload
    );
    setUserPreferences(updatedUserPreferences);
    saveUserPreferences(api, payload);
  };

  return (
    <div className="folder-project-container">
      <div className="folder-list" ref={foldersRef}>
        <Folders />
      </div>
      <div className="app-page-inner">
        {isLoading && <LoadingSpinner messages={loadingMessages} />}

        {currentPreview && (
          <Modal
            open={currentPreview !== undefined}
            onClose={() => {
              setCurrentPreview(undefined);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="project-preview">
              <RedactPreview
                url={currentPreview}
                transcribeUrl={currentTranscribeUrl}
                handleSaveUserPreferences={handleSaveUserPreferences}
              />
            </Box>
          </Modal>
        )}

        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          <h2 className="page-title">Projects</h2>
          <div className="project-search-wrapper">
            <FormControl fullWidth>
              <div className="project-search-icn">
                <IconButton aria-label="search" className="project-icon">
                  <SearchIcon />
                </IconButton>
              </div>
              <BootstrapInput
                className="project-search"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)} //z
                inputProps={{ "aria-label": "search" }}
              />
            </FormControl>
          </div>

          <Button
            component={RouterLink}
            to={"/create"}
            disabled={!addProjectFromWebApp}
            variant="contained"
            className="project-button"
          >
            New Project
          </Button>
          <Tooltip title="New Project">
            <Button
              className="project-addIcon"
              disabled={!addProjectFromWebApp}
              onClick={() => history.push("/create")}
            >
              <AddCircleIcon />
            </Button>
          </Tooltip>
        </Stack>
        <br />
        <div className="project-container" ref={projectsRef}>
          <div className="project-list">
            <Grid className="product-grid">
              {showDefaultMessage()}
              {projects.map((p, i) => {
                return (
                  <RedactProjectSummary
                    project={p}
                    key={i}
                    showPreview={() => {
                      setCurrentPreview(p.previewUrl);
                      setCurrentTranscribeUrl(p.transcribeUrl);
                    }}
                    removeProject={removeProject}
                    moveProject={handleMoveProject}
                  />
                );
              })}
            </Grid>
          </div>
          {projects.length > 0 && (
            <div className="project-footer">
              <Pagination
                className="project-pagination"
                showFirstButton={true}
                showLastButton={true}
                count={pageCount}
                page={page}
                onChange={handlePagination}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
