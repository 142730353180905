import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { fabric } from "fabric";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import _ from "lodash";
import "./videoCanvas.scss";
import { VideoJsPlayer } from "video.js";
import { crossMultiply, greatestCommonDivisor } from "../../services/functions/coordinates";
import PROJECT_FEATURE_STATE from "../../enums/projectFeatureState";
import { AppState } from "../../context/AppContext/types";
import AppContext from "../../context/AppContext";

const VideoCanvas = () => {
  const { redact } = useContext<AppState>(AppContext);
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { videoPlayer } = state;
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const canvasRef = useRef<fabric.Canvas>();
  const videoPlayerRef = useRef<VideoJsPlayer>();
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    if (canvas) {
      canvasRef.current = canvas;
      state.setCanvas(canvas);
    }
  }, [canvas]);

  useEffect(() => {
    if (videoPlayer && redact && state.videoRedactionState === PROJECT_FEATURE_STATE.SUCCESS) {
      videoPlayerRef.current = videoPlayer;
      setCanvas(initCanvas());
    }
  }, [videoPlayer, redact, state.videoRedactionState]);

  const initCanvas = () => {

    const _fabric = new fabric.Canvas("canvas", {
      fireRightClick: true,
      stopContextMenu: true,
      height: videoPlayer.currentHeight(),
      width: videoPlayer.currentWidth(),
      backgroundColor: "transparent",
      preserveObjectStacking: true,
    });

    videoPlayer.on('loadeddata', (e) => {
      resizeCanvas(_fabric, videoPlayer)
    })

    return _fabric;
  };

  const resizeCanvas = (canvas: fabric.Canvas, player: VideoJsPlayer) => {
    const actualVideoHeight = player.videoHeight();
    const actualVideoWidth = player.videoWidth();

    let canvasWidth = player.currentWidth();
    let canvasHeight = player.currentHeight();

    if ((actualVideoHeight > actualVideoWidth) || (actualVideoHeight < actualVideoWidth)) {
      const fraction = actualVideoWidth / actualVideoHeight;
      const fractionLength = fraction.toString().length - 2;
  
      //get original video resolution to determine how to scale (example: 16:9 etc)
      let denominator = Math.pow(10, fractionLength);
      let numerator = fraction * denominator;
  
      const divisor = greatestCommonDivisor(numerator, denominator);
      numerator /= divisor;
      denominator /= divisor;

      const isSmallerResolution = (actualVideoHeight < canvasHeight && actualVideoWidth < canvasWidth);
      if (actualVideoHeight > actualVideoWidth || actualVideoHeight > canvasHeight || isSmallerResolution) {
        canvasWidth = crossMultiply(denominator, canvasHeight, numerator);
      } 
      if (actualVideoHeight < actualVideoWidth) {
        canvasHeight = crossMultiply(numerator, canvasWidth, denominator);
      }
  
      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
    }
  }

  return (
    <div onContextMenu={(e) => {e.preventDefault(); e.stopPropagation()}}>
      <canvas className="my-5" id="canvas" style={{ zIndex: 1 }} />
    </div>
  );
};

export default VideoCanvas;
