import { useContext, useEffect, useRef, useState } from "react";
import {
  List,
  ListItem,
  TextField,
  InputLabel,
  Switch,
  FormControlLabel,
  Button,
  FormControl,
  Stack,
  Tooltip,
  LinearProgress
} from "@mui/material";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { DetectedObject } from "../../types/object";
import { BootstrapInput } from "./../InfoNameInput";
import DeleteModal from '../../components/DeleteModal';
import useApi from "../../hooks/useApi";
import { splitObject } from "./helper";
import useFrameTime from "../../hooks/useFrameTime";

interface DetectedInfoProps {
  objectId: string
}

const DetectedInfo = ({ objectId }: DetectedInfoProps) => {

  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { objectMap, updateDetectedObjectList, setCurrentObjectId } = state;
  const [currentObject, setCurrentObject] = useState<DetectedObject>();
  const [autoSaveRunning, setAutoSave] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const { api } = useApi();
  const timeRef = useFrameTime();

  useEffect(() => {

    if (objectId && objectMap && objectMap.size > 0) {
      const target = objectMap.get(objectId)
      if (target) {
        setCurrentObject({ ...target })
      }
    }

  }, [objectId, objectMap])


  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (currentObject && autoSaveRunning) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setAutoSave(false);
        updateDetectedObjectList([currentObject], []);
      }, 500);
    }
  }, [currentObject])

  const toggleDialog = () => {
    setOpen(!open);
  }

  const handleDelete = () => {
    updateDetectedObjectList([], [currentObject])
    setCurrentObject(undefined);
    setCurrentObjectId(undefined);
  }

  const handleSplit = async () => {
    await splitObject(api, currentObject, timeRef.current, state);
  }

  const updateObjectValue = (key: string, value: any) => {
    setAutoSave(true)
    const clone = { ...currentObject, [key]: value }
    setCurrentObject(clone)
  }

  const getThumbnailImage = (obj: DetectedObject): string => {
    if (!obj || !obj.thumbnailUrl) {
      return "https://via.placeholder.com/150"
    }

    return obj.thumbnailUrl
  }

  return (
    <>
      {objectId && (
        <section className="project-detect-card">
          <h2 className="page-title">Object {currentObject?.sequence}</h2>
          <div className="preview-img">
            {currentObject?.thumbnailUrl &&
              <img src={getThumbnailImage(currentObject)} />
            }
          </div>
          <List dense disablePadding>
            <ListItem>
              <FormControl fullWidth variant="standard" className="form-wrapper">
                <InputLabel className="project-label" shrink htmlFor="framename">
                  Name
                </InputLabel>
                <BootstrapInput placeholder="Enter object name" className="project-input" id="framename" value={currentObject?.name || ''} onChange={(e => updateObjectValue('name', e.target.value))} />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth variant="standard">
                <InputLabel className="project-label" shrink htmlFor="description">
                  Description
                </InputLabel>
                <TextField variant="outlined" className='project-description' placeholder="Describe Detected Object" id="description" multiline rows={2}
                  value={currentObject?.description || ''} onChange={(e => updateObjectValue('description', e.target.value))} />
              </FormControl>
            </ListItem>
            <ListItem>
              <div className="project-detect-card-footer">
                <Stack direction={"row"} spacing={2}>
                  <Tooltip title="Toggle Redaction">
                    <FormControlLabel
                      control={<Switch checked={(currentObject?.redact || false)} onChange={(e => updateObjectValue('redact', e.target.checked))} />}
                      label="Redact"
                    />
                  </Tooltip>
                  <Tooltip title="Split this object at the current time">
                    <Button
                      onClick={handleSplit}
                      variant="contained"
                      style={{ backgroundColor: autoSaveRunning ? '#dddddd' : '#6c5ecf' }}
                      disabled={autoSaveRunning}
                    >
                      Split
                    </Button>
                  </Tooltip>
                  <Tooltip title="Delete the object">
                    <Button
                      onClick={() => setOpen(true)}
                      variant="contained"
                      style={{ backgroundColor: autoSaveRunning ? '#dddddd' : '#ff4040' }}
                      disabled={autoSaveRunning}
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </Stack>
                {
                  (currentObject?.trackingInfo) && <>
                    <br />
                    <Stack direction='row' spacing={2}>
                      <Stack justifyContent={"space-between"}>
                        <p style={{ margin: 0, fontSize: 12 }}>Tracking</p>
                        <LinearProgress color="primary" />
                      </Stack>
                      <Tooltip title="Cancel the current tracking operation for this object.">
                        <Button
                          onClick={() => updateObjectValue('trackingInfo', undefined)}
                          variant="contained"
                          style={{ backgroundColor: '#6C5ECF' }}
                        >
                          Cancel
                        </Button>
                      </Tooltip>
                    </Stack>
                  </>
                }
              </div>
            </ListItem>
          </List>

        </section>
      )}
      <DeleteModal
        open={open}
        onClose={toggleDialog}
        onConfirm={handleDelete}
        title='Are you sure?'
        contentText='Are you sure you want to delete the selected object from the video?'
      />
    </>
  );
};

export default DetectedInfo
