import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
      // mode: "dark",
      primary: { 500: '#467fcf' }
    },
    typography: {
      fontFamily: ["SF Pro Text", "sans-serif"].join(","),
      fontSize: 15
    }
  });


  export default theme