import { HubConnectionState } from "@microsoft/signalr";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AppContext from "../context/AppContext";
import { AppState } from "../context/AppContext/types";
import { RedactEditorState } from "../context/RedactEditorContext/types";
import NOTIFICATION_TYPE from "../enums/notificationType";
import PROJECT_STATUS from "../enums/projectStatus";
import UPDATE_PROJECT_RESPONSE_STATUS from "../enums/updateProjectResponseStatus";
import {
  createProject,
  downloadProject,
  getProjectStatus,
  updateProject,
  saveThumbnail,
  redactProject,
  deleteProject,
  retryCreateProject,
} from "../services/api/projectApi";
import {
  ProjectStatusUpdate,
  RedactProgressNotification,
} from "../types/notification";
import { ProjectStatus } from "../types/project";
import { caseWithSpace } from "../utils/redact-util";

import useApi from "./useApi";

export interface IRedactProjectActions {
  projectId: string;
  canLeave: boolean;
  loadingMessages: string[];
  fetchProjectTrigger: number;
  status?: ProjectStatus;
  create: (project: FormData) => void;
  update: (
    editorState: RedactEditorState,
    applyRedaction: boolean
  ) => Promise<boolean>;
  redact: () => void;
  remove: () => void;
  download: () => void;
  retryCreate: () => void;
  saveThumbnailImage: (formData: FormData, projectId: string) => void;
}

interface RouteParams {
  projectId?: string;
}

const useRedactProjectActions = (): IRedactProjectActions => {
  const routeParams = useParams<RouteParams>();
  const history = useHistory();

  const { api, apiToken } = useApi();
  const { hubConnection, notification } = useContext<AppState>(AppContext);

  const [canLeave, setCanLeave] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<string>();
  const [loadingMessages, setLoadingMessages] = useState<string[]>();
  const [status, setStatus] = useState<ProjectStatus>();
  const [fetchProjectTrigger, setFetchProjectTrigger] = useState<number>(0);

  useEffect(() => {
    if (routeParams?.projectId) {
      setProjectId(routeParams?.projectId);
    }
  }, [routeParams?.projectId]);

  useEffect(() => {
    switch (notification?.type) {
      case NOTIFICATION_TYPE.PROGRESS:
        notifyProgress(notification.data as RedactProgressNotification);
        break;
      case NOTIFICATION_TYPE.STATUS_UPDATE:
        notifyStatusUpdate(notification.data as ProjectStatusUpdate);
        break;
      default:
        break;
    }
  }, [notification]);

  useEffect(() => {
    if (
      hubConnection?.state === HubConnectionState.Connected &&
      projectId &&
      apiToken
    ) {
      subscribe(projectId);
      fetchProject();
    }
  }, [projectId, apiToken, hubConnection?.state, hubConnection?.connectionId]);

  const fetchProject = () => {
    getProjectStatus(api, projectId).then((projectStatus: ProjectStatus) => {
      setStatus(projectStatus);
      switch (projectStatus) {
        case PROJECT_STATUS.FILE_CONVERSION_IN_PROGRESS:
        case PROJECT_STATUS.REDACTION_STARTED:
        case PROJECT_STATUS.FILE_UPLOAD_IN_PROGRESS:
        case PROJECT_STATUS.REDACTION_QUEUED:
        case PROJECT_STATUS.FILE_CONVERSION_QUEUED:
        case PROJECT_STATUS.UPLOAD_TO_REMOTE_IN_PROGRESS:
        case PROJECT_STATUS.UPLOAD_TO_REMOTE_QUEUED:
          setLoadingMessages([
            caseWithSpace(projectStatus),
            `You can leave the page to work on other projects.`,
          ]);
          break;
        default:
          break;
      }
    });
  };

  const toggleFetchProject = () => {
    setFetchProjectTrigger(fetchProjectTrigger + 1);
    setLoadingMessages(undefined);
  };

  const handleRedactionSuccess = (projectId: string) => {
    toast("Redaction Successfully Completed!", { type: "success" });
    toggleFetchProject();
  };

  const handleRedactionFailed = (projectId: string) => {
    toast("Redaction Failed!", { type: "error" });
    toggleFetchProject();
  };

  const notifyStatusUpdate = (update: ProjectStatusUpdate) => {
    if (projectId !== update.projectId) {
      return;
    }

    if (status !== update.status) {
      setStatus(update.status);
    }

    switch (update.status) {
      case PROJECT_STATUS.REDACTION_SUCCESS:
        handleRedactionSuccess(projectId);
        break;
      case PROJECT_STATUS.REDACTION_FAILED:
        handleRedactionFailed(projectId);
        break;
      case PROJECT_STATUS.FILE_CONVERSION_IN_PROGRESS:
      case PROJECT_STATUS.REDACTION_STARTED:
      case PROJECT_STATUS.FILE_UPLOAD_IN_PROGRESS:
      case PROJECT_STATUS.UPLOAD_TO_REMOTE_IN_PROGRESS:
        toast(caseWithSpace(update.status), { type: "info" });
        setLoadingMessages([caseWithSpace(update.status)]);
        break;
      case PROJECT_STATUS.FILE_UPLOAD_COMPLETED:
      case PROJECT_STATUS.UPLOAD_TO_REMOTE_SUCCESS:
        toast(caseWithSpace(update.status), { type: "success" });
        window.location.reload();
        break;
      case PROJECT_STATUS.FILE_CONVERSION_COMPLETED:
        toast(caseWithSpace(update.status), { type: "success" });
        toggleFetchProject();
        break;
      case PROJECT_STATUS.UPLOAD_TO_REMOTE_FAILED:
      case PROJECT_STATUS.FILE_UPLOAD_FAILED:
      case PROJECT_STATUS.FILE_CONVERSION_FAILED:
        toast(caseWithSpace(update.status), { type: "error" });
        setLoadingMessages(undefined);
        break;
      default:
        break;
    }
  };

  const notifyProgress = (progress: RedactProgressNotification) => {
    if (progress.projectId !== projectId || !progress.status) {
      return;
    }

    const queued = [
      PROJECT_STATUS.REDACTION_QUEUED,
      PROJECT_STATUS.FILE_CONVERSION_QUEUED,
      PROJECT_STATUS.UPLOAD_TO_REMOTE_QUEUED,
      PROJECT_STATUS.FILE_UPLOAD_QUEUED
    ];

    const inProgress = [
      PROJECT_STATUS.REDACTION_STARTED,
      PROJECT_STATUS.FILE_CONVERSION_IN_PROGRESS,
      PROJECT_STATUS.UPLOAD_TO_REMOTE_IN_PROGRESS,
      PROJECT_STATUS.FILE_UPLOAD_IN_PROGRESS
    ];

    const combinedStatus = [...queued, ...inProgress];
    if (combinedStatus.indexOf(progress.status) < 0 || combinedStatus.indexOf(status) < 0) {
      return;
    }

    if (inProgress.indexOf(progress.status) >= 0) {
      const progressPercentage =
        progress.percentComplete > 0
          ? ` (${Math.floor(progress.percentComplete)}%).`
          : "";
      setLoadingMessages([`Stage: ${progress.stage}${progressPercentage}`]);
    } else {
      setLoadingMessages([
        "Project has been placed in a Processing Queue.",
        "You can leave the page to work on other projects.",
      ]);
    }
  };

  const update = async (
    editorState: RedactEditorState,
    applyRedaction: boolean
  ): Promise<boolean> => {
    await subscribe(editorState.projectId);
    setLoadingMessages(["Updating project. Do not close the page..."]);
    setCanLeave(false);

    const response = await updateProject(api, applyRedaction, editorState);
    setCanLeave(true);
    if (!response) {
      setLoadingMessages(undefined);
      toast("Failed to complete update. Please reload the page and try again", {
        type: "error",
      });
      return false;
    }

    if (response.status === UPDATE_PROJECT_RESPONSE_STATUS.SAVE_ONLY) {
      toggleFetchProject();
    } else {
      setLoadingMessages(["Generating preview. You can leave the page."]);
    }
    return true;
  };

  const download = async () => {
    setLoadingMessages(["Downloading Project..."]);
    await downloadProject(api, projectId, projectId);
    toast("Download Successful!", { type: "success" });
    setLoadingMessages(undefined);
  };

  const create = (formData: FormData) => {
    setLoadingMessages(["Creating project. Do not leave the page."]);
    setCanLeave(false);
    createProject(api, formData).then(async (projectId: string) => {
      if (!projectId) {
        toast("Failed to create project. Please try again", {
          type: "error",
          autoClose: false,
        });
        setLoadingMessages(undefined);
        return;
      }

      setCanLeave(true);
      await subscribe(projectId);
      setProjectId(projectId);
    });
  };

  const retryCreate = async () => {
    if (projectId) {
      setCanLeave(false);
      setLoadingMessages(["Retrying project. Do not leave the page."]);
      await retryCreateProject(api, projectId);
      setLoadingMessages(["Retrying project. You can leave the page."]);
      setCanLeave(true);
    } else {
      toast("Unable to redact this project. Please try again", {
        type: "error",
      });
    }
  };

  const redact = async () => {
    if (projectId) {
      setCanLeave(false);
      setLoadingMessages(["Redacting project. Do not leave the page."]);
      await redactProject(api, projectId);
      setLoadingMessages(["Redacting project. You can leave the page."]);
      setCanLeave(true);
    } else {
      toast("Unable to redact this project. Please try again", {
        type: "error",
      });
    }
  };

  const remove = async () => {
    setLoadingMessages(["Deleting project. Do not leave the page."]);
    await deleteProject(api, projectId);
    history.push("/projects");
  };

  const saveThumbnailImage = (formData: FormData, projectId: string) => {
    saveThumbnail(api, formData, projectId);
  };

  const subscribe = async (projectId: string) => {
    if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
      await hubConnection.invoke("SubscribeToProject", projectId);
    }
  };

  return {
    canLeave,
    projectId,
    loadingMessages,
    fetchProjectTrigger,
    status,
    create,
    update,
    redact,
    download,
    remove,
    retryCreate,
    saveThumbnailImage,
  };
};

export default useRedactProjectActions;
