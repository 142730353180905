import { coord } from "../../types/coordinates"

export const greatestCommonDivisor = (a: number, b: number) => {
    if (b < 0.0000001) return a

    return greatestCommonDivisor(b, Math.floor(a % b))
}

export const crossMultiply = (source: number, current: number, value: number): number => {
    return (current * value) / source
}

export const interpolate = (start: coord, target: coord, step: number): coord => {
    const x = start.x + (target.x - start.x) * step
    const y = start.y + (target.y - start.y) * step
    return { x, y }
}

export const distance = (start: coord, target: coord): number => {

    const a = start.x - target.x
    const b = start.y - target.y

    const distance = Math.sqrt((a * a) + (b * b))
    return distance
}

export const clamp = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))