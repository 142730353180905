import React, { Fragment, SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  InputLabel,
  InputBase,
  styled,
  Switch,
  FormControlLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import TrashIcon from '@mui/icons-material/Delete';
import DeleteModal from '../DeleteModal';
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { AudioSegment } from "../../types/audio";
import { removeAudioSegment, saveAudioSegment } from "../../services/functions/audioChannel";
import { FocusedAudioSegment } from "../../types/focusedObjects";
import { DetectedObject } from "../../types/object";
import DetectedObjectCard from "../detectedObjectCard";
import { Stack } from "@mui/system";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: "#808191",
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 3,
    position: "relative",
    border: "1px solid rgba(207, 207, 207, 0.05)",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderColor: "#6C5ECF",
    },
  },
}));


const AudioSegmentDetails = () => {
  const { focusedItem, channels, setAudioChannels, detectedObjects, setCurrentObjectId, videoPlayer } = useContext<RedactEditorState>(RedactEditorContext)
  const [currentSegment, setCurrentSegment] = useState<AudioSegment>()
  const [currentSegmentDetectedObjects, setCurrentSegmentDetectedObjects] = useState<DetectedObject[]>()
  const [autoSaveRunning, setAutoSave] = useState<boolean>(false)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!currentSegment || !currentSegment.startTime || !currentSegment.endTime) setCurrentSegmentDetectedObjects([]);
    else setCurrentSegmentDetectedObjects(detectedObjects.filter(d => d.startTime >= currentSegment.startTime && d.endTime < currentSegment.endTime))
  }, [detectedObjects, currentSegment])

  useEffect(() => {
    if (focusedItem) {
      const focused = focusedItem as FocusedAudioSegment
      const segment = (channels.filter(x => x.id === focused.channelId)[0] || {}).segments.filter(x => x.id === focused.segmentId)[0];
      setCurrentSegment({...segment})
    }
  }, [focusedItem, channels])

  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (currentSegment && autoSaveRunning) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        const focused = focusedItem as FocusedAudioSegment
        setAudioChannels(saveAudioSegment(focused.channelId, currentSegment, channels));
        setAutoSave(false)
      }, 500);
    }
  }, [currentSegment])

  const toggleMuteTone = (key: string, value: boolean) => {

    setAutoSave(true);
    let updateValue = { ...currentSegment, [key]: value };
    if (key === 'blip' && value) {
      updateValue = { ...updateValue, 'mute': false };
    } else if (key === 'mute' && value) {
      updateValue = { ...updateValue, 'blip': false };
    }

    setCurrentSegment(updateValue);
  }

  const updateObjectValue = (key: string, value: any) => {
    setAutoSave(true)
    const clone = { ...currentSegment, [key]: value }
    setCurrentSegment(clone)
  }

  const roundToTwo = (num: any) => {
    //@ts-ignore
    return +(Math.round(num + "e+2") + "e-2");
  }

  const deleteRegion = () => {
    const focused = focusedItem as FocusedAudioSegment
    setAudioChannels(removeAudioSegment(focused.channelId, currentSegment.id, channels))
    setCurrentSegment(undefined)
  }

  const toggleDialog = () => {
    setOpen(!open);
  }

  const handleObjectClick = (obj: DetectedObject) => () => {
      setCurrentObjectId(obj.objectId);
      if(videoPlayer) {
          videoPlayer.currentTime(obj.startTime);
      }
  }

  return (
    <Fragment>
      {currentSegment && (
        <section className="project-detect-card">
          <h2 className="page-title">Audio Property</h2>
          <List dense disablePadding>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  primary='Region ID'
                  secondary={currentSegment.id || 'N/A'}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  primary='Start'
                  secondary={roundToTwo(currentSegment.startTime) || 'N/A'}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  primary='End'
                  secondary={roundToTwo(currentSegment.endTime) || 'N/A'}
                />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <FormControl fullWidth variant="standard" className="form-wrapper">
                <InputLabel className="project-label" shrink htmlFor="framename">
                  Name
                </InputLabel>
                <BootstrapInput placeholder="Enter redact label" className="project-input" id="framename" value={currentSegment.name || ''} onChange={(e => updateObjectValue('name', e.target.value))} />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch checked={currentSegment.blip} onChange={(e => {
                  toggleMuteTone('blip', e.target.checked);
                })} />}
                label="Tone"
              />
              <FormControlLabel
                control={<Switch checked={currentSegment.mute} onChange={(e => {
                  toggleMuteTone('mute', e.target.checked);
                })} />}
                label="Mute"
              />
              <IconButton
                onClick={toggleDialog} color="error" size="small">
                <TrashIcon />
              </IconButton>
            </ListItem>
          </List>
          <h2 className="page-title">Detected Objects</h2>

          <div className="detected-wrap" >
            {currentSegmentDetectedObjects?.length > 0 && (
              <Stack direction="row" justifyContent="center" flexWrap="wrap" spacing={2}>
                {currentSegmentDetectedObjects.map(obj =>
                  <div style={{ width: 200, margin: 10 }} onClick={handleObjectClick(obj)} key={obj.objectId}>
                    <DetectedObjectCard key={obj.objectId} {...obj} />
                  </div>
                )}
              </Stack>
            )}
          </div>
          <DeleteModal
            open={open}
            onClose={toggleDialog}
            onConfirm={deleteRegion}
            title='Are you sure?'
            contentText='Are you sure you want to delete the region?'
          />
        </section>
      )}
    </Fragment>
  );
};

export default AudioSegmentDetails
