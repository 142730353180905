import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import { DetectedObject } from "../../types/object";
import DetectedObjectCard from "../detectedObjectCard";
import { FocusedDetectedObject, FocusedMultiObjects } from "../../types/focusedObjects";

interface IProps {
    scrollToItemIndex?: number
}

const DetectedObjectList = ({ scrollToItemIndex }: IProps) => {
    const state = useContext<RedactEditorState>(RedactEditorContext);
    const { focusedItem, setCurrentObjectId, detectedObjects, videoPlayer } = state;
    const [triggerRefresh, setTriggerRefresh] = useState<number>(0);
    const itemWidth = 200;
    const itemMargin = 10;

    const containerRef = useRef<HTMLDivElement>();
    const scrollableContainerRef = useRef<HTMLElement>();

    useEffect(() => {
        const defaultResizeFn = window.onresize;
        window.onresize = () => {
            setTriggerRefresh(Math.random());
        }
        
        return () => {
            window.onresize = defaultResizeFn;
        }
    }, []);

    useEffect(() => {
        setTimeout(() => scrollToInitial(), 10);
    }, [scrollToItemIndex])

    useEffect(() => {
        if (containerRef.current) {
            setTriggerRefresh(triggerRefresh + 1);
        }
    }, [containerRef.current]);

    const scrollToInitial = () => {
        if (!scrollToItemIndex) {
            return;
        }

        const { listHeight } = virtualizeList();
        const totalItemHeight = 175 + (itemMargin * 2);//actual estimated item height 
        const totalRows = listHeight/totalItemHeight;
        const itemPerRowCount = detectedObjects.length / totalRows;
        const rowNumber = Math.ceil((scrollToItemIndex + 1) / itemPerRowCount);
        scrollableContainerRef.current.scrollTo(0, Math.max(0, (rowNumber * totalItemHeight) - totalItemHeight)); 
    }

    const getScrollContainer = () => {
        if (!containerRef.current) {
            return;
        }

        let parent: HTMLElement = containerRef.current.parentElement;
        for (let i = 0; i < 5; i++) {
            if (parent) {
                if (parent.className == "editor-sidebar") {
                    parent.onscroll = () => {
                        setTriggerRefresh(Math.random());
                    };

                    scrollableContainerRef.current = parent;
                }
                else {
                    parent = parent.parentElement;
                }
            }
        }
    }

    const virtualizeList = (): { top: number, listHeight: number, list: DetectedObject[]} => {
        // console.log('called virtualize', containerRef.current)
        if (!containerRef.current) {
            return { top: 0, listHeight: 0, list: []};
        }

        //use a loop to find the first parent that has a fixed height
        if (!scrollableContainerRef.current) {
            getScrollContainer();
        }
        const scrollableContainer = scrollableContainerRef.current || containerRef.current;
        // console.log('container parent', scrollableContainer);

        const totalItemWidth = itemWidth + (itemMargin * 2); //actual item width plus margin (left and right)
        const totalItemHeight = 175 + (itemMargin * 2);//actual estimated item height 
        const container = containerRef.current;
        const itemPerRowCount = Math.floor(container.offsetWidth / totalItemWidth);
        const totalRows = Math.ceil(detectedObjects.length/itemPerRowCount);
        
        const visibleHeight = scrollableContainer.offsetHeight - container.offsetTop;
        const maxVisibleItems = Math.min((Math.ceil(visibleHeight / totalItemHeight) * itemPerRowCount), detectedObjects.length);
        //use math.floor to prevent items from being hidden prematurely
        const hiddenItemCount = Math.floor(scrollableContainer.scrollTop/totalItemHeight) * itemPerRowCount;
        
        const startIndex = Math.min(Math.max(0, hiddenItemCount), detectedObjects.length); 
        const endIndex = Math.min(Math.max(0, hiddenItemCount + maxVisibleItems), detectedObjects.length);
        
        return { top: (hiddenItemCount/itemPerRowCount) * totalItemHeight, listHeight: (totalItemHeight * totalRows), list: detectedObjects.slice(startIndex, endIndex) };
    }

    const handleObjectClick = (obj: DetectedObject) => () => {
        setCurrentObjectId(obj.objectId);
        if(videoPlayer) {
            videoPlayer.currentTime(obj.startTime);
        }
    }

    const getActiveClass = (obj: DetectedObject): string => {
        if (!focusedItem) {
            return ''
        }

        const focused = focusedItem as FocusedDetectedObject
        if (!focused.objectId) {
            return ''
        }

        return (focused.objectId == obj.objectId) ? "detected-block-active" : '';
    }


    const { top, listHeight, list } = useMemo(() => virtualizeList(), [triggerRefresh]);
    return (
        <div className="detected-wrap" style={{ height: listHeight, position: "relative" }} ref={containerRef}>
            {list?.length > 0 && (
                <Stack direction="row" justifyContent="center" flexWrap="wrap" spacing={2} position="absolute" top={top}>
                    {list.map(obj => 
                        <div style={{ width: itemWidth, margin: itemMargin }} onClick={handleObjectClick(obj)}
                            className={getActiveClass(obj)} key={obj.objectId}>
                            <DetectedObjectCard key={obj.objectId} {...obj} />
                        </div>
                    )}
                </Stack>
            )}
        </div>
    )
}

export default DetectedObjectList
