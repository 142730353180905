import { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardContent, CardMedia, Typography, IconButton, Stack, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, CircularProgress } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/FolderOpenOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LoopIcon from '@mui/icons-material/Loop';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { CloudUpload, CloudDone, MoveUp, PlayCircleFilled } from "@mui/icons-material";
import { IRedactProjectSummary } from "../../types/redactProject";
import PROJECT_STATUS from "../../enums/projectStatus";
import DeleteModal from "../DeleteModal";
import "./RedactProjectSummary.scss"
import { caseWithSpace } from "../../utils/redact-util";

import StudioDefaultThumbnail from '../../../src/assets/images/StudioDefaultThumbnail.png';
import AppContext from "../../context/AppContext";
import { Select, MenuItem, FormControl } from '@mui/material';

interface IRedactProjectSummaryProps {
  project: IRedactProjectSummary
  showPreview: (string) => void
  removeProject: (projectId: string) => void,
  moveProject: (folderId: string, projectId: string) => Promise<any>,
}

const RedactProjectSummary = (props: IRedactProjectSummaryProps) => {
  const { folders } = useContext(AppContext);
  const { project, showPreview, removeProject } = props
  const [moveFolderId, setMoveFolderId] = useState<string>(project.folderId)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false)
  const [openModal, toggleModal] = useState<boolean>(false)

  useEffect(() => {
    setMoveFolderId(project.folderId ?? 'null')
  }, [project]);

  const getThumbnail = (url?: string) => {
    if (!url) {
      return StudioDefaultThumbnail;
    }

    return url
  }

  const getDescription = (description?: string) => {
    if (!description) {
      return 'N/A'
    }

    if (description.length > 30) {
      return description.substring(0, 27) + '...'
    }

    return description
  }

  const handleDeleteProject = (projectId: string) => {
    removeProject(projectId)
    toggleModal(false)
  }

  const handleMoveProject = () => {
    setIsLoading(true);
    props.moveProject(moveFolderId, project.projectId)
      .then(() => {
        setIsLoading(false);
        setIsMoveModalOpen(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsMoveModalOpen(false);
      });
  }

  const handleFolderIdChange = (value) => {
    const folderId = value === 'null' ? null : value;
    setMoveFolderId(folderId);
  }

  const getStatusIcon = () => {
    switch (project.status) {
      case PROJECT_STATUS.REDACTION_QUEUED:
        return <Tooltip title={caseWithSpace(project.status)}><AutoAwesomeMotionIcon /></Tooltip>
      case PROJECT_STATUS.REDACTION_STARTED:
        return <Tooltip title={caseWithSpace(project.status)}><LoopIcon style={{ color: 'yellow' }} /></Tooltip>;
      case PROJECT_STATUS.REDACTION_SUCCESS:
        return <Tooltip title={caseWithSpace(project.status)}><DoneIcon style={{ color: 'green' }} /></Tooltip>;
      case PROJECT_STATUS.REDACTION_FAILED:
        return <Tooltip title={caseWithSpace(project.status)}><CloseIcon style={{ color: 'red' }} /></Tooltip>;
      case PROJECT_STATUS.FILE_UPLOAD_QUEUED:
      case PROJECT_STATUS.UPLOAD_TO_REMOTE_QUEUED:
            return <Tooltip title={caseWithSpace(project.status)}><CloudUpload /></Tooltip>;
      case PROJECT_STATUS.FILE_UPLOAD_IN_PROGRESS:
      case PROJECT_STATUS.UPLOAD_TO_REMOTE_IN_PROGRESS:
        return <Tooltip title={caseWithSpace(project.status)}><CloudUpload style={{ color: 'yellow' }} /></Tooltip>;
      case PROJECT_STATUS.FILE_UPLOAD_COMPLETED:
      case PROJECT_STATUS.UPLOAD_TO_REMOTE_SUCCESS:
        return <Tooltip title={caseWithSpace(project.status)}><CloudDone style={{ color: 'green' }} /></Tooltip>;
      case PROJECT_STATUS.FILE_UPLOAD_FAILED:
      case PROJECT_STATUS.UPLOAD_TO_REMOTE_FAILED:
        return <Tooltip title={caseWithSpace(project.status)}><CloseIcon style={{ color: 'red' }} /></Tooltip>;
      case PROJECT_STATUS.FILE_CONVERSION_IN_PROGRESS:
        return <Tooltip title={caseWithSpace(project.status)}><MoveUp style={{ color: 'yellow' }} /></Tooltip>;
      case PROJECT_STATUS.FILE_CONVERSION_COMPLETED:
        return <Tooltip title={caseWithSpace(project.status)}><MoveUp style={{ color: 'green' }} /></Tooltip>;
      case PROJECT_STATUS.FILE_CONVERSION_FAILED:
        return <Tooltip title={caseWithSpace(project.status)}><CloseIcon style={{ color: 'red' }} /></Tooltip>;
      case PROJECT_STATUS.PENDING:
      default:
        return <Tooltip title={PROJECT_STATUS.PENDING}><FiberManualRecordIcon /></Tooltip>;
    }
  }

  return (
    <>
      <Dialog open={isMoveModalOpen} onClose={() => setIsMoveModalOpen(false)} className="folder folder-dialog move-folder-dialog">
        <DialogTitle
          id="dialog-title"
          className="dialog-title"
        >
          Move Project
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="select-folder">
              <span>Move <span className='project-name'>{project.name}</span> to:</span>
              <FormControl fullWidth>
                <Select
                  className="project-select-folder"
                  value={moveFolderId}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleFolderIdChange(value === 'null' ? null : value);
                  }}
                >
                  {
                    folders?.map((option) => {
                      const value = option.id === null ? 'null' : option.id;
                      return (<MenuItem key={value} value={value}>{option.name}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleMoveProject} className='finish-button'>
            {isLoading && (<CircularProgress size={18} color="inherit" style={{ marginRight: 8 }} />)}
            Move
          </Button>
          <Button disabled={isLoading} onClick={() => setIsMoveModalOpen(false)} className='cancel-button'>
            {isLoading && (<CircularProgress size={18} color="inherit" style={{ marginRight: 8 }} />)}
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteModal
        open={openModal}
        onClose={() => toggleModal(false)}
        onConfirm={() => handleDeleteProject(project.projectId)}
        title={"Delete project?"}
        contentText={"This project & all redactions will be deleted from the system, proceed?"}
      />
      <Card className="project-card">
        <div className="project-img">
          <CardMedia
            component="img"
            image={getThumbnail(project.thumbnailUrl)}
            alt="project thumbnail"
          />
          {(project.previewUrl?.length > 0) &&
            <IconButton title="Play Video"
              className="project-play"
              style={{ position: "absolute", left: "25%", right: "25%", top: "25%", bottom: "25%" }}
              onClick={() => showPreview(project.projectId)}>
              <PlayCircleFilled style={{ color: 'white', fontSize: '70px' }} />
            </IconButton>
          }
        </div>
        <CardContent className="project-content">
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography gutterBottom variant="h4" component="p" className='project-name'>
              {project.name}
            </Typography>
            <div className='project-status'>
              <p>Status:</p> {getStatusIcon()}
            </div>
          </Stack>
          <Stack direction='row' justifyContent={'space-between'}>
            <Stack direction='column' className='project-details'>
              <Stack direction='row'>
                <Typography component="label" className='project-updated'>
                  Updated:
                </Typography>
                <Typography component="p" className='project-updated'>
                  {new Date(project.updatedDate).toLocaleDateString()}
                </Typography>
              </Stack>
              <Typography component="p" className='project-detect'>
                {project.updatedBy ?? 'N/A'}
              </Typography>
            </Stack>
            <Stack direction='column' className='project-details'>
              <Stack direction='row'>
                <Typography component="label" className='project-updated'>
                  Created:
                </Typography>
                <Typography component="p" className='project-updated'>
                  {new Date(project.createdDate).toLocaleDateString()}
                </Typography>
              </Stack>
              <Typography component="p" className='project-detect'>
                {project.createdBy ?? 'N/A'}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent="space-between" spacing={2}>
            <Stack>
              <Typography component="p" className='project-detect'>
                <RemoveRedEyeIcon className='project-eyeicn' /> <span>Detected: {project.autoDetectedObjectCount}</span>
              </Typography>
              <Typography component="p" className='project-detect'>
                <DescriptionIcon className='project-eyeicn' />
                <span>Description: {getDescription(props.project.description)}  </span>
              </Typography>
            </Stack>
            <Stack spacing={1} direction='row'>
              <Tooltip title="Move">
                <IconButton aria-label="move" className='project-editpreicn' onClick={() => setIsMoveModalOpen(true)}>
                  <FolderIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton aria-label="edit" className='project-editpreicn' component={RouterLink} to={`/editor/redact/${project.projectId}`}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => toggleModal(true)} aria-label="delete" className='project-deleteicn'>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

export default RedactProjectSummary
