import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { AppState } from "../../context/AppContext/types";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import EDITOR_TAB from "../../enums/editorTab";
import PROJECT_FEATURE_STATE from "../../enums/projectFeatureState";
import { IRedactProjectActions } from "../../hooks/useRedactProjectActions";
import { FocusedDetectedObject, FocusedMultiObjects } from "../../types/focusedObjects";
import DetectedObjectList from "../DetectedObjectList";
import TranscriptionFeatureLoader from "../ProjectFeatureLoaders/TranscriptionFeatureLoader";
import ProjectSettings from "../ProjectSettings";
import RedactPropertyTab from "../RedactPropertyTab";
import RedactTranscriptionTab from "../RedactTranscriptionTab";

interface IProps {
    activeTab: EDITOR_TAB | undefined
    projectActions: IRedactProjectActions
    setActiveEditorTab: (EDITOR_TAB) => void,
}

const RedactEditorTabs: React.FC<IProps> = ({ activeTab, projectActions, setActiveEditorTab, }: IProps) => {
    const { transcribe } = useContext<AppState>(AppContext)
    const state = useContext<RedactEditorState>(RedactEditorContext);
    const [focusedItemIndex, setFocusedItemIndex] = useState<number>(0);
    useEffect(() => {
        if (state.focusedItem) {
            const currentFocus = (state.focusedItem as FocusedDetectedObject);
            if (currentFocus.objectId) {
                setFocusedItemIndex(state.detectedObjects.findIndex(x => x.objectId === currentFocus.objectId));
            } else {
                const batchItems = (state.focusedItem as FocusedMultiObjects);
                if (batchItems && batchItems.objects && batchItems.objects.length > 0) {
                    const lastItemSelected = batchItems.objects[batchItems.objects.length - 1];
                    setFocusedItemIndex(state.detectedObjects.findIndex(x => x.objectId === lastItemSelected.objectId));
                }
            } 

        }

    }, [state.focusedItem]);

    const renderActiveTab = () => {
        switch (activeTab) {
            case EDITOR_TAB.SETTINGS:
                return <ProjectSettings 
                    onCancel={() => setActiveEditorTab(undefined)}
                    handleSettingSave={projectActions.update}
                    handleSaveThumbnail={projectActions.saveThumbnailImage}
                />
            case EDITOR_TAB.OBJECT_LIST:
                return <DetectedObjectList scrollToItemIndex={focusedItemIndex} />
            case EDITOR_TAB.OBJECT_PROPERTY:
                return <RedactPropertyTab />
            case EDITOR_TAB.TRANSCRIPTION:
                switch (transcribe) {
                    case undefined: 
                        return <p>Fetching Workspace Transcription Status</p>
                    case false:
                        return <p>Transcription feature is not enabled for this workspace, please contact administrator.</p>
                    case true:
                        return state.transcriptionState === PROJECT_FEATURE_STATE.SUCCESS ? <RedactTranscriptionTab /> : <TranscriptionFeatureLoader />
                }
            default: return <></>
        }
    }

    return renderActiveTab();
}

export default RedactEditorTabs;