import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  InputLabel,
  Box,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Button,
  Alert,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import RedactEditorContext from "../../context/RedactEditorContext";
import { RedactEditorState } from "../../context/RedactEditorContext/types";
import EditIcon from "@mui/icons-material/Edit";
import { BootstrapInput } from "../InfoNameInput";
import VideoThumbnailSelector from "../VideoThumbnailSelector";
import { VideoThumbnail } from "../VideoThumbnailSelector/types";

import "./index.scss";
import { getExtensionFromMimeType } from "../../services/functions/fileType";
import VIDEO_REDACTION_TYPE from "../../enums/videoRedactionType";
import PROJECT_FEATURE_STATE from "../../enums/projectFeatureState";
import { AppState } from "../../context/AppContext/types";
import AppContext from "../../context/AppContext";

interface IProps {
  onCancel: () => void;
  handleSettingSave: (editorState: RedactEditorState, applyRedaction: boolean) => Promise<boolean>;
  handleSaveThumbnail: (formData: FormData, projectId: string) => void;
}

const ProjectSettings: React.FC<IProps> = ({
  onCancel,
  handleSettingSave,
  handleSaveThumbnail
}) => {
  const state = useContext<RedactEditorState>(RedactEditorContext);
  const { projectId, thumbnailUrl, setProjectSetting } = state;

  const [formObject, setData] = useState(state);
  const [editProjectName, setEditProjectName] = useState<boolean>();
  const [editDescription, setEditDescription] = useState<boolean>();
  const [isDetectionChange, setIsDetectionChange] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [showThumbnails, toggleShowThumbnails] = useState<boolean>(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState<VideoThumbnail>();
  const { redact } = useContext<AppState>(AppContext);


  useEffect(() => {
    if (state.name && !formObject.name) {
      setData(state);
    }
  }, [state.name]);

  const onChangeSetting = (name: string, value: any, isDetection?: boolean) => {
    if (isDetection && !isDetectionChange) {
      setIsDetectionChange(true);
    }
    setData({ ...formObject, [name]: value });
    setError(false);
  };

  const handleSubmit = async () => {
    const {
      name,
      description,
      detectFace,
      detectLicensePlate,
      detectWeapon,
      confidence,
      transcribeUrl,
      videoRedactionType,
    } = formObject;
    if (selectedThumbnail) {
      const formData = new FormData();
      formData.append(
        "VideoThumbnail",
        selectedThumbnail?.blob,
        "thumbnail." + getExtensionFromMimeType(selectedThumbnail?.blob.type)
      );
      handleSaveThumbnail(formData, projectId);
    }
    if (formObject.name.trim().length > 0) {
      const updateData = { ...state, name, description, videoRedactionType, detectFace, confidence };
      const updateResult = await handleSettingSave(updateData, (state.videoRedactionType !== videoRedactionType));
      setProjectSetting({
        name,
        description,
        videoRedactionType,
        detectFace,
        detectLicensePlate,
        detectWeapon,
        confidence,
        thumbnailUrl: selectedThumbnail?.url || thumbnailUrl,
        isModified: updateResult,
        transcribeUrl        
      });
      setIsDetectionChange(false);
    }

    closeEditMode();
  };

  const closeEditMode = (isCancel?: boolean) => {
    setEditDescription(false);
    setEditProjectName(false);
    onCancel();
    setError(false);
    setSelectedThumbnail(null);
    if (isCancel) {
      setData(state);
    }
  };

  const videoRedactionEnabled = useMemo(() => {
    return formObject.videoRedactionState !== PROJECT_FEATURE_STATE.DISABLED;
  }, [formObject.videoRedactionState])

  const { name, description, videoRedactionType } = formObject;
  return (
    <Box className="settings-container" style={{ padding: 15, background: '#252836' }}>
      <h2 className="title">Settings</h2>
      {error && (
        <Alert severity="error" className="error-message">
          Please fill all mandatory(*) fields!
        </Alert>
      )}
      <div className="section">
        <div style={{ marginBottom: 15 }}>
          {!editProjectName ? (
            <Stack direction="row" justifyContent="space-between">
              <div>
                <InputLabel
                  className="project-label"
                  shrink
                  htmlFor="project"
                >
                  Project Name
                </InputLabel>
                <span className="project-value">
                  {name ?? "There is no Project name"}
                </span>
              </div>
              <IconButton style={{ color: "#808191" }} onClick={() => setEditProjectName(true)}>
                <EditIcon />
              </IconButton>
            </Stack>
          ) : (
            <FormControl fullWidth variant="standard">
              <InputLabel className="project-label" shrink htmlFor="project">
                Project Name<span style={{ color: "red" }}> *</span>
              </InputLabel>
              <BootstrapInput
                className="project-name"
                id="name"
                placeholder="Enter project name"
                value={name}
                onChange={(e) => onChangeSetting("name", e.target.value)}
                required
              />
            </FormControl>
          )}
        </div>
        <div style={{ marginBottom: 15 }}>
          {!editDescription ? (
            <Stack direction="row" justifyContent="space-between">
              <p>
                <InputLabel
                  className="project-label"
                  shrink
                  htmlFor="description"
                >
                  Project Description
                </InputLabel>
                <span className="project-value">
                  {description ? description : "There is no description"}
                </span>
              </p>
              <IconButton style={{ color: "#808191" }} onClick={() => setEditDescription(true)} >
                <EditIcon />
              </IconButton>
            </Stack>
          ) : (
            <FormControl fullWidth variant="standard">
              <InputLabel
                className="project-label"
                shrink
                htmlFor="description"
              >
                Project Description
              </InputLabel>
              <TextField
                className="project-description"
                id="description"
                multiline
                rows={2}
                placeholder="Enter project description"
                value={description}
                onChange={(e) =>
                  onChangeSetting("description", e.target.value)
                }
                maxRows={4}
                style={{ margin: 0 }}
              />
            </FormControl>
          )}
        </div>
      </div>

      <div className="edit-project-thumb">
        <>
          <div className="thumbnail-div">
            {thumbnailUrl && (
              <img src={thumbnailUrl} className="thumbnail-img" />
            )}
            <div className="thumbnail-column-div">
              <a
                className="thumbnail-link"
                onClick={() => toggleShowThumbnails(true)}
              >
                Replace thumbnail
              </a>
            </div>
          </div>
          {showThumbnails && (
            <div>
              <VideoThumbnailSelector
                setSelectedThumbnail={setSelectedThumbnail}
                isEdit={true}
              />
            </div>
          )}
        </>
      </div>

      <Tooltip title={!videoRedactionEnabled ? "Enable Video Redaction to change" : "" }>
        <div className="form-item project-form-right">
          <InputLabel
            id="video-redaction-type-label"
            className="project-label"
            shrink
            disabled={!videoRedactionEnabled}
            style={{ marginBottom: 0, top: 12 }}
          >
            Video Redaction Type
          </InputLabel>
          <FormControl
            className="project-checkbox">
            <RadioGroup
              aria-labelledby="video-redaction-type-label"
              defaultValue={VIDEO_REDACTION_TYPE.BLUR}
              name="videoRedactionType"
              value={videoRedactionType}
              className="radio-buttons"
              onChange={(e) =>
                onChangeSetting("videoRedactionType", e.target.value)
              }
            >
              <FormControlLabel value={VIDEO_REDACTION_TYPE.BLUR} control={<Radio disabled={!videoRedactionEnabled || !redact} className="chk-box-color" />} label="Blur" />
              <FormControlLabel value={VIDEO_REDACTION_TYPE.PIXELATED} control={<Radio disabled={!videoRedactionEnabled || !redact} className="chk-box-color" />} label="Pixelated" />
            </RadioGroup>
          </FormControl>
        </div>
      </Tooltip>
      <Stack spacing={2} direction="row">
        <Tooltip title="Save/Apply Changes">
          <Button
            autoFocus
            className="action-button save"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Tooltip>
        <Tooltip title="Cancel Changes">
          <Button
            className="action-button cancel"
            onClick={() => closeEditMode(true)}
          >
            Cancel
          </Button>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default ProjectSettings;
