import { sortBy } from "lodash";
import { Speaker, TranscribeJsonResponse, Transcription, TranscriptionStatus, } from "../../types/transcription";
import { Duration } from "../../types/transcription";

export const convertToMilliseconds = (timeString: string) => {
  const regEx = new RegExp('_', "g");
  let time = timeString.replace(regEx, '0').split(/[:.]/);
  let hours = parseInt(time[0]);
  let minutes = parseInt(time[1]);
  let seconds = parseInt(time[2]);
  let totalMilliseconds = ((hours * 60 * 60) + (minutes * 60) + seconds) * 1000;
  return totalMilliseconds;
}

export const convertToTimeString = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padToDigits(hours)}:${padToDigits(minutes)}:${padToDigits(seconds)}`;
}

export const getDuration = (timeString: string): Duration => {
  const regEx = new RegExp('_', "g");
  let time = timeString.replace(regEx, '0').split(/[:.]/);
  let hours = parseInt(time[0]);
  let minutes = parseInt(time[1]);
  let seconds = parseInt(time[2]);

  return {
    hours,
    minutes,
    seconds,
  };
}

export const getTimeString = (duration: Duration) => {
  return `${padToDigits(duration?.hours ?? 0)}:${padToDigits(duration?.minutes ?? 0)}:${padToDigits(duration?.seconds ?? 0)}`;
}

const speakersColors = ["#FEDFCD", "#A0A9F2", "#E6FEED", "#F9C1B9"];

const padToDigits = (num, dig = 2) => {
  return num.toString().padStart(dig, '0');
}
export const getSpeakerColor = (id: number) => {
  if (id % 8 === 0) {
    return speakersColors[2];
  } else if (id % 7 === 0) {
    return speakersColors[3];
  } else if (id % 6 === 0) {
    return speakersColors[0];
  } else if (id % 5 === 0) {
    return speakersColors[1];
  } else if (id % 4 === 0) {
    return speakersColors[3];
  } else if (id % 3 === 0) {
    return speakersColors[2];
  } else if (id % 2 === 0) {
    return speakersColors[1];
  }

  return speakersColors[0];
}

export const getSpeakerAbbreviation = (name: string) => {
  if (!name)
    return '';

  name = capitalizeFirstLetter(name);
  let words = name.split(' ');
  if (words.length == 1) {
    return words[0].slice(0, 2);
  } else if (words.length == 2) {
    return words[0].slice(0, 2) + "." + words[1].slice(0, 1);
  } else {
    return words.filter((w, i) => i < 4).map((n) => n[0]).join("");
  }
}

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return '';

  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const getTranscriptions = (
  transcribeUrl: string,
  setSpeakers: React.Dispatch<React.SetStateAction<{ [id: number]: Speaker; }>>,
  setTranscriptions: React.Dispatch<React.SetStateAction<Transcription[]>>
) => {
  fetch(transcribeUrl)
    .then((response) => response.json())
    .then((response: TranscribeJsonResponse) => {
      let speakers = {};
      response.Speakers.forEach((speaker, si) => {
        speakers[speaker.Id] = {
          id: speaker.Id,
          abbreviation: speaker.Abbreviation,
          name: speaker.Name,
          color: getSpeakerColor(si),
          rightAligned: si % 2 !== 0
        }
      });

      setSpeakers(speakers);

      let formattedTranscription: Transcription[] = [];
      response.Transcriptions.forEach((t) => {
        let transcript = {
          id: t.Id,
          start: t.Start,
          end: t.End,
          sentence: t.Sentence,
          speakerId: t.SpeakerId,
          status: getStatus(t.Status),
          original: null
        };
        transcript.original = JSON.parse(JSON.stringify(transcript));
        formattedTranscription.push(transcript);
      });
      formattedTranscription = sortBy(formattedTranscription, 'start');

      setTranscriptions(formattedTranscription);
      
    });
}

const getStatus = (status) => {
  return Object.values(TranscriptionStatus)[status];
}

export const formatTranscriptList = (transcripts: Transcription[]) => {
  let formattedTranscription = sortBy(transcripts, ['start']);
  let previousStart = Number.MAX_SAFE_INTEGER;
  for (let i = formattedTranscription.length - 1; i >= 0; i--) {
    formattedTranscription[i].end = previousStart - 1;
    if (i > 0 && formattedTranscription[i].start !== formattedTranscription[i - 1].start) {
      previousStart = formattedTranscription[i].start;
    }
  }

  return formattedTranscription;
}