import { Button, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { Dialog, DialogTitle } from "@mui/material";
import "./index.scss";

interface IConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    contentText: string;
};

const ConfirmationModal = ({
    open,
    onClose,
    onConfirm,
    title,
    contentText
}: IConfirmationModalProps) => {

    const handleSubmit = () => {
        onClose();
        onConfirm();
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                className="confirm-dialog"
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    className="confirm-dialog-title"
                >
                    {title}
                </DialogTitle>
                <DialogContent
                    className="confirm-dialog-title-content"
                >
                    <DialogContentText
                        className="confirm-dialog-title-notes">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={handleSubmit}
                        className='confirm-button'
                    >
                        Agree
                    </Button>
                    <Button
                        onClick={handleClose}
                        className="cancel-button"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default ConfirmationModal;