import { Redirect, Route, Switch } from "react-router-dom";

import Projects from "./pages/projects";
import RedactEditor from "./pages/editor";
import CreateProject from "./pages/createProject";
import Workspace from "./pages/workspace";
import Admin from "./pages/admin";
import AddEditWorkspace from "./pages/admin/AddEditWorkspace";

import RedactEditorContextProvider from "./context/RedactEditorContext/Provider";
import AppContextProvider from "./context/AppContext/Provider";

import { Box } from "@mui/material";
import Navbar from "./layouts/navbar";

import "./App.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LicenseKeyAuthentication from "./pages/licensekeyauth";

interface AppRoutesProps {
}

function AppRoutes({}: AppRoutesProps) {
  return (
    <AppContextProvider>
      <Box className="app-layout">
        <div className="app-page">
          <Navbar />
          <div className="app-main">
            <ToastContainer />
            <Switch>
              <Route path="/" exact component={Projects} />
              <Route path="/projects" component={Projects} />
              
              <Route path="/workspace" component={Workspace} />
              <Route exact path="/admin/workspace/create" component={AddEditWorkspace} />
              <Route exact path="/admin/workspace/create/:id" component={AddEditWorkspace} />
              <Route exact path="/admin" component={Admin} />
              
              <Route exact path="/create" component={CreateProject} />
              <Route path="/editor/redact/:projectId" component={() => 
                <RedactEditorContextProvider>
                  <RedactEditor />
                </RedactEditorContextProvider>
              } />

              <Route path="/license/:licenseKey" exact component={LicenseKeyAuthentication} />
              <Route path="/license/:licenseKey/project/:projectId" exact component={LicenseKeyAuthentication} />
              <Route path="/license" component={LicenseKeyAuthentication} />

              <Redirect from='*' to='/' />
            </Switch>
          </div>
        </div>
      </Box>
    </AppContextProvider>
  );
}

export default AppRoutes;