import { debounce, find, findIndex, first, last } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import videojs, { VideoJsPlayer } from "video.js";
import AppContext from "../../context/AppContext";
import { SearchMatch, Speaker, Transcription } from "../../types/transcription";
import { IUserPreferencePayload } from "../../types/user";
import { formatTranscriptList, getTranscriptions } from "../RedactTranscriptionTab/helper";
import RedactTranscriptionChatSimple from "../RedactTranscriptionTab/RedactTranscriptionChatSimple";
import "./RedactPreview.scss"

interface RedactPreviewProps {
  url: string,
  transcribeUrl: string,
  handleSaveUserPreferences: (payload: IUserPreferencePayload) => void
}

const RedactPreview = ({ url, transcribeUrl, handleSaveUserPreferences }: RedactPreviewProps) => {

  const playerRef = useRef<any>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { userPreferences } = useContext(AppContext);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (url && url.length > 0) {
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) return;
        const player = (playerRef.current = videojs(
          videoElement,
          videoOptions,
          () => {
            handlePlayerReady(player);
          }
        ));
      } else {
        // you can update player here [update player through props]
        const player = playerRef.current;
        player.src(videoOptions.sources);
      }
    }
  }, [url]);

  const videoOptions = {
    controls: true,
    responsive: true,
    fluid: false,
    height: 500,
    width: 500,
    sources: {
      src: url,
      type: 'video/mp4',
    }
  } as any;

  // debounced version of handleSaveUserPreferences
  const debouncedSaveUserPreferences = debounce((volume, muted) => {
    handleSaveUserPreferences({
      volume: volume,
      playerMuted: muted
    });
  }, 500);

  const handlePlayerReady = (player: VideoJsPlayer) => {
    playerRef.current = player;

    if (userPreferences.playerMuted != null)
      player.muted(userPreferences.playerMuted)

    if (userPreferences.volume != null)
      player.volume(userPreferences.volume)

    // attach the debounced function to the volumechange event
    player.on('volumechange', () => {
      if (player) {
        let volumeData = parseFloat(player.volume().toFixed(2));
        let isMuted = player.muted();
        debouncedSaveUserPreferences(volumeData, isMuted);
      }
    });

  };

  //// preview transcription starts

  //variable below is used to track changes for undo event.

  const [transcriptionChat, setTranscriptionChat] = useState<Transcription[]>([]);
  const [transcriptionSpeakers, setTranscriptionSpeakers] = useState<{ [id: string]: Speaker }>({});
  const [pages, setPages] = useState<Transcription[]>([]);
  const [triggerRefresh, setTriggerRefresh] = useState<number>(0);
  const [currentFrameTime, setCurrentFrameTime] = useState<number>(undefined);
  const [pageHeight, setPageHeight] = useState<number>(undefined);

  const [searchText, setSearchText] = useState<string>(undefined);
  const [currentSearchIndex, setCurrentSearchIndex] = useState<number>(undefined);
  const [currentSearchMatches, setCurrentSearchMatches] = useState<SearchMatch[]>([]);

  const scrollableContainerRef = useRef<HTMLElement>();
  const containerRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLElement>();
  const textCanvasRef = useRef<HTMLCanvasElement>();

  //ref is being used here to ensure frametime is being updated
  //change was made to this snippet because useframe time does not work for audio only videos.
  const setFrameTimeRef = useRef(setCurrentFrameTime);
  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.on('timeupdate', () => {
        setFrameTimeRef.current(playerRef.current.currentTime() * 1000);
      });
    }

    return () => {
      playerRef.current?.off('timeupdate');
    }
  }, [playerRef.current])

  useEffect(() => {

    getTranscriptions(transcribeUrl,
      setTranscriptionSpeakers,
      (result: Transcription[]) => setTranscriptionChat(formatTranscriptList(result)));

    updatePageHeight();

    const defaultResizeFn = window.onresize;
    window.onresize = () => {
      updatePageHeight();
      setTriggerRefresh(Math.random());
    }

    return () => {
      window.onresize = defaultResizeFn;
    }
  }, []);

  useEffect(() => {

    if (scrollableContainerRef.current) {
      const firstChild = scrollableContainerRef.current.children[0];
      if (!firstChild) {
        return;
      }
    }
  }, [scrollableContainerRef?.current])

  useEffect(() => {
    paginateTranscription();
    calculateTextDimensions();

    if (containerRef.current) {
      setTriggerRefresh(triggerRefresh + 1);
    }

  }, [containerRef.current, transcriptionChat, pageHeight, headerRef?.current?.offsetWidth]);

  useEffect(() => {
    checkSearchMatches();
  }, [currentSearchIndex]);

  useEffect(() => {
    if (currentSearchMatches.length > 0 && !currentSearchIndex) {
      setCurrentSearchIndex(0);
    }
    scrollToSearch();
  }, [currentSearchMatches, currentSearchIndex]);

  useEffect(() => {
    scrollToIndex();
  }, [currentFrameTime])

  const scrollToSearch = () => {
    if (currentSearchIndex < 0 || currentSearchIndex >= currentSearchMatches.length)
      return;

    if (scrollableContainerRef?.current && currentSearchMatches.length > 0 && searchText) {
      let item = find(transcriptionChat, (t) => t.id === currentSearchMatches[currentSearchIndex]?.transcriptionId)
      playerRef.current.currentTime(item.start / 1000);
    }
  }

  const scrollToIndex = () => {
    if (scrollableContainerRef?.current) {
      let item = transcriptionChat.find(t => currentFrameTime >= t.start && currentFrameTime < t.end);
      if (!item) return;
      scrollableContainerRef.current.scrollTo(0, item.startPos);
    }
  }

  const checkSearchMatches = () => {
    let result = transcriptionChat
      .filter(t => t.sentence?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()))
      .flatMap(t => (t.sentence?.toLocaleLowerCase().match(new RegExp(searchText?.toLocaleLowerCase(), "g")) || [])
        .map((m, i) => {
          return {
            transcriptionId: t.id,
            index: i
          }
        }));
    if (currentSearchIndex >= result.length) {
      setCurrentSearchIndex(0);
    } else if (currentSearchIndex < 0) {
      setCurrentSearchIndex(result.length - 1);
    } else {
      setCurrentSearchMatches(result);
    }
  }

  const updatePageHeight = () => {
    if (!scrollableContainerRef.current) {
      getScrollContainer();
    }

    setPageHeight(scrollableContainerRef?.current?.clientHeight)
  }

  const getScrollContainer = () => {
    if (!containerRef.current) {
      return;
    }

    let parent: HTMLElement = containerRef.current.parentElement;
    for (let i = 0; i < 5; i++) {
      if (parent) {
        if (parent.className == "preview-transcription") {
          parent.onscroll = () => {
            setTriggerRefresh(Math.random());
          };

          scrollableContainerRef.current = parent;
        }
        else {
          parent = parent.parentElement;
        }
      }
    }
  }

  const getTranscriptContentKey = (transcript: Transcription, index: number) => {
    return transcript.id + transcript.sentence + transcript.start + transcript.end + transcript.speakerId + index;
  }

  const paginateTranscription = () => {
    let rowHeight = 26;
    let rowsPerPage = Math.floor(pageHeight / rowHeight);
    let currentRowWidth = 0;
    let currentPageRows = 1;
    let currentPage = 0;
    let pages = [];

    const font = "400 17px SF Pro Text";
    const canvasCtx = textCanvasRef.current.getContext('2d');
    canvasCtx.font = font;
    const spaceSize = canvasCtx.measureText(' ').width;

    transcriptionChat.forEach((t, ti) => {
      let words = t.sentence.trim().split(' ');
      t.newPage = false;
      words.forEach((w, wi) => {

        const currentWordDim = canvasCtx.measureText(w);
        if ((currentRowWidth + currentWordDim.width) > (headerRef?.current?.clientWidth - 30)) {
          //break to next line
          currentPageRows++;
          currentRowWidth = currentWordDim.width + spaceSize;
          if (currentPageRows > rowsPerPage) {
            currentPageRows = 1;
            t.newPage = true;
            currentPage++;
          }
        } else {
          currentRowWidth += currentWordDim.width + spaceSize;
        }

      });
      t.pageIndex = currentPage;
      if (pages.length <= currentPage) {
        pages.push([t]);
      } else {
        pages[currentPage].push(t)
      }
    });

    setPages(pages);
  }

  const calculateTextDimensions = () => {

    //todo: constant values should be derived from actual style values
    const rowHeight = 20;
    const timeStampHeight = 30;
    const textHeightPadding = 12;
    const textContainerBottomMargin = 10;

    const speakerBubbleWidth = 50;
    const transcriptRowPadding = 30;
    const transcriptBubbleMargins = 30;
    const textWidthPadding = 28;
    const font = "400 17px SF Pro Text";

    const textContainerWidth = (speakerBubbleWidth + textWidthPadding + transcriptRowPadding + transcriptBubbleMargins);
    const canvasCtx = textCanvasRef.current.getContext('2d');
    canvasCtx.font = font;
    let totalHeight = 0;

    const spaceSize = canvasCtx.measureText(' ').width;
    transcriptionChat.forEach((t) => {
      const words = t.sentence.trim().split(' ');
      let currentRowWidth = 0;

      let rowCount = 1;
      words.forEach((w, wi) => {
        const currentWordDim = canvasCtx.measureText(w);
        if ((currentRowWidth + currentWordDim.width) > textContainerWidth) {
          //break to next line
          rowCount++;
          currentRowWidth = currentWordDim.width + spaceSize;
        } else {
          currentRowWidth += currentWordDim.width + spaceSize
        }
      });

      t.startPos = totalHeight;
      const itemHeight = (rowCount * rowHeight) + timeStampHeight + textHeightPadding + textContainerBottomMargin;
      totalHeight += itemHeight;
      t.endPos = totalHeight;
    });
  }

  const virtualizeList = (): { top: number, listHeight: number, list: Transcription[] } => {
    if (!containerRef.current) {
      return { top: 0, listHeight: 0, list: [] };
    }

    if (!scrollableContainerRef.current) {
      getScrollContainer();
    }

    const scrollableContainer = scrollableContainerRef.current || containerRef.current;

    if (transcriptionChat.length === 0) {
      if (scrollableContainerRef.current)
        scrollableContainerRef.current.style.display = "none";

      return {
        top: 0,
        listHeight: 0,
        list: transcriptionChat,
      }
    } else {
      if (scrollableContainerRef.current)
        scrollableContainerRef.current.style.display = "block";
    }

    const container = containerRef.current;
    const visibleHeight = scrollableContainer.offsetHeight - container.offsetTop;

    let lastVisible = last(transcriptionChat.filter((t) => t.startPos < scrollableContainer.scrollTop));
    if (!lastVisible)
      lastVisible = first(transcriptionChat);
    let startIndex = findIndex(transcriptionChat, (t) => t.id == lastVisible?.id);
    let endIndex = findIndex(transcriptionChat, (t) => t.startPos > scrollableContainer.scrollTop + visibleHeight);

    if (endIndex === -1) endIndex = transcriptionChat.length;
    endIndex = Math.min(endIndex + 1, transcriptionChat.length);

    const scrollBasedList = transcriptionChat.slice(startIndex, endIndex);

    return {
      top: lastVisible.startPos,
      listHeight: last(transcriptionChat).endPos, //+ totalHeaderHeight,
      list: scrollBasedList,
    }
  }
  const { top, listHeight, list } = useMemo(() => virtualizeList(), [triggerRefresh]);

  //// preview transcription ends

  return (
    <>
      <div className="redact-preview-container" >
        <div data-vjs-player>
          <video
            ref={videoRef}
            className="video-js"
          />
        </div>

        <div className="preview-transcription">
          <div ref={containerRef}>
            <div style={{ height: listHeight, position: "relative" }}>
              <canvas style={{ position: "absolute" }} ref={textCanvasRef} />

              <div className="vertical" style={{ position: "absolute", top: top, width: '100%', background: '#252836' }}>

                {list.map((transcript, i) => {
                  return <RedactTranscriptionChatSimple
                    key={getTranscriptContentKey(transcript, i)}
                    transcript={transcript}
                    transcriptionSpeakers={transcriptionSpeakers}
                    frameTime={currentFrameTime}
                    first={i === 0}
                    searchText={searchText}
                    searchMatch={currentSearchMatches[currentSearchIndex]}
                    videoPlayer={playerRef.current}
                  />
                })}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RedactPreview