import { ApiResponse } from "../../types/apiResponse";
import { IApi } from "../../types/apiTypes";
import { LoginRequestPayload } from "../../types/auth";
import { clearLicenseKey, saveLicenseKey } from "../functions/licenseKeyAuth";

export const login = (api: IApi, payload: LoginRequestPayload) => {
  return api.post("identity/login", payload).then((result) => {
    if (!result) {
      return;
    }
    return result;
  });
};

export const authenticate = (api: IApi, licenseKey: string) => {
  return api
    .post(`identity/license-key-login`, {
      licenseKey,
    })
    .then((result) => {
      if (result && result.data) {
        saveLicenseKey(licenseKey, result.data);
        return result.data;
      } else {
        clearLicenseKey();
        return null;
      }
    });
};